export default {
    index: {
        title: "大五人格测试",
        subtitle: "通过科学的人格测试，全面了解你的性格特质",
        tips: {
            list: [
                "本测试用于评估您的五大人格特质：开放性、责任心、外向性、宜人性和神经质。请根据您的真实感受作答。",
                "大五人格测试能帮助您了解自己的性格特点，并为个人成长、职业发展和人际关系提供参考。",
                "测试包含一系列陈述，您需要根据自己的实际情况选择最符合的选项。没有“对”或“错”的答案。",
                "请尽量按照第一反应作答，而不要过度思考，以确保测试结果最贴近您的真实性格。",
                "测试结果仅供参考，不代表对您的性格做出绝对判断，请以开放的心态对待分析结果。",
            ],
        },
        buttons: {
            whatIsBigFive: "什么是大五人格",
            bigFiveTypes: "五个维度详解",
        },
        meta: {
            title: "免费大五人格测试 - 科学的性格特质评估工具",
            description:
                "通过科学的大五人格测试，了解你在开放性、尽责性、外向性、宜人性和情绪稳定性五个维度上的特质表现。",
            keywords:
                "大五人格,性格测试,人格测试,心理测试,性格分析,Big Five,五因素人格",
        },
    },
    introduction: {
        meta: {
            title: "什么是大五人格测试？大五人格维度详细介绍",
            description:
                "大五人格测试是一种科学的人格测评工具，通过开放性、尽责性、外向性、宜人性和情绪稳定性五个维度来描述人的性格特征。",
            keywords:
                "大五人格,性格测试,人格测试,开放性,尽责性,外向性,宜人性,情绪稳定性,心理测试,性格分析",
        },
        title: "大五人格测试介绍",
        whatIsBigFive: {
            title: "什么是大五人格",
            content:
                "大五人格（Big Five）是当代人格心理学研究中最具影响力的人格理论之一。它通过五个基本维度（OCEAN 模型）来描述人的性格特征，这些维度是通过大量研究和统计分析得出的，具有很强的科学性和跨文化适用性。",
        },
        dimensions: {
            title: "五个维度",
            openness: {
                title: "开放性 (O)",
                description: "反映个体对新经验、新想法的接受程度",
                high: "富有想象力、创造性强、喜欢尝试新事物、思维开放",
                low: "实际、传统、偏好熟悉的事物、不喜欢改变",
            },
            conscientiousness: {
                title: "尽责性 (C)",
                description: "反映个体的自律程度和目标导向性",
                high: "有条理、负责任、自律性强、注重计划",
                low: "随性、灵活、不拘小节、较少计划",
            },
            extraversion: {
                title: "外向性 (E)",
                description: "反映个体在人际交往中的活力和社交倾向",
                high: "外向、善于社交、精力充沛、乐观开朗",
                low: "内向、独处、安静、深思熟虑",
            },
            agreeableness: {
                title: "宜人性 (A)",
                description: "反映个体在人际关系中的合作和同理心倾向",
                high: "友善、富有同情心、乐于助人、重视他人感受",
                low: "直率、独立、重视逻辑、关注任务",
            },
            neuroticism: {
                title: "情绪稳定性 (N)",
                description: "反映个体的情绪稳定程度和压力应对能力",
                high: "容易紧张、情绪波动大、对压力敏感",
                low: "情绪稳定、冷静、抗压能力强",
            },
        },
        applications: {
            title: "应用领域",
            items: [
                "职业规划：帮助个人找到适合自己的职业，提升工作满意度和职业发展方向。",
                "团队管理：用于优化团队组合，提升团队成员之间的协作效率和沟通能力。",
                "心理健康评估：帮助识别个体的情绪稳定性，预测压力管理能力，为心理咨询提供参考。",
                "人际关系：通过了解自己和他人的人格特质，改善社交互动，提高沟通质量。",
                "教育与学习：帮助教师了解学生的学习风格，从而调整教学方法，提高学习效率。",
                "领导力发展：帮助管理者理解自身及团队成员的个性特质，调整领导方式，提高团队绩效。",
                "个性发展与自我认知：帮助个人了解自身优势与劣势，制定个性化的成长策略。",
            ],
        },
        notes: {
            title: "注意事项",
            items: [
                "真实回答：尽量根据自己的真实想法作答，而非迎合社会期望，以确保测试结果的准确性。",
                "避免过度解读：测试结果仅反映个性的一部分，不应作为唯一的判断标准。",
                "定期评估：个性可能会随着经历和成长发生变化，定期测试可以帮助更好地了解自身变化。",
                "文化影响：不同文化背景可能会影响个性测试的结果，不同地区的人可能会有不同的评分倾向。",
                "结合实际应用：测试结果应与实际情况结合使用，而非盲目依赖，以便更有效地指导个人发展和决策。",
            ],
        },
    },
    types: {
        meta: {
            title: "大五人格维度详解 - 全面了解五个性格维度",
            description:
                "详细解读大五人格测试的五个维度：开放性、尽责性、外向性、宜人性和情绪稳定性，帮助你更好地理解自己的性格特征。",
            keywords:
                "大五人格,性格维度,开放性,尽责性,外向性,宜人性,情绪稳定性,性格特征,心理测试",
        },
        title: "大五人格维度详解",
        dimensions: {
            openness: {
                title: "开放性 (O)",
                description: "反映一个人对新经验的接受程度和创造性思维的倾向",
                highTitle: "高开放性特征",
                highTraits: [
                    "富有想象力和创造力",
                    "对艺术和美感兴趣",
                    "喜欢尝试新事物",
                    "思维灵活开放",
                    "对抽象概念感兴趣",
                    "享受学习新知识",
                ],
                lowTitle: "低开放性特征",
                lowTraits: [
                    "倾向于实际和具体",
                    "喜欢熟悉的事物",
                    "偏好传统方式",
                    "注重实践经验",
                    "不太关注艺术",
                    "对改变持谨慎态度",
                ],
            },
            conscientiousness: {
                title: "尽责性 (C)",
                description: "反映一个人的组织能力、责任心和目标导向性",
                highTitle: "高尽责性特征",
                highTraits: [
                    "组织能力强",
                    "注重计划和细节",
                    "可靠负责",
                    "自律性强",
                    "追求完美",
                    "坚持目标",
                ],
                lowTitle: "低尽责性特征",
                lowTraits: [
                    "较为随性灵活",
                    "不拘小节",
                    "适应力强",
                    "自发性行为多",
                    "不太注重规划",
                    "轻松自在",
                ],
            },
            extraversion: {
                title: "外向性 (E)",
                description: "反映一个人在社交互动中的能量和热情程度",
                highTitle: "高外向性特征",
                highTraits: [
                    "热情开朗",
                    "善于社交",
                    "充满活力",
                    "寻求刺激",
                    "表达欲强",
                    "乐观积极",
                ],
                lowTitle: "低外向性特征",
                lowTraits: [
                    "安静内敛",
                    "独处自在",
                    "深思熟虑",
                    "注重隐私",
                    "能量内敛",
                    "倾听者",
                ],
            },
            agreeableness: {
                title: "宜人性 (A)",
                description: "反映一个人在人际关系中的友善和合作程度",
                highTitle: "高宜人性特征",
                highTraits: [
                    "富有同理心",
                    "乐于助人",
                    "重视和谐",
                    "信任他人",
                    "谦逊友善",
                    "善解人意",
                ],
                lowTitle: "低宜人性特征",
                lowTraits: [
                    "直率坦诚",
                    "独立自主",
                    "重视逻辑",
                    "竞争意识强",
                    "注重效率",
                    "决策果断",
                ],
            },
            neuroticism: {
                title: "情绪稳定性 (N)",
                description: "反映一个人的情绪反应模式和压力应对能力",
                highTitle: "高神经质特征",
                highTraits: [
                    "情绪敏感",
                    "容易焦虑",
                    "对压力敏感",
                    "自我意识强",
                    "情绪波动大",
                    "担忧倾向",
                ],
                lowTitle: "低神经质特征",
                lowTraits: [
                    "情绪稳定",
                    "从容冷静",
                    "抗压能力强",
                    "乐观豁达",
                    "适应力强",
                    "自信坦然",
                ],
            },
        },
    },
    test: {
        meta: {
            title: "大五人格测试 - 专业的性格特质评估",
            description:
                "通过科学的大五人格测试，了解你的性格特质。完成测试后获取详细的个性分析报告。",
            keywords:
                "大五人格测试,性格测试,人格测试,心理测试,性格分析,Big Five,五因素人格",
        },
        title: "大五人格测试",
        buttons: {
            viewTypes: "五个维度详解",
        },
        scale: {
            stronglyDisagree: "非常不同意",
            stronglyAgree: "非常同意",
        },
        standardOptions: [
            { value: 5, text: "非常同意" },
            { value: 4, text: "比较同意" },
            { value: 3, text: "一般" },
            { value: 2, text: "比较不同意" },
            { value: 1, text: "非常不同意" },
        ],
        questions: [
            // 开放性 (O) - 9题
            {
                question: "我是一个喜欢尝试新事物的人",
                dimension: "O",
            },
            {
                question: "我对艺术、音乐或文学作品产生强烈的情感共鸣",
                dimension: "O",
            },
            {
                question: "我倾向于坚持已经熟悉的事物",
                dimension: "O",
                reverse: true,
            },
            {
                question: "我喜欢思考抽象的概念和理论",
                dimension: "O",
            },
            {
                question: "我对学习新知识和技能充满热情",
                dimension: "O",
            },
            {
                question: "我喜欢探索新的思维方式",
                dimension: "O",
            },
            {
                question: "我对哲学讨论感兴趣",
                dimension: "O",
            },
            {
                question: "我对艺术创作充满热情",
                dimension: "O",
            },
            {
                question: "我喜欢想象力丰富的事物",
                dimension: "O",
            },
            // 尽责性 (C) - 9题
            {
                question: "我是一个做事有条理的人",
                dimension: "C",
            },
            {
                question: "我喜欢制定详细的计划并严格执行",
                dimension: "C",
            },
            {
                question: "我经常拖延重要的任务",
                dimension: "C",
                reverse: true,
            },
            {
                question: "我是一个注重细节的人",
                dimension: "C",
            },
            {
                question: "我做事常常缺乏计划性",
                dimension: "C",
                reverse: true,
            },
            {
                question: "我会为重要事情提前做准备",
                dimension: "C",
            },
            {
                question: "我总是把事情做到最好",
                dimension: "C",
            },
            {
                question: "我的工作态度认真负责",
                dimension: "C",
            },
            {
                question: "我经常忘记把东西放回原处",
                dimension: "C",
                reverse: true,
            },
            // 外向性 (E) - 8题
            {
                question: "我喜欢成为人群的焦点",
                dimension: "E",
            },
            {
                question: "我更喜欢独处而不是和他人在一起",
                dimension: "E",
                reverse: true,
            },
            {
                question: "我容易与陌生人交谈",
                dimension: "E",
            },
            {
                question: "在团体中我倾向于保持沉默",
                dimension: "E",
                reverse: true,
            },
            {
                question: "我喜欢热闹的环境",
                dimension: "E",
            },
            {
                question: "我是一个活力充沛的人",
                dimension: "E",
            },
            {
                question: "我喜欢参加社交活动",
                dimension: "E",
            },
            {
                question: "我善于带动气氛",
                dimension: "E",
            },
            // 宜人性 (A) - 9题
            {
                question: "我经常关心他人的感受",
                dimension: "A",
            },
            {
                question: "我有时会对他人的需求漠不关心",
                dimension: "A",
                reverse: true,
            },
            {
                question: "我愿意花时间帮助他人",
                dimension: "A",
            },
            {
                question: "我善于理解他人的立场",
                dimension: "A",
            },
            {
                question: "我倾向于相信他人的善意",
                dimension: "A",
            },
            {
                question: "我容易与人产生共情",
                dimension: "A",
            },
            {
                question: "我喜欢与人合作",
                dimension: "A",
            },
            {
                question: "我会考虑他人的利益",
                dimension: "A",
            },
            {
                question: "我有时会说一些伤害他人的话",
                dimension: "A",
                reverse: true,
            },
            // 神经质 (N) - 9题
            {
                question: "我经常感到焦虑或担忧",
                dimension: "N",
            },
            {
                question: "我很少为事情烦恼",
                dimension: "N",
                reverse: true,
            },
            {
                question: "我的情绪容易受到环境的影响",
                dimension: "N",
            },
            {
                question: "我容易受到批评的影响",
                dimension: "N",
            },
            {
                question: "我经常感到压力很大",
                dimension: "N",
            },
            {
                question: "我容易产生负面情绪",
                dimension: "N",
            },
            {
                question: "我对未来感到担忧",
                dimension: "N",
            },
            {
                question: "我能很好地控制自己的情绪",
                dimension: "N",
                reverse: true,
            },
            {
                question: "压力下我也能保持冷静",
                dimension: "N",
                reverse: true,
            },
        ],
    },
    result: {
        meta: {
            title: "你的大五人格测试结果",
            description: "查看你的大五人格测试详细结果和分析",
            keywords: "大五人格结果,性格测试结果,性格分析,特质分析",
        },
        title: "你的大五人格测试结果",
        subtitle: "探索你在五个关键维度上的性格特质",
        dimensions: {
            openness: {
                title: "开放性",
                description: "反映你对新体验和新想法的接受程度",
                highTraits: [
                    "富有创造力和想象力",
                    "对知识充满好奇",
                    "欣赏艺术",
                    "愿意尝试新事物",
                    "擅长抽象思维",
                ],
                lowTraits: [
                    "务实稳重",
                    "传统保守",
                    "喜欢常规",
                    "注重具体事实",
                    "偏好熟悉的事物",
                ],
            },
            conscientiousness: {
                title: "尽责性",
                description: "展示你的组织能力和责任心水平",
                highTraits: [
                    "组织能力强",
                    "负责任",
                    "注重细节",
                    "目标导向",
                    "自律性强",
                ],
                lowTraits: [
                    "灵活随性",
                    "对目标态度轻松",
                    "适应力强",
                    "随和",
                    "活在当下",
                ],
            },
            extraversion: {
                title: "外向性",
                description: "表明你在社交场合的活跃度和能量水平",
                highTraits: [
                    "外向好社交",
                    "精力充沛",
                    "善于表达",
                    "行动导向",
                    "热情洋溢",
                ],
                lowTraits: [
                    "安静内敛",
                    "深思熟虑",
                    "独立自主",
                    "喜欢小群体",
                    "享受独处",
                ],
            },
            agreeableness: {
                title: "宜人性",
                description: "反映你与他人建立关系的方式",
                highTraits: [
                    "富有同理心",
                    "乐于合作",
                    "为他人着想",
                    "信任他人",
                    "乐于助人",
                ],
                lowTraits: [
                    "直接坦率",
                    "富有竞争心",
                    "持怀疑态度",
                    "注重任务",
                    "思维独立",
                ],
            },
            neuroticism: {
                title: "情绪稳定性",
                description: "显示你体验负面情绪的倾向",
                highTraits: [
                    "情绪敏感",
                    "容易感到压力",
                    "经常担忧",
                    "自我意识强",
                    "情绪波动大",
                ],
                lowTraits: [
                    "情绪稳定",
                    "从容应对压力",
                    "心理韧性强",
                    "自信",
                    "情绪平和",
                ],
            },
        },
        traits: {
            title: "主要特征",
        },
    },
};
