export default {
    index: {
        meta: {
            title: "16PF 人格测试 - 全面了解你的个性特征",
            description:
                "通过 16PF 人格测试深入了解你的性格特征，发现你的独特个性，帮助你更好地认识自己",
            keywords: "16PF 测试,人格测试,性格分析,心理测试,卡特尔16种人格因素",
        },
        title: "16PF 人格测试",
        subtitle: "发现你独特的个性特征",
        tips: {
            list: [
                "欢迎参加 16PF 人格测试！通过本测试，您将了解自己在16种人格特质上的表现，帮助您全面了解自己的个性。",
                "16PF 测试通过量化评估个体的人格特质，提供一个详细的个性剖析，帮助您更好地理解自己的优势与弱点。",
                "请根据您的真实感受和行为习惯作答，测试结果将帮助您在职业生涯中做出更合适的选择。",
                "16PF 测试是一个科学且精确的工具，用于揭示个体的内在特质，并提供个性化的建议与指导。",
            ],
        },
        buttons: {
            what16PF: "了解 16PF 测试",
        },
    },
    introduction: {
        meta: {
            title: "什么是 16PF 人格测试？- 全面解读",
            description:
                "深入了解 16PF 人格测试的理论基础、发展历史、应用价值和16个人格因素的详细解读",
            keywords: "16PF 介绍,人格因素,卡特尔理论,心理测试解读,性格分析",
        },
        title: "关于 16PF 人格测试",
        sections: [
            {
                title: "什么是 16PF 测试？",
                content: [
                    "16PF （Sixteen Personality Factor Questionnaire）是由著名心理学家雷蒙德·卡特尔（Raymond Cattell）博士开发的一套科学的人格测试体系。",
                    "这是一个基于因素分析方法，通过测量16个基本人格特质维度来全面评估个人性格特征的专业心理测试工具。",
                    "经过半个多世纪的研究和实践， 16PF 已成为全球最受认可的人格测评工具之一。",
                ],
            },
            {
                title: "16个人格因素",
                content: [
                    "乐群性（A）：衡量一个人在人际交往中的态度和方式",
                    "聪慧性（B）：反映一个人的抽象思维能力",
                    "稳定性（C）：测量情绪稳定性和应对压力的能力",
                    "恃强性（E）：表现自我主张和支配倾向的程度",
                    "兴奋性（F）：反映活力、热情和自发性的程度",
                    "有恒性（G）：测量责任心和遵守规范的程度",
                    "敢为性（H）：衡量社交场合中的大胆程度",
                    "敏感性（I）：反映感性与理性的倾向",
                    "怀疑性（L）：表现对他人的信任程度",
                    "幻想性（M）：衡量实用性与创造性的倾向",
                    "世故性（N）：反映社交策略的运用能力",
                    "忧虑性（O）：测量自信与焦虑的程度",
                    "实验性（Q1）：表现对新事物的开放程度",
                    "独立性（Q2）：衡量群体依赖与自主的倾向",
                    "自律性（Q3）：反映自我控制的程度",
                    "紧张性（Q4）：测量压力和焦虑的水平",
                ],
            },
            {
                title: "应用价值",
                content: [
                    "职业选择与职业规划：帮助个体了解自身的个性特征，从而做出适合自己的职业选择与规划。",
                    "心理健康评估：通过测试，评估个体的心理健康状况，为心理咨询和治疗提供依据。",
                    "人才招聘与管理：在企业招聘和人才管理中，帮助HR了解候选人的个性特点，提升招聘匹配度。",
                    "团队建设与沟通：帮助团队成员了解彼此的人格特征，提高团队协作与沟通效率。",
                    "个人发展与自我提升：通过了解自身的个性特征，帮助个人识别自己的优势与改进空间，促进个人成长。",
                ],
            },
            {
                title: "注意事项",
                content: [
                    "真实作答：请根据自己的实际感受作答，避免迎合测试的期望，确保测试结果的准确性。",
                    "理解测试的局限性： 16PF 测试虽然科学且全面，但仅为人格评估的一部分，不能单凭测试结果做出重大决策。",
                    "综合解读：测试结果应结合其他心理测评工具和专家意见进行解读，而非仅凭结果来判断个体。",
                    "文化差异：文化和背景的不同可能影响个体的人格表现，测试结果需考虑到文化背景的差异。",
                    "适用范围： 16PF 测试适用于成人群体，部分内容可能不适用于青少年或特定群体，因此在使用时要有所选择。",
                ],
            },
        ],
    },
    test: {
        meta: {
            title: "16PF 人格测试 - 开始测试",
            description: "开始进行 16PF 人格测试，了解你的性格特征",
            keywords: "16PF 测试,人格测试,在线测试,心理测评",
        },
        title: "16PF 人格测试",
        defaultOptions: [
            { value: "a", text: "是" },
            { value: "b", text: "不确定" },
            { value: "c", text: "否" },
        ],
        questions: [
            {
                id: 1,
                text: "我觉得自己是一个充满活力的人。",
            },
            {
                id: 2,
                text: "我喜欢仔细思考问题，而不是马上做决定。",
            },
            {
                id: 3,
                text: "如果邻居对我不友好，那一定是他们的错。",
            },
            {
                id: 4,
                text: "我能很容易地让一个沉闷的聚会变得热闹起来。",
            },
            {
                id: 5,
                text: "我经常感到自己需要寻求朋友的建议。",
            },
            {
                id: 6,
                text: "我倾向于独立完成任务，很少寻求他人帮助。",
            },
            {
                id: 7,
                text: "在社交场合中，我通常会等别人先来和我说话。",
            },
            {
                id: 8,
                text: "我喜欢按计划行事，而不是随性而为。",
            },
            {
                id: 9,
                text: "我经常为一些小事感到烦恼。",
            },
            {
                id: 10,
                text: "我喜欢尝试新的、不同的事物。",
            },
            {
                id: 11,
                text: "我做事情总是很有条理。",
            },
            {
                id: 12,
                text: "我经常感到孤单，即使在人群中也是如此。",
            },
            {
                id: 13,
                text: "我喜欢和别人一起工作而不是独自工作。",
            },
            {
                id: 14,
                text: "我倾向于对人保持警惕。",
            },
            {
                id: 15,
                text: "我很容易理解抽象的概念。",
            },
            {
                id: 16,
                text: "我喜欢按自己的方式做事，不喜欢被别人指导。",
            },
            {
                id: 17,
                text: "我很少对事情感到后悔。",
            },
            {
                id: 18,
                text: "在团队中，我通常会主动承担领导角色。",
            },
            {
                id: 19,
                text: "我经常需要时间独处来整理思绪。",
            },
            {
                id: 20,
                text: "我倾向于相信他人的好意。",
            },
            {
                id: 21,
                text: "我喜欢有规律的生活。",
            },
            {
                id: 22,
                text: "我经常觉得自己充满创意和想象力。",
            },
            {
                id: 23,
                text: "在社交场合，我通常是最活跃的人之一。",
            },
            {
                id: 24,
                text: "我很容易被他人的情绪影响。",
            },
            {
                id: 25,
                text: "我喜欢解决复杂的问题。",
            },
            {
                id: 26,
                text: "我很少对自己的决定产生怀疑。",
            },
            {
                id: 27,
                text: "我喜欢井井有条的环境。",
            },
            {
                id: 28,
                text: "我经常感到压力很大。",
            },
            {
                id: 29,
                text: "我喜欢尝试新的工作方法。",
            },
            {
                id: 30,
                text: "我很容易与陌生人交谈。",
            },
            {
                id: 31,
                text: "我做事情时很注重细节。",
            },
            {
                id: 32,
                text: "我经常担心未来会发生什么。",
            },
            {
                id: 33,
                text: "我喜欢参加社交活动。",
            },
            {
                id: 34,
                text: "我倾向于独立做决定。",
            },
            {
                id: 35,
                text: "我很容易被批评所困扰。",
            },
            {
                id: 36,
                text: "我喜欢制定详细的计划。",
            },
            {
                id: 37,
                text: "我经常对人产生怀疑。",
            },
            {
                id: 38,
                text: "我喜欢冒险和刺激。",
            },
            {
                id: 39,
                text: "我很容易对他人产生同理心。",
            },
            {
                id: 40,
                text: "我喜欢按规则办事。",
            },
            {
                id: 41,
                text: "我经常主动帮助他人。",
            },
            {
                id: 42,
                text: "我喜欢按部就班地完成工作。",
            },
            {
                id: 43,
                text: "我很容易对他人的痛苦感同身受。",
            },
            {
                id: 44,
                text: "我倾向于独自承担责任。",
            },
            {
                id: 45,
                text: "我经常感到精力充沛。",
            },
            {
                id: 46,
                text: "我喜欢探索新的思维方式。",
            },
            {
                id: 47,
                text: "在团队中我更愿意当追随者而不是领导者。",
            },
            {
                id: 48,
                text: "我很少对未来感到担忧。",
            },
            {
                id: 49,
                text: "我喜欢与不同类型的人交往。",
            },
            {
                id: 50,
                text: "我做事情时常常三思而行。",
            },
            {
                id: 51,
                text: "我很容易相信他人。",
            },
            {
                id: 52,
                text: "我喜欢有条理的生活方式。",
            },
            {
                id: 53,
                text: "我经常感到内心平静。",
            },
            {
                id: 54,
                text: "我喜欢尝试新事物。",
            },
            {
                id: 55,
                text: "我倾向于保持谨慎的态度。",
            },
            {
                id: 56,
                text: "我很容易适应环境的变化。",
            },
            {
                id: 57,
                text: "我喜欢深入思考问题。",
            },
            {
                id: 58,
                text: "我经常保持乐观的心态。",
            },
            {
                id: 59,
                text: "我喜欢按计划行事。",
            },
            {
                id: 60,
                text: "我善于处理紧急情况。",
            },
            {
                id: 61,
                text: "我经常关注细节。",
            },
            {
                id: 62,
                text: "我容易对他人产生同情心。",
            },
            {
                id: 63,
                text: "我喜欢独立思考问题。",
            },
            {
                id: 64,
                text: "我经常保持警惕。",
            },
            {
                id: 65,
                text: "我善于控制自己的情绪。",
            },
            {
                id: 66,
                text: "我喜欢追求完美。",
            },
            {
                id: 67,
                text: "我经常感到紧张不安。",
            },
            {
                id: 68,
                text: "我喜欢富有挑战性的工作。",
            },
            {
                id: 69,
                text: "我倾向于相信直觉。",
            },
            {
                id: 70,
                text: "我善于与人合作。",
            },
            {
                id: 71,
                text: "我喜欢按规则办事。",
            },
            {
                id: 72,
                text: "我经常感到充满活力。",
            },
            {
                id: 73,
                text: "我善于解决复杂问题。",
            },
            {
                id: 74,
                text: "我容易对他人敞开心扉。",
            },
            {
                id: 75,
                text: "我喜欢有序的环境。",
            },
            {
                id: 76,
                text: "我经常保持冷静。",
            },
            {
                id: 77,
                text: "我喜欢创新的想法。",
            },
            {
                id: 78,
                text: "我倾向于谨慎行事。",
            },
            {
                id: 79,
                text: "我善于调节自己的情绪。",
            },
            {
                id: 80,
                text: "我喜欢追求卓越。",
            },
            {
                id: 81,
                text: "我喜欢与人深入交谈。",
            },
            {
                id: 82,
                text: "我做事情时很少拖延。",
            },
            {
                id: 83,
                text: "我容易被周围的环境影响。",
            },
            {
                id: 84,
                text: "我喜欢探索未知的领域。",
            },
            {
                id: 85,
                text: "我经常主动承担责任。",
            },
            {
                id: 86,
                text: "我很少对自己的决定感到后悔。",
            },
            {
                id: 87,
                text: "我喜欢有规律的生活节奏。",
            },
            {
                id: 88,
                text: "我善于处理人际关系。",
            },
            {
                id: 89,
                text: "我经常保持专注。",
            },
            {
                id: 90,
                text: "我喜欢帮助他人解决问题。",
            },
            {
                id: 91,
                text: "我做事情时很有耐心。",
            },
            {
                id: 92,
                text: "我容易接受新观点。",
            },
            {
                id: 93,
                text: "我喜欢独立完成任务。",
            },
            {
                id: 94,
                text: "我经常保持警觉。",
            },
            {
                id: 95,
                text: "我善于控制自己的冲动。",
            },
            {
                id: 96,
                text: "我喜欢追求高标准。",
            },
            {
                id: 97,
                text: "我经常感到焦虑。",
            },
            {
                id: 98,
                text: "我喜欢富有创意的工作。",
            },
            {
                id: 99,
                text: "我倾向于相信第一印象。",
            },
            {
                id: 100,
                text: "我善于团队合作。",
            },
            {
                id: 101,
                text: "我严格遵守规则。",
            },
            {
                id: 102,
                text: "我经常感到精力充沛。",
            },
            {
                id: 103,
                text: "我善于分析复杂情况。",
            },
            {
                id: 104,
                text: "我容易与人建立信任关系。",
            },
            {
                id: 105,
                text: "我喜欢整洁的环境。",
            },
            {
                id: 106,
                text: "我很少感到紧张。",
            },
            {
                id: 107,
                text: "我喜欢尝试新方法。",
            },
            {
                id: 108,
                text: "我做决定时很谨慎。",
            },
            {
                id: 109,
                text: "我善于调节自己的心情。",
            },
            {
                id: 110,
                text: "我追求完美的表现。",
            },
            {
                id: 111,
                text: "我善于倾听他人。",
            },
            {
                id: 112,
                text: "我做事有条理。",
            },
            {
                id: 113,
                text: "我容易受到情绪影响。",
            },
            {
                id: 114,
                text: "我喜欢接受新挑战。",
            },
            {
                id: 115,
                text: "我经常表现出领导力。",
            },
            {
                id: 116,
                text: "我对自己的判断很有信心。",
            },
            {
                id: 117,
                text: "我喜欢按部就班。",
            },
            {
                id: 118,
                text: "我善于处理人际冲突。",
            },
            {
                id: 119,
                text: "我做事专心致志。",
            },
            {
                id: 120,
                text: "我乐于助人。",
            },
            {
                id: 121,
                text: "我做事情时很有条理。",
            },
            {
                id: 122,
                text: "我经常感到内心平静。",
            },
            {
                id: 123,
                text: "我喜欢尝试新事物。",
            },
            {
                id: 124,
                text: "我做事情时很谨慎。",
            },
            {
                id: 125,
                text: "我容易适应环境变化。",
            },
            {
                id: 126,
                text: "我喜欢思考深层问题。",
            },
            {
                id: 127,
                text: "我经常保持乐观。",
            },
            {
                id: 128,
                text: "我喜欢按计划行事。",
            },
            {
                id: 129,
                text: "我善于处理突发情况。",
            },
            {
                id: 130,
                text: "我注重细节。",
            },
            {
                id: 131,
                text: "我容易理解他人感受。",
            },
            {
                id: 132,
                text: "我喜欢独立思考。",
            },
            {
                id: 133,
                text: "我经常保持警惕。",
            },
            {
                id: 134,
                text: "我善于控制情绪。",
            },
            {
                id: 135,
                text: "我追求完美主义。",
            },
            {
                id: 136,
                text: "我经常感到不安。",
            },
            {
                id: 137,
                text: "我喜欢具有挑战性的工作。",
            },
            {
                id: 138,
                text: "我相信直觉判断。",
            },
            {
                id: 139,
                text: "我善于与人合作。",
            },
            {
                id: 140,
                text: "我遵守规则。",
            },
            {
                id: 141,
                text: "我精力充沛。",
            },
            {
                id: 142,
                text: "我善于解决问题。",
            },
            {
                id: 143,
                text: "我容易与人交心。",
            },
            {
                id: 144,
                text: "我喜欢整洁有序。",
            },
            {
                id: 145,
                text: "我保持冷静。",
            },
            {
                id: 146,
                text: "我喜欢创新。",
            },
            {
                id: 147,
                text: "我行事谨慎。",
            },
            {
                id: 148,
                text: "我情绪稳定。",
            },
            {
                id: 149,
                text: "我追求卓越。",
            },
            {
                id: 150,
                text: "我善于沟通。",
            },
            {
                id: 151,
                text: "我做事有计划。",
            },
            {
                id: 152,
                text: "我容易受环境影响。",
            },
            {
                id: 153,
                text: "我喜欢探索。",
            },
            {
                id: 154,
                text: "我主动承担责任。",
            },
            {
                id: 155,
                text: "我很少后悔。",
            },
            {
                id: 156,
                text: "我生活有规律。",
            },
            {
                id: 157,
                text: "我善于处理关系。",
            },
            {
                id: 158,
                text: "我注意力集中。",
            },
            {
                id: 159,
                text: "我乐于助人。",
            },
            {
                id: 160,
                text: "我有耐心。",
            },
            {
                id: 161,
                text: "我容易接受新想法。",
            },
            {
                id: 162,
                text: "我喜欢独立工作。",
            },
            {
                id: 163,
                text: "我保持警觉性。",
            },
            {
                id: 164,
                text: "我能控制冲动。",
            },
            {
                id: 165,
                text: "我追求高质量。",
            },
            {
                id: 166,
                text: "我很少感到焦虑。",
            },
            {
                id: 167,
                text: "我富有创造力。",
            },
            {
                id: 168,
                text: "我相信自己的判断。",
            },
            {
                id: 169,
                text: "我善于团队协作。",
            },
            {
                id: 170,
                text: "我遵守纪律。",
            },
            {
                id: 171,
                text: "我充满活力。",
            },
            {
                id: 172,
                text: "我善于分析。",
            },
            {
                id: 173,
                text: "我容易建立信任。",
            },
            {
                id: 174,
                text: "我保持环境整洁。",
            },
            {
                id: 175,
                text: "我心态平和。",
            },
            {
                id: 176,
                text: "我勇于创新。",
            },
            {
                id: 177,
                text: "我深思熟虑。",
            },
            {
                id: 178,
                text: "我情绪稳定。",
            },
            {
                id: 179,
                text: "我追求进步。",
            },
            {
                id: 180,
                text: "我善于表达。",
            },
            {
                id: 181,
                text: "我做事有序。",
            },
            {
                id: 182,
                text: "我适应力强。",
            },
            {
                id: 183,
                text: "我勇于探索。",
            },
            {
                id: 184,
                text: "我负责任。",
            },
            {
                id: 185,
                text: "我处事冷静。",
            },
        ],
    },
    result: {
        meta: {
            title: "16PF 测试结果 - 详细分析",
            description: "查看你的 16PF 人格测试结果和详细分析",
            keywords: "16PF 结果,性格分析,人格特征,职业建议",
        },
        title: "测试结果分析",
        note: "以上结果仅供参考，具体性格特征需要结合实际情况综合分析。",
        summary: {
            title: "总体特征",
            description:
                "基于你的答题情况，我们对你的性格特征进行了全面分析。以下是16个维度的具体得分和解读：",
        },
        factors: {
            A: {
                name: "乐群性",
                high: "热情开放，容易相处",
                low: "保守含蓄，独来独往",
            },
            B: {
                name: "聪慧性",
                high: "思维敏捷，善于抽象",
                low: "思维具体，较为实际",
            },
            C: {
                name: "稳定性",
                high: "情绪稳定，应对自如",
                low: "易受情绪影响，较为敏感",
            },
            E: {
                name: "恃强性",
                high: "独立自主，有主见",
                low: "谦逊随和，易于合作",
            },
            F: {
                name: "兴奋性",
                high: "活泼热情，富有朝气",
                low: "严肃谨慎，深思熟虑",
            },
            G: {
                name: "有恒性",
                high: "负责任，重规则",
                low: "灵活变通，不拘小节",
            },
            H: {
                name: "敢为性",
                high: "大胆自信，善于社交",
                low: "谨慎含蓄，不爱冒险",
            },
            I: {
                name: "敏感性",
                high: "感性细腻，富同理心",
                low: "理性客观，实事求是",
            },
            L: {
                name: "怀疑性",
                high: "谨慎多疑，有戒心",
                low: "信任他人，乐观开放",
            },
            M: {
                name: "幻想性",
                high: "富想象力，有创意",
                low: "务实稳重，重实践",
            },
            N: {
                name: "世故性",
                high: "圆滑老练，善交际",
                low: "率真直接，不善伪装",
            },
            O: {
                name: "忧虑性",
                high: "谨慎担忧，自我反省",
                low: "自信乐观，少有顾虑",
            },
            Q1: {
                name: "实验性",
                high: "创新开放，勇于尝试",
                low: "保守传统，循规蹈矩",
            },
            Q2: {
                name: "独立性",
                high: "独立自主，自给自足",
                low: "随和依赖，重视群体",
            },
            Q3: {
                name: "自律性",
                high: "自律严谨，追求完美",
                low: "随性自然，不拘小节",
            },
            Q4: {
                name: "紧张性",
                high: "紧张焦虑，精力充沛",
                low: "放松平和，安于现状",
            },
        },
        analysis: {
            title: "性格特征分析",
            sections: {
                personality: {
                    title: "核心性格特征",
                    content: "根据测试结果，你的性格特征呈现出以下特点：",
                },
                strengths: {
                    title: "个人优势",
                    content: "你的主要优势包括：",
                },
                weaknesses: {
                    title: "发展建议",
                    content: "以下方面可以考虑进一步提升：",
                },
            },
            format: {
                highFactors: "你在{factors}等方面表现突出。",
                lowFactors: "在{factors}等方面有待提升。",
                noStrengths: "暂无明显优势特征",
                noWeaknesses: "暂无明显需要改进的方面",
                weaknessFormat: "提升{factor}方面的表现",
                delimiter: "、",
                noPersonality: "暂无明显的性格特征",
            },
        },
        suggestions: {
            title: "个性化建议",
            career: {
                title: "职业发展建议",
                content: "基于你的性格特征，以下职业方向较为适合：",
                empty: "暂无职业发展建议",
                A: "乐群性高的你适合从事需要与人打交道的工作，如：销售、教师、人力资源等",
                B: "聪慧性高的你适合从事需要分析思考的工作，如：研究、策划、咨询等",
                C: "稳定性高的你适合从事需要承受压力的工作，如：管理、危机处理、医疗等",
                E: "恃强性高的你适合从事需要决策和领导的工作，如：企业管理、项目负责人等",
                F: "兴奋性高的你适合从事富有创造性和活力的工作，如：市场营销、公关、艺术等",
                G: "有恒性高的你适合从事需要严谨和规范的工作，如：财务、法律、质量控制等",
                H: "敢为性高的你适合从事需要冒险和创新的工作，如：创业、投资、产品开发等",
                I: "敏感性高的你适合从事需要细腻感受的工作，如：艺术创作、心理咨询、设计等",
                L: "怀疑性高的你适合从事需要审慎和分析的工作，如：审计、调查、评估等",
                M: "幻想性高的你适合从事需要创意和想象的工作，如：广告创意、文学创作等",
                N: "世故性高的你适合从事需要社交技巧的工作，如：外交、谈判、公共关系等",
                O: "忧虑性高的你适合从事需要细致和谨慎的工作，如：研究、编辑、质检等",
                Q1: "实验性高的你适合从事需要创新和改革的工作，如：研发、创新管理等",
                Q2: "独立性高的你适合从事需要独立思考的工作，如：学术研究、独立咨询等",
                Q3: "自律性高的你适合从事需要高度自律的工作，如：项目管理、质量控制等",
                Q4: "紧张性高的你适合从事富有挑战性的工作，如：竞争性行业、创业等",
            },
            relationship: {
                title: "人际关系建议",
                content: "在人际交往中，你可以：",
                empty: "暂无人际关系建议",
                A: "你善于社交，建议多参与团体活动，发挥你的人际优势",
                B: "你思维敏捷，可以在团队中担任智囊角色",
                C: "你情绪稳定，可以在团队中起到稳定军心的作用",
                E: "你领导力强，可以在团队中承担更多责任",
                F: "你活泼开朗，可以在社交场合担任活跃气氛的角色",
                G: "你重规则，可以在团队中担任监督和规范的角色",
                H: "你善于表达，可以主动发起对话，带动团队氛围",
                I: "你敏感细腻，可以在团队中担任情感沟通的桥梁",
                L: "你善于观察，可以帮助团队规避风险",
                M: "你富有想象力，可以为团队提供创新思路",
                N: "你善于交际，可以担任团队对外沟通的角色",
                O: "你谨慎细致，可以帮助团队完善细节",
                Q1: "你勇于创新，可以为团队带来新的观点和方法",
                Q2: "你独立自主，可以独立完成重要任务",
                Q3: "你自律性强，可以帮助团队提高效率",
                Q4: "你充满动力，可以激发团队的积极性",
            },
            growth: {
                title: "个人成长建议",
                content: "为了更好的发展，建议你：",
                empty: "暂无个人成长建议",
                A: "可以通过参加社交活动来提升人际交往能力",
                B: "可以通过阅读和学习来提升思维能力",
                C: "可以通过冥想和运动来提升情绪管理能力",
                E: "可以通过承担领导角色来提升决策能力",
                F: "可以通过参与创意活动来发展表现力",
                G: "可以通过制定计划来提升执行力",
                H: "可以通过尝试新事物来增强自信心",
                I: "可以通过艺术活动来培养感受力",
                L: "可以通过团队合作来建立信任感",
                M: "可以通过创意练习来发展想象力",
                N: "可以通过社交实践来提升交际能力",
                O: "可以通过放松练习来减少焦虑",
                Q1: "可以通过接触新事物来开拓视野",
                Q2: "可以通过独立项目来锻炼自主能力",
                Q3: "可以通过目标管理来提升自律性",
                Q4: "可以通过压力管理来提升抗压能力",
            },
            format: {
                item: "{name}：{description}",
                growthAdvice: "建议加强{factor}方面的发展",
            },
        },
        share: {
            title: "分享结果",
            buttons: {
                download: "下载PDF报告",
                share: "分享给好友",
            },
        },
    },
};
