export default {
    common: {
        scanQRCode: "手机扫码访问",
        scanQRCodeTip: "使用手机扫描二维码访问",
        loading: "加载中...",
        test: {
            title: "测试须知：",
        },
        buttons: {
            startTest: "开始测试",
            viewTypes: "查看性格类型",
            learnMore: "了解更多",
            backHome: "返回首页",
            reTakeTest: "重新测试",
        },
        notFound: {
            meta: {
                title: "404 页面未找到 - 专业心理测评中心",
                description: "您访问的页面不存在，请检查网址或返回首页。",
                keywords: "404,页面未找到,错误页面,心理测评,性格测试",
            },
            title: "页面未找到",
            message: "抱歉，您访问的页面不存在。",
        },
        progress: {
            question: "问题 {current}/{total}",
            percentage: "{percent}%",
        },
        result: {
            tips: "测试结果仅供参考，每个人都是独特的个体，在不同的时间、地点、情境下，测试结果可能会有所不同",
            error: "抱歉，加载结果时出现错误",
        },
    },
    home: {
        title: "心理测评中心",
        subtitle: "专业的心理测评平台，发现真实的自己",
        categories: {
            popular: "热门测试",
            career: "职业测评",
            traits: "特质测试",
        },
        tests: {
            mbti: {
                title: "MBTI 性格测试",
                brief: "通过 16 种性格类型分析，帮助你了解性格特质并进行职业规划",
                tags: ["性格", "职业规划"],
            },
            bigFive: {
                title: "大五人格测试",
                brief: "基于科学研究的全面性格评估工具，广泛应用于心理学研究领域",
                tags: ["全面性格评估", "科研"],
            },
            enneagram: {
                title: "九型人格测试",
                brief: "深入探索你的性格动机和行为模式，改善人际关系",
                tags: ["人际关系", "心理探索"],
            },
            disc: {
                title: "DISC 行为风格",
                brief: "评估你的行为风格，提升职场沟通效率和团队协作能力",
                tags: ["职场沟通", "团队协作"],
            },
            holland: {
                title: "霍兰德职业兴趣测试",
                brief: "帮助你选择适合的专业方向，规划未来职业发展道路",
                tags: ["选专业", "职业规划"],
            },
            "16pf": {
                title: "16PF 人格测试",
                brief: "通过 16 个特质维度，全面深入地分析你的性格特征",
                tags: ["细致性格分析"],
            },
            hsp: {
                title: "高敏感人群测试",
                brief: "评估你的情绪敏感程度，学习更好地管理敏感特质",
                tags: ["情绪敏感度评估"],
            },
            trueColors: {
                title: "颜色性格测试",
                brief: "通过色彩偏好分析性格特征，提升个人与团队的协作效果",
                tags: ["个人与团队配合"],
            },
            attachment: {
                title: "依恋类型测试",
                brief: "了解你在亲密关系中的依恋模式，建立健康的关系互动",
                tags: ["亲密关系模式"],
            },
        },
        meta: {
            title: "专业心理测评中心 - 全面的心理测试平台",
            description:
                "提供MBTI、大五人格、九型人格等多种专业心理测评，帮助你更好地认识自己。",
            keywords:
                "心理测试,性格测试,职业测评,MBTI,大五人格,九型人格,DISC,霍兰德,16PF,高敏感,真实色彩,依恋类型",
        },
    },
    noResults: "暂无测试结果",
    pleaseCompleteTest: "请先完成测试",
};
