export default {
    index: {
        meta: {
            title: "依恋类型测试 - 了解你的亲密关系模式",
            description:
                "通过科学的依恋类型测试，了解你在亲密关系中的行为模式和情感表达方式，提升关系质量。",
            keywords:
                "依恋类型,依恋理论,亲密关系,人际关系,心理测试,安全型依恋,焦虑型依恋,回避型依恋",
        },
        title: "依恋类型测试",
        subtitle: "探索你的依恋模式，了解你在亲密关系中的表现方式",
        tips: {
            list: [
                "欢迎参加依恋类型测试！通过本测试，您将了解自己在亲密关系中的依恋风格及行为模式。",
                "依恋类型测试将帮助您识别自己在人际关系中的情感需求，以及如何与他人建立深层的情感连接。",
                "请根据自己在与他人建立关系时的真实体验作答，测试结果将为您提供关于自己依恋风格的有价值的见解。",
                "此测试仅用于帮助您了解自己的依恋类型，并不意味着一定会影响您的生活或关系，它是自我探索的一部分。",
            ],
        },
        buttons: {
            whatIsAttachment: "什么是依恋类型",
        },
    },
    introduction: {
        meta: {
            title: "依恋理论介绍 - 理解不同的依恋类型",
            description:
                "深入了解依恋理论的核心概念，以及不同依恋类型对人际关系的影响。",
            keywords:
                "依恋理论,依恋类型,安全型依恋,焦虑型依恋,回避型依恋,亲密关系",
        },
        title: "依恋理论简介",
        sections: [
            {
                title: "什么是依恋理论",
                content: [
                    "依恋理论是由英国心理学家约翰·鲍尔比（John Bowlby）提出的重要理论，描述了人与人之间形成亲密情感联结的过程。",
                    "这个理论最初用于解释婴儿与照顾者之间的情感纽带，后来被扩展到成年人的亲密关系研究中。",
                    "依恋模式在童年早期形成，并会影响我们未来的人际关系、情感表达和亲密关系处理方式。",
                ],
            },
            {
                title: "依恋类型的形成",
                content: [
                    "依恋类型主要在婴幼儿时期通过与主要照顾者的互动形成。",
                    "早期的依恋经历会形成内部工作模型，影响我们对自己和他人的基本认知。",
                    "这些模式往往会持续到成年，影响我们的亲密关系和社交方式。",
                ],
            },
            {
                title: "主要的依恋类型",
                content: [
                    "安全型依恋：对亲密关系感到舒适，能够建立健康的情感联结。",
                    "焦虑型依恋：对关系充满不安全感，害怕被抛弃，需要频繁的确认。",
                    "回避型依恋：倾向于保持情感距离，难以建立深入的亲密关系。",
                    "混乱型依恋：在亲密关系中表现出矛盾的行为模式。",
                ],
            },
            {
                title: "依恋类型的影响",
                content: [
                    "依恋类型会影响我们的情感表达方式、冲突处理方式和亲密关系质量。",
                    "了解自己的依恋类型有助于改善人际关系和情感生活。",
                    "通过自我认知和努力，依恋模式是可以改变的。",
                ],
            },
            {
                title: "应用场景",
                content: [
                    "自我认知：帮助个体了解自己在亲密关系中的行为模式，识别自己的依恋类型及情感需求。",
                    "情感成长：通过识别依恋类型，个体可以更好地管理亲密关系中的情感需求，促进个人情感成长。",
                    "关系改善：了解依恋类型可以帮助改善现有的人际关系，减少情感冲突，提高情感连接的质量。",
                    "心理咨询和治疗：为心理咨询师提供个体情感需求的基本信息，作为治疗依恋问题的参考。",
                    "情感支持：帮助个体识别自己在情感关系中的盲点，促使更健康的情感支持系统的建立。",
                ],
            },
            {
                title: "注意事项",
                content: [
                    "回答真实：请根据自己在情感关系中的真实感受作答，不要过于理想化或过度修饰自己的答案。",
                    "理解依恋类型：依恋类型只是个性化的情感需求分析，不代表人的全部性格特征。",
                    "多维度考虑：依恋风格会受到生活经历、文化背景等多种因素的影响，测试结果需要结合实际情况理解。",
                    "情感发展：依恋类型可能会随着时间和个人经历的变化而变化，定期反思自己的情感需求和依恋风格是有益的。",
                    "寻求专业帮助：如果测试结果表明您有情感上的困扰或依恋问题，可以寻求心理咨询师的帮助，进行更深入的自我探索和成长。",
                ],
            },
        ],
    },
    test: {
        meta: {
            title: "依恋类型测试题",
            description: "通过36个问题了解你的依恋类型",
            keywords: "依恋类型测试,亲密关系测试,依恋风格量表,ECR量表",
        },
        title: "依恋类型测试",
        options: [
            { value: 1, text: "完全不同意" },
            { value: 2, text: "比较不同意" },
            { value: 3, text: "有点不同意" },
            { value: 4, text: "中立" },
            { value: 5, text: "有点同意" },
            { value: 6, text: "比较同意" },
            { value: 7, text: "完全同意" },
        ],
        questions: [
            { question: "我担心被亲密伴侣抛弃", dimension: "anxiety" },
            {
                question: "我不太愿意向亲密伴侣敞开心扉",
                dimension: "avoidance",
            },
            { question: "我经常担心伴侣不真正爱我", dimension: "anxiety" },
            {
                question: "我觉得与伴侣亲密相处让我不舒服",
                dimension: "avoidance",
            },
            { question: "我发现自己很难完全信任伴侣", dimension: "anxiety" },
            {
                question: "向伴侣表达真实感受让我感到困难",
                dimension: "avoidance",
            },
            {
                question: "我经常希望伴侣对我的感情和我对他们的一样强烈",
                dimension: "anxiety",
            },
            {
                question: "我不喜欢与伴侣分享个人的想法和感受",
                dimension: "avoidance",
            },
            { question: "我经常担心伴侣会离开我", dimension: "anxiety" },
            { question: "我很难允许自己依赖亲密伴侣", dimension: "avoidance" },
            { question: "我经常担心伴侣不想和我亲近", dimension: "anxiety" },
            {
                question: "我倾向于不向伴侣展示内心深处的感受",
                dimension: "avoidance",
            },
            {
                question: "当伴侣开始亲近我时，我发现自己在退缩",
                dimension: "avoidance",
            },
            { question: "我担心一个人独处", dimension: "anxiety" },
            {
                question: "与伴侣分享私密想法让我感到不自在",
                dimension: "avoidance",
            },
            { question: "我对得不到关注感到焦虑", dimension: "anxiety" },
            {
                question: "我发现要依赖伴侣是件困难的事",
                dimension: "avoidance",
            },
            { question: "我需要大量的安慰和肯定", dimension: "anxiety" },
            { question: "我觉得很难让自己依靠伴侣", dimension: "avoidance" },
            { question: "我很容易对伴侣产生依恋", dimension: "anxiety" },
            { question: "我不太习惯向伴侣倾诉烦恼", dimension: "avoidance" },
            {
                question: "如果无法得到伴侣的关注和支持，我会感到沮丧",
                dimension: "anxiety",
            },
            {
                question: "我尽量避免与伴侣建立过于亲密的关系",
                dimension: "avoidance",
            },
            {
                question: "如果伴侣不在身边，我会感到不安全",
                dimension: "anxiety",
            },
            { question: "我不太依赖别人", dimension: "avoidance" },
            {
                question: "当我需要伴侣的帮助时，总觉得他们不会在身边",
                dimension: "anxiety",
            },
            { question: "我更喜欢保持情感上的距离", dimension: "avoidance" },
            { question: "我经常希望能与他人更亲近", dimension: "anxiety" },
            {
                question: "我不太愿意表现出需要伴侣的样子",
                dimension: "avoidance",
            },
            { question: "我害怕失去伴侣的爱", dimension: "anxiety" },
            { question: "我不太习惯让别人安慰我", dimension: "avoidance" },
            { question: "我总是担心自己不够好", dimension: "anxiety" },
            { question: "我倾向于在关系中保持独立", dimension: "avoidance" },
            { question: "我经常担心自己会被拒绝", dimension: "anxiety" },
            { question: "我不喜欢别人过分依赖我", dimension: "avoidance" },
            { question: "我需要伴侣不断地确认他们爱我", dimension: "anxiety" },
        ],
        scale: {
            disagree: "完全不同意",
            agree: "完全同意",
        },
    },
    types: {
        meta: {
            title: "依恋类型详解 - 四种主要依恋类型及其特征",
            description:
                "详细了解安全型、焦虑型、回避型和混乱型依恋的特点、形成原因和影响",
            keywords:
                "依恋类型,安全型依恋,焦虑型依恋,回避型依恋,混乱型依恋,依恋模式,亲密关系",
        },
        title: "依恋类型详解",
        subtitle: "了解不同依恋类型的特征与表现",
        types: [
            {
                id: "secure",
                name: "安全型依恋",
                shortDesc: "能够在亲密关系中保持健康的亲近与独立",
                characteristics: [
                    "对自己和他人持积极态度",
                    "在关系中感到自在和安全",
                    "能够建立和维持健康的亲密关系",
                    "有效地处理冲突和沟通",
                    "不过分依赖或回避亲密关系",
                ],
                formation: "童年时期获得了稳定、可靠的照顾和情感支持",
                relationships: {
                    title: "在关系中的表现",
                    points: [
                        "善于表达情感和需求",
                        "能够信任并依赖伴侣",
                        "在亲密和独立之间保持平衡",
                        "遇到问题时愿意寻求帮助",
                        "能够给予伴侣适当的空间",
                    ],
                },
                improvement: {
                    title: "优势",
                    points: [
                        "情感表达健康",
                        "人际关系稳定",
                        "自我认知清晰",
                        "有效处理冲突",
                        "心理韧性强",
                    ],
                },
            },
            {
                id: "anxious",
                name: "焦虑型依恋",
                shortDesc: "在关系中过分需要确认和安全感",
                characteristics: [
                    "对关系充满不安全感",
                    "过分需要他人的认可和关注",
                    "害怕被抛弃或拒绝",
                    "倾向于过度依赖伴侣",
                    "对伴侣的反应特别敏感",
                ],
                formation: "童年时期经历了不稳定或不一致的情感回应",
                relationships: {
                    title: "在关系中的表现",
                    points: [
                        "经常寻求确认和保证",
                        "对伴侣的行为过度解读",
                        "难以承受分离和距离",
                        "容易产生依赖性",
                        "情绪波动较大",
                    ],
                },
                improvement: {
                    title: "改善建议",
                    points: [
                        "培养自我价值感",
                        "学习独立性",
                        "练习情绪调节",
                        "建立健康界限",
                        "发展个人兴趣",
                    ],
                },
            },
            {
                id: "avoidant",
                name: "回避型依恋",
                shortDesc: "倾向于在亲密关系中保持情感距离",
                characteristics: [
                    "难以建立亲密关系",
                    "强调个人独立性",
                    "不愿表达情感需求",
                    "在关系中保持距离",
                    "回避情感依赖",
                ],
                formation: "童年时期缺乏情感回应或被忽视的经历",
                relationships: {
                    title: "在关系中的表现",
                    points: [
                        "难以表达情感",
                        "回避亲密接触",
                        "重视个人空间",
                        "不愿依赖他人",
                        "在压力下倾向于退缩",
                    ],
                },
                improvement: {
                    title: "改善建议",
                    points: [
                        "学习表达情感",
                        "逐步接纳亲密关系",
                        "培养同理心",
                        "练习脆弱表达",
                        "寻求专业帮助",
                    ],
                },
            },
            {
                id: "disorganized",
                name: "混乱型依恋",
                shortDesc: "在亲密关系中表现出矛盾的行为模式",
                characteristics: [
                    "对亲密关系既渴望又恐惧",
                    "行为模式不一致",
                    "难以调节情绪",
                    "人际关系不稳定",
                    "自我认知模糊",
                ],
                formation: "童年时期经历创伤或严重的情感忽视",
                relationships: {
                    title: "在关系中的表现",
                    points: [
                        "关系模式混乱",
                        "情感反应强烈",
                        "难以建立信任",
                        "在亲密关系中感到困惑",
                        "容易陷入冲突",
                    ],
                },
                improvement: {
                    title: "改善建议",
                    points: [
                        "寻求心理治疗",
                        "建立安全感",
                        "学习情绪管理",
                        "发展稳定关系",
                        "培养自我认知",
                    ],
                },
            },
        ],
        characteristics: "主要特征",
        formation: "形成原因",
    },
    result: {
        meta: {
            title: "依恋类型测试结果 - 了解你的依恋模式",
            description:
                "查看你的依恋类型测试结果，深入了解你在亲密关系中的表现方式",
            keywords: "依恋类型结果,依恋测试,亲密关系,心理测试结果,个人洞察",
        },
        title: "你的依恋类型测试结果",
        subtitle: "基于你的回答，我们分析出以下结果",
        dimensions: {
            anxiety: {
                title: "依恋焦虑度",
                high: "较高",
                medium: "中等",
                low: "较低",
                description: {
                    high: "你在关系中倾向于担心被抛弃和过分需要确认",
                    medium: "你对关系的不安全感处于一般水平",
                    low: "你较少担心关系安全，对亲密关系比较放心",
                },
            },
            avoidance: {
                title: "依恋回避度",
                high: "较高",
                medium: "中等",
                low: "较低",
                description: {
                    high: "你倾向于保持情感距离，难以亲密",
                    medium: "你在亲密关系中的回避倾向处于一般水平",
                    low: "你能够自在地与他人建立亲密关系",
                },
            },
        },
        attachmentType: {
            title: "你的主要依恋类型",
            secure: {
                title: "安全型依恋",
                description:
                    "你在亲密关系中表现得比较安全和自在，能够平衡亲密与独立",
            },
            anxious: {
                title: "焦虑型依恋",
                description: "你在关系中容易感到不安全，需要频繁的确认和安慰",
            },
            avoidant: {
                title: "回避型依恋",
                description: "你倾向于保持情感距离，对亲密关系感到不适",
            },
            disorganized: {
                title: "混乱型依恋",
                description:
                    "你在亲密关系中既渴望又恐惧，可能表现出矛盾的行为模式",
            },
        },
        scores: {
            title: "详细得分",
            anxiety: "焦虑维度得分",
            avoidance: "回避维度得分",
            outOf: "满分 {total} 分",
        },
        suggestions: {
            title: "改善建议",
            secure: [
                "继续保持健康的关系模式",
                "帮助他人建立安全的依恋关系",
                "在困难时期保持积极的沟通方式",
            ],
            anxious: [
                "培养自我价值感，减少对他人认可的依赖",
                "学习独立自主，建立个人兴趣爱好",
                "练习情绪调节，减少过度反应",
                "设立健康的个人界限",
            ],
            avoidant: [
                "逐步尝试表达情感需求",
                "学习与他人建立更深层的连接",
                "接纳亲密关系中的脆弱感",
                "培养同理心和情感表达能力",
            ],
            disorganized: [
                "考虑寻求专业心理咨询",
                "学习情绪管理技巧",
                "逐步建立安全感",
                "发展稳定的社交支持系统",
            ],
        },
        buttons: {
            retake: "重新测试",
            share: "分享结果",
            learnMore: "了解更多",
        },
        share: {
            title: "分享你的依恋类型结果",
            text: "我的依恋类型是{type}，焦虑度{anxiety}%，回避度{avoidance}%。来测测你的依恋类型吧！",
            success: "分享链接已复制到剪贴板",
            error: "分享失败，请重试",
        },
    },
};
