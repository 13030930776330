export default {
    index: {
        meta: {
            title: "Holland Career Interest Test - Discover Your Career Orientation",
            description:
                "Understand your career orientation type through the Holland Career Interest Test and find the most suitable career path.",
            keywords:
                "Holland test, career interest test, career orientation, career planning, RIASEC model",
        },
        title: "Holland Career Interest Test",
        subtitle:
            "Discover your career orientation and find the most suitable career path",
        tips: {
            list: [
                "Welcome to the Holland Career Interest Test! By taking this test, you will discover your tendencies in six career interest types, helping you find the career path that suits you best.",
                "The Holland Test analyzes your career interests and helps you find a work field that aligns with your interests and personality, increasing career satisfaction.",
                "Please answer each question as honestly as possible based on your true interests and preferences. The results will provide valuable career guidance.",
                "The Holland Career Interest Test is a scientifically backed career planning tool that can help you discover career directions and development opportunities suited to your interests.",
            ],
        },
        buttons: {
            whatIsHolland: "What is Holland Test",
            hollandTypes: "Holland Types",
        },
        features: [
            {
                title: "Scientific RIASEC Model",
                description:
                    "Based on Dr. John Holland's career interest theory, evaluating your career orientation through six basic types",
            },
            {
                title: "Comprehensive Career Analysis",
                description:
                    "Deep understanding of your career interests, skill tendencies, and personality traits for scientific career choice",
            },
            {
                title: "Personalized Career Advice",
                description:
                    "Recommend the most suitable career direction and development path based on test results",
            },
        ],
    },
    introduction: {
        meta: {
            title: "Holland Career Interest Test Introduction - Understanding the RIASEC Model",
            description:
                "Deep understanding of the theoretical foundation, six types, and application value of the Holland Career Interest Test.",
            keywords:
                "Holland test introduction, RIASEC model, career interest theory, career types",
        },
        title: "Holland Career Interest Test Introduction",
        what: {
            title: "What is the Holland Career Interest Test?",
            content:
                "The Holland Career Interest Test is an assessment tool developed based on Dr. John Holland's career interest theory. This theory suggests that career choice is an extension of personality, and people's career interests can be categorized into six basic types: Realistic (R), Investigative (I), Artistic (A), Social (S), Enterprising (E), and Conventional (C). The Holland Test helps individuals understand their career interests and plan their career paths accordingly.",
        },
        history: {
            title: "Historical Background",
            content:
                "Dr. John Holland began researching career interest theory in the 1950s and first proposed the RIASEC model in 1959. After decades of development and validation, this model has become one of the most widely used theories in career counseling.",
        },
        types: {
            title: "Six Types",
            traits: "Traits",
            careers: "Suitable Careers",
            items: [
                {
                    icon: "🔧",
                    title: "Realistic (R)",
                    description:
                        "Enjoys hands-on work, has mechanical and athletic skills",
                    traits: [
                        "Strong practical skills",
                        "Enjoys using tools",
                        "Pragmatic",
                        "Good at solving concrete problems",
                    ],
                    careers: [
                        "Engineer",
                        "Technician",
                        "Mechanic",
                        "Architect",
                    ],
                },
                {
                    icon: "🔬",
                    title: "Investigative (I)",
                    description:
                        "Enjoys exploration and research, has scientific thinking",
                    traits: [
                        "Analytical",
                        "Enjoys research",
                        "Pursues truth",
                        "Independent thinker",
                    ],
                    careers: [
                        "Scientist",
                        "Researcher",
                        "Data Analyst",
                        "Doctor",
                    ],
                },
                {
                    icon: "🎨",
                    title: "Artistic (A)",
                    description: "Creative, enjoys expression and creation",
                    traits: [
                        "Highly creative",
                        "Good at expression",
                        "Pursues beauty",
                        "Enjoys innovation",
                    ],
                    careers: ["Artist", "Designer", "Musician", "Writer"],
                },
                {
                    icon: "🤝",
                    title: "Social (S)",
                    description:
                        "Enjoys interpersonal interaction, likes helping others",
                    traits: [
                        "Good at communication",
                        "Likes helping others",
                        "Empathetic",
                        "Enjoys teaching",
                    ],
                    careers: [
                        "Teacher",
                        "Counselor",
                        "Social Worker",
                        "HR Professional",
                    ],
                },
                {
                    icon: "💼",
                    title: "Enterprising (E)",
                    description:
                        "Has leadership ability, likes influencing others",
                    traits: [
                        "Good at leadership",
                        "Enjoys competition",
                        "Pursues achievement",
                        "Good at persuasion",
                    ],
                    careers: ["Entrepreneur", "Manager", "Sales", "Lawyer"],
                },
                {
                    icon: "📊",
                    title: "Conventional (C)",
                    description: "Detail-oriented, enjoys organized work",
                    traits: [
                        "Detail-oriented",
                        "Well-organized",
                        "Follows rules",
                        "Good at organization",
                    ],
                    careers: [
                        "Accountant",
                        "Administrator",
                        "Librarian",
                        "Data Analyst",
                    ],
                },
            ],
        },
        hexagon: {
            title: "Hexagon Model",
            description:
                "The core of Holland's theory is the hexagon model, which shows the relationships between the six types. Adjacent types share similar characteristics, while opposite types are more different.",
            points: [
                "Adjacent types share similar traits",
                "Opposite types are more different",
                "Can combine to form complex types",
                "Helps understand career choice continuity",
            ],
            mock: [
                {
                    label: "Realistic (R)",
                    score: 100,
                },
                {
                    label: "Investigative (I)",
                    score: 100,
                },
                {
                    label: "Artistic (A)",
                    score: 100,
                },
                {
                    label: "Social (S)",
                    score: 100,
                },
                {
                    label: "Enterprising (E)",
                    score: 100,
                },
                {
                    label: "Conventional (C)",
                    score: 100,
                },
            ],
        },
        applications: {
            title: "Application Value",
            items: [
                "Career Planning: Helps individuals choose the right career path based on their interest types.",
                "Self-awareness: Through the test, individuals can gain a better understanding of their interests and personality traits, enhancing self-awareness.",
                "Career Development: Recommends suitable positions based on interest types, helping individuals achieve greater satisfaction and success in their careers.",
                "Educational Guidance: Provides career direction advice for students and educators, helping students choose suitable career paths.",
                "Corporate Recruitment: Companies can use career interest test results to match candidates with the most suitable roles, improving recruitment effectiveness.",
            ],
        },
        notes: {
            title: "Important Notes",
            items: [
                "Answer Honestly: The test results are based on your true interests, so please answer as truthfully as possible, without trying to please social or external expectations.",
                "Understand the Limitations: The Holland Test reflects your career interests, but does not assess your overall abilities or competencies. It should be used alongside other assessment tools.",
                "Test Results Are for Reference: The results can offer career direction guidance, but should not be solely relied upon for making career decisions.",
                "Interests May Change Over Time: As you gain experience and your interests evolve, your career interest type may change, so it’s a good idea to take the test periodically.",
                "Cultural Differences: Different cultural backgrounds may influence how interest types are expressed, so consider cultural factors when interpreting the results.",
            ],
        },
    },
    test: {
        meta: {
            title: "Holland Career Interest Test - Start Testing",
            description:
                "Understand your career orientation type by answering a series of questions.",
            keywords:
                "Holland test, career interest test, career orientation test",
        },
        title: "Holland Career Interest Test",
        scale: {
            stronglyDisagree: "Strongly Disagree",
            stronglyAgree: "Strongly Agree",
        },
        questions: {
            options: [
                "Strongly Agree",
                "Agree",
                "Neutral",
                "Disagree",
                "Strongly Disagree",
            ],
            questions: [
                {
                    question: "I like to work with tools and machines",
                    dimension: "R",
                },
                {
                    question: "I enjoy solving complex problems",
                    dimension: "I",
                },
                {
                    question: "I like to express myself through art or music",
                    dimension: "A",
                },
                {
                    question: "I enjoy helping and teaching others",
                    dimension: "S",
                },
                {
                    question: "I like to lead and persuade others",
                    dimension: "E",
                },
                {
                    question: "I prefer to follow established procedures",
                    dimension: "C",
                },
                {
                    question: "I like to work outdoors",
                    dimension: "R",
                },
                {
                    question: "I enjoy conducting research and experiments",
                    dimension: "I",
                },
                {
                    question: "I like to create original work",
                    dimension: "A",
                },
                {
                    question: "I enjoy working with people in groups",
                    dimension: "S",
                },
                {
                    question: "I like to sell things and promote ideas",
                    dimension: "E",
                },
                {
                    question: "I prefer to work with numbers and data",
                    dimension: "C",
                },
                {
                    question: "I like to build and repair things",
                    dimension: "R",
                },
                {
                    question: "I enjoy analyzing and solving puzzles",
                    dimension: "I",
                },
                {
                    question: "I like to write stories or poems",
                    dimension: "A",
                },
                {
                    question: "I enjoy counseling and advising others",
                    dimension: "S",
                },
                {
                    question: "I like to start and run my own business",
                    dimension: "E",
                },
                {
                    question: "I prefer to keep detailed records",
                    dimension: "C",
                },
                {
                    question: "I like to work with my hands",
                    dimension: "R",
                },
                {
                    question: "I enjoy learning about scientific theories",
                    dimension: "I",
                },
                {
                    question: "I like to perform or act",
                    dimension: "A",
                },
                {
                    question: "I enjoy working with children",
                    dimension: "S",
                },
                {
                    question: "I like to make decisions and take risks",
                    dimension: "E",
                },
                {
                    question: "I prefer to work in an organized environment",
                    dimension: "C",
                },
                {
                    question: "I like to work with animals or plants",
                    dimension: "R",
                },
                {
                    question: "I enjoy reading scientific articles",
                    dimension: "I",
                },
                {
                    question: "I like to design and create things",
                    dimension: "A",
                },
                {
                    question: "I enjoy helping people with their problems",
                    dimension: "S",
                },
                {
                    question: "I like to be in charge of projects",
                    dimension: "E",
                },
                {
                    question: "I prefer to work with computers and software",
                    dimension: "C",
                },
                {
                    question: "I like to operate heavy machinery",
                    dimension: "R",
                },
                {
                    question: "I enjoy working with mathematical formulas",
                    dimension: "I",
                },
                {
                    question: "I like to compose music or songs",
                    dimension: "A",
                },
                {
                    question: "I enjoy teaching and mentoring others",
                    dimension: "S",
                },
                {
                    question: "I like to negotiate and make deals",
                    dimension: "E",
                },
                {
                    question:
                        "I prefer to work with spreadsheets and databases",
                    dimension: "C",
                },
                {
                    question: "I like to work with construction materials",
                    dimension: "R",
                },
                {
                    question: "I enjoy conducting laboratory experiments",
                    dimension: "I",
                },
                {
                    question: "I like to paint or draw",
                    dimension: "A",
                },
                {
                    question: "I enjoy providing emotional support to others",
                    dimension: "S",
                },
                {
                    question: "I like to develop business strategies",
                    dimension: "E",
                },
                {
                    question: "I prefer to maintain financial records",
                    dimension: "C",
                },
                {
                    question: "I like to work with electrical equipment",
                    dimension: "R",
                },
                {
                    question: "I enjoy analyzing statistical data",
                    dimension: "I",
                },
                {
                    question: "I like to choreograph dance routines",
                    dimension: "A",
                },
                {
                    question: "I enjoy organizing community events",
                    dimension: "S",
                },
                {
                    question: "I like to manage and supervise others",
                    dimension: "E",
                },
                {
                    question: "I prefer to work with filing systems",
                    dimension: "C",
                },
                {
                    question: "I like to work with wood or metal",
                    dimension: "R",
                },
                {
                    question: "I enjoy developing new theories",
                    dimension: "I",
                },
                {
                    question: "I like to write scripts or plays",
                    dimension: "A",
                },
                {
                    question: "I enjoy working with elderly people",
                    dimension: "S",
                },
                {
                    question: "I like to invest in stocks and bonds",
                    dimension: "E",
                },
                {
                    question: "I prefer to work with accounting software",
                    dimension: "C",
                },
                {
                    question: "I like to work with agricultural equipment",
                    dimension: "R",
                },
                {
                    question: "I enjoy conducting field research",
                    dimension: "I",
                },
                {
                    question: "I like to design clothing or jewelry",
                    dimension: "A",
                },
                {
                    question: "I enjoy working with people with disabilities",
                    dimension: "S",
                },
                {
                    question: "I like to develop marketing campaigns",
                    dimension: "E",
                },
                {
                    question: "I prefer to work with inventory systems",
                    dimension: "C",
                },
            ],
            dimensions: {
                R: {
                    name: "Realistic",
                    description:
                        "People who like to work with their hands, tools, machines, or animals. They prefer practical, concrete problems and solutions.",
                },
                I: {
                    name: "Investigative",
                    description:
                        "People who like to observe, learn, investigate, analyze, evaluate, or solve problems. They prefer working with ideas and data.",
                },
                A: {
                    name: "Artistic",
                    description:
                        "People who like to work in unstructured situations using their creativity and imagination. They prefer working with ideas and artistic forms.",
                },
                S: {
                    name: "Social",
                    description:
                        "People who like to work with people to inform, train, develop, cure, or enlighten. They prefer working with people and helping others.",
                },
                E: {
                    name: "Enterprising",
                    description:
                        "People who like to work with people to influence, persuade, lead, or manage for organizational goals or economic gain.",
                },
                C: {
                    name: "Conventional",
                    description:
                        "People who like to work with data and numbers, carrying out tasks in detail and following through with others' instructions.",
                },
            },
            defaultValues: {
                careers: [
                    "Professional Skills",
                    "Team Collaboration",
                    "Project Management",
                    "Technical Learning",
                ],
                developmentSkills: [
                    "Enhance Professional Skills",
                    "Develop Team Collaboration",
                    "Strengthen Project Management",
                    "Continue Technical Learning",
                ],
                traits: [
                    "Professional Competency",
                    "Team Collaboration",
                    "Project Management",
                    "Continuous Learning",
                ],
            },
        },
    },
    result: {
        meta: {
            title: "Holland Career Interest Test Results - Understand Your Career Orientation",
            description:
                "View your Holland Career Interest Test results and understand your career orientation type and suitable career directions.",
            keywords:
                "Holland test results, career orientation analysis, career advice",
        },
        title: "Your Career Orientation Analysis",
        subtitle:
            "Based on your answers, we've generated a detailed career orientation analysis",
        primaryType: {
            title: "Primary Type",
            traits: "Traits",
            careers: "Suitable Careers",
            default: {
                icon: "🔧",
                title: "Realistic (R)",
                description:
                    "You are a practical person with strong hands-on skills. You enjoy using tools and machines and are good at solving concrete problems.",
                traits: [
                    "Strong practical skills",
                    "Enjoys using tools",
                    "Pragmatic",
                    "Good at solving concrete problems",
                ],
                careers: ["Engineer", "Technician", "Mechanic", "Architect"],
            },
        },
        hexagon: {
            title: "Hexagon Model Analysis",
            dimensions: [
                {
                    label: "Realistic (R)",
                    score: 85,
                },
                {
                    label: "Investigative (I)",
                    score: 65,
                },
                {
                    label: "Artistic (A)",
                    score: 45,
                },
                {
                    label: "Social (S)",
                    score: 55,
                },
                {
                    label: "Enterprising (E)",
                    score: 70,
                },
                {
                    label: "Conventional (C)",
                    score: 75,
                },
            ],
        },
        careerAdvice: {
            title: "Career Advice",
            mainDirection: {
                title: "Main Career Directions",
                description:
                    "Based on your career orientation, the following career directions are most suitable for you:",
                skills: [
                    "Mechanical operation",
                    "Technical application",
                    "Problem-solving",
                    "Project management",
                ],
            },
            development: {
                title: "Development Suggestions",
                description:
                    "To achieve better development in your career path, we recommend:",
                skills: [
                    "Enhance professional skills",
                    "Develop team collaboration",
                    "Strengthen project management",
                    "Continue learning new technologies",
                ],
            },
        },
        developmentAdvice: {
            title: "Development Advice",
            personalTraits: {
                title: "Personal Traits",
                description:
                    "Your personality traits determine your career development direction:",
            },
            suggestions: {
                title: "Development Suggestions",
                description:
                    "To achieve better development in your career path, we recommend:",
            },
            items: [
                {
                    title: "Skill Enhancement",
                    description:
                        "We recommend you focus on improving the following skills:",
                    skills: [
                        "Enhance professional skills",
                        "Develop team collaboration",
                        "Strengthen project management",
                        "Continue learning new technologies",
                    ],
                },
                {
                    title: "Career Planning",
                    description:
                        "Make the following plans for your career development:",
                    skills: [
                        "Enhance professional skills",
                        "Develop team collaboration",
                        "Strengthen project management",
                        "Continue learning new technologies",
                    ],
                },
            ],
        },
    },
    types: {
        meta: {
            title: "Holland Career Types - Understanding RIASEC Model",
            description:
                "Explore the six types of the Holland Career Interest Test and understand their characteristics, suitable careers, and development paths.",
            keywords:
                "Holland types, RIASEC model, career types, career orientation, career development",
        },
        title: "Holland Career Types",
        groups: {
            realistic: "Realistic Types",
            investigative: "Investigative Types",
            artistic: "Artistic Types",
            social: "Social Types",
            enterprising: "Enterprising Types",
            conventional: "Conventional Types",
        },
        types: {
            realisticTypes: ["R", "RI", "RA", "RIA"],
            investigativeTypes: ["I", "IR", "IA", "IRA"],
            artisticTypes: ["A", "AI", "AS", "AIS"],
            socialTypes: ["S", "SA", "SE", "SAE"],
            enterprisingTypes: ["E", "EA", "EC", "EAC"],
            conventionalTypes: ["C", "CR", "CE", "CRE"],
            typeMap: {
                R: {
                    name: "Realistic",
                    description:
                        "Good with hands, likes machinery, practical, seeks stability.",
                    traits: [
                        "Good with hands",
                        "Likes machinery",
                        "Practical",
                        "Stable",
                        "Pragmatic",
                        "Independent",
                    ],
                    careers: [
                        "Mechanical Engineer",
                        "Electrical Engineer",
                        "Construction Engineer",
                        "Automotive Technician",
                        "Agricultural Technician",
                        "Carpenter",
                        "Electrician",
                        "Plumber",
                    ],
                    developmentSkills: [
                        "Technical Problem Solving",
                        "Equipment Operation",
                        "Safety Management",
                        "Project Planning",
                        "Quality Control",
                        "Maintenance Skills",
                        "Blueprint Reading",
                        "Tool Management",
                    ],
                },
                RI: {
                    name: "Realistic-Investigative",
                    description:
                        "Good with hands, analytical, practical, innovative.",
                    traits: [
                        "Good with hands",
                        "Analytical",
                        "Practical",
                        "Innovative",
                        "Pragmatic",
                        "Independent",
                    ],
                },
                RA: {
                    name: "Realistic-Artistic",
                    description:
                        "Good with hands, creative, practical, aesthetic.",
                    traits: [
                        "Good with hands",
                        "Creative",
                        "Practical",
                        "Aesthetic",
                        "Pragmatic",
                        "Independent",
                    ],
                },
                RIA: {
                    name: "Realistic-Investigative-Artistic",
                    description:
                        "Good with hands, analytical, creative, practical.",
                    traits: [
                        "Good with hands",
                        "Analytical",
                        "Creative",
                        "Practical",
                        "Pragmatic",
                        "Independent",
                    ],
                },
                I: {
                    name: "Investigative",
                    description:
                        "Analytical, truth-seeking, independent, logical.",
                    traits: [
                        "Analytical",
                        "Truth-seeking",
                        "Independent",
                        "Logical",
                        "Rational",
                        "Innovative",
                    ],
                    careers: [
                        "Research Scientist",
                        "Data Analyst",
                        "Software Engineer",
                        "Medical Researcher",
                        "Mathematician",
                        "Biologist",
                        "Chemist",
                        "Statistician",
                    ],
                    developmentSkills: [
                        "Research Methodology",
                        "Data Analysis",
                        "Scientific Writing",
                        "Statistical Analysis",
                        "Problem Solving",
                        "Laboratory Skills",
                        "Critical Thinking",
                        "Technical Writing",
                    ],
                },
                IR: {
                    name: "Investigative-Realistic",
                    description:
                        "Analytical, good with hands, practical, innovative.",
                    traits: [
                        "Analytical",
                        "Good with hands",
                        "Practical",
                        "Innovative",
                        "Rational",
                        "Innovative",
                    ],
                },
                IA: {
                    name: "Investigative-Artistic",
                    description:
                        "Analytical, creative, truth-seeking, innovative.",
                    traits: [
                        "Analytical",
                        "Creative",
                        "Truth-seeking",
                        "Innovative",
                        "Rational",
                        "Innovative",
                    ],
                },
                IRA: {
                    name: "Investigative-Realistic-Artistic",
                    description:
                        "Analytical, good with hands, creative, innovative.",
                    traits: [
                        "Analytical",
                        "Good with hands",
                        "Creative",
                        "Innovative",
                        "Rational",
                        "Innovative",
                    ],
                },
                A: {
                    name: "Artistic",
                    description:
                        "Creative, aesthetic, expressive, individualistic.",
                    traits: [
                        "Creative",
                        "Aesthetic",
                        "Expressive",
                        "Individualistic",
                        "Sensitive",
                        "Unique",
                    ],
                    careers: [
                        "Graphic Designer",
                        "Musician",
                        "Writer",
                        "Interior Designer",
                        "Photographer",
                        "Actor",
                        "Fashion Designer",
                        "Art Director",
                    ],
                    developmentSkills: [
                        "Creative Design",
                        "Visual Communication",
                        "Artistic Expression",
                        "Color Theory",
                        "Digital Media",
                        "Storytelling",
                        "Composition",
                        "Brand Development",
                    ],
                },
                AI: {
                    name: "Artistic-Investigative",
                    description:
                        "Creative, analytical, innovative, individualistic.",
                    traits: [
                        "Creative",
                        "Analytical",
                        "Innovative",
                        "Individualistic",
                        "Sensitive",
                        "Unique",
                    ],
                },
                AS: {
                    name: "Artistic-Social",
                    description: "Creative, sociable, expressive, emotional.",
                    traits: [
                        "Creative",
                        "Sociable",
                        "Expressive",
                        "Emotional",
                        "Sensitive",
                        "Unique",
                    ],
                },
                AIS: {
                    name: "Artistic-Investigative-Social",
                    description:
                        "Creative, analytical, sociable, individualistic.",
                    traits: [
                        "Creative",
                        "Analytical",
                        "Sociable",
                        "Individualistic",
                        "Sensitive",
                        "Unique",
                    ],
                },
                S: {
                    name: "Social",
                    description: "Sociable, empathetic, helpful, emotional.",
                    traits: [
                        "Sociable",
                        "Empathetic",
                        "Helpful",
                        "Emotional",
                        "Friendly",
                        "Cooperative",
                    ],
                    careers: [
                        "Teacher",
                        "Counselor",
                        "Social Worker",
                        "Healthcare Provider",
                        "Human Resources Manager",
                        "Community Organizer",
                        "Career Coach",
                        "Public Relations Specialist",
                    ],
                    developmentSkills: [
                        "Communication",
                        "Empathy",
                        "Conflict Resolution",
                        "Team Building",
                        "Leadership",
                        "Public Speaking",
                        "Cultural Awareness",
                        "Mentoring",
                    ],
                },
                SA: {
                    name: "Social-Artistic",
                    description: "Sociable, creative, expressive, emotional.",
                    traits: [
                        "Sociable",
                        "Creative",
                        "Expressive",
                        "Emotional",
                        "Friendly",
                        "Cooperative",
                    ],
                },
                SE: {
                    name: "Social-Enterprising",
                    description:
                        "Sociable, leadership, influential, relationship-oriented.",
                    traits: [
                        "Sociable",
                        "Leadership",
                        "Influential",
                        "Relationship-oriented",
                        "Friendly",
                        "Cooperative",
                    ],
                },
                SAE: {
                    name: "Social-Artistic-Enterprising",
                    description:
                        "Sociable, creative, leadership, relationship-oriented.",
                    traits: [
                        "Sociable",
                        "Creative",
                        "Leadership",
                        "Relationship-oriented",
                        "Friendly",
                        "Cooperative",
                    ],
                },
                E: {
                    name: "Enterprising",
                    description:
                        "Leadership, influential, sociable, achievement-oriented.",
                    traits: [
                        "Leadership",
                        "Influential",
                        "Sociable",
                        "Achievement-oriented",
                        "Confident",
                        "Ambitious",
                    ],
                    careers: [
                        "Business Manager",
                        "Sales Representative",
                        "Entrepreneur",
                        "Marketing Manager",
                        "Real Estate Agent",
                        "Financial Advisor",
                        "Event Planner",
                        "Project Manager",
                    ],
                    developmentSkills: [
                        "Leadership",
                        "Negotiation",
                        "Strategic Planning",
                        "Sales Techniques",
                        "Risk Management",
                        "Business Development",
                        "Public Speaking",
                        "Decision Making",
                    ],
                },
                EA: {
                    name: "Enterprising-Artistic",
                    description:
                        "Leadership, creative, expressive, achievement-oriented.",
                    traits: [
                        "Leadership",
                        "Creative",
                        "Expressive",
                        "Achievement-oriented",
                        "Confident",
                        "Ambitious",
                    ],
                },
                EC: {
                    name: "Enterprising-Conventional",
                    description:
                        "Leadership, detail-oriented, efficient, order-oriented.",
                    traits: [
                        "Leadership",
                        "Detail-oriented",
                        "Efficient",
                        "Order-oriented",
                        "Confident",
                        "Ambitious",
                    ],
                },
                EAC: {
                    name: "Enterprising-Artistic-Conventional",
                    description:
                        "Leadership, creative, detail-oriented, achievement-oriented.",
                    traits: [
                        "Leadership",
                        "Creative",
                        "Detail-oriented",
                        "Achievement-oriented",
                        "Confident",
                        "Ambitious",
                    ],
                },
                C: {
                    name: "Conventional",
                    description:
                        "Detail-oriented, efficient, rule-following, order-oriented.",
                    traits: [
                        "Detail-oriented",
                        "Efficient",
                        "Rule-following",
                        "Order-oriented",
                        "Careful",
                        "Reliable",
                    ],
                    careers: [
                        "Accountant",
                        "Office Manager",
                        "Bank Teller",
                        "Data Entry Specialist",
                        "Administrative Assistant",
                        "Bookkeeper",
                        "Quality Control Inspector",
                        "Insurance Agent",
                    ],
                    developmentSkills: [
                        "Organization",
                        "Attention to Detail",
                        "Time Management",
                        "Record Keeping",
                        "Process Improvement",
                        "Data Management",
                        "Documentation",
                        "Quality Assurance",
                    ],
                },
                CR: {
                    name: "Conventional-Realistic",
                    description:
                        "Detail-oriented, good with hands, stable, order-oriented.",
                    traits: [
                        "Detail-oriented",
                        "Good with hands",
                        "Stable",
                        "Order-oriented",
                        "Careful",
                        "Reliable",
                    ],
                },
                CE: {
                    name: "Conventional-Enterprising",
                    description:
                        "Detail-oriented, leadership, efficient, order-oriented.",
                    traits: [
                        "Detail-oriented",
                        "Leadership",
                        "Efficient",
                        "Order-oriented",
                        "Careful",
                        "Reliable",
                    ],
                },
                CRE: {
                    name: "Conventional-Realistic-Enterprising",
                    description:
                        "Detail-oriented, good with hands, leadership, order-oriented.",
                    traits: [
                        "Detail-oriented",
                        "Good with hands",
                        "Leadership",
                        "Order-oriented",
                        "Careful",
                        "Reliable",
                    ],
                },
            },
        },
    },
};
