export default {
    index: {
        meta: {
            title: "Holland 职业兴趣测试 - 发现你的职业倾向",
            description:
                "通过 Holland 职业兴趣测试了解你的职业倾向类型，发现最适合你的职业方向。",
            keywords: "Holland 测试,职业兴趣测试,职业倾向,职业规划,RIASEC模型",
        },
        title: "Holland 职业兴趣测试",
        subtitle: "发现你的职业倾向，找到最适合的职业方向",
        tips: {
            list: [
                "欢迎参加霍兰德职业兴趣测试！通过本次测试，您将了解自己在六种职业兴趣类型中的倾向，帮助您发现最适合自己的职业路径。",
                "霍兰德测试通过分析您的职业兴趣，帮助您找到符合个人兴趣和性格的工作领域，提高职业发展的满意度。",
                "请根据您的兴趣和偏好，尽量真实地回答每一个问题，测试结果将为您提供有价值的职业建议。",
                "霍兰德职业兴趣测试是一种科学的职业规划工具，可以帮助您了解适合自己的职业方向和发展机会。",
            ],
        },
        buttons: {
            whatIsHolland: "什么是  Holland  测试",
            hollandTypes: "Holland  类型",
        },
        features: [
            {
                title: "科学的RIASEC模型",
                description:
                    "基于John  Holland 博士的职业兴趣理论，通过六种基本类型评估你的职业倾向",
            },
            {
                title: "全面的职业分析",
                description:
                    "深入了解你的职业兴趣、技能倾向和个性特征，为职业选择提供科学依据",
            },
            {
                title: "个性化的职业建议",
                description: "根据测试结果为你推荐最适合的职业方向和发展路径",
            },
        ],
    },
    introduction: {
        meta: {
            title: "Holland 职业兴趣测试介绍 - 了解RIASEC模型",
            description:
                "深入了解 Holland 职业兴趣测试的理论基础、六种类型及其应用价值。",
            keywords: "Holland 测试介绍,RIASEC模型,职业兴趣理论,职业类型",
        },
        title: "Holland 职业兴趣测试介绍",
        what: {
            title: "什么是 Holland 职业兴趣测试？",
            content:
                "Holland 职业兴趣测试是基于John  Holland 博士的职业兴趣理论开发的测评工具。该理论认为，职业选择是人格的延伸，人们的职业兴趣可以归纳为六种基本类型：现实型(R)、研究型(I)、艺术型(A)、社会型(S)、企业型(E)和常规型(C)。 Holland 测试帮助人们了解自己对不同职业领域的兴趣，并根据这些兴趣进行职业规划和选择。",
        },
        history: {
            title: "历史背景",
            content:
                "John  Holland 博士在20世纪50年代开始研究职业兴趣理论，并于1959年首次提出了RIASEC模型。这个模型经过数十年的发展和验证，已成为职业咨询领域最广泛使用的理论之一。",
        },
        types: {
            title: "六种类型",
            traits: "特征",
            careers: "适合的职业",
            items: [
                {
                    icon: "🔧",
                    title: "现实型 (R)",
                    description: "喜欢动手操作，具有机械和运动技能",
                    traits: [
                        "动手能力强",
                        "喜欢使用工具",
                        "注重实际",
                        "善于解决具体问题",
                    ],
                    careers: ["工程师", "技术员", "机械师", "建筑师"],
                },
                {
                    icon: "🔬",
                    title: "研究型 (I)",
                    description: "喜欢探索和研究，具有科学思维",
                    traits: ["善于分析", "喜欢研究", "追求真理", "独立思考"],
                    careers: ["科学家", "研究员", "数据分析师", "医生"],
                },
                {
                    icon: "🎨",
                    title: "艺术型 (A)",
                    description: "富有创造力，喜欢表达和创作",
                    traits: ["富有创造力", "善于表达", "追求美感", "喜欢创新"],
                    careers: ["艺术家", "设计师", "音乐家", "作家"],
                },
                {
                    icon: "🤝",
                    title: "社会型 (S)",
                    description: "喜欢与人交往，乐于助人",
                    traits: [
                        "善于沟通",
                        "乐于助人",
                        "富有同理心",
                        "喜欢教导他人",
                    ],
                    careers: ["教师", "心理咨询师", "社工", "人力资源"],
                },
                {
                    icon: "💼",
                    title: "企业型 (E)",
                    description: "具有领导才能，喜欢影响他人",
                    traits: ["善于领导", "喜欢竞争", "追求成就", "善于说服"],
                    careers: ["企业家", "经理", "销售", "律师"],
                },
                {
                    icon: "📊",
                    title: "常规型 (C)",
                    description: "注重细节，喜欢有序的工作",
                    traits: ["注重细节", "做事有条理", "遵守规则", "善于组织"],
                    careers: ["会计", "行政", "图书管理员", "数据分析师"],
                },
            ],
        },
        hexagon: {
            title: "六边形模型",
            description:
                "Holland 理论的核心是六边形模型，它展示了六种类型之间的关系。相邻的类型具有相似的特征，而对角的类型则差异较大。",
            points: [
                "相邻类型具有相似特征",
                "对角类型差异较大",
                "可以组合形成复合类型",
                "帮助理解职业选择的连续性",
            ],
            mock: [
                {
                    label: "现实型 (R)",
                    score: 100,
                },
                {
                    label: "研究型 (I)",
                    score: 100,
                },
                {
                    label: "艺术型 (A)",
                    score: 100,
                },
                {
                    label: "社会型 (S)",
                    score: 100,
                },
                {
                    label: "企业型 (E)",
                    score: 100,
                },
                {
                    label: "常规型 (C)",
                    score: 100,
                },
            ],
        },
        applications: {
            title: "应用价值",
            items: [
                "职业规划：帮助个体根据自己的兴趣类型选择合适的职业方向。",
                "自我认知：通过测试，个体可以更好地了解自己的兴趣和性格特征，提升自我认知。",
                "职业发展：根据兴趣类型推荐合适的职位，帮助个人在职业生涯中取得更高的满意度和成就感。",
                "教育指导：帮助学生和教育工作者为学生提供科学的职业方向建议。",
                "企业招聘：企业可以根据候选人的职业兴趣测试结果进行更为精准的岗位匹配，提升招聘效果。",
            ],
        },
        notes: {
            title: "注意事项",
            items: [
                "回答时保持真实：测试结果基于您的真实兴趣，因此请尽量真实地作答，不要迎合社会或他人的期望。",
                "了解测试局限性：霍兰德测试仅反映您的职业兴趣，不能全面评估您的能力或综合素质，结果应与其他评估工具结合使用。",
                "测试结果仅供参考：霍兰德测试结果可以为您提供职业方向的参考，但不应完全依赖其结果作出职业决策。",
                "兴趣会随时间变化：随着经历的增长和兴趣的变化，您的职业兴趣类型可能会发生变化，因此应定期进行测试。",
                "文化差异：不同文化和背景可能会影响兴趣类型的表现，因此应综合考虑文化背景因素。",
            ],
        },
    },
    test: {
        meta: {
            title: "Holland 职业兴趣测试 - 开始测试",
            description: "通过回答一系列问题，了解你的职业倾向类型。",
            keywords: "Holland 测试,职业兴趣测试,职业倾向测试",
        },
        title: "Holland 职业兴趣测试",
        scale: {
            stronglyDisagree: "非常不同意",
            stronglyAgree: "非常同意",
        },
        questions: {
            options: ["非常同意", "同意", "一般", "不同意", "非常不同意"],
            questions: [
                {
                    question: "我喜欢使用工具和机器工作",
                    dimension: "R",
                },
                {
                    question: "我喜欢解决复杂的问题",
                    dimension: "I",
                },
                {
                    question: "我喜欢通过艺术或音乐表达自己",
                    dimension: "A",
                },
                {
                    question: "我喜欢帮助和教导他人",
                    dimension: "S",
                },
                {
                    question: "我喜欢领导和说服他人",
                    dimension: "E",
                },
                {
                    question: "我喜欢遵循既定的程序",
                    dimension: "C",
                },
                {
                    question: "我喜欢在户外工作",
                    dimension: "R",
                },
                {
                    question: "我喜欢进行研究和实验",
                    dimension: "I",
                },
                {
                    question: "我喜欢创作原创作品",
                    dimension: "A",
                },
                {
                    question: "我喜欢在团队中与他人合作",
                    dimension: "S",
                },
                {
                    question: "我喜欢销售和推广想法",
                    dimension: "E",
                },
                {
                    question: "我喜欢处理数字和数据",
                    dimension: "C",
                },
                {
                    question: "我喜欢建造和修理东西",
                    dimension: "R",
                },
                {
                    question: "我喜欢分析和解决谜题",
                    dimension: "I",
                },
                {
                    question: "我喜欢写故事或诗歌",
                    dimension: "A",
                },
                {
                    question: "我喜欢为他人提供咨询和建议",
                    dimension: "S",
                },
                {
                    question: "我喜欢创办和经营自己的事业",
                    dimension: "E",
                },
                {
                    question: "我喜欢保持详细的记录",
                    dimension: "C",
                },
                {
                    question: "我喜欢动手工作",
                    dimension: "R",
                },
                {
                    question: "我喜欢学习科学理论",
                    dimension: "I",
                },
                {
                    question: "我喜欢表演或演戏",
                    dimension: "A",
                },
                {
                    question: "我喜欢与儿童一起工作",
                    dimension: "S",
                },
                {
                    question: "我喜欢做决定和承担风险",
                    dimension: "E",
                },
                {
                    question: "我喜欢在有组织的工作环境中工作",
                    dimension: "C",
                },
                {
                    question: "我喜欢与动物或植物一起工作",
                    dimension: "R",
                },
                {
                    question: "我喜欢阅读科学文章",
                    dimension: "I",
                },
                {
                    question: "我喜欢设计和创造事物",
                    dimension: "A",
                },
                {
                    question: "我喜欢帮助他人解决问题",
                    dimension: "S",
                },
                {
                    question: "我喜欢负责项目",
                    dimension: "E",
                },
                {
                    question: "我喜欢使用计算机和软件工作",
                    dimension: "C",
                },
                {
                    question: "我喜欢操作重型机械",
                    dimension: "R",
                },
                {
                    question: "我喜欢使用数学公式",
                    dimension: "I",
                },
                {
                    question: "我喜欢创作音乐或歌曲",
                    dimension: "A",
                },
                {
                    question: "我喜欢教导和指导他人",
                    dimension: "S",
                },
                {
                    question: "我喜欢谈判和达成交易",
                    dimension: "E",
                },
                {
                    question: "我喜欢使用电子表格和数据库",
                    dimension: "C",
                },
                {
                    question: "我喜欢使用建筑材料工作",
                    dimension: "R",
                },
                {
                    question: "我喜欢进行实验室实验",
                    dimension: "I",
                },
                {
                    question: "我喜欢绘画或素描",
                    dimension: "A",
                },
                {
                    question: "我喜欢为他人提供情感支持",
                    dimension: "S",
                },
                {
                    question: "我喜欢制定商业策略",
                    dimension: "E",
                },
                {
                    question: "我喜欢维护财务记录",
                    dimension: "C",
                },
                {
                    question: "我喜欢使用电气设备",
                    dimension: "R",
                },
                {
                    question: "我喜欢分析统计数据",
                    dimension: "I",
                },
                {
                    question: "我喜欢编排舞蹈动作",
                    dimension: "A",
                },
                {
                    question: "我喜欢组织社区活动",
                    dimension: "S",
                },
                {
                    question: "我喜欢管理和监督他人",
                    dimension: "E",
                },
                {
                    question: "我喜欢使用档案系统",
                    dimension: "C",
                },
                {
                    question: "我喜欢使用木材或金属工作",
                    dimension: "R",
                },
                {
                    question: "我喜欢发展新理论",
                    dimension: "I",
                },
                {
                    question: "我喜欢写剧本或戏剧",
                    dimension: "A",
                },
                {
                    question: "我喜欢与老年人一起工作",
                    dimension: "S",
                },
                {
                    question: "我喜欢投资股票和债券",
                    dimension: "E",
                },
                {
                    question: "我喜欢使用会计软件",
                    dimension: "C",
                },
                {
                    question: "我喜欢使用农业设备",
                    dimension: "R",
                },
                {
                    question: "我喜欢进行实地研究",
                    dimension: "I",
                },
                {
                    question: "我喜欢设计服装或珠宝",
                    dimension: "A",
                },
                {
                    question: "我喜欢与残障人士一起工作",
                    dimension: "S",
                },
                {
                    question: "我喜欢制定营销策略",
                    dimension: "E",
                },
                {
                    question: "我喜欢使用库存系统",
                    dimension: "C",
                },
            ],
            dimensions: {
                R: {
                    name: "现实型",
                    description:
                        "喜欢使用工具、机器、动物或动手工作的人。他们倾向于解决实际、具体的问题。",
                },
                I: {
                    name: "研究型",
                    description:
                        "喜欢观察、学习、调查、分析、评估或解决问题的人。他们倾向于处理想法和数据。",
                },
                A: {
                    name: "艺术型",
                    description:
                        "喜欢在非结构化环境中工作，运用创造力和想象力的人。他们倾向于处理想法和艺术形式。",
                },
                S: {
                    name: "社会型",
                    description:
                        "喜欢与人一起工作，以告知、培训、发展、治愈或启发他人的人。他们倾向于帮助他人。",
                },
                E: {
                    name: "企业型",
                    description:
                        "喜欢与人一起工作，以影响、说服、领导或管理，实现组织目标或获得经济利益的人。",
                },
                C: {
                    name: "常规型",
                    description:
                        "喜欢处理数据和数字，详细执行任务并遵循他人指示的人。",
                },
            },
        },
    },
    result: {
        meta: {
            title: "Holland 职业兴趣测试结果 - 了解你的职业倾向",
            description:
                "查看你的 Holland 职业兴趣测试结果，了解你的职业倾向类型和适合的职业方向。",
            keywords: "Holland 测试结果,职业倾向分析,职业建议",
        },
        title: "你的职业倾向分析",
        subtitle: "基于你的回答，我们为你生成了详细的职业倾向分析",
        primaryType: {
            title: "主要类型",
            traits: "特征",
            careers: "适合的职业",
            default: {
                icon: "🔧",
                title: "现实型 (R)",
                description:
                    "你是一个注重实际、动手能力强的人。你喜欢使用工具和机器，善于解决具体问题。",
                traits: [
                    "动手能力强",
                    "喜欢使用工具",
                    "注重实际",
                    "善于解决具体问题",
                ],
                careers: ["工程师", "技术员", "机械师", "建筑师"],
            },
        },
        hexagon: {
            title: "六边形模型分析",
            dimensions: [
                {
                    label: "现实型 (R)",
                    score: 85,
                },
                {
                    label: "研究型 (I)",
                    score: 65,
                },
                {
                    label: "艺术型 (A)",
                    score: 45,
                },
                {
                    label: "社会型 (S)",
                    score: 55,
                },
                {
                    label: "企业型 (E)",
                    score: 70,
                },
                {
                    label: "常规型 (C)",
                    score: 75,
                },
            ],
        },
        careerAdvice: {
            title: "职业建议",
            mainDirection: {
                title: "主要职业方向",
                description: "基于你的职业倾向，以下职业方向最适合你：",
                skills: ["机械操作", "技术应用", "问题解决", "项目管理"],
            },
            development: {
                title: "发展建议",
                description: "为了在职业道路上获得更好的发展，建议你：",
                skills: [
                    "提升专业技能",
                    "培养团队协作能力",
                    "加强项目管理能力",
                    "持续学习新技术",
                ],
            },
        },
        developmentAdvice: {
            title: "发展建议",
            personalTraits: {
                title: "个性特征",
                description: "你的个性特征决定了你的职业发展方向：",
            },
            suggestions: {
                title: "发展建议",
                description: "为了在职业道路上获得更好的发展，建议你：",
            },
            items: [
                {
                    title: "技能提升",
                    description: "建议你重点提升以下技能：",
                    skills: [
                        "提升专业技能",
                        "培养团队协作能力",
                        "加强项目管理能力",
                        "持续学习新技术",
                    ],
                },
                {
                    title: "职业规划",
                    description: "为你的职业发展制定以下规划：",
                    skills: [
                        "提升专业技能",
                        "培养团队协作能力",
                        "加强项目管理能力",
                        "持续学习新技术",
                    ],
                },
            ],
        },
    },
    types: {
        meta: {
            title: "Holland 职业类型 - 了解RIASEC模型",
            description:
                "探索 Holland 职业兴趣测试的六种类型，了解其特征、适合的职业和发展路径。",
            keywords: "Holland 类型,RIASEC模型,职业类型,职业倾向,职业发展",
        },
        title: "Holland 职业类型",
        groups: {
            realistic: "现实型",
            investigative: "研究型",
            artistic: "艺术型",
            social: "社会型",
            enterprising: "企业型",
            conventional: "常规型",
        },
        types: {
            realisticTypes: ["R", "RI", "RA", "RIA"],
            investigativeTypes: ["I", "IR", "IA", "IRA"],
            artisticTypes: ["A", "AI", "AS", "AIS"],
            socialTypes: ["S", "SA", "SE", "SAE"],
            enterprisingTypes: ["E", "EA", "EC", "EAC"],
            conventionalTypes: ["C", "CR", "CE", "CRE"],
            typeMap: {
                R: {
                    name: "现实型",
                    description: "动手能力强、喜欢机械、注重实际、追求稳定。",
                    traits: [
                        "动手能力强",
                        "喜欢机械",
                        "注重实际",
                        "追求稳定",
                        "务实",
                        "独立",
                    ],
                    careers: [
                        "机械工程师",
                        "电气工程师",
                        "建筑工程师",
                        "汽车维修技师",
                        "农业技术员",
                        "木工",
                        "电工",
                        "管道工",
                    ],
                    developmentSkills: [
                        "提升专业技能",
                        "培养团队协作能力",
                        "加强项目管理能力",
                        "持续学习新技术",
                    ],
                },
                RI: {
                    name: "现实研究型",
                    description: "动手能力强、善于分析、注重实际、追求创新。",
                    traits: [
                        "动手能力强",
                        "善于分析",
                        "注重实际",
                        "追求创新",
                        "务实",
                        "独立",
                    ],
                },
                RA: {
                    name: "现实艺术型",
                    description: "动手能力强、富有创造力、注重实际、追求美感。",
                    traits: [
                        "动手能力强",
                        "富有创造力",
                        "注重实际",
                        "追求美感",
                        "务实",
                        "独立",
                    ],
                },
                RIA: {
                    name: "现实研究艺术型",
                    description: "动手能力强、善于分析、富有创造力、注重实际。",
                    traits: [
                        "动手能力强",
                        "善于分析",
                        "富有创造力",
                        "注重实际",
                        "务实",
                        "独立",
                    ],
                },
                I: {
                    name: "研究型",
                    description: "善于分析、追求真理、独立思考、注重逻辑。",
                    traits: [
                        "善于分析",
                        "追求真理",
                        "独立思考",
                        "注重逻辑",
                        "理性",
                        "创新",
                    ],
                },
                IR: {
                    name: "研究现实型",
                    description: "善于分析、动手能力强、注重实际、追求创新。",
                    traits: [
                        "善于分析",
                        "动手能力强",
                        "注重实际",
                        "追求创新",
                        "理性",
                        "创新",
                    ],
                },
                IA: {
                    name: "研究艺术型",
                    description: "善于分析、富有创造力、追求真理、注重创新。",
                    traits: [
                        "善于分析",
                        "富有创造力",
                        "追求真理",
                        "注重创新",
                        "理性",
                        "创新",
                    ],
                },
                IRA: {
                    name: "研究现实艺术型",
                    description: "善于分析、动手能力强、富有创造力、注重创新。",
                    traits: [
                        "善于分析",
                        "动手能力强",
                        "富有创造力",
                        "注重创新",
                        "理性",
                        "创新",
                    ],
                },
                A: {
                    name: "艺术型",
                    description: "富有创造力、追求美感、表达能力强、注重个性。",
                    traits: [
                        "富有创造力",
                        "追求美感",
                        "表达能力强",
                        "注重个性",
                        "感性",
                        "独特",
                    ],
                },
                AI: {
                    name: "艺术研究型",
                    description: "富有创造力、善于分析、追求创新、注重个性。",
                    traits: [
                        "富有创造力",
                        "善于分析",
                        "追求创新",
                        "注重个性",
                        "感性",
                        "独特",
                    ],
                },
                AS: {
                    name: "艺术社会型",
                    description: "富有创造力、善于交际、表达能力强、注重情感。",
                    traits: [
                        "富有创造力",
                        "善于交际",
                        "表达能力强",
                        "注重情感",
                        "感性",
                        "独特",
                    ],
                },
                AIS: {
                    name: "艺术研究社会型",
                    description: "富有创造力、善于分析、善于交际、注重个性。",
                    traits: [
                        "富有创造力",
                        "善于分析",
                        "善于交际",
                        "注重个性",
                        "感性",
                        "独特",
                    ],
                },
                S: {
                    name: "社会型",
                    description: "善于交际、富有同理心、乐于助人、注重情感。",
                    traits: [
                        "善于交际",
                        "富有同理心",
                        "乐于助人",
                        "注重情感",
                        "友善",
                        "合作",
                    ],
                },
                SA: {
                    name: "社会艺术型",
                    description: "善于交际、富有创造力、表达能力强、注重情感。",
                    traits: [
                        "善于交际",
                        "富有创造力",
                        "表达能力强",
                        "注重情感",
                        "友善",
                        "合作",
                    ],
                },
                SE: {
                    name: "社会企业型",
                    description: "善于交际、领导能力强、富有感染力、注重关系。",
                    traits: [
                        "善于交际",
                        "领导能力强",
                        "富有感染力",
                        "注重关系",
                        "友善",
                        "合作",
                    ],
                },
                SAE: {
                    name: "社会艺术企业型",
                    description: "善于交际、富有创造力、领导能力强、注重关系。",
                    traits: [
                        "善于交际",
                        "富有创造力",
                        "领导能力强",
                        "注重关系",
                        "友善",
                        "合作",
                    ],
                },
                E: {
                    name: "企业型",
                    description: "领导能力强、富有感染力、善于交际、注重成就。",
                    traits: [
                        "领导能力强",
                        "富有感染力",
                        "善于交际",
                        "注重成就",
                        "自信",
                        "进取",
                    ],
                },
                EA: {
                    name: "企业艺术型",
                    description:
                        "领导能力强、富有创造力、表达能力强、注重成就。",
                    traits: [
                        "领导能力强",
                        "富有创造力",
                        "表达能力强",
                        "注重成就",
                        "自信",
                        "进取",
                    ],
                },
                EC: {
                    name: "企业常规型",
                    description: "领导能力强、注重细节、追求效率、注重秩序。",
                    traits: [
                        "领导能力强",
                        "注重细节",
                        "追求效率",
                        "注重秩序",
                        "自信",
                        "进取",
                    ],
                },
                EAC: {
                    name: "企业艺术常规型",
                    description: "领导能力强、富有创造力、注重细节、注重成就。",
                    traits: [
                        "领导能力强",
                        "富有创造力",
                        "注重细节",
                        "注重成就",
                        "自信",
                        "进取",
                    ],
                },
                C: {
                    name: "常规型",
                    description: "注重细节、追求效率、遵守规则、注重秩序。",
                    traits: [
                        "注重细节",
                        "追求效率",
                        "遵守规则",
                        "注重秩序",
                        "谨慎",
                        "可靠",
                    ],
                },
                CR: {
                    name: "常规现实型",
                    description: "注重细节、动手能力强、追求稳定、注重秩序。",
                    traits: [
                        "注重细节",
                        "动手能力强",
                        "追求稳定",
                        "注重秩序",
                        "谨慎",
                        "可靠",
                    ],
                },
                CE: {
                    name: "常规企业型",
                    description: "注重细节、领导能力强、追求效率、注重秩序。",
                    traits: [
                        "注重细节",
                        "领导能力强",
                        "追求效率",
                        "注重秩序",
                        "谨慎",
                        "可靠",
                    ],
                },
                CRE: {
                    name: "常规现实企业型",
                    description: "注重细节、动手能力强、领导能力强、注重秩序。",
                    traits: [
                        "注重细节",
                        "动手能力强",
                        "领导能力强",
                        "注重秩序",
                        "谨慎",
                        "可靠",
                    ],
                },
            },
        },
    },
};
