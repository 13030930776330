export default {
    index: {
        meta: {
            title: "HSP 高敏感人格测试 - 了解你的敏感特质",
            description:
                "通过专业的 HSP 测试，了解你是否是一个高敏感人格者。发现你的独特敏感特质，更好地理解自己。",
            keywords: "HSP 测试,高敏感人格,敏感特质,人格测试,心理测试",
        },
        title: "HSP 高敏感人格测试",
        subtitle: "探索你的敏感特质，了解自己的独特之处",
        tips: {
            list: [
                "欢迎参加 HSP 高敏感人格测试！通过本测试，您将了解自己是否属于高敏感人格，以及如何更好地应对外界刺激。",
                "HSP 测试旨在评估您对环境刺激和他人情绪的反应程度，帮助您了解自己的敏感特质。",
                "请根据您对生活中的各种情境的感受回答问题，测试结果将帮助您更好地理解自己，并做出适合的生活调整。",
                "高敏感人格并不是缺点，而是一种特殊的个性特征。此测试将帮助您发现如何与自己的敏感性和谐相处。",
            ],
        },
        buttons: {
            whatIsHSP: "了解 HSP ",
        },
    },
    introduction: {
        meta: {
            title: "什么是 HSP 高敏感人格？- 特征与优势",
            description:
                "深入了解 HSP 高敏感人格的定义、特征及优势。帮助你更好地理解高敏感者的独特之处。",
            keywords: "HSP 介绍,高敏感人格,敏感特质,人格特征",
        },
        title: "了解 HSP 高敏感人格",
        sections: [
            {
                title: "什么是 HSP ",
                content:
                    "HSP （Highly Sensitive Person）高敏感人格测试是一种旨在评估个体是否具有高度敏感的特质的心理测评工具。根据心理学家伊莲·阿伦（Elaine Aron）的研究，高敏感人格的人通常对环境刺激（如声音、气味、情感等）反应较强，对他人的情绪更为敏感，容易感到过度刺激或疲劳。 HSP 测试帮助个体了解自己是否属于高敏感群体，并为其提供更适合的生活方式与情感管理建议。",
            },
            {
                title: "HSP 的主要特征",
                content: [
                    "深度加工：倾向于深入思考和处理信息",
                    "情绪敏感：对他人的情绪和环境变化特别敏感",
                    "感官敏感：对声音、光线、气味等物理刺激较为敏感",
                    "易于过度刺激：在强烈的环境刺激下容易感到疲惫",
                    "审美敏感：对艺术、音乐等具有较强的感受力",
                    "同理心强：容易理解和感受他人的情绪和需求",
                ],
            },
            {
                title: "HSP 的优势",
                content: [
                    "创造力：丰富的想象力和创造潜能",
                    "洞察力：敏锐的观察力和直觉",
                    "同理心：深刻理解他人的能力",
                    "专注力：对细节的关注和专注",
                    "审美能力：对美的敏锐感知",
                    "深度思考：善于深入分析问题",
                ],
            },
            {
                title: "如何更好地理解 HSP ",
                content: [
                    "接纳特质：认识到高敏感是一种正常的个性特征",
                    "合理安排：注意环境刺激的调节和休息",
                    "发挥优势：善用敏感特质带来的优势",
                    "自我关爱：学会照顾自己的需求",
                    "寻求支持：必要时寻求专业的帮助和理解",
                ],
            },
            {
                title: "HSP 的应用",
                content: [
                    "自我认知：帮助个体了解自己是否属于高敏感人格群体，从而提升自我认知。",
                    "情感调节：了解高敏感特质后，个体可以采取更加适合自己的方法来调节情绪和压力，减少外界刺激带来的负面影响。",
                    "生活方式调整：帮助高敏感的人更好地调整生活和工作环境，避免过度刺激和疲劳，保持身心健康。",
                    "人际关系改善：通过了解高敏感人格的特点，帮助个体改善与他人之间的沟通与互动，减少误解和冲突。",
                    "心理健康：高敏感人格者容易经历情绪波动，此测试能够提供应对策略，提升心理健康水平。",
                ],
            },
            {
                title: "注意事项",
                content: [
                    "回答时保持真实：请根据您对生活中各种情境的真实感受作答，不要迎合测试的期望。",
                    "测试结果仅供参考： HSP 测试仅评估个体的敏感程度，不是对个体全面的心理评估工具，结果应结合其他心理学评估进行综合分析。",
                    "尊重个体差异：每个人的敏感程度不同，测试结果只是一个参考，理解和接受自己是高敏感性的一部分，避免过度焦虑。",
                    "理解敏感性并非缺陷：高敏感并不意味着脆弱或缺点，而是一种独特的人格特征。测试的目的是帮助个体发现与敏感性和平相处的方法。",
                    "适度测试使用：此测试并非诊断工具，若有任何心理健康问题，建议寻求专业心理咨询师的帮助。",
                ],
            },
        ],
    },
    test: {
        meta: {
            title: "HSP 高敏感人格测试 - 开始测试",
            description: "开始进行 HSP 高敏感人格测试，了解你的敏感特质",
            keywords: "HSP 测试,高敏感人格测试,在线测试,心理测评",
        },
        title: "HSP 高敏感人格测试",
        defaultOptions: [
            { value: "a", text: "非常符合" },
            { value: "b", text: "比较符合" },
            { value: "c", text: "一般" },
            { value: "d", text: "比较不符合" },
            { value: "e", text: "完全不符合" },
        ],
        scale: {
            stronglyDisagree: "非常不符合",
            stronglyAgree: "非常符合",
        },
        questions: [
            {
                id: 1,
                text: "我容易被强烈的感官刺激所影响（如强光、噪音、气味等）",
            },
            {
                id: 2,
                text: "我能察觉到他人细微的情绪变化",
            },
            {
                id: 3,
                text: "在紧张或压力下，我容易感到不适",
            },
            {
                id: 4,
                text: "我对艺术和音乐有着强烈的感受力",
            },
            {
                id: 5,
                text: "我需要独处的时间来恢复精力",
            },
            {
                id: 6,
                text: "我对细节特别敏感",
            },
            {
                id: 7,
                text: "我容易被他人的情绪影响",
            },
            {
                id: 8,
                text: "我对疼痛特别敏感",
            },
            {
                id: 9,
                text: "我在嘈杂的环境中难以集中注意力",
            },
            {
                id: 10,
                text: "我经常深入思考生活中的各种问题",
            },
            {
                id: 11,
                text: "我对咖啡因等刺激物特别敏感",
            },
            {
                id: 12,
                text: "我容易被环境中的微小变化所影响",
            },
            {
                id: 13,
                text: "我对他人的痛苦有强烈的同理心",
            },
            {
                id: 14,
                text: "我需要安静的环境才能好好工作或学习",
            },
            {
                id: 15,
                text: "我对美有着敏锐的感知",
            },
            {
                id: 16,
                text: "我容易感到情绪波动",
            },
            {
                id: 17,
                text: "我对温度变化特别敏感",
            },
            {
                id: 18,
                text: "我能感受到他人未说出口的需求",
            },
            {
                id: 19,
                text: "我在人多的场合容易感到不适",
            },
            {
                id: 20,
                text: "我对食物的味道特别敏感",
            },
            {
                id: 21,
                text: "我容易被任务的期限压得喘不过气",
            },
            {
                id: 22,
                text: "我对香水或其他气味特别敏感",
            },
            {
                id: 23,
                text: "我经常需要避免一次承担太多任务",
            },
            {
                id: 24,
                text: "我对光线变化特别敏感",
            },
            {
                id: 25,
                text: "我能深刻体会艺术作品传达的情感",
            },
            {
                id: 26,
                text: "我在紧张的氛围中容易感到不安",
            },
            {
                id: 27,
                text: "我对周围环境的氛围变化很敏感",
            },
        ],
    },
    result: {
        meta: {
            title: "HSP 测试结果 - 了解你的敏感特质",
            description:
                "查看你的 HSP 高敏感人格测试结果，了解你的敏感程度和特质。",
            keywords: "HSP 测试结果,高敏感人格,敏感特质分析,个性特征",
        },
        title: "你的 HSP 测试结果",
        sensitivityLevels: {
            high: {
                threshold: 75,
                name: "高敏感",
            },
            medium: {
                threshold: 50,
                name: "中等敏感",
            },
            low: {
                threshold: 0,
                name: "低敏感",
            },
        },
        analysis: {
            overview: {
                title: "总体评估",
                content: "你的 HSP 敏感度得分为{score}分。{level}",
                levels: {
                    high: "你属于高度敏感人格，对外界刺激有着极强的感知能力。",
                    medium: "你具有中等程度的敏感特质，在某些方面表现出敏感性。",
                    low: "你的敏感度较低，对外界刺激有较强的承受能力。",
                },
            },
            characteristics: {
                title: "你的敏感特质表现",
                high: [
                    "对环境变化有着极强的感知能力",
                    "容易受到情绪和环境的影响",
                    "具有深度的思考和处理能力",
                    "对艺术和美有着敏锐的感受",
                    "需要较多独处时间来恢复能量",
                ],
                medium: [
                    "对某些环境变化比较敏感",
                    "在特定情况下会受环境影响",
                    "具有一定的思考深度",
                    "对艺术和美有一定感受力",
                    "偶尔需要独处时间",
                ],
                low: [
                    "对环境变化的适应能力较强",
                    "较少受环境和情绪影响",
                    "处事更加务实",
                    "对外界刺激有较强的承受力",
                    "社交活动较少受到影响",
                ],
            },
            suggestions: {
                title: "个性化建议",
                high: [
                    "注意调节环境刺激的强度",
                    "保证充足的独处和休息时间",
                    "发挥敏感特质带来的优势",
                    "学会设置适当的界限",
                    "培养自我关爱的习惯",
                ],
                medium: [
                    "关注自己的敏感阈值",
                    "在需要时给自己休息的空间",
                    "利用敏感特质提升洞察力",
                    "平衡社交和独处时间",
                    "发展自我调节能力",
                ],
                low: [
                    "提升对细节的关注度",
                    "培养同理心",
                    "增强情绪感知能力",
                    "发展深度思考能力",
                    "保持开放和包容的态度",
                ],
            },
        },
    },
};
