export default {
    index: {
        meta: {
            title: "TrueColors 性格测试 - 发现你的性格色彩",
            description:
                "通过 TrueColors 性格测试，了解你的主导性格色彩。发现蓝色、金色、绿色、橙色中哪种颜色最能代表你。",
            keywords: "TrueColors 测试,性格色彩,性格测试,心理测试,性格分析",
        },
        title: "TrueColors 性格色彩测试",
        subtitle: "探索你的性格色彩，了解自己的独特之处",
        tips: {
            list: [
                "欢迎参加 TrueColor 颜色性格测试！通过本测试，您将了解到自己对颜色的偏好与选择如何反映您的个性特征。",
                "TrueColor 颜色性格测试根据您的颜色偏好，为您提供有关个人性格、情感状态和行为特征的洞察。",
                "请在测试中根据您的直觉选择颜色，测试结果将帮助您更好地了解自己在不同情境下的情感与行为倾向。",
                "每个人对颜色的偏好都不同，这些偏好能够揭示您的内在需求与个性特征，帮助您在生活中作出更适合自己的决策。",
            ],
        },
        buttons: {
            whatIsTC: "什么是 TrueColors",
        },
    },
    introduction: {
        meta: {
            title: "什么是 TrueColors 性格色彩？- 特征与应用",
            description:
                "深入了解 TrueColors 性格色彩理论的定义、特征及应用。帮助你更好地理解不同性格色彩的特点。",
            keywords: "TrueColors 介绍,性格色彩理论,性格特征,人际关系",
        },
        title: "了解 TrueColors 性格色彩",
        sections: [
            {
                title: "什么是 TrueColors",
                content:
                    "TrueColor 颜色性格测试是一种基于颜色偏好来评估个体性格特征的心理测试方法。此测试的核心理念是每个人对特定颜色的偏好和感知，能够反映出个体的性格、情感状态和行为特征。测试通常通过分析个体对不同颜色的选择与反应，帮助揭示其内在的情感需求、个性倾向以及潜在的心理特质。",
            },
            {
                title: "四种性格色彩",
                content: {
                    blue: {
                        name: "蓝色性格",
                        subtitle: "关怀者与和谐追求者",
                        traits: [
                            "富有同理心和关怀心",
                            "重视人际关系和情感联系",
                            "追求和谐与真诚的互动",
                            "善于倾听和理解他人",
                        ],
                        overview:
                            "蓝色性格者是天生的关怀者，他们重视深层的人际关系，渴望建立真诚的情感联系。他们富有同理心，善于倾听和理解他人的需求。",
                        characteristics: [
                            "高度重视人际关系和情感联系",
                            "富有同理心和同情心",
                            "追求真诚和深度的交流",
                            "善于倾听和理解他人",
                            "重视个人价值和成长",
                            "追求和谐的环境",
                            "富有创造力和想象力",
                        ],
                        strengths: [
                            "出色的沟通能力",
                            "强大的同理心",
                            "良好的团队协作能力",
                            "能够建立深厚的人际关系",
                            "富有创造力和艺术感",
                            "善于激励和支持他人",
                        ],
                        challenges: [
                            "容易过分在意他人感受",
                            "可能忽视自身需求",
                            "对批评较为敏感",
                            "有时过于理想主义",
                            "可能难以做出艰难决定",
                        ],
                        growth: "蓝色性格者的成长方向是学会在关心他人的同时也要照顾好自己，建立健康的界限，培养更多的务实性思维，同时保持自己独特的关怀特质。",
                    },
                    gold: {
                        name: "金色性格",
                        subtitle: "负责任的组织者",
                        traits: [
                            "重视秩序和规划",
                            "注重责任和承诺",
                            "追求稳定和可靠",
                            "关注细节和准确性",
                        ],
                        overview:
                            "金色性格者是天生的组织者和计划者，他们重视秩序、责任和传统。他们追求稳定和可预测性，善于创建和维护系统化的结构。",
                        characteristics: [
                            "高度组织化和系统化",
                            "重视责任和义务",
                            "注重细节和准确性",
                            "遵守规则和传统",
                            "追求稳定和可预测性",
                            "重视时间管理",
                            "追求完美和高标准",
                        ],
                        strengths: [
                            "出色的组织能力",
                            "可靠和负责任",
                            "注重细节",
                            "高效的执行力",
                            "强烈的责任心",
                            "优秀的管理能力",
                        ],
                        challenges: [
                            "可能过于死板",
                            "难以适应突发变化",
                            "有时过分追求完美",
                            "可能对他人要求过高",
                            "不够灵活变通",
                        ],
                        growth: "金色性格者的成长方向是学会在保持组织和规划能力的同时增加灵活性，接纳变化和不完美，同时培养对他人不同工作方式的理解和包容。",
                    },
                    green: {
                        name: "绿色性格",
                        subtitle: "理性的思考者",
                        traits: [
                            "追求知识和理解",
                            "重视逻辑和分析",
                            "独立思考",
                            "追求创新和改进",
                        ],
                        overview:
                            "绿色性格者是天生的思考者和分析者，他们追求知识和理解，重视逻辑和理性思维。他们喜欢探索新想法，追求持续改进。",
                        characteristics: [
                            "强烈的求知欲",
                            "重视逻辑和理性",
                            "追求独立思考",
                            "关注概念和理论",
                            "重视能力和专业性",
                            "追求持续改进",
                            "善于解决复杂问题",
                        ],
                        strengths: [
                            "出色的分析能力",
                            "强大的问题解决能力",
                            "独立的思维方式",
                            "追求创新和改进",
                            "深入的专业知识",
                            "理性的决策能力",
                        ],
                        challenges: [
                            "可能显得过于冷漠",
                            "有时忽视情感因素",
                            "可能过分追求完美",
                            "交际能力可能欠佳",
                            "可能显得过于直接",
                        ],
                        growth: "绿色性格者的成长方向是在保持理性思维的同时，培养情商和人际交往能力，学会在适当的场合表达情感，建立更好的人际关系。",
                    },
                    orange: {
                        name: "橙色性格",
                        subtitle: "活力四射的行动者",
                        traits: [
                            "追求自由和冒险",
                            "充满活力和热情",
                            "灵活应变",
                            "享受当下",
                        ],
                        overview:
                            "橙色性格者是天生的行动者和冒险家，他们追求自由和刺激，充满活力和热情。他们善于把握当下，灵活应对各种情况。",
                        characteristics: [
                            "充满活力和热情",
                            "追求自由和变化",
                            "善于把握当下",
                            "灵活应变",
                            "喜欢冒险和挑战",
                            "实用主义导向",
                            "善于解决突发问题",
                        ],
                        strengths: [
                            "出色的应变能力",
                            "强大的行动力",
                            "天生的乐观精神",
                            "创造性问题解决能力",
                            "感染力强",
                            "善于把握机会",
                        ],
                        challenges: [
                            "可能缺乏耐心",
                            "难以遵守常规",
                            "有时过于冲动",
                            "可能缺乏计划性",
                            "容易感到被束缚",
                        ],
                        growth: "橙色性格者的成长方向是在保持活力和灵活性的同时，学会制定适当的计划和结构，培养耐心和持续性，建立更稳定的工作和生活方式。",
                    },
                },
            },
            {
                title: "TrueColors 的优势",
                content: [
                    "简单易懂：使用颜色作为记忆符号，容易理解和记忆",
                    "实用性强：适用于工作、学习、人际关系等多个领域",
                    "提升沟通：帮助理解不同性格类型的沟通方式",
                    "自我认知：加深对自己和他人的了解",
                    "团队协作：促进团队成员间的理解和配合",
                ],
            },
            {
                title: "应用场景",
                content: [
                    "自我认知：通过分析颜色偏好，帮助个体深入了解自己的性格特点、情感需求和行为模式。",
                    "心理健康：此测试能够揭示个体的情感状态和压力水平，为心理健康的自我评估提供参考。",
                    "职业规划：根据性格特征，帮助个体选择更适合自己的职业方向，优化职业发展路径。",
                    "人际关系：了解自己和他人的颜色性格，有助于改善人际沟通，避免冲突，提升合作效率。",
                    "情绪调节：通过识别自己对特定颜色的反应，个体可以更好地管理情绪，减少压力和焦虑。",
                ],
            },
            {
                title: "注意事项",
                content: [
                    "保持真实：请根据自己对颜色的真实感觉作答，不要过于迎合测试的预设选项。",
                    "结果仅供参考：颜色性格测试的结果仅供参考，并不能完全准确地描述您的个性特征，需结合其他评估工具综合分析。",
                    "避免焦虑：测试结果是一个自我探索的工具，结果不应成为焦虑或压力的来源。",
                    "环境因素：测试时，确保光线适宜，以免影响对颜色的感知。",
                    "个体差异：每个人的颜色偏好和性格特征都有差异，测试结果的适用性因人而异，应结合实际情况解读。",
                ],
            },
        ],
    },
    detail: {
        overview: "概述",
        characteristics: "性格特征",
        strengths: "优势特点",
        challenges: "潜在挑战",
        growth: "成长方向",
        traits: "核心特质",
    },
    test: {
        meta: {
            title: "TrueColors 性格测试 - 发现你的性格色彩",
            description: "完成 TrueColors 性格测试，了解你的主导性格色彩类型。",
            keywords: "TrueColors 测试,性格测试,性格色彩测试,在线测试",
        },
        title: "TrueColors 性格测试",
        questions: [
            {
                id: 1,
                options: [
                    { text: "我喜欢与他人建立深厚的情感联系", color: "blue" },
                    { text: "我注重计划和按时完成任务", color: "gold" },
                    { text: "我喜欢分析问题并寻找解决方案", color: "green" },
                    { text: "我喜欢自由自在，随心所欲", color: "orange" },
                ],
            },
            {
                id: 2,
                options: [
                    { text: "我经常关心他人的感受", color: "blue" },
                    { text: "我喜欢有条理和规律的生活", color: "gold" },
                    { text: "我喜欢探索新的想法和概念", color: "green" },
                    { text: "我喜欢冒险和刺激的体验", color: "orange" },
                ],
            },
            {
                id: 3,
                options: [
                    { text: "我善于倾听和理解他人", color: "blue" },
                    { text: "我做事认真负责，注重细节", color: "gold" },
                    { text: "我喜欢独立思考和解决问题", color: "green" },
                    { text: "我喜欢即兴发挥，灵活应变", color: "orange" },
                ],
            },
            {
                id: 4,
                options: [
                    { text: "我重视和谐的人际关系", color: "blue" },
                    { text: "我喜欢按部就班，循序渐进", color: "gold" },
                    { text: "我追求知识和专业能力", color: "green" },
                    { text: "我喜欢尝试新事物和改变", color: "orange" },
                ],
            },
            {
                id: 5,
                options: [
                    { text: "我容易感受到他人的情绪", color: "blue" },
                    { text: "我注重时间管理和效率", color: "gold" },
                    { text: "我喜欢研究事物的原理", color: "green" },
                    { text: "我喜欢自由发挥创意", color: "orange" },
                ],
            },
            {
                id: 6,
                options: [
                    { text: "我愿意花时间倾听他人的烦恼", color: "blue" },
                    { text: "我做事有计划和目标", color: "gold" },
                    { text: "我喜欢思考复杂的问题", color: "green" },
                    { text: "我喜欢充满活力的环境", color: "orange" },
                ],
            },
            {
                id: 7,
                options: [
                    { text: "我善于安慰和鼓励他人", color: "blue" },
                    { text: "我重视规则和秩序", color: "gold" },
                    { text: "我喜欢探讨深层次的话题", color: "green" },
                    { text: "我喜欢行动和实践", color: "orange" },
                ],
            },
            {
                id: 8,
                options: [
                    { text: "我重视真诚的情感交流", color: "blue" },
                    { text: "我做事追求完美和准确", color: "gold" },
                    { text: "我喜欢提出创新的想法", color: "green" },
                    { text: "我喜欢富有挑战性的任务", color: "orange" },
                ],
            },
            {
                id: 9,
                options: [
                    { text: "我愿意为他人付出时间和精力", color: "blue" },
                    { text: "我注重责任和承诺", color: "gold" },
                    { text: "我喜欢学习新知识", color: "green" },
                    { text: "我喜欢竞争和挑战", color: "orange" },
                ],
            },
            {
                id: 10,
                options: [
                    { text: "我重视情感上的联系", color: "blue" },
                    { text: "我做事有条理和规划", color: "gold" },
                    { text: "我喜欢理性分析问题", color: "green" },
                    { text: "我喜欢多样化的生活", color: "orange" },
                ],
            },
            {
                id: 11,
                options: [
                    { text: "我容易理解他人的需求", color: "blue" },
                    { text: "我注重传统和稳定", color: "gold" },
                    { text: "我追求专业和卓越", color: "green" },
                    { text: "我喜欢自由和变化", color: "orange" },
                ],
            },
            {
                id: 12,
                options: [
                    { text: "我善于创造温暖的氛围", color: "blue" },
                    { text: "我做事细心谨慎", color: "gold" },
                    { text: "我喜欢探索未知领域", color: "green" },
                    { text: "我喜欢即兴决策", color: "orange" },
                ],
            },
            {
                id: 13,
                options: [
                    { text: "我重视人与人之间的关系", color: "blue" },
                    { text: "我注重效率和成果", color: "gold" },
                    { text: "我追求逻辑和理性", color: "green" },
                    { text: "我喜欢冒险和尝试", color: "orange" },
                ],
            },
            {
                id: 14,
                options: [
                    { text: "我善于体贴他人的感受", color: "blue" },
                    { text: "我做事有始有终", color: "gold" },
                    { text: "我喜欢思考和分析", color: "green" },
                    { text: "我喜欢活在当下", color: "orange" },
                ],
            },
            {
                id: 15,
                options: [
                    { text: "我愿意为他人提供帮助", color: "blue" },
                    { text: "我注重质量和标准", color: "gold" },
                    { text: "我追求知识和真理", color: "green" },
                    { text: "我喜欢自由发挥", color: "orange" },
                ],
            },
        ],
        tips: "请根据最符合你的程度进行排序，最符合你的选项给4分，次之给3分，再次之给2分，最不符合的给1分",
    },
    result: {
        meta: {
            title: "TrueColors 性格测试结果",
            description:
                "查看你的 TrueColors 性格测试结果，了解你的主导性格色彩。",
            keywords: "TrueColors 结果,性格色彩分析,性格测试结果",
        },
        title: "你的 TrueColors 性格测试结果",
        subtitle: "发现你的主导性格色彩",
        scoreTitle: "你的性格色彩得分",
        dominantColor: "主导色彩",
        secondaryColor: "次要色彩",
        analysis: {
            title: "性格分析",
            primaryType: "主要类型",
            secondaryType: "次要类型",
            combination: "性格组合分析",
        },
        combination: {
            "blue-gold":
                "你既重视人际关系，也注重责任和规划。这种组合使你成为一个可靠的支持者和组织者。",
            "blue-green":
                "你既有同理心，又具有分析能力。这种组合让你能够深入理解他人，同时保持理性思考。",
            "blue-orange":
                "你既关心他人，又充满活力。这种组合使你成为一个富有同情心且有趣的伙伴。",
            "gold-green":
                "你既重视秩序，又具有创新思维。这种组合让你能够系统地解决问题并追求改进。",
            "gold-orange":
                "你既注重计划，又具有适应能力。这种组合使你能够在保持效率的同时保持灵活性。",
            "green-orange":
                "你既有分析能力，又喜欢冒险。这种组合让你能够创新地解决问题并付诸行动。",
        },
        buttons: {
            retake: "重新测试",
            share: "分享结果",
            download: "下载报告",
        },
    },
};
