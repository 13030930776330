export default {
    index: {
        meta: {
            title: "True Colors Personality Test - Discover Your Color Spectrum",
            description:
                "Take the True Colors personality test to understand your dominant color type. Find out which of Blue, Gold, Green, or Orange best represents you.",
            keywords:
                "True Colors test,personality colors,personality test,psychological assessment,color personality",
        },
        title: "True Colors Personality Test",
        subtitle:
            "Explore your personality colors and understand your uniqueness",
        tips: {
            list: [
                "Welcome to the TrueColor Personality Test! By taking this test, you will learn how your color preferences and choices reflect your personality traits.",
                "The TrueColor Personality Test provides insights into your personality, emotional state, and behavioral tendencies based on your color preferences.",
                "Please choose the colors intuitively during the test. The results will help you understand your emotional and behavioral tendencies in different situations.",
                "Everyone has different color preferences, and these preferences can reveal your inner needs and personality traits, helping you make decisions that better suit your life.",
            ],
        },
        buttons: {
            whatIsTC: "What is True Colors",
        },
    },
    introduction: {
        meta: {
            title: "What is True Colors Personality? - Traits and Applications",
            description:
                "Learn about the True Colors personality theory, its characteristics, and applications. Better understand the traits of different personality colors.",
            keywords:
                "True Colors introduction,personality color theory,personality traits,interpersonal relationships",
        },
        title: "Understanding True Colors",
        sections: [
            {
                title: "What is True Colors?",
                content:
                    "The TrueColor Personality Test is a psychological assessment tool that evaluates an individual's personality traits based on their color preferences. The underlying principle of this test is that the colors a person is drawn to can reflect their personality, emotional state, and behavioral tendencies. By analyzing an individual's responses to various colors, the test helps uncover their emotional needs, personality traits, and potential psychological characteristics.",
            },
            {
                title: "The Four Personality Colors",
                content: {
                    blue: {
                        name: "Blue Personality",
                        subtitle: "The Caring Harmonizer",
                        traits: [
                            "Empathetic and caring",
                            "Values relationships and emotional connections",
                            "Seeks harmony and authentic interactions",
                            "Excellent listener and understanding",
                        ],
                        overview:
                            "Blue personalities are natural caregivers who value deep personal relationships and seek authentic emotional connections. They are empathetic, good listeners, and understand others' needs.",
                        characteristics: [
                            "Highly values relationships and emotional connections",
                            "Naturally empathetic and compassionate",
                            "Seeks authentic and deep communication",
                            "Excellent listening skills",
                            "Values personal growth and development",
                            "Pursues harmonious environments",
                            "Creative and imaginative",
                        ],
                        strengths: [
                            "Strong communication skills",
                            "Deep empathy",
                            "Excellent team collaboration",
                            "Builds deep relationships",
                            "Creative and artistic",
                            "Inspires and supports others",
                        ],
                        challenges: [
                            "May be overly concerned with others' feelings",
                            "Can neglect own needs",
                            "Sensitive to criticism",
                            "Sometimes too idealistic",
                            "May struggle with tough decisions",
                        ],
                        growth: "Blue personalities can grow by learning to balance caring for others with self-care, establishing healthy boundaries, and developing more practical thinking while maintaining their unique caring nature.",
                    },
                    gold: {
                        name: "Gold Personality",
                        subtitle: "The Responsible Organizer",
                        traits: [
                            "Values order and planning",
                            "Focuses on responsibility and commitment",
                            "Seeks stability and reliability",
                            "Attention to detail and accuracy",
                        ],
                        overview:
                            "Gold personalities are natural organizers and planners who value order, responsibility, and tradition. They seek stability and predictability, excelling at creating and maintaining systematic structures.",
                        characteristics: [
                            "Highly organized and systematic",
                            "Values responsibility and duty",
                            "Detail-oriented and accurate",
                            "Follows rules and traditions",
                            "Seeks stability and predictability",
                            "Strong time management",
                            "Pursues perfection and high standards",
                        ],
                        strengths: [
                            "Excellent organizational skills",
                            "Reliable and responsible",
                            "Detail-oriented",
                            "Strong execution abilities",
                            "High sense of duty",
                            "Excellent management skills",
                        ],
                        challenges: [
                            "Can be too rigid",
                            "Struggles with sudden changes",
                            "May be perfectionist",
                            "Can be too demanding of others",
                            "Sometimes inflexible",
                        ],
                        growth: "Gold personalities can grow by learning to balance their organizational skills with flexibility, accepting change and imperfection, while developing understanding for different working styles.",
                    },
                    green: {
                        name: "Green Personality",
                        subtitle: "The Rational Thinker",
                        traits: [
                            "Seeks knowledge and understanding",
                            "Values logic and analysis",
                            "Independent thinking",
                            "Pursues innovation and improvement",
                        ],
                        overview:
                            "Green personalities are natural thinkers and analysts who seek knowledge and understanding. They value logic and rational thinking, enjoying the exploration of new ideas and continuous improvement.",
                        characteristics: [
                            "Strong desire for knowledge",
                            "Values logic and rationality",
                            "Independent thinking",
                            "Focuses on concepts and theories",
                            "Values competence and expertise",
                            "Seeks continuous improvement",
                            "Skilled at solving complex problems",
                        ],
                        strengths: [
                            "Strong analytical skills",
                            "Excellent problem-solving abilities",
                            "Independent thinking",
                            "Innovation and improvement focus",
                            "Deep expertise",
                            "Rational decision-making",
                        ],
                        challenges: [
                            "May appear too detached",
                            "Can overlook emotional factors",
                            "May be perfectionist",
                            "Social skills may need development",
                            "Can be too direct",
                        ],
                        growth: "Green personalities can grow by balancing their rational thinking with emotional intelligence, learning to express feelings appropriately, and building better interpersonal relationships.",
                    },
                    orange: {
                        name: "Orange Personality",
                        subtitle: "The Energetic Doer",
                        traits: [
                            "Seeks freedom and adventure",
                            "Full of energy and enthusiasm",
                            "Adaptable and flexible",
                            "Lives in the present",
                        ],
                        overview:
                            "Orange personalities are natural actors and adventurers who seek freedom and excitement. They are full of energy and enthusiasm, skilled at living in the moment and adapting to various situations.",
                        characteristics: [
                            "Full of energy and enthusiasm",
                            "Seeks freedom and change",
                            "Lives in the present",
                            "Highly adaptable",
                            "Enjoys adventure and challenges",
                            "Pragmatic approach",
                            "Good at handling emergencies",
                        ],
                        strengths: [
                            "Excellent adaptability",
                            "Strong action orientation",
                            "Natural optimism",
                            "Creative problem-solving",
                            "Influential",
                            "Seizes opportunities",
                        ],
                        challenges: [
                            "May lack patience",
                            "Struggles with routine",
                            "Can be impulsive",
                            "May lack planning",
                            "Feels restricted easily",
                        ],
                        growth: "Orange personalities can grow by learning to balance their energy and flexibility with appropriate planning and structure, developing patience and consistency, while building more stable work and life patterns.",
                    },
                },
            },
            {
                title: "Advantages of True Colors",
                content: [
                    "Simple: Uses colors as memory aids, easy to understand and remember",
                    "Practical: Applicable in work, study, relationships, and other areas",
                    "Enhanced Communication: Helps understand different personality types",
                    "Self-Awareness: Deepens understanding of self and others",
                    "Team Collaboration: Promotes understanding between team members",
                ],
            },
            {
                title: "Applications",
                content: [
                    "Self-awareness: Helps individuals gain a deeper understanding of their personality, emotional needs, and behavioral patterns by analyzing their color preferences.",
                    "Mental Health: The test can reveal an individual's emotional state and stress levels, providing a reference for self-assessment of mental health.",
                    "Career Planning: Based on personality traits, the test can assist individuals in choosing a career path that better aligns with their strengths and tendencies.",
                    "Interpersonal Relationships: Understanding your own and others' color personalities can improve communication, avoid conflicts, and enhance collaboration.",
                    "Emotional Regulation: By identifying reactions to specific colors, individuals can better manage their emotions, reducing stress and anxiety.",
                ],
            },
            {
                title: "Important Notes",
                content: [
                    "Be Honest: Answer the questions based on your true feelings towards colors, and avoid trying to fit the answers to the test's preset options.",
                    "Results for Reference Only: The results of the color personality test are for reference only and cannot fully describe your personality traits. They should be combined with other assessments for a comprehensive analysis.",
                    "Avoid Anxiety: The test results are a tool for self-exploration and should not be a source of anxiety or stress.",
                    "Environmental Factors: Ensure the lighting is appropriate during the test to avoid misperception of colors.",
                    "Individual Differences: Every person's color preferences and personality traits are unique, and the applicability of the test results varies from person to person. The results should be interpreted based on individual circumstances.",
                ],
            },
        ],
    },
    detail: {
        overview: "Overview",
        characteristics: "Key Characteristics",
        strengths: "Strengths",
        challenges: "Potential Challenges",
        growth: "Growth Areas",
        traits: "Core Traits",
    },
    test: {
        meta: {
            title: "True Colors Personality Test - Discover Your Color Spectrum",
            description:
                "Complete the True Colors personality test to understand your dominant color type.",
            keywords:
                "True Colors test,personality test,color personality test,online test",
        },
        title: "True Colors Personality Test",
        questions: [
            {
                id: 1,
                options: [
                    {
                        text: "I enjoy building deep emotional connections with others",
                        color: "blue",
                    },
                    {
                        text: "I focus on planning and completing tasks on time",
                        color: "gold",
                    },
                    {
                        text: "I like analyzing problems and finding solutions",
                        color: "green",
                    },
                    {
                        text: "I enjoy being free and spontaneous",
                        color: "orange",
                    },
                ],
            },
            {
                id: 2,
                options: [
                    {
                        text: "I often care about others' feelings",
                        color: "blue",
                    },
                    {
                        text: "I prefer an organized and structured life",
                        color: "gold",
                    },
                    {
                        text: "I enjoy exploring new ideas and concepts",
                        color: "green",
                    },
                    {
                        text: "I like adventure and excitement",
                        color: "orange",
                    },
                ],
            },
            {
                id: 3,
                options: [
                    {
                        text: "I am good at listening and understanding others",
                        color: "blue",
                    },
                    {
                        text: "I am responsible and detail-oriented",
                        color: "gold",
                    },
                    {
                        text: "I enjoy independent thinking and problem-solving",
                        color: "green",
                    },
                    {
                        text: "I like to improvise and be flexible",
                        color: "orange",
                    },
                ],
            },
            {
                id: 4,
                options: [
                    { text: "I value harmonious relationships", color: "blue" },
                    {
                        text: "I prefer to follow established procedures",
                        color: "gold",
                    },
                    {
                        text: "I pursue knowledge and expertise",
                        color: "green",
                    },
                    {
                        text: "I enjoy trying new things and changes",
                        color: "orange",
                    },
                ],
            },
            {
                id: 5,
                options: [
                    {
                        text: "I am sensitive to others' emotions",
                        color: "blue",
                    },
                    {
                        text: "I focus on time management and efficiency",
                        color: "gold",
                    },
                    { text: "I like to study how things work", color: "green" },
                    {
                        text: "I enjoy expressing creativity freely",
                        color: "orange",
                    },
                ],
            },
            {
                id: 6,
                options: [
                    {
                        text: "I am willing to listen to others' troubles",
                        color: "blue",
                    },
                    { text: "I work with plans and goals", color: "gold" },
                    {
                        text: "I enjoy thinking about complex problems",
                        color: "green",
                    },
                    {
                        text: "I thrive in energetic environments",
                        color: "orange",
                    },
                ],
            },
            {
                id: 7,
                options: [
                    {
                        text: "I am good at comforting and encouraging others",
                        color: "blue",
                    },
                    { text: "I value rules and order", color: "gold" },
                    { text: "I enjoy discussing deep topics", color: "green" },
                    { text: "I prefer action and practice", color: "orange" },
                ],
            },
            {
                id: 8,
                options: [
                    {
                        text: "I value sincere emotional connections",
                        color: "blue",
                    },
                    {
                        text: "I strive for perfection and accuracy",
                        color: "gold",
                    },
                    {
                        text: "I like to propose innovative ideas",
                        color: "green",
                    },
                    { text: "I enjoy challenging tasks", color: "orange" },
                ],
            },
            {
                id: 9,
                options: [
                    {
                        text: "I am willing to spend time and energy on others",
                        color: "blue",
                    },
                    {
                        text: "I emphasize responsibility and commitment",
                        color: "gold",
                    },
                    { text: "I enjoy learning new knowledge", color: "green" },
                    {
                        text: "I like competition and challenges",
                        color: "orange",
                    },
                ],
            },
            {
                id: 10,
                options: [
                    { text: "I value emotional connections", color: "blue" },
                    { text: "I am organized and methodical", color: "gold" },
                    {
                        text: "I prefer rational analysis of problems",
                        color: "green",
                    },
                    { text: "I enjoy diverse experiences", color: "orange" },
                ],
            },
            {
                id: 11,
                options: [
                    {
                        text: "I easily understand others' needs",
                        color: "blue",
                    },
                    { text: "I value tradition and stability", color: "gold" },
                    {
                        text: "I pursue excellence and expertise",
                        color: "green",
                    },
                    { text: "I embrace freedom and change", color: "orange" },
                ],
            },
            {
                id: 12,
                options: [
                    {
                        text: "I am good at creating a warm atmosphere",
                        color: "blue",
                    },
                    { text: "I am careful and cautious", color: "gold" },
                    {
                        text: "I like exploring unknown territories",
                        color: "green",
                    },
                    {
                        text: "I enjoy spontaneous decision-making",
                        color: "orange",
                    },
                ],
            },
            {
                id: 13,
                options: [
                    {
                        text: "I value interpersonal relationships",
                        color: "blue",
                    },
                    {
                        text: "I focus on efficiency and results",
                        color: "gold",
                    },
                    { text: "I pursue logic and rationality", color: "green" },
                    {
                        text: "I enjoy adventure and experimentation",
                        color: "orange",
                    },
                ],
            },
            {
                id: 14,
                options: [
                    {
                        text: "I am considerate of others' feelings",
                        color: "blue",
                    },
                    {
                        text: "I see things through to completion",
                        color: "gold",
                    },
                    { text: "I enjoy thinking and analyzing", color: "green" },
                    {
                        text: "I like living in the present moment",
                        color: "orange",
                    },
                ],
            },
            {
                id: 15,
                options: [
                    { text: "I am willing to help others", color: "blue" },
                    { text: "I focus on quality and standards", color: "gold" },
                    { text: "I pursue knowledge and truth", color: "green" },
                    { text: "I enjoy free expression", color: "orange" },
                ],
            },
        ],
        tips: "Please rank the options based on how much you identify with each description. Choose 4 points for the most accurate description, 3 points for the next, 2 points for the next, and 1 point for the least accurate.",
    },
    result: {
        meta: {
            title: "True Colors Personality Test Results",
            description:
                "View your True Colors personality test results and understand your dominant color type.",
            keywords:
                "True Colors results,color personality analysis,personality test results",
        },
        title: "Your True Colors Test Results",
        subtitle: "Discover Your Dominant Color Personality",
        scoreTitle: "Your Color Scores",
        dominantColor: "Dominant Color",
        secondaryColor: "Secondary Color",
        analysis: {
            title: "Personality Analysis",
            primaryType: "Primary Type",
            secondaryType: "Secondary Type",
            combination: "Personality Combination Analysis",
        },
        combination: {
            "blue-gold":
                "You value both relationships and responsibility. This combination makes you a reliable supporter and organizer.",
            "blue-green":
                "You combine empathy with analytical ability. This allows you to understand others deeply while maintaining rational thinking.",
            "blue-orange":
                "You are both caring and energetic. This combination makes you a compassionate and fun companion.",
            "gold-green":
                "You value both order and innovation. This combination enables you to solve problems systematically while seeking improvement.",
            "gold-orange":
                "You combine planning with adaptability. This allows you to maintain efficiency while staying flexible.",
            "green-orange":
                "You are both analytical and adventurous. This combination enables you to solve problems innovatively and take action.",
        },
        buttons: {
            retake: "Retake Test",
            share: "Share Results",
            download: "Download Report",
        },
    },
};
