export default {
    index: {
        meta: {
            title: "Enneagram Test - Discover Your Personality Type",
            description:
                "Take a professional Enneagram test to understand your personality type, behavior patterns, and interpersonal relationships.",
            keywords:
                "enneagram, personality test, personality type, enneagram test, behavior patterns",
        },
        title: "Enneagram Test",
        subtitle:
            "Explore your personality type and understand your behavior patterns and relationships",
        tips: {
            list: [
                "Welcome to the Enneagram test! By taking this test, you will gain insights into your personality tendencies based on nine distinct types, helping you understand yourself better and make more meaningful decisions in life.",
                "The Enneagram test allows you to explore your core motivations, fears, and emotional patterns, enhancing your self-awareness and improving your interpersonal relationships.",
                "Please answer the Enneagram test questions as honestly as possible, based on your true feelings. The results will help you understand your behavior patterns and potential growth opportunities.",
                "The Enneagram test is designed to help you discover your personality traits and provide a tailored personal growth path.",
            ],
        },
        buttons: {
            whatIsEnneagram: "What is Enneagram",
            enneagramTypes: "Enneagram Types",
        },
    },
    introduction: {
        meta: {
            title: "Enneagram Introduction - Understanding the Enneagram Theory",
            description:
                "Deep dive into the Enneagram theory, understanding the characteristics, strengths, and limitations of nine personality types.",
            keywords:
                "enneagram, personality types, personality theory, personal development, personality analysis",
        },
        title: "Introduction to Enneagram Theory",
        whatIsEnneagram: {
            title: "What is the Enneagram?",
            content:
                "The Enneagram is an ancient and profound personality type system that describes nine basic personality types, each with its unique thinking patterns, emotional responses, and behavioral tendencies. This system helps us not only understand ourselves but also better understand others.",
        },
        types: {
            title: "Nine Personality Types",
            content:
                "The Enneagram divides human personality into nine basic types, each with its unique characteristics:",
            list: [
                {
                    title: "The Perfectionist",
                    description:
                        "Strives for perfection, focuses on principles, has a strong sense of responsibility and morality.",
                },
                {
                    title: "The Helper",
                    description:
                        "Empathetic, helpful, desires love and recognition.",
                },
                {
                    title: "The Achiever",
                    description:
                        "Pursues success, image-conscious, highly adaptable.",
                },
                {
                    title: "The Artist",
                    description:
                        "Creative, seeks uniqueness, emotionally rich.",
                },
                {
                    title: "The Observer",
                    description:
                        "Analytical, knowledge-seeking, values privacy.",
                },
                {
                    title: "The Loyalist",
                    description:
                        "Security-focused, seeks support, responsible.",
                },
                {
                    title: "The Enthusiast",
                    description: "Optimistic, pleasure-seeking, imaginative.",
                },
                {
                    title: "The Leader",
                    description: "Confident, protective, seeks control.",
                },
                {
                    title: "The Peacemaker",
                    description: "Peaceful, inclusive, avoids conflict.",
                },
            ],
        },
        applications: {
            title: "Applications",
            intro: "The Enneagram theory has important applications in various fields:",
            items: [
                "Self-awareness: Helps individuals understand their personality traits, motivations, and behavior patterns, enhancing self-awareness.",
                "Interpersonal Relationships: Improves communication and interaction with others by understanding both your own and others’ personality types.",
                "Career Development: Offers valuable insights for personal career growth and helps individuals find career paths suited to their personality type.",
                "Mental Health: Provides deep insights into an individual’s psychological state, assisting with managing stress, anxiety, and emotional struggles.",
                "Team Building: Enhances teamwork by helping team members understand each other’s personality differences, fostering collaboration and efficiency.",
                "Leadership Development: Offers insights into leadership styles and helps improve leadership skills and team management.",
            ],
        },
        notes: {
            title: "Important Notes",
            items: [
                "Answer Honestly: Try to answer the questions truthfully, based on your inner feelings and habits, not to please or avoid certain answers.",
                "Do Not Over-interpret: The results should be seen as a tool for reference, not as an absolute conclusion.",
                "Personality Development is Dynamic: Personality evolves over time, so the test results may change as you grow and experience life differently.",
                "Cultural Differences: Cultural backgrounds may influence the manifestation of personality types, so be mindful of cultural differences in test results.",
                "For Reference Only: The Enneagram test is a tool for self-awareness, but it should not replace professional psychological assessments. Seek professional help if experiencing significant emotional distress.",
            ],
        },
    },
    detail: {
        meta: {
            title: "Enneagram Type {type} {typeName} - Detailed Analysis",
            description:
                "In-depth analysis of Enneagram Type {type} {typeName}, including core traits, strengths, challenges, and growth opportunities.",
            keywords:
                "enneagram type {type} {typeName}, personality analysis, personal growth, behavior patterns, personality development",
        },
        coreTraits: {
            title: "Core Traits",
            types: [
                {
                    list: [
                        "Strong sense of right and wrong",
                        "High standards and perfectionism",
                        "Self-discipline and responsibility",
                        "Critical thinking and analysis",
                        "Desire for improvement",
                    ],
                },
                {
                    list: [
                        "Empathetic and caring",
                        "Generous and helpful",
                        "People-pleasing tendencies",
                        "Strong emotional intelligence",
                        "Desire for connection",
                    ],
                },
                {
                    list: [
                        "Goal-oriented and ambitious",
                        "Image-conscious and adaptable",
                        "Efficient and productive",
                        "Confident and charismatic",
                        "Success-driven",
                    ],
                },
                {
                    list: [
                        "Creative and artistic",
                        "Emotionally expressive",
                        "Individualistic",
                        "Deep and introspective",
                        "Authentic and unique",
                    ],
                },
                {
                    list: [
                        "Analytical and observant",
                        "Knowledge-seeking",
                        "Independent and private",
                        "Objective and detached",
                        "Focused and thorough",
                    ],
                },
                {
                    list: [
                        "Loyal and committed",
                        "Security-focused",
                        "Responsible and reliable",
                        "Cautious and prepared",
                        "Supportive and protective",
                    ],
                },
                {
                    list: [
                        "Optimistic and enthusiastic",
                        "Adventure-seeking",
                        "Spontaneous and fun-loving",
                        "Versatile and adaptable",
                        "Future-oriented",
                    ],
                },
                {
                    list: [
                        "Confident and assertive",
                        "Protective and powerful",
                        "Direct and decisive",
                        "Independent and self-reliant",
                        "Justice-oriented",
                    ],
                },
                {
                    list: [
                        "Peaceful and agreeable",
                        "Patient and accepting",
                        "Diplomatic and mediating",
                        "Easy-going and relaxed",
                        "Harmony-seeking",
                    ],
                },
            ],
        },
        strengths: {
            title: "Strengths",
            types: [
                {
                    list: [
                        "High ethical standards",
                        "Strong organizational skills",
                        "Reliable and responsible",
                        "Detail-oriented",
                        "Self-disciplined",
                    ],
                },
                {
                    list: [
                        "Excellent interpersonal skills",
                        "Natural caregivers",
                        "Emotionally intuitive",
                        "Warm and supportive",
                        "Strong team players",
                    ],
                },
                {
                    list: [
                        "Highly motivated",
                        "Excellent problem solvers",
                        "Adaptable and flexible",
                        "Natural leaders",
                        "Goal achievers",
                    ],
                },
                {
                    list: [
                        "Highly creative",
                        "Deep emotional understanding",
                        "Authentic and genuine",
                        "Unique perspective",
                        "Artistic expression",
                    ],
                },
                {
                    list: [
                        "Analytical thinkers",
                        "Independent workers",
                        "Objective observers",
                        "Knowledge experts",
                        "Problem solvers",
                    ],
                },
                {
                    list: [
                        "Loyal and committed",
                        "Responsible team members",
                        "Good at planning",
                        "Reliable and trustworthy",
                        "Strong supporters",
                    ],
                },
                {
                    list: [
                        "Optimistic outlook",
                        "Creative problem solvers",
                        "Enthusiastic motivators",
                        "Adaptable to change",
                        "Natural entertainers",
                    ],
                },
                {
                    list: [
                        "Strong leaders",
                        "Protective of others",
                        "Decisive and direct",
                        "Natural authority",
                        "Justice-oriented",
                    ],
                },
                {
                    list: [
                        "Peacemakers",
                        "Good listeners",
                        "Diplomatic mediators",
                        "Patient and accepting",
                        "Harmony creators",
                    ],
                },
            ],
        },
        challenges: {
            title: "Challenges",
            types: [
                {
                    list: [
                        "Perfectionism can be overwhelming",
                        "Self-criticism",
                        "Rigid thinking",
                        "Difficulty relaxing",
                        "High expectations",
                    ],
                },
                {
                    list: [
                        "Difficulty saying no",
                        "Neglecting own needs",
                        "Over-involvement in others",
                        "Seeking validation",
                        "Emotional dependency",
                    ],
                },
                {
                    list: [
                        "Workaholism",
                        "Image obsession",
                        "Fear of failure",
                        "Competitive nature",
                        "Emotional suppression",
                    ],
                },
                {
                    list: [
                        "Emotional intensity",
                        "Self-absorption",
                        "Moodiness",
                        "Difficulty fitting in",
                        "Identity issues",
                    ],
                },
                {
                    list: [
                        "Emotional detachment",
                        "Social isolation",
                        "Over-analysis",
                        "Difficulty expressing feelings",
                        "Privacy obsession",
                    ],
                },
                {
                    list: [
                        "Anxiety and worry",
                        "Indecisiveness",
                        "Over-cautiousness",
                        "Dependency on others",
                        "Fear of change",
                    ],
                },
                {
                    list: [
                        "Impulsiveness",
                        "Difficulty committing",
                        "Scattered focus",
                        "Avoidance of pain",
                        "Restlessness",
                    ],
                },
                {
                    list: [
                        "Stubbornness",
                        "Control issues",
                        "Intensity",
                        "Difficulty with vulnerability",
                        "Aggressive tendencies",
                    ],
                },
                {
                    list: [
                        "Passivity",
                        "Conflict avoidance",
                        "Difficulty making decisions",
                        "Suppression of needs",
                        "Resistance to change",
                    ],
                },
            ],
        },
        growth: {
            title: "Growth Tips",
            types: [
                {
                    list: [
                        "Learn to accept imperfection",
                        "Practice self-compassion",
                        "Be more flexible",
                        "Balance work and rest",
                        "Accept different perspectives",
                    ],
                },
                {
                    list: [
                        "Set healthy boundaries",
                        "Practice self-care",
                        "Learn to say no",
                        "Value your own needs",
                        "Develop independence",
                    ],
                },
                {
                    list: [
                        "Balance work and life",
                        "Focus on authenticity",
                        "Accept failure as learning",
                        "Express emotions openly",
                        "Value relationships over achievements",
                    ],
                },
                {
                    list: [
                        "Balance emotions and logic",
                        "Practice self-acceptance",
                        "Develop stability",
                        "Connect with others",
                        "Find constructive outlets",
                    ],
                },
                {
                    list: [
                        "Develop emotional awareness",
                        "Build social connections",
                        "Balance analysis and action",
                        "Share knowledge with others",
                        "Practice presence",
                    ],
                },
                {
                    list: [
                        "Build self-confidence",
                        "Take calculated risks",
                        "Trust your instincts",
                        "Embrace change",
                        "Develop independence",
                    ],
                },
                {
                    list: [
                        "Develop focus and commitment",
                        "Face difficult emotions",
                        "Practice mindfulness",
                        "Complete projects",
                        "Build lasting relationships",
                    ],
                },
                {
                    list: [
                        "Practice vulnerability",
                        "Develop patience",
                        "Listen to others",
                        "Control intensity",
                        "Accept help from others",
                    ],
                },
                {
                    list: [
                        "Take initiative",
                        "Express your needs",
                        "Make decisions actively",
                        "Embrace conflict constructively",
                        "Develop assertiveness",
                    ],
                },
            ],
        },
    },
    test: {
        meta: {
            title: "Enneagram Personality Test - Start Testing",
            description:
                "Discover your personality type with the Enneagram personality test. It's completely free and helps you better understand yourself.",
            keywords:
                "Enneagram test,personality test,online test,free test,personality assessment,psychological test,Enneagram questionnaire",
        },
        title: "Enneagram Personality Test",
        questions: [
            {
                question: "When facing difficulties, I tend to:",
                options: [
                    "Analyze problems and create perfect solutions",
                    "Seek help and support from others",
                    "Take immediate action to solve problems",
                ],
                types: [1, 6, 8],
            },
            {
                question: "In a team, I usually:",
                options: [
                    "Ensure everything follows the rules",
                    "Care for others' feelings and needs",
                    "Drive the team towards goals",
                ],
                types: [1, 2, 3],
            },
            {
                question: "Under pressure, I:",
                options: [
                    "Become more perfectionist",
                    "Seek approval and support",
                    "Distract myself with work and achievements",
                ],
                types: [1, 2, 3],
            },
            {
                question: "When making decisions, I usually:",
                options: [
                    "Consider what's right and correct",
                    "Consider the impact on others",
                    "Consider how to achieve goals most effectively",
                ],
                types: [1, 2, 3],
            },
            {
                question: "I value most:",
                options: [
                    "Integrity and moral standards",
                    "Relationships and emotional connections",
                    "Achievements and recognition",
                ],
                types: [1, 2, 3],
            },
            {
                question: "I fear:",
                options: [
                    "Making mistakes and imperfection",
                    "Being rejected by others",
                    "Failure and worthlessness",
                ],
                types: [1, 2, 3],
            },
            {
                question: "At work, I tend to:",
                options: [
                    "Pursue perfection and high standards",
                    "Build good relationships",
                    "Pursue efficiency and success",
                ],
                types: [1, 2, 3],
            },
            {
                question: "When criticized, I:",
                options: [
                    "Work hard to correct mistakes",
                    "Feel emotionally hurt",
                    "Prove my capabilities",
                ],
                types: [1, 4, 3],
            },
            {
                question: "I believe what's most important is:",
                options: [
                    "Doing the right thing",
                    "Maintaining uniqueness",
                    "Achieving success",
                ],
                types: [1, 4, 3],
            },
            {
                question: "In relationships, I tend to:",
                options: [
                    "Maintain appropriate distance",
                    "Get deeply involved",
                    "Show my best side",
                ],
                types: [5, 2, 3],
            },
            {
                question: "My most common thoughts are about:",
                options: [
                    "How to do things better",
                    "What others need",
                    "How to succeed",
                ],
                types: [1, 2, 3],
            },
            {
                question: "When facing conflicts, I:",
                options: [
                    "Stick to principles",
                    "Seek harmony",
                    "Confront issues",
                ],
                types: [1, 9, 8],
            },
            {
                question: "Deep inside, I long to:",
                options: [
                    "Be a perfect person",
                    "Be needed and loved",
                    "Be successful and recognized",
                ],
                types: [1, 2, 3],
            },
            {
                question: "When alone, I tend to:",
                options: [
                    "Think and analyze",
                    "Feel and experience emotions",
                    "Plan for the future",
                ],
                types: [5, 4, 3],
            },
            {
                question: "In new environments, I usually:",
                options: [
                    "Observe and analyze the situation",
                    "Look for people I can help",
                    "Look for opportunities to shine",
                ],
                types: [5, 2, 3],
            },
            {
                question: "I most easily feel:",
                options: [
                    "Anger (at imperfection)",
                    "Sadness (at lost connections)",
                    "Shame (at failure)",
                ],
                types: [1, 4, 3],
            },
            {
                question: "Under stress, I become:",
                options: [
                    "More critical and strict",
                    "More emotional",
                    "More competitive",
                ],
                types: [1, 4, 3],
            },
            {
                question: "I find it most difficult to:",
                options: [
                    "Accept imperfection",
                    "Handle emotional needs",
                    "Admit failure",
                ],
                types: [1, 2, 3],
            },
            {
                question: "I most want others to say I am:",
                options: [
                    "Honest and reliable",
                    "Compassionate and caring",
                    "Capable and successful",
                ],
                types: [1, 2, 3],
            },
            {
                question: "In relationships, I care most about:",
                options: [
                    "Honesty and trust",
                    "Intimacy and understanding",
                    "Mutual support and growth",
                ],
                types: [1, 2, 3],
            },
            {
                question: "I often find myself:",
                options: [
                    "Self-reflecting and self-criticizing",
                    "Focusing on others' needs",
                    "Comparing and competing",
                ],
                types: [1, 2, 3],
            },
            {
                question: "When things go wrong, I:",
                options: [
                    "Analyze causes and improve",
                    "Seek comfort and support",
                    "Take immediate remedial action",
                ],
                types: [1, 2, 8],
            },
            {
                question: "I most admire:",
                options: [
                    "Integrity and morality",
                    "Empathy and caring",
                    "Capability and achievement",
                ],
                types: [1, 2, 3],
            },
            {
                question: "At work, I value most:",
                options: [
                    "Quality and accuracy",
                    "Teamwork",
                    "Efficiency and results",
                ],
                types: [1, 2, 3],
            },
            {
                question: "I tend to:",
                options: [
                    "Be overly concerned with details",
                    "Be overly concerned with others' feelings",
                    "Be overly concerned with success",
                ],
                types: [1, 2, 3],
            },
            {
                question: "When facing change, I:",
                options: [
                    "Carefully evaluate pros and cons",
                    "Consider impact on relationships",
                    "Look for opportunities",
                ],
                types: [6, 2, 7],
            },
            {
                question: "My strengths lie in:",
                options: [
                    "Organization and planning",
                    "Understanding and caring",
                    "Execution and completion",
                ],
                types: [1, 2, 3],
            },
            {
                question: "In teams, I usually play the role of:",
                options: ["Quality controller", "Mediator", "Driver"],
                types: [1, 9, 3],
            },
            {
                question: "I cannot tolerate:",
                options: ["Irresponsibility", "Indifference", "Incompetence"],
                types: [1, 2, 3],
            },
            {
                question: "I am motivated by:",
                options: [
                    "Pursuing perfection",
                    "Helping others",
                    "Achieving success",
                ],
                types: [1, 2, 3],
            },
            {
                question: "In difficult situations, I:",
                options: ["Stick to principles", "Seek support", "Take action"],
                types: [1, 6, 8],
            },
            {
                question: "I am most likely to:",
                options: [
                    "Be too hard on myself",
                    "Neglect my own needs",
                    "Be too competitive",
                ],
                types: [1, 2, 3],
            },
            {
                question: "Others say I am:",
                options: [
                    "Too perfectionist",
                    "Too concerned about others",
                    "Too focused on success",
                ],
                types: [1, 2, 3],
            },
            {
                question: "I wish I could be:",
                options: ["More relaxed", "More independent", "More peaceful"],
                types: [1, 2, 9],
            },
            {
                question: "When making decisions, I rely on:",
                options: [
                    "Principles and logic",
                    "Intuition and feelings",
                    "Experience and effectiveness",
                ],
                types: [1, 4, 3],
            },
            {
                question: "My biggest challenge is:",
                options: [
                    "Accepting reality",
                    "Setting boundaries",
                    "Slowing down",
                ],
                types: [1, 2, 3],
            },
            {
                question: "In life, I pursue:",
                options: [
                    "Morality and justice",
                    "Harmony and care",
                    "Achievement and excellence",
                ],
                types: [1, 2, 3],
            },
            {
                question: "I often worry about:",
                options: [
                    "Whether I'm doing well enough",
                    "Whether others need me",
                    "Whether I'm successful enough",
                ],
                types: [1, 2, 3],
            },
            {
                question: "My code of conduct is:",
                options: [
                    "Do the right thing",
                    "Care for others",
                    "Pursue excellence",
                ],
                types: [1, 2, 3],
            },
            {
                question: "During leisure time, I tend to:",
                options: [
                    "Improve myself",
                    "Spend time with others",
                    "Try new things",
                ],
                types: [1, 2, 7],
            },
            {
                question: "I consider myself:",
                options: [
                    "A responsible person",
                    "A compassionate person",
                    "An ambitious person",
                ],
                types: [1, 2, 3],
            },
            {
                question: "When facing choices, I:",
                options: [
                    "Choose what's right",
                    "Choose what benefits others",
                    "Choose what's effective",
                ],
                types: [1, 2, 3],
            },
            {
                question: "I value relationships that are:",
                options: [
                    "Based on mutual respect",
                    "Emotionally deep",
                    "Mutually supportive",
                ],
                types: [1, 4, 2],
            },
            {
                question: "My attitude towards life is:",
                options: [
                    "Pursuing perfection",
                    "Pursuing harmony",
                    "Pursuing success",
                ],
                types: [1, 9, 3],
            },
            {
                question: "When handling problems, I:",
                options: [
                    "Look for the best solution",
                    "Consider everyone's feelings",
                    "Find quick solutions",
                ],
                types: [1, 2, 8],
            },
            {
                question: "I value most the ability to:",
                options: [
                    "Judge right from wrong",
                    "Understand others",
                    "Solve problems",
                ],
                types: [1, 2, 5],
            },
            {
                question: "My life goal is to:",
                options: [
                    "Achieve moral perfection",
                    "Help others grow",
                    "Achieve outstanding success",
                ],
                types: [1, 2, 3],
            },
            {
                question: "I feel most satisfied when:",
                options: [
                    "I've done the right thing",
                    "I've helped someone in need",
                    "I've achieved my goals",
                ],
                types: [1, 2, 3],
            },
            {
                question: "In social situations, I usually:",
                options: [
                    "Maintain restraint and politeness",
                    "Actively care for others",
                    "Display confidence and charm",
                ],
                types: [1, 2, 3],
            },
            {
                question: "When interacting with others, I focus on:",
                options: [
                    "Integrity and principles",
                    "Care and warmth",
                    "Capability and achievement",
                ],
                types: [1, 2, 3],
            },
            {
                question: "When others make mistakes, I:",
                options: [
                    "Point out errors and suggest corrections",
                    "Show understanding and support",
                    "Offer solutions",
                ],
                types: [1, 2, 3],
            },
            {
                question: "In team collaboration, I expect:",
                options: [
                    "Everyone to be responsible",
                    "A harmonious atmosphere",
                    "Excellent results",
                ],
                types: [1, 9, 3],
            },
            {
                question: "Facing others' emotions, I:",
                options: [
                    "Stay rational and controlled",
                    "Feel empathetic",
                    "Look for solutions",
                ],
                types: [1, 2, 3],
            },
            {
                question: "When communicating, I tend to:",
                options: [
                    "Point out problems directly",
                    "Consider others' feelings",
                    "Emphasize solutions",
                ],
                types: [1, 2, 3],
            },
            {
                question: "When others need help, I:",
                options: [
                    "Provide correct advice",
                    "Offer emotional support",
                    "Provide practical solutions",
                ],
                types: [1, 2, 3],
            },
            {
                question: "In interpersonal conflicts, I usually:",
                options: [
                    "Stick to principles",
                    "Seek reconciliation",
                    "Take action",
                ],
                types: [1, 9, 8],
            },
            {
                question: "I believe good relationships should:",
                options: [
                    "Respect each other's principles",
                    "Understand each other's feelings",
                    "Support each other's growth",
                ],
                types: [1, 2, 3],
            },
            {
                question: "When giving feedback, I:",
                options: [
                    "Point out areas for improvement",
                    "Choose words carefully to avoid hurt",
                    "Focus on how to increase efficiency",
                ],
                types: [1, 2, 3],
            },
            {
                question: "I expect leaders to be:",
                options: [
                    "Fair and principled",
                    "Caring and considerate",
                    "Capable and decisive",
                ],
                types: [1, 2, 8],
            },
            {
                question: "When handling disagreements, I tend to:",
                options: [
                    "Maintain the correct position",
                    "Seek mutually acceptable solutions",
                    "Take decisive action",
                ],
                types: [1, 9, 8],
            },
            {
                question: "Under stress, I:",
                options: [
                    "Become more demanding of myself",
                    "Need more support from others",
                    "Work harder to prove myself",
                ],
                types: [1, 2, 3],
            },
            {
                question: "When facing failure, I usually:",
                options: [
                    "Analyze causes and improve",
                    "Seek comfort and encouragement",
                    "Quickly make new plans",
                ],
                types: [1, 2, 3],
            },
            {
                question: "My self-improvement direction is:",
                options: [
                    "Increasing accuracy in tasks",
                    "Enhancing emotional sensitivity",
                    "Improving work efficiency",
                ],
                types: [1, 2, 3],
            },
            {
                question: "When feeling anxious, I:",
                options: [
                    "Check if I've made any mistakes",
                    "Seek emotional support",
                    "Focus on solving problems",
                ],
                types: [1, 2, 3],
            },
            {
                question: "I need to overcome:",
                options: [
                    "Being too critical of myself and others",
                    "Being too dependent on others",
                    "Being too focused on success",
                ],
                types: [1, 2, 3],
            },
            {
                question: "To grow, I need to learn to:",
                options: [
                    "Accept imperfection",
                    "Focus on my own needs",
                    "Enjoy the process",
                ],
                types: [1, 2, 3],
            },
            {
                question: "In adversity, I:",
                options: [
                    "Maintain principles and standards",
                    "Seek help from others",
                    "Actively look for opportunities",
                ],
                types: [1, 2, 3],
            },
            {
                question: "My ideal life is:",
                options: [
                    "Orderly and meaningful",
                    "Full of love and care",
                    "Successful and respected",
                ],
                types: [1, 2, 3],
            },
            {
                question: "Facing changes, I:",
                options: [
                    "Ensure changes are correct",
                    "Consider impact on people",
                    "Seize opportunities from changes",
                ],
                types: [1, 2, 3],
            },
            {
                question: "I believe the key to success is:",
                options: [
                    "Following correct principles",
                    "Maintaining good relationships",
                    "Seizing important opportunities",
                ],
                types: [1, 2, 3],
            },
            {
                question: "When facing challenges, I:",
                options: [
                    "Carefully evaluate and plan",
                    "Seek advice and support",
                    "Face them head-on",
                ],
                types: [1, 6, 8],
            },
            {
                question: "My life principle is:",
                options: [
                    "Pursue perfection and correctness",
                    "Pursue love and harmony",
                    "Pursue success and excellence",
                ],
                types: [1, 2, 3],
            },
        ],
    },
    result: {
        meta: {
            title: "Enneagram Test Result",
            description:
                "View your Enneagram test result, understand your personality traits.",
            keywords:
                "Enneagram result, personality analysis, personality type",
        },
        title: "Your Enneagram Personality Type is",
        characteristics: {
            title: "Characteristics",
        },
        advice: {
            title: "Advice",
        },
        scoreDistribution: "Score Distribution",
        strengths: "Strengths",
        weaknesses: "Weaknesses",
        growthSuggestions: "Growth Suggestions",
        buttons: {
            viewDetail: "View Detailed Analysis",
        },
        types: {
            type1: {
                title: "The Perfectionist",
                description:
                    "Ethical, dedicated and reliable, committed to improvement.",
                characteristics: [
                    "High self-discipline",
                    "Strong sense of right and wrong",
                    "Pursuit of perfection and progress",
                    "Attention to detail",
                    "Strong sense of responsibility",
                ],
                strengths: [
                    "Principled",
                    "Reliable",
                    "Hardworking",
                    "Pursuing excellence",
                    "Quality-focused",
                ],
                weaknesses: [
                    "Overly critical",
                    "Self-criticizing",
                    "Inflexible",
                    "Difficulty relaxing",
                    "Too serious",
                ],
                growthSuggestions: [
                    "Learn to accept imperfection",
                    "Develop empathy",
                    "Relax attitude",
                    "Increase flexibility",
                    "Enjoy the present",
                ],
            },
            type2: {
                title: "The Helper",
                description:
                    "Empathetic, helpful, desires love and recognition.",
                characteristics: [
                    "Strong empathy",
                    "Helpful nature",
                    "Values relationships",
                    "Emotionally rich",
                    "Understanding",
                ],
                strengths: [
                    "Compassionate",
                    "Caring",
                    "Giving",
                    "Good at relationships",
                    "Emotionally intuitive",
                ],
                weaknesses: [
                    "Over-concerned with others' feelings",
                    "Neglects own needs",
                    "Emotional dependency",
                    "Difficulty saying no",
                    "Over-giving",
                ],
                growthSuggestions: [
                    "Learn to set boundaries",
                    "Focus on self-needs",
                    "Develop independence",
                    "Balance giving",
                    "Maintain self-identity",
                ],
            },
            type3: {
                title: "The Achiever",
                description:
                    "Success-oriented, image-conscious, highly adaptable.",
                characteristics: [
                    "Goal-oriented",
                    "Efficiency-focused",
                    "Image-conscious",
                    "Highly adaptable",
                    "Driven",
                ],
                strengths: [
                    "Strong execution",
                    "Good communication",
                    "High efficiency",
                    "Adaptability",
                    "Leadership",
                ],
                weaknesses: [
                    "Overly focused on success",
                    "Workaholic tendencies",
                    "Emotional suppression",
                    "Over-competitive",
                    "Image obsession",
                ],
                growthSuggestions: [
                    "Balance work and life",
                    "Connect with feelings",
                    "Build authentic connections",
                    "Accept failure",
                    "Stay authentic",
                ],
            },
            type4: {
                title: "The Artist",
                description: "Creative, unique, emotionally rich.",
                characteristics: [
                    "Unique personality",
                    "Rich emotions",
                    "Artistic temperament",
                    "Strong self-awareness",
                    "Authenticity-seeking",
                ],
                strengths: [
                    "Creative",
                    "Emotionally deep",
                    "Aesthetic sense",
                    "Intuitive",
                    "Authentic",
                ],
                weaknesses: [
                    "Emotional volatility",
                    "Self-absorption",
                    "Idealization",
                    "Difficulty adapting",
                    "Oversensitivity",
                ],
                growthSuggestions: [
                    "Develop emotional stability",
                    "Accept ordinariness",
                    "Establish routines",
                    "Improve adaptability",
                    "Maintain balance",
                ],
            },
            type5: {
                title: "The Observer",
                description: "Analytical, knowledge-seeking, values privacy.",
                characteristics: [
                    "Rational thinking",
                    "Knowledge-seeking",
                    "Independent",
                    "Observant",
                    "Privacy-oriented",
                ],
                strengths: [
                    "Strong analysis",
                    "Deep knowledge",
                    "Independent thinking",
                    "Insightful",
                    "Focused",
                ],
                weaknesses: [
                    "Emotional detachment",
                    "Social withdrawal",
                    "Over-analysis",
                    "Resource hoarding",
                    "Difficulty taking action",
                ],
                growthSuggestions: [
                    "Enhance emotional connections",
                    "Engage socially",
                    "Take action",
                    "Share knowledge",
                    "Stay open-minded",
                ],
            },
            type6: {
                title: "The Loyalist",
                description:
                    "Security-focused, supportive, cautious and responsible.",
                characteristics: [
                    "Loyal and reliable",
                    "Cautious",
                    "Responsible",
                    "Security-conscious",
                    "Certainty-seeking",
                ],
                strengths: [
                    "High loyalty",
                    "Strong awareness",
                    "Responsibility",
                    "Team player",
                    "Problem anticipation",
                ],
                weaknesses: [
                    "Excessive worry",
                    "Indecisiveness",
                    "Over-cautiousness",
                    "Lack of confidence",
                    "Dependency",
                ],
                growthSuggestions: [
                    "Build confidence",
                    "Develop courage",
                    "Trust intuition",
                    "Accept uncertainty",
                    "Make independent decisions",
                ],
            },
            type7: {
                title: "The Enthusiast",
                description:
                    "Optimistic, adventurous, versatile and imaginative.",
                characteristics: [
                    "Optimistic",
                    "Energetic",
                    "Flexible thinking",
                    "Pleasure-seeking",
                    "Freedom-loving",
                ],
                strengths: [
                    "Creative ideas",
                    "Adaptability",
                    "Inspiring",
                    "Optimistic outlook",
                    "High energy",
                ],
                weaknesses: [
                    "Difficulty focusing",
                    "Pain avoidance",
                    "Poor planning",
                    "Lack of patience",
                    "Excessive optimism",
                ],
                growthSuggestions: [
                    "Develop focus",
                    "Face reality",
                    "Complete commitments",
                    "Accept negatives",
                    "Deepen experiences",
                ],
            },
            type8: {
                title: "The Leader",
                description: "Confident, protective, seeks control.",
                characteristics: [
                    "Leadership presence",
                    "Strong will",
                    "Protective instinct",
                    "Direct and decisive",
                    "Control-seeking",
                ],
                strengths: [
                    "Decisive",
                    "Strong execution",
                    "Protective",
                    "Straightforward",
                    "Takes responsibility",
                ],
                weaknesses: [
                    "Overly forceful",
                    "Controlling",
                    "Insensitive",
                    "Conflict-prone",
                    "Difficulty showing vulnerability",
                ],
                growthSuggestions: [
                    "Cultivate gentleness",
                    "Listen to others",
                    "Show vulnerability",
                    "Moderate intensity",
                    "Increase understanding",
                ],
            },
            type9: {
                title: "The Peacemaker",
                description: "Peaceful, inclusive, avoids conflict.",
                characteristics: [
                    "Gentle nature",
                    "Understanding",
                    "Peace-seeking",
                    "Mediation ability",
                    "Adaptable",
                ],
                strengths: [
                    "Inclusive",
                    "Mediation skills",
                    "Good listener",
                    "Harmony creation",
                    "Stable presence",
                ],
                weaknesses: [
                    "Indecisiveness",
                    "Conflict avoidance",
                    "Passive",
                    "Self-neglect",
                    "Procrastination",
                ],
                growthSuggestions: [
                    "Increase assertiveness",
                    "Express needs",
                    "Take timely action",
                    "Face conflicts",
                    "Prioritize self",
                ],
            },
        },
    },
};
