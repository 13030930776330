export default {
    index: {
        meta: {
            title: "DISC 性格测试 - 了解你的行为风格",
            description:
                "通过 DISC 性格测试了解你的行为风格，提升自我认知和人际沟通能力。",
            keywords: "DISC 测试,性格测试,行为风格,人际沟通,自我认知",
        },
        title: "DISC 性格测试",
        subtitle: "了解你的行为风格，提升人际沟通能力",
        tips: {
            list: [
                "欢迎参加 DISC 测试！通过本次测试，您将了解自己在四种行为类型中的倾向，并获得如何更好地与他人合作和沟通的建议。",
                "DISC 测试帮助您探索自己的行为模式，让您了解如何在工作中与不同类型的人相处，并提高团队合作效率。",
                "请根据您平时的行为和态度，尽量真实地回答每一个问题，测试结果将帮助您发现个人优势和发展空间。",
                "DISC 测试通过分析您的个性特点，帮助您在职场中更好地发挥潜力并建立更有效的人际关系。",
            ],
        },
        buttons: {
            whatIsDISC: "什么是 DISC ",
            discTypes: "DISC 类型介绍",
        },
        features: [
            {
                title: "科学的 DISC 模型",
                description:
                    "基于William Moulton Marston博士的行为风格理论，通过四个维度评估你的性格特征",
            },
            {
                title: "全面的行为分析",
                description:
                    "深入了解你的行为风格、沟通方式和决策特点，为个人发展提供指导",
            },
            {
                title: "实用的沟通建议",
                description:
                    "根据测试结果为你提供针对性的沟通策略和人际关系建议",
            },
        ],
    },
    introduction: {
        meta: {
            title: "DISC 性格测试介绍 - 了解行为风格理论",
            description:
                "深入了解 DISC 性格测试的理论基础、四个维度及其应用价值。",
            keywords: "DISC 测试介绍,行为风格理论,性格维度,沟通方式",
        },
        title: "DISC 性格测试介绍",
        subtitle: "了解行为风格理论，提升自我认知",
        what: {
            title: "什么是 DISC 性格测试",
            content:
                "DISC 性格测试是基于William Moulton Marston博士的行为风格理论开发的测评工具。该理论认为，人们的行为风格可以归纳为四个基本维度：支配型(D)、影响型(I)、稳健型(S)和谨慎型(C)。 DISC 测试帮助个人理解自身在工作和社交中的行为模式，以便在不同场合做出适当反应。",
        },
        history: {
            title: "历史背景",
            content:
                "William Moulton Marston博士在1928年首次提出了 DISC 理论，并在其著作《正常人的情绪》中详细阐述了这一理论。经过近百年的发展和应用， DISC 已成为最广泛使用的行为风格评估工具之一。",
        },
        types: {
            title: "四个维度",
            traits: "特征",
            strengths: "优势",
            weaknesses: "劣势",
            items: [
                {
                    icon: "👑",
                    title: "支配型 (D)",
                    description: "果断、直接、目标导向",
                    traits: ["果断", "直接", "目标导向", "喜欢挑战"],
                    strengths: ["决策迅速", "执行力强", "善于领导", "勇于创新"],
                    weaknesses: [
                        "可能过于强势",
                        "缺乏耐心",
                        "不善于倾听",
                        "容易冲动",
                    ],
                },
                {
                    icon: "🌟",
                    title: "影响型 (I)",
                    description: "外向、热情、善于表达",
                    traits: ["外向", "热情", "善于表达", "乐观"],
                    strengths: [
                        "善于交际",
                        "富有感染力",
                        "创意丰富",
                        "适应力强",
                    ],
                    weaknesses: [
                        "可能过于感性",
                        "容易冲动",
                        "缺乏计划性",
                        "难以专注",
                    ],
                },
                {
                    icon: "🤝",
                    title: "稳健型 (S)",
                    description: "耐心、友善、注重和谐",
                    traits: ["耐心", "友善", "注重和谐", "忠诚"],
                    strengths: [
                        "善于倾听",
                        "团队协作",
                        "稳定可靠",
                        "富有同理心",
                    ],
                    weaknesses: [
                        "可能过于保守",
                        "害怕改变",
                        "优柔寡断",
                        "难以说'不'",
                    ],
                },
                {
                    icon: "📊",
                    title: "谨慎型 (C)",
                    description: "细致、准确、追求完美",
                    traits: ["细致", "准确", "追求完美", "注重细节"],
                    strengths: [
                        "分析能力强",
                        "注重质量",
                        "善于规划",
                        "追求准确",
                    ],
                    weaknesses: [
                        "可能过于完美主义",
                        "容易焦虑",
                        "决策较慢",
                        "难以接受变化",
                    ],
                },
            ],
        },
        applications: {
            title: "应用价值",
            items: [
                "自我认知：帮助个人了解自己的行为特征和决策方式，提升自我认知和情商。",
                "团队建设：优化团队成员间的合作与沟通，提升整体团队效能。",
                "职业发展：根据测试结果为个人提供职业规划建议，帮助其选择最适合自己的职业方向。",
                "领导力发展：帮助管理者理解自己的领导风格，从而改善领导方法并提升团队管理能力。",
                "冲突管理：通过了解行为特征，帮助个人在冲突中采取更合适的应对策略。",
                "人际沟通：帮助个体改善与他人的沟通方式，提高沟通效率。",
            ],
        },
        notes: {
            title: "注意事项",
            items: [
                "回答时保持真实：测试结果基于您的真实行为，因此请尽量避免迎合答案。",
                "理解测试的局限性： DISC 测试提供的是行为模式的指导，并非绝对的人格定义，结果应与其他评估工具结合使用。",
                "测试不代表全部：人的行为具有多样性和复杂性， DISC 仅为一种参考，不能单纯以此来判断一个人的全面素质。",
                "文化背景的影响：不同文化和背景可能会影响行为模式的表现，因此测试结果应根据文化背景进行适当分析。",
                "非临床工具： DISC 测试用于个人和团队的自我发展和工作效率提升，不应作为心理诊断或治疗的工具。",
            ],
        },
    },
    test: {
        meta: {
            title: "DISC 性格测试 - 开始测试",
            description: "通过回答一系列问题，了解你的行为风格类型。",
            keywords: "DISC 测试,性格测试,行为风格测试",
        },
        title: "DISC 性格测试",
        questions: [
            // 支配性 (D) - 10题
            {
                question: "在团队中，你通常扮演什么角色？",
                options: [
                    "领导者，负责决策和指导",
                    "活跃气氛者，善于调动团队情绪",
                    "支持者，确保团队和谐",
                    "分析者，注重细节和规划",
                ],
            },
            {
                question: "面对压力时，你的第一反应是：",
                options: [
                    "立即采取行动解决问题",
                    "寻求他人支持和鼓励",
                    "保持冷静，耐心等待",
                    "仔细分析，制定计划",
                ],
            },
            {
                question: "在社交场合，你倾向于：",
                options: [
                    "主导话题，表达观点",
                    "活跃气氛，结交新朋友",
                    "倾听他人，保持和谐",
                    "观察分析，谨慎参与",
                ],
            },
            {
                question: "做决定时，你更看重：",
                options: [
                    "快速行动和结果",
                    "团队感受和氛围",
                    "稳定性和和谐",
                    "准确性和细节",
                ],
            },
            {
                question: "在工作中，你最注重：",
                options: [
                    "目标和成就",
                    "人际关系和氛围",
                    "团队协作和稳定",
                    "质量和规范",
                ],
            },
            {
                question: "遇到问题时，你通常会：",
                options: [
                    "立即采取行动解决",
                    "寻求他人建议和帮助",
                    "耐心等待最佳时机",
                    "仔细分析各种可能",
                ],
            },
            {
                question: "在团队讨论中，你倾向于：",
                options: [
                    "主导讨论方向",
                    "活跃讨论氛围",
                    "倾听并支持他人",
                    "分析各种观点",
                ],
            },
            {
                question: "面对新项目时，你首先会：",
                options: [
                    "制定目标并立即行动",
                    "与团队分享想法",
                    "了解团队意见",
                    "制定详细计划",
                ],
            },
            {
                question: "在冲突情况下，你会：",
                options: [
                    "直接面对并解决",
                    "寻求共识和妥协",
                    "保持中立和和谐",
                    "分析原因和方案",
                ],
            },
            {
                question: "完成任务时，你更关注：",
                options: [
                    "效率和结果",
                    "团队协作和氛围",
                    "稳定性和持续性",
                    "质量和准确性",
                ],
            },

            // 影响力 (I) - 10题
            {
                question: "在社交场合，你更喜欢：",
                options: [
                    "主导谈话方向",
                    "活跃气氛，结交新朋友",
                    "倾听他人，保持和谐",
                    "观察分析，谨慎参与",
                ],
            },
            {
                question: "面对挑战时，你会：",
                options: [
                    "立即采取行动",
                    "寻求他人支持",
                    "保持耐心等待",
                    "仔细分析方案",
                ],
            },
            {
                question: "在团队中，你更倾向于：",
                options: [
                    "领导团队前进",
                    "活跃团队氛围",
                    "支持团队和谐",
                    "分析团队问题",
                ],
            },
            {
                question: "面对变化时，你会：",
                options: [
                    "主动适应变化",
                    "寻求他人帮助",
                    "保持稳定心态",
                    "分析变化影响",
                ],
            },
            {
                question: "在工作中，你更喜欢：",
                options: [
                    "快速完成任务",
                    "与他人合作",
                    "按部就班进行",
                    "注重细节质量",
                ],
            },
            {
                question: "做决定时，你倾向于：",
                options: [
                    "快速做出决定",
                    "听取他人意见",
                    "保持谨慎态度",
                    "仔细分析利弊",
                ],
            },
            {
                question: "面对压力时，你会：",
                options: [
                    "立即采取行动",
                    "寻求他人支持",
                    "保持耐心等待",
                    "分析解决方案",
                ],
            },
            {
                question: "在团队中，你更注重：",
                options: ["目标和效率", "人际关系", "团队和谐", "工作质量"],
            },
            {
                question: "处理问题时，你倾向于：",
                options: [
                    "快速解决问题",
                    "寻求他人建议",
                    "保持耐心等待",
                    "仔细分析原因",
                ],
            },
            {
                question: "在工作中，你更注重：",
                options: ["效率和结果", "团队氛围", "工作稳定", "质量标准"],
            },

            // 稳定性 (S) - 10题
            {
                question: "面对新任务时，你会：",
                options: [
                    "立即开始行动",
                    "与团队讨论",
                    "保持谨慎态度",
                    "制定详细计划",
                ],
            },
            {
                question: "在团队中，你更倾向于：",
                options: ["领导团队", "活跃氛围", "支持他人", "分析问题"],
            },
            {
                question: "做决定时，你更看重：",
                options: ["快速行动", "团队感受", "稳定和谐", "准确细节"],
            },
            {
                question: "面对变化时，你会：",
                options: ["主动适应", "寻求帮助", "保持稳定", "分析影响"],
            },
            {
                question: "在社交场合，你倾向于：",
                options: ["主导谈话", "活跃气氛", "倾听他人", "观察分析"],
            },
            {
                question: "面对压力时，你会：",
                options: ["立即行动", "寻求支持", "保持耐心", "分析方案"],
            },
            {
                question: "在工作中，你更注重：",
                options: ["效率结果", "团队氛围", "工作稳定", "质量标准"],
            },
            {
                question: "处理问题时，你倾向于：",
                options: ["快速解决", "寻求建议", "保持耐心", "分析原因"],
            },
            {
                question: "在团队中，你更倾向于：",
                options: ["领导团队", "活跃氛围", "支持他人", "分析问题"],
            },
            {
                question: "面对新项目时，你会：",
                options: ["立即行动", "团队讨论", "保持谨慎", "详细计划"],
            },

            // 谨慎性 (C) - 10题
            {
                question: "做决定时，你更看重：",
                options: ["快速行动", "团队感受", "稳定和谐", "准确细节"],
            },
            {
                question: "面对变化时，你会：",
                options: ["主动适应", "寻求帮助", "保持稳定", "分析影响"],
            },
            {
                question: "在社交场合，你倾向于：",
                options: ["主导谈话", "活跃气氛", "倾听他人", "观察分析"],
            },
            {
                question: "面对压力时，你会：",
                options: ["立即行动", "寻求支持", "保持耐心", "分析方案"],
            },
            {
                question: "在工作中，你更注重：",
                options: ["效率结果", "团队氛围", "工作稳定", "质量标准"],
            },
            {
                question: "处理问题时，你倾向于：",
                options: ["快速解决", "寻求建议", "保持耐心", "分析原因"],
            },
            {
                question: "在团队中，你更倾向于：",
                options: ["领导团队", "活跃氛围", "支持他人", "分析问题"],
            },
            {
                question: "面对新项目时，你会：",
                options: ["立即行动", "团队讨论", "保持谨慎", "详细计划"],
            },
            {
                question: "在团队讨论中，你倾向于：",
                options: ["主导方向", "活跃氛围", "支持他人", "分析观点"],
            },
            {
                question: "完成任务时，你更关注：",
                options: ["效率结果", "团队氛围", "工作稳定", "质量标准"],
            },
        ],
    },
    result: {
        meta: {
            title: "DISC 性格测试结果 - 了解你的行为风格",
            description:
                "查看你的 DISC 性格测试结果，了解你的行为风格类型和沟通特点。",
            keywords: "DISC 测试结果,行为风格分析,沟通建议",
        },
        title: "你的行为风格分析",
        subtitle: "基于你的回答，我们为你生成了详细的行为风格分析",
        primaryType: {
            title: "主要类型",
            traits: "特征",
            strengths: "优势",
            weaknesses: "劣势",
            default: {
                icon: "👑",
                title: "支配型 (D)",
                description:
                    "你是一个果断、直接、目标导向的人。你喜欢挑战，善于决策和领导。",
                traits: ["果断", "直接", "目标导向", "喜欢挑战"],
                strengths: ["决策迅速", "执行力强", "善于领导", "勇于创新"],
                weaknesses: [
                    "可能过于强势",
                    "缺乏耐心",
                    "不善于倾听",
                    "容易冲动",
                ],
            },
        },
        dimensions: {
            title: "维度分析",
            items: [
                {
                    label: "支配型 (D)",
                    score: 85,
                },
                {
                    label: "影响型 (I)",
                    score: 65,
                },
                {
                    label: "稳健型 (S)",
                    score: 45,
                },
                {
                    label: "谨慎型 (C)",
                    score: 75,
                },
            ],
            D: {
                description: "倾向于通过克服阻力来控制环境以实现结果",
            },
            I: {
                description: "倾向于通过交谈和活动来影响他人，依靠个人热情",
            },
            S: {
                description: "倾向于与他人合作完成任务，保持稳定和谐",
            },
            C: {
                description: "倾向于在现有环境中认真工作，确保质量和准确性",
            },
        },
        communicationAdvice: {
            title: "沟通建议",
            items: [
                {
                    title: "与不同风格的人沟通",
                    description:
                        "根据你的行为风格，以下是与不同风格的人沟通的建议：",
                    tips: [
                        "与支配型：直接、简洁、注重结果",
                        "与影响型：热情、互动、注重感受",
                        "与稳健型：耐心、友善、注重和谐",
                        "与谨慎型：准确、专业、注重细节",
                    ],
                },
                {
                    title: "发展建议",
                    description: "为了提升你的沟通效果，建议你：",
                    tips: [
                        "培养倾听能力",
                        "提高情绪管理",
                        "加强团队协作",
                        "注重细节把控",
                    ],
                },
            ],
        },
        developmentAdvice: {
            title: "发展建议",
            items: [
                {
                    title: "个人成长",
                    description: "建议你重点提升以下方面：",
                },
                {
                    title: "职业发展",
                    description: "为你的职业发展制定以下规划：",
                },
            ],
        },
        yourType: "你的 DISC 类型",
        details: {
            title: "详细分析",
            strengths: {
                title: "你的优势",
            },
            weaknesses: {
                title: "待提升方面",
            },
            career: {
                title: "推荐职业方向",
            },
        },
        cta: {
            title: "下一步",
            description: "继续你的自我探索之旅",
        },
        types: {
            D: {
                description:
                    "你是一个果断、直接、目标导向的人。你喜欢挑战，善于决策和领导。",
                strengths: [
                    "决策迅速",
                    "执行力强",
                    "善于领导",
                    "创新思维",
                    "注重结果",
                    "主动性强",
                ],
                weaknesses: [
                    "可能过于强势",
                    "对他人缺乏耐心",
                    "倾听能力欠佳",
                    "可能不够体贴",
                    "可能忽视细节",
                    "可能过于控制",
                ],
                careers: [
                    "企业高管",
                    "创业者",
                    "销售管理",
                    "项目管理",
                    "业务发展",
                    "军事领导",
                ],
            },
            I: {
                description:
                    "你是一个外向、热情、善于表达的人。你擅长社交和激励他人。",
                strengths: [
                    "沟通能力强",
                    "热情乐观",
                    "善于社交",
                    "创意解决问题",
                    "激励团队",
                    "适应力强",
                ],
                weaknesses: [
                    "可能话太多",
                    "可能缺乏条理",
                    "可能缺乏后续跟进",
                    "决策过于感性",
                    "细节注意力不足",
                    "可能过于冲动",
                ],
                careers: [
                    "销售和市场营销",
                    "公共关系",
                    "培训与发展",
                    "娱乐行业",
                    "人力资源",
                    "客户服务管理",
                ],
            },
            S: {
                description:
                    "你是一个耐心、支持性强、注重和谐的人。你擅长建立稳定的关系和保持一致性。",
                strengths: [
                    "出色的倾听能力",
                    "耐心且富有支持性",
                    "团队合作",
                    "可靠且始终如一",
                    "善于跟进",
                    "创造稳定环境",
                ],
                weaknesses: [
                    "抗拒变化",
                    "可能过于随和",
                    "行动较慢",
                    "避免冲突",
                    "可能过于被动",
                    "难以快速决策",
                ],
                careers: [
                    "人力资源",
                    "医疗保健",
                    "教育工作",
                    "咨询顾问",
                    "客户支持",
                    "行政管理",
                ],
            },
            C: {
                description:
                    "你是一个分析型、精确且注重细节的人。你擅长维持高标准和解决复杂问题。",
                strengths: [
                    "分析能力强",
                    "注重细节",
                    "高质量标准",
                    "规划能力好",
                    "系统性方法",
                    "出色的问题解决能力",
                ],
                weaknesses: [
                    "可能过于完美主义",
                    "可能过于挑剔",
                    "决策较慢",
                    "可能过度分析",
                    "抗拒改变",
                    "可能过于谨慎",
                ],
                careers: [
                    "研究与分析",
                    "财务规划",
                    "质量控制",
                    "IT开发",
                    "工程技术",
                    "法律职业",
                ],
            },
        },
        note: "*  DISC 类型并非固定不变 - 它们会随时间和不同情况而改变。",
    },
    types: {
        meta: {
            title: "DISC 性格类型 - 了解不同行为风格",
            description: "探索不同的 DISC 性格类型，了解它们独特的特征和特点。",
            keywords: "DISC 类型,性格类型,行为风格, DISC 特征",
        },
        title: "DISC 性格类型",
        groups: {
            dominance: "支配型类型",
            influence: "影响型类型",
            steadiness: "稳健型类型",
            conscientiousness: "谨慎型类型",
        },
        types: {
            dominanceTypes: ["D", "DI", "DC", "DIC"],
            influenceTypes: ["I", "ID", "IS", "IDS"],
            steadinessTypes: ["S", "SI", "SC", "SIC"],
            conscientiousnessTypes: ["C", "CD", "CS", "CDS"],
            typeMap: {
                D: {
                    name: "支配型",
                    description: "果断、直接、目标导向、喜欢掌控局面。",
                    traits: [
                        "果断",
                        "直接",
                        "目标导向",
                        "掌控欲强",
                        "自信",
                        "竞争意识强",
                    ],
                },
                DI: {
                    name: "支配影响型",
                    description: "外向、果断、善于交际、富有感染力。",
                    traits: [
                        "外向",
                        "果断",
                        "善于交际",
                        "富有感染力",
                        "自信",
                        "竞争意识强",
                    ],
                },
                DC: {
                    name: "支配谨慎型",
                    description: "果断、谨慎、注重细节、追求完美。",
                    traits: [
                        "果断",
                        "谨慎",
                        "注重细节",
                        "追求完美",
                        "自信",
                        "竞争意识强",
                    ],
                },
                DIC: {
                    name: "支配影响谨慎型",
                    description: "果断、外向、谨慎、追求完美。",
                    traits: [
                        "果断",
                        "外向",
                        "谨慎",
                        "追求完美",
                        "自信",
                        "竞争意识强",
                    ],
                },
                I: {
                    name: "影响型",
                    description: "外向、乐观、善于交际、富有感染力。",
                    traits: [
                        "外向",
                        "乐观",
                        "善于交际",
                        "富有感染力",
                        "热情",
                        "创造力强",
                    ],
                },
                ID: {
                    name: "影响支配型",
                    description: "外向、果断、目标导向、富有感染力。",
                    traits: [
                        "外向",
                        "果断",
                        "目标导向",
                        "富有感染力",
                        "热情",
                        "创造力强",
                    ],
                },
                IS: {
                    name: "影响稳健型",
                    description: "外向、耐心、善于倾听、富有同理心。",
                    traits: [
                        "外向",
                        "耐心",
                        "善于倾听",
                        "富有同理心",
                        "热情",
                        "创造力强",
                    ],
                },
                IDS: {
                    name: "影响支配稳健型",
                    description: "外向、果断、耐心、富有感染力。",
                    traits: [
                        "外向",
                        "果断",
                        "耐心",
                        "富有感染力",
                        "热情",
                        "创造力强",
                    ],
                },
                S: {
                    name: "稳健型",
                    description: "耐心、稳定、善于倾听、富有同理心。",
                    traits: [
                        "耐心",
                        "稳定",
                        "善于倾听",
                        "富有同理心",
                        "忠诚",
                        "团队合作",
                    ],
                },
                SI: {
                    name: "稳健影响型",
                    description: "耐心、外向、善于交际、富有同理心。",
                    traits: [
                        "耐心",
                        "外向",
                        "善于交际",
                        "富有同理心",
                        "忠诚",
                        "团队合作",
                    ],
                },
                SC: {
                    name: "稳健谨慎型",
                    description: "耐心、谨慎、注重细节、追求完美。",
                    traits: [
                        "耐心",
                        "谨慎",
                        "注重细节",
                        "追求完美",
                        "忠诚",
                        "团队合作",
                    ],
                },
                SIC: {
                    name: "稳健影响谨慎型",
                    description: "耐心、外向、谨慎、追求完美。",
                    traits: [
                        "耐心",
                        "外向",
                        "谨慎",
                        "追求完美",
                        "忠诚",
                        "团队合作",
                    ],
                },
                C: {
                    name: "谨慎型",
                    description: "谨慎、注重细节、追求完美、善于分析。",
                    traits: [
                        "谨慎",
                        "注重细节",
                        "追求完美",
                        "善于分析",
                        "准确",
                        "系统思维",
                    ],
                },
                CD: {
                    name: "谨慎支配型",
                    description: "谨慎、果断、目标导向、追求完美。",
                    traits: [
                        "谨慎",
                        "果断",
                        "目标导向",
                        "追求完美",
                        "准确",
                        "系统思维",
                    ],
                },
                CS: {
                    name: "谨慎稳健型",
                    description: "谨慎、耐心、注重细节、追求完美。",
                    traits: [
                        "谨慎",
                        "耐心",
                        "注重细节",
                        "追求完美",
                        "准确",
                        "系统思维",
                    ],
                },
                CDS: {
                    name: "谨慎支配稳健型",
                    description: "谨慎、果断、耐心、追求完美。",
                    traits: [
                        "谨慎",
                        "果断",
                        "耐心",
                        "追求完美",
                        "准确",
                        "系统思维",
                    ],
                },
            },
        },
    },
};
