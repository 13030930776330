<template>
  <div id="app">
    <LanguageSwitch />
    <router-view />
    <div class="float-container">
      <el-button class="qr-button" type="primary" circle @click="showQRCode">
        <el-icon size="20">
          <Iphone />
        </el-icon>
      </el-button>
    </div>

    <el-dialog
      v-model="dialogVisible"
      :title="$t('common.scanQRCode')"
      width="300px"
      align-center
    >
      <div class="qr-container">
        <div id="qrcode" ref="qrcodeRef"></div>
        <p class="qr-tip">{{ $t("common.scanQRCodeTip") }}</p>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, nextTick, defineExpose, watch } from "vue";
import { Iphone } from "@element-plus/icons-vue";
import QRCode from "qrcode";
import LanguageSwitch from "./components/LanguageSwitch.vue";
import { useStore } from "vuex";

const store = useStore();
const dialogVisible = ref(false);
const qrcodeRef = ref(null);

// 确保在应用启动时就设置正确的语言
const initLang = localStorage.getItem("lang") || "zh";
document.documentElement.lang = initLang === "zh" ? "zh-CN" : "en";

// 监听语言变化
watch(
  () => store.state.lang,
  (newLang) => {
    document.documentElement.lang = newLang === "zh" ? "zh-CN" : "en";
  },
  {
    immediate: true,
    deep: true,
    flush: "post",
  }
);

const showQRCode = async () => {
  dialogVisible.value = true;
  await nextTick();
  const currentUrl = window.location.href;
  try {
    const qrCanvas = await QRCode.toCanvas(currentUrl, {
      width: 200,
      margin: 1,
      color: {
        dark: "#000",
        light: "#fff",
      },
    });
    if (qrcodeRef.value.firstChild) {
      qrcodeRef.value.removeChild(qrcodeRef.value.firstChild);
    }
    qrcodeRef.value.appendChild(qrCanvas);
  } catch (err) {
    console.error(err);
  }
};

defineExpose({
  showQRCode,
});
</script>

<style lang="scss">
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
}
#app {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  color: #ffffff;
  overflow: hidden; // 防止光晕溢出
}

// 优化滚动条样式
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 4px;

  &:hover {
    background: rgba(255, 255, 255, 0.3);
  }
}

// 禁用文本选择，优化体验
* {
  user-select: none;
}

// 允许输入框和特定内容可选
input,
textarea,
[contenteditable="true"] {
  user-select: text;
}

.float-container {
  position: fixed;
  right: 12px;
  bottom: 20px;
  z-index: 2000;
  display: flex;
  align-items: center;
}

.qr-button {
  transition: transform 0.3s ease;
  width: 36px !important;
  height: 36px !important;
  padding: 0 !important;
  border-radius: 50% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.qr-button:hover {
  transform: scale(1.1);
}

.qr-button .el-icon {
  width: 20px !important;
  height: 20px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.qr-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.qr-tip {
  margin-top: 15px;
  color: #666;
  font-size: 14px;
}

#qrcode {
  background: #fff;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
</style>
