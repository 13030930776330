import common from "./common";
import mbti from "./mbti";
import bigFive from "./bigfive";
import disc from "./disc";
import holland from "./holland";
import enneagram from "./enneagram";
import sixteenpf from "./16pf";
import hsp from "./hsp";
import truecolors from "./truecolors";
import attachment from "./attachment";

export default {
    ...common,
    mbti: {
        ...mbti,
    },
    bigfive: {
        ...bigFive,
    },
    disc: {
        ...disc,
    },
    holland: {
        ...holland,
    },
    enneagram: {
        ...enneagram,
    },
    "16pf": {
        ...sixteenpf,
    },
    hsp: {
        ...hsp,
    },
    truecolors: {
        ...truecolors,
    },
    attachment: {
        ...attachment,
    },
};
