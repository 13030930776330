export default {
    index: {
        meta: {
            title: "DISC Personality Test - Understand Your Behavioral Style",
            description:
                "Learn about your behavioral style through the DISC personality test and enhance your self-awareness and interpersonal communication skills.",
            keywords:
                "DISC test, personality test, behavioral style, interpersonal communication, self-awareness",
        },
        title: "DISC Personality Test",
        subtitle:
            "Understand Your Behavioral Style, Enhance Communication Skills",
        tips: {
            list: [
                "Welcome to the DISC test! Through this test, you will understand your behavioral tendencies in four types and gain insights on how to collaborate and communicate more effectively with others.",
                "The DISC test helps you explore your behavior patterns, enabling you to understand how to work better with people of different types and improve team efficiency.",
                "Please answer each question as honestly as possible based on your usual behaviors and attitudes. The results will help you identify your strengths and areas for personal development.",
                "By analyzing your personality traits, the DISC test helps you maximize your potential in the workplace and build more effective interpersonal relationships.",
            ],
        },
        buttons: {
            whatIsDISC: "What is DISC",
            discTypes: "DISC Types",
        },
        features: [
            {
                title: "Scientific DISC Model",
                description:
                    "Based on Dr. William Moulton Marston's behavioral style theory, evaluating your personality traits through four dimensions",
            },
            {
                title: "Comprehensive Analysis",
                description:
                    "Deep understanding of your behavioral style, communication patterns, and decision-making characteristics",
            },
            {
                title: "Practical Communication Tips",
                description:
                    "Receive targeted communication strategies and relationship advice based on your test results",
            },
        ],
    },
    introduction: {
        meta: {
            title: "DISC Personality Test Introduction - Understanding Behavioral Style Theory",
            description:
                "Deep dive into the theoretical foundation, four dimensions, and application value of the DISC personality test.",
            keywords:
                "DISC test introduction, behavioral style theory, personality dimensions, communication styles",
        },
        title: "DISC Personality Test Introduction",
        subtitle:
            "Understanding Behavioral Style Theory, Enhancing Self-Awareness",
        what: {
            title: "What is the DISC Personality Test",
            content:
                "The DISC personality test is an assessment tool developed based on Dr. William Moulton Marston's behavioral style theory. This theory suggests that people's behavioral styles can be categorized into four basic dimensions: Dominance (D), Influence (I), Steadiness (S), and Conscientiousness (C).The DISC test helps individuals understand their behavior patterns in work and social settings, enabling them to respond appropriately in different situations.",
        },
        history: {
            title: "Historical Background",
            content:
                "Dr. William Moulton Marston first proposed the DISC theory in 1928 and detailed it in his book 'The Emotions of Normal People'. After nearly a century of development and application, DISC has become one of the most widely used behavioral style assessment tools.",
        },
        types: {
            title: "Four Dimensions",
            traits: "Traits",
            strengths: "Strengths",
            weaknesses: "Weaknesses",
            items: [
                {
                    icon: "👑",
                    title: "Dominance (D)",
                    description: "Decisive, direct, goal-oriented",
                    traits: [
                        "Decisive",
                        "Direct",
                        "Goal-oriented",
                        "Challenge-seeking",
                    ],
                    strengths: [
                        "Quick decision-making",
                        "Strong execution",
                        "Good leadership",
                        "Innovative",
                    ],
                    weaknesses: [
                        "May be too aggressive",
                        "Impatient",
                        "Poor listening",
                        "Impulsive",
                    ],
                },
                {
                    icon: "🌟",
                    title: "Influence (I)",
                    description: "Outgoing, enthusiastic, expressive",
                    traits: [
                        "Outgoing",
                        "Enthusiastic",
                        "Expressive",
                        "Optimistic",
                    ],
                    strengths: [
                        "Good at socializing",
                        "Influential",
                        "Creative",
                        "Adaptable",
                    ],
                    weaknesses: [
                        "May be too emotional",
                        "Impulsive",
                        "Lack of planning",
                        "Difficulty focusing",
                    ],
                },
                {
                    icon: "🤝",
                    title: "Steadiness (S)",
                    description: "Patient, friendly, harmony-focused",
                    traits: ["Patient", "Friendly", "Harmony-focused", "Loyal"],
                    strengths: [
                        "Good listener",
                        "Team player",
                        "Reliable",
                        "Empathetic",
                    ],
                    weaknesses: [
                        "May be too conservative",
                        "Resistant to change",
                        "Indecisive",
                        "Difficulty saying no",
                    ],
                },
                {
                    icon: "📊",
                    title: "Conscientiousness (C)",
                    description: "Detail-oriented, accurate, perfectionist",
                    traits: [
                        "Detail-oriented",
                        "Accurate",
                        "Perfectionist",
                        "Focused on details",
                    ],
                    strengths: [
                        "Strong analytical skills",
                        "Quality-focused",
                        "Good at planning",
                        "Pursues accuracy",
                    ],
                    weaknesses: [
                        "May be too perfectionist",
                        "Easily anxious",
                        "Slow decision-making",
                        "Resistant to change",
                    ],
                },
            ],
        },
        applications: {
            title: "Application Value",
            items: [
                "Self-awareness: Helps individuals understand their behavioral traits and decision-making styles, enhancing self-awareness and emotional intelligence.",
                "Team Building: Optimizes collaboration and communication among team members, improving overall team performance.",
                "Career Development: Provides career guidance based on test results, helping individuals choose the career paths best suited to their behavior style.",
                "Leadership Development: Helps managers understand their leadership style and improve their approach to leading teams.",
                "Conflict Management: By understanding behavioral traits, the test helps individuals adopt appropriate strategies for handling conflicts.",
                "Interpersonal Communication: Helps individuals improve their communication methods, increasing communication effectiveness.",
            ],
        },
        notes: {
            title: "Important Notes",
            items: [
                "Be Honest When Answering: The test results are based on your real behavior, so try to answer as truthfully as possible and avoid tailoring your answers.",
                "Understand the Limitations of the Test: The DISC test provides guidance on behavior patterns, but it is not a definitive personality diagnosis. Results should be used in conjunction with other assessment tools.",
                "The Test Does Not Define Everything: Human behavior is diverse and complex, and DISC is only a reference tool, not a comprehensive assessment of a person’s overall qualities.",
                "Cultural Impact: Cultural backgrounds may influence the expression of behavior patterns, so test results should be analyzed in the context of cultural influences.",
                "Non-clinical Tool: The DISC test is used for personal and team development and improving work efficiency, but it should not be used as a tool for psychological diagnosis or treatment.",
            ],
        },
    },
    test: {
        meta: {
            title: "DISC Personality Test - Start Testing",
            description:
                "Learn about your behavioral style type by answering a series of questions.",
            keywords: "DISC test, personality test, behavioral style test",
        },
        title: "DISC Personality Test",
        questions: [
            // Dominance (D) - 10 questions
            {
                question: "What role do you typically play in a team?",
                options: [
                    "Leader, responsible for decision-making and guidance",
                    "Energizer, good at motivating team spirit",
                    "Supporter, ensuring team harmony",
                    "Analyst, focusing on details and planning",
                ],
            },
            {
                question: "When facing pressure, your first reaction is to:",
                options: [
                    "Take immediate action to solve problems",
                    "Seek support and encouragement from others",
                    "Stay calm and wait patiently",
                    "Analyze carefully and make plans",
                ],
            },
            {
                question: "In social situations, you tend to:",
                options: [
                    "Lead conversations and express opinions",
                    "Create lively atmosphere and make new friends",
                    "Listen to others and maintain harmony",
                    "Observe and analyze, participate cautiously",
                ],
            },
            {
                question: "When making decisions, you value most:",
                options: [
                    "Quick action and results",
                    "Team feelings and atmosphere",
                    "Stability and harmony",
                    "Accuracy and details",
                ],
            },
            {
                question: "At work, you focus most on:",
                options: [
                    "Goals and achievements",
                    "Interpersonal relationships and atmosphere",
                    "Team collaboration and stability",
                    "Quality and standards",
                ],
            },
            {
                question: "When encountering problems, you usually:",
                options: [
                    "Take immediate action to solve",
                    "Seek advice and help from others",
                    "Wait patiently for the best timing",
                    "Analyze various possibilities carefully",
                ],
            },
            {
                question: "In team discussions, you tend to:",
                options: [
                    "Guide the discussion direction",
                    "Create lively discussion atmosphere",
                    "Listen and support others",
                    "Analyze various viewpoints",
                ],
            },
            {
                question: "When facing a new project, you first:",
                options: [
                    "Set goals and take immediate action",
                    "Share ideas with the team",
                    "Understand team opinions",
                    "Make detailed plans",
                ],
            },
            {
                question: "In conflict situations, you will:",
                options: [
                    "Face and resolve directly",
                    "Seek consensus and compromise",
                    "Maintain neutrality and harmony",
                    "Analyze causes and solutions",
                ],
            },
            {
                question: "When completing tasks, you focus more on:",
                options: [
                    "Efficiency and results",
                    "Team collaboration and atmosphere",
                    "Stability and continuity",
                    "Quality and accuracy",
                ],
            },

            // Influence (I) - 10 questions
            {
                question: "In social situations, you prefer to:",
                options: [
                    "Lead conversations",
                    "Create lively atmosphere and make new friends",
                    "Listen and maintain harmony",
                    "Observe and analyze carefully",
                ],
            },
            {
                question: "When facing challenges, you will:",
                options: [
                    "Take immediate action",
                    "Seek support from others",
                    "Maintain patience",
                    "Analyze solutions carefully",
                ],
            },
            {
                question: "In a team, you tend to:",
                options: [
                    "Lead the team forward",
                    "Create lively atmosphere",
                    "Support team harmony",
                    "Analyze team issues",
                ],
            },
            {
                question: "When facing changes, you will:",
                options: [
                    "Adapt actively",
                    "Seek help from others",
                    "Maintain stability",
                    "Analyze impacts",
                ],
            },
            {
                question: "At work, you prefer to:",
                options: [
                    "Complete tasks quickly",
                    "Work with others",
                    "Follow established procedures",
                    "Focus on quality",
                ],
            },
            {
                question: "When making decisions, you tend to:",
                options: [
                    "Make quick decisions",
                    "Listen to others' opinions",
                    "Maintain caution",
                    "Analyze pros and cons",
                ],
            },
            {
                question: "When facing pressure, you will:",
                options: [
                    "Take immediate action",
                    "Seek support from others",
                    "Maintain patience",
                    "Analyze solutions",
                ],
            },
            {
                question: "In a team, you focus more on:",
                options: [
                    "Goals and efficiency",
                    "Interpersonal relationships",
                    "Team harmony",
                    "Work quality",
                ],
            },
            {
                question: "When handling problems, you tend to:",
                options: [
                    "Solve quickly",
                    "Seek advice from others",
                    "Maintain patience",
                    "Analyze causes carefully",
                ],
            },
            {
                question: "At work, you focus more on:",
                options: [
                    "Efficiency and results",
                    "Team atmosphere",
                    "Work stability",
                    "Quality standards",
                ],
            },

            // Steadiness (S) - 10 questions
            {
                question: "When facing new tasks, you will:",
                options: [
                    "Start immediately",
                    "Discuss with team",
                    "Maintain caution",
                    "Make detailed plans",
                ],
            },
            {
                question: "In a team, you tend to:",
                options: [
                    "Lead the team",
                    "Create lively atmosphere",
                    "Support others",
                    "Analyze problems",
                ],
            },
            {
                question: "When making decisions, you value:",
                options: [
                    "Quick action",
                    "Team feelings",
                    "Stability and harmony",
                    "Accuracy and details",
                ],
            },
            {
                question: "When facing changes, you will:",
                options: [
                    "Adapt actively",
                    "Seek help",
                    "Maintain stability",
                    "Analyze impacts",
                ],
            },
            {
                question: "In social situations, you tend to:",
                options: [
                    "Lead conversations",
                    "Create lively atmosphere",
                    "Listen to others",
                    "Observe and analyze",
                ],
            },
            {
                question: "When facing pressure, you will:",
                options: [
                    "Take immediate action",
                    "Seek support from others",
                    "Maintain patience",
                    "Analyze solutions",
                ],
            },
            {
                question: "At work, you focus more on:",
                options: [
                    "Efficiency and results",
                    "Team atmosphere",
                    "Work stability",
                    "Quality standards",
                ],
            },
            {
                question: "When handling problems, you tend to:",
                options: [
                    "Solve quickly",
                    "Seek advice from others",
                    "Maintain patience",
                    "Analyze causes carefully",
                ],
            },
            {
                question: "In a team, you tend to:",
                options: [
                    "Lead the team",
                    "Create lively atmosphere",
                    "Support others",
                    "Analyze problems",
                ],
            },
            {
                question: "When facing new projects, you will:",
                options: [
                    "Start immediately",
                    "Discuss with team",
                    "Maintain caution",
                    "Make detailed plans",
                ],
            },

            // Conscientiousness (C) - 10 questions
            {
                question: "When making decisions, you value:",
                options: [
                    "Quick action",
                    "Team feelings",
                    "Stability and harmony",
                    "Accuracy and details",
                ],
            },
            {
                question: "When facing changes, you will:",
                options: [
                    "Adapt actively",
                    "Seek help",
                    "Maintain stability",
                    "Analyze impacts",
                ],
            },
            {
                question: "In social situations, you tend to:",
                options: [
                    "Lead conversations",
                    "Create lively atmosphere",
                    "Listen to others",
                    "Observe and analyze",
                ],
            },
            {
                question: "When facing pressure, you will:",
                options: [
                    "Take immediate action",
                    "Seek support from others",
                    "Maintain patience",
                    "Analyze solutions",
                ],
            },
            {
                question: "At work, you focus more on:",
                options: [
                    "Efficiency and results",
                    "Team atmosphere",
                    "Work stability",
                    "Quality standards",
                ],
            },
            {
                question: "When handling problems, you tend to:",
                options: [
                    "Solve quickly",
                    "Seek advice from others",
                    "Maintain patience",
                    "Analyze causes carefully",
                ],
            },
            {
                question: "In a team, you tend to:",
                options: [
                    "Lead the team",
                    "Create lively atmosphere",
                    "Support others",
                    "Analyze problems",
                ],
            },
            {
                question: "When facing new projects, you will:",
                options: [
                    "Start immediately",
                    "Discuss with team",
                    "Maintain caution",
                    "Make detailed plans",
                ],
            },
            {
                question: "In team discussions, you tend to:",
                options: [
                    "Guide the direction",
                    "Create lively atmosphere",
                    "Support others",
                    "Analyze viewpoints",
                ],
            },
            {
                question: "When completing tasks, you focus more on:",
                options: [
                    "Efficiency and results",
                    "Team atmosphere",
                    "Work stability",
                    "Quality standards",
                ],
            },
        ],
    },
    result: {
        meta: {
            title: "DISC Personality Test Results - Understand Your Behavioral Style",
            description:
                "View your DISC personality test results and understand your behavioral style type and communication characteristics.",
            keywords:
                "DISC test results, behavioral style analysis, communication advice",
        },
        title: "Your Behavioral Style Analysis",
        subtitle:
            "Based on your answers, we've generated a detailed analysis of your behavioral style",
        primaryType: {
            title: "Primary Type",
            traits: "Traits",
            strengths: "Strengths",
            weaknesses: "Weaknesses",
            default: {
                icon: "👑",
                title: "Dominance (D)",
                description:
                    "You are a decisive, direct, and goal-oriented person. You enjoy challenges and are good at decision-making and leadership.",
                traits: [
                    "Decisive",
                    "Direct",
                    "Goal-oriented",
                    "Challenge-seeking",
                ],
                strengths: [
                    "Quick decision-making",
                    "Strong execution",
                    "Good leadership",
                    "Innovative",
                ],
                weaknesses: [
                    "May be too aggressive",
                    "Impatient",
                    "Poor listening",
                    "Impulsive",
                ],
            },
        },
        dimensions: {
            title: "Dimension Analysis",
            items: [
                {
                    label: "Dominance (D)",
                    score: 85,
                },
                {
                    label: "Influence (I)",
                    score: 65,
                },
                {
                    label: "Steadiness (S)",
                    score: 45,
                },
                {
                    label: "Conscientiousness (C)",
                    score: 75,
                },
            ],
            D: {
                description:
                    "Focuses on controlling the environment by overcoming opposition to accomplish results",
            },
            I: {
                description:
                    "Focuses on influencing others through talking and activity and relying on personal enthusiasm",
            },
            S: {
                description:
                    "Focuses on cooperating with others to carry out tasks and maintaining stability and harmony",
            },
            C: {
                description:
                    "Focuses on working conscientiously within existing circumstances to ensure quality and accuracy",
            },
        },
        communicationAdvice: {
            title: "Communication Advice",
            items: [
                {
                    title: "Communicating with Different Styles",
                    description:
                        "Based on your behavioral style, here are tips for communicating with different styles:",
                    tips: [
                        "With Dominance: Direct, concise, focus on results",
                        "With Influence: Enthusiastic, interactive, focus on feelings",
                        "With Steadiness: Patient, friendly, focus on harmony",
                        "With Conscientiousness: Accurate, professional, focus on details",
                    ],
                },
                {
                    title: "Development Tips",
                    description:
                        "To improve your communication effectiveness, we recommend:",
                    tips: [
                        "Develop listening skills",
                        "Improve emotional management",
                        "Strengthen team collaboration",
                        "Focus on detail control",
                    ],
                },
            ],
        },
        developmentAdvice: {
            title: "Development Advice",
            items: [
                {
                    title: "Personal Growth",
                    description:
                        "We recommend focusing on the following areas:",
                },
                {
                    title: "Career Development",
                    description: "Here's a plan for your career development:",
                },
            ],
        },
        yourType: "Your DISC Type",
        details: {
            title: "Detailed Analysis",
            strengths: {
                title: "Your Strengths",
            },
            weaknesses: {
                title: "Areas for Improvement",
            },
            career: {
                title: "Recommended Career Paths",
            },
        },
        cta: {
            title: "What's Next?",
            description: "Continue your journey of self-discovery",
        },
        types: {
            D: {
                description:
                    "You are a decisive, direct, and goal-oriented person. You enjoy challenges and are good at decision-making and leadership.",
                strengths: [
                    "Quick decision-making",
                    "Strong execution",
                    "Good leadership",
                    "Innovative thinking",
                    "Results-oriented",
                    "High initiative",
                ],
                weaknesses: [
                    "May be too aggressive",
                    "Impatient with others",
                    "Poor listening skills",
                    "Can be insensitive",
                    "May overlook details",
                    "Can be too controlling",
                ],
                careers: [
                    "Executive Leadership",
                    "Entrepreneurship",
                    "Sales Management",
                    "Project Management",
                    "Business Development",
                    "Military Leadership",
                ],
            },
            I: {
                description:
                    "You are an outgoing, enthusiastic, and expressive person. You excel at networking and inspiring others.",
                strengths: [
                    "Strong communication skills",
                    "Enthusiastic and optimistic",
                    "Good at networking",
                    "Creative problem solving",
                    "Team motivation",
                    "Adaptable",
                ],
                weaknesses: [
                    "May be too talkative",
                    "Can be disorganized",
                    "May lack follow-through",
                    "Emotional decision making",
                    "Poor attention to detail",
                    "Can be impulsive",
                ],
                careers: [
                    "Sales and Marketing",
                    "Public Relations",
                    "Training and Development",
                    "Entertainment",
                    "Human Resources",
                    "Customer Service Management",
                ],
            },
            S: {
                description:
                    "You are a patient, supportive, and harmony-focused person. You excel at building stable relationships and maintaining consistency.",
                strengths: [
                    "Excellent listening skills",
                    "Patient and supportive",
                    "Team player",
                    "Reliable and consistent",
                    "Good at following through",
                    "Creates stable environment",
                ],
                weaknesses: [
                    "Resistant to change",
                    "May be too accommodating",
                    "Slow to take action",
                    "Avoids confrontation",
                    "May be too passive",
                    "Difficulty with quick decisions",
                ],
                careers: [
                    "Human Resources",
                    "Healthcare",
                    "Education",
                    "Counseling",
                    "Customer Support",
                    "Administrative Management",
                ],
            },
            C: {
                description:
                    "You are an analytical, precise, and detail-oriented person. You excel at maintaining high standards and solving complex problems.",
                strengths: [
                    "Strong analytical skills",
                    "Attention to detail",
                    "High quality standards",
                    "Good planning skills",
                    "Systematic approach",
                    "Excellent problem solving",
                ],
                weaknesses: [
                    "May be too perfectionist",
                    "Can be overly critical",
                    "Slow decision making",
                    "May over-analyze",
                    "Resistant to change",
                    "Can be too cautious",
                ],
                careers: [
                    "Research and Analysis",
                    "Financial Planning",
                    "Quality Control",
                    "IT Development",
                    "Engineering",
                    "Legal Profession",
                ],
            },
        },
        note: "* DISC types are not fixed - they can change over time and in different situations.",
    },
    types: {
        meta: {
            title: "DISC Personality Types - Understanding Different Behavioral Styles",
            description:
                "Explore the different DISC personality types and understand their unique characteristics and traits.",
            keywords:
                "DISC types, personality types, behavioral styles, DISC characteristics",
        },
        title: "DISC Personality Types",
        groups: {
            dominance: "Dominance Types",
            influence: "Influence Types",
            steadiness: "Steadiness Types",
            conscientiousness: "Conscientiousness Types",
        },
        types: {
            dominanceTypes: ["D", "DI", "DC", "DIC"],
            influenceTypes: ["I", "ID", "IS", "IDS"],
            steadinessTypes: ["S", "SI", "SC", "SIC"],
            conscientiousnessTypes: ["C", "CD", "CS", "CDS"],
            typeMap: {
                D: {
                    name: "Dominance",
                    description:
                        "Decisive, direct, goal-oriented, likes to be in control.",
                    traits: [
                        "Decisive",
                        "Direct",
                        "Goal-oriented",
                        "Controlling",
                        "Confident",
                        "Competitive",
                    ],
                },
                DI: {
                    name: "Dominance-Influence",
                    description: "Outgoing, decisive, sociable, influential.",
                    traits: [
                        "Outgoing",
                        "Decisive",
                        "Sociable",
                        "Influential",
                        "Confident",
                        "Competitive",
                    ],
                },
                DC: {
                    name: "Dominance-Conscientiousness",
                    description:
                        "Decisive, cautious, detail-oriented, perfectionist.",
                    traits: [
                        "Decisive",
                        "Cautious",
                        "Detail-oriented",
                        "Perfectionist",
                        "Confident",
                        "Competitive",
                    ],
                },
                DIC: {
                    name: "Dominance-Influence-Conscientiousness",
                    description: "Decisive, outgoing, cautious, perfectionist.",
                    traits: [
                        "Decisive",
                        "Outgoing",
                        "Cautious",
                        "Perfectionist",
                        "Confident",
                        "Competitive",
                    ],
                },
                I: {
                    name: "Influence",
                    description: "Outgoing, optimistic, sociable, influential.",
                    traits: [
                        "Outgoing",
                        "Optimistic",
                        "Sociable",
                        "Influential",
                        "Enthusiastic",
                        "Creative",
                    ],
                },
                ID: {
                    name: "Influence-Dominance",
                    description:
                        "Outgoing, decisive, goal-oriented, influential.",
                    traits: [
                        "Outgoing",
                        "Decisive",
                        "Goal-oriented",
                        "Influential",
                        "Enthusiastic",
                        "Creative",
                    ],
                },
                IS: {
                    name: "Influence-Steadiness",
                    description:
                        "Outgoing, patient, good listener, empathetic.",
                    traits: [
                        "Outgoing",
                        "Patient",
                        "Good listener",
                        "Empathetic",
                        "Enthusiastic",
                        "Creative",
                    ],
                },
                IDS: {
                    name: "Influence-Dominance-Steadiness",
                    description: "Outgoing, decisive, patient, influential.",
                    traits: [
                        "Outgoing",
                        "Decisive",
                        "Patient",
                        "Influential",
                        "Enthusiastic",
                        "Creative",
                    ],
                },
                S: {
                    name: "Steadiness",
                    description: "Patient, stable, good listener, empathetic.",
                    traits: [
                        "Patient",
                        "Stable",
                        "Good listener",
                        "Empathetic",
                        "Loyal",
                        "Team player",
                    ],
                },
                SI: {
                    name: "Steadiness-Influence",
                    description: "Patient, outgoing, sociable, empathetic.",
                    traits: [
                        "Patient",
                        "Outgoing",
                        "Sociable",
                        "Empathetic",
                        "Loyal",
                        "Team player",
                    ],
                },
                SC: {
                    name: "Steadiness-Conscientiousness",
                    description:
                        "Patient, cautious, detail-oriented, perfectionist.",
                    traits: [
                        "Patient",
                        "Cautious",
                        "Detail-oriented",
                        "Perfectionist",
                        "Loyal",
                        "Team player",
                    ],
                },
                SIC: {
                    name: "Steadiness-Influence-Conscientiousness",
                    description: "Patient, outgoing, cautious, perfectionist.",
                    traits: [
                        "Patient",
                        "Outgoing",
                        "Cautious",
                        "Perfectionist",
                        "Loyal",
                        "Team player",
                    ],
                },
                C: {
                    name: "Conscientiousness",
                    description:
                        "Cautious, detail-oriented, perfectionist, analytical.",
                    traits: [
                        "Cautious",
                        "Detail-oriented",
                        "Perfectionist",
                        "Analytical",
                        "Accurate",
                        "Systematic",
                    ],
                },
                CD: {
                    name: "Conscientiousness-Dominance",
                    description:
                        "Cautious, decisive, goal-oriented, perfectionist.",
                    traits: [
                        "Cautious",
                        "Decisive",
                        "Goal-oriented",
                        "Perfectionist",
                        "Accurate",
                        "Systematic",
                    ],
                },
                CS: {
                    name: "Conscientiousness-Steadiness",
                    description:
                        "Cautious, patient, detail-oriented, perfectionist.",
                    traits: [
                        "Cautious",
                        "Patient",
                        "Detail-oriented",
                        "Perfectionist",
                        "Accurate",
                        "Systematic",
                    ],
                },
                CDS: {
                    name: "Conscientiousness-Dominance-Steadiness",
                    description: "Cautious, decisive, patient, perfectionist.",
                    traits: [
                        "Cautious",
                        "Decisive",
                        "Patient",
                        "Perfectionist",
                        "Accurate",
                        "Systematic",
                    ],
                },
            },
        },
    },
};
