export default {
    namespaced: true,
    state: {
        answers: [],
        currentQuestion: 0,
        isTestStarted: false,
    },
    mutations: {
        saveAnswer(state, { index, answer }) {
            state.answers[index] = answer;
            localStorage.setItem("disc_answers", JSON.stringify(state.answers));
        },
        updateCurrentQuestion(state, index) {
            state.currentQuestion = index;
            localStorage.setItem("disc_current_question", index.toString());
        },
        resetTest(state) {
            state.answers = [];
            state.currentQuestion = 0;
            state.isTestStarted = false;
            localStorage.removeItem("disc_answers");
            localStorage.removeItem("disc_current_question");
        },
        setTestStarted(state, value) {
            state.isTestStarted = value;
        },
        restoreState(state) {
            const savedAnswers = localStorage.getItem("disc_answers");
            const savedQuestion = localStorage.getItem("disc_current_question");
            if (savedAnswers) {
                state.answers = JSON.parse(savedAnswers);
            }
            if (savedQuestion) {
                state.currentQuestion = parseInt(savedQuestion);
            }
        },
    },
    actions: {
        saveAnswer({ commit }, { index, answer }) {
            commit("saveAnswer", { index, answer });
        },
        updateCurrentQuestion({ commit }, index) {
            commit("updateCurrentQuestion", index);
        },
        resetTest({ commit }) {
            commit("resetTest");
        },
        startTest({ commit }) {
            commit("resetTest");
            commit("setTestStarted", true);
            commit("updateCurrentQuestion", 0);
        },
        checkAnswersComplete({ state }, totalQuestions) {
            return (
                state.answers.length === totalQuestions &&
                state.answers.every((answer) => answer !== undefined)
            );
        },
        restoreState({ commit }) {
            commit("restoreState");
        },
    },
    getters: {
        getCurrentQuestion: (state) => state.currentQuestion,
        getAnswers: (state) => state.answers,
        hasTestStarted: (state) => state.isTestStarted,
        isAnswersComplete: (state) => (totalQuestions) => {
            return (
                state.answers.length === totalQuestions &&
                state.answers.every((answer) => answer !== undefined)
            );
        },
    },
};
