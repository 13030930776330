import { createStore } from "vuex";
import truecolors from "./modules/truecolors";
import mbti from "./modules/mbti";
import bigfive from "./modules/bigfive";
import enneagram from "./modules/enneagram";
import disc from "./modules/disc";
import holland from "./modules/holland";
import sixteenpf from "./modules/16pf";
import hsp from "./modules/hsp";
import attachment from "./modules/attachment";

export default createStore({
    state: {
        currentTest: null,
        testStates: {
            mbti: { answers: [], currentQuestion: 0 },
            disc: { answers: [], currentQuestion: 0 },
            hsp: { answers: [], currentQuestion: 0 },
            holland: { answers: [], currentQuestion: 0 },
            enneagram: { answers: [], currentQuestion: 0 },
            attachment: { answers: [], currentQuestion: 0 },
            bigfive: { answers: [], currentQuestion: 0 },
            "16pf": { answers: [], currentQuestion: 0 },
        },
        lang: localStorage.getItem("lang") || "zh",
    },
    mutations: {
        setCurrentTest(state, testType) {
            state.currentTest = testType;
        },
        saveAnswer(state, { testType, index, answer }) {
            state.testStates[testType].answers[index] = answer;
        },
        updateCurrentQuestion(state, { testType, index }) {
            state.testStates[testType].currentQuestion = index;
        },
        resetTest(state, testType) {
            state.testStates[testType].answers = [];
            state.testStates[testType].currentQuestion = 0;
        },
        setLang(state, lang) {
            state.lang = lang;
            localStorage.setItem("lang", lang);
        },
    },
    actions: {
        startTest({ commit }, testType) {
            commit("setCurrentTest", testType);
            commit("updateCurrentQuestion", { testType, index: 0 });
        },
        saveAnswer({ commit }, { testType, index, answer }) {
            commit("saveAnswer", { testType, index, answer });
        },
        updateCurrentQuestion({ commit }, { testType, index }) {
            commit("updateCurrentQuestion", { testType, index });
        },
        resetTest({ commit }, testType) {
            commit("resetTest", testType);
        },
    },
    getters: {
        getCurrentTest: (state) => state.currentTest,
        getTestAnswers: (state) => (testType) =>
            state.testStates[testType].answers,
        getCurrentQuestion: (state) => (testType) =>
            state.testStates[testType].currentQuestion,
    },
    modules: {
        truecolors,
        mbti,
        bigfive,
        enneagram,
        disc,
        holland,
        sixteenpf,
        hsp,
        attachment,
    },
});
