export default {
    index: {
        title: "Big Five Personality Test",
        subtitle:
            "Understand your personality traits through scientific assessment",
        tips: {
            list: [
                "This test is designed to assess your five personality traits: Openness, Conscientiousness, Extraversion, Agreeableness, and Neuroticism. Please answer based on your true feelings.",
                "The Big Five Personality Test helps you understand your personality traits and provides insights for personal growth, career development, and relationships.",
                'The test consists of a series of statements, and you need to select the option that best describes you. There are no "right" or "wrong" answers.',
                "Try to answer based on your first instinct without overthinking, to ensure the results reflect your true personality.",
                "The test results are for reference only and do not make absolute judgments about your personality. Please approach the analysis with an open mind.",
            ],
        },
        buttons: {
            whatIsBigFive: "What is Big Five",
            bigFiveTypes: "Five Dimensions",
        },
        meta: {
            title: "Free Big Five Personality Test - Scientific Trait Assessment Tool",
            description:
                "Discover your personality traits through the scientific Big Five test, measuring Openness, Conscientiousness, Extraversion, Agreeableness, and Emotional Stability.",
            keywords:
                "Big Five,personality test,trait assessment,psychological test,personality analysis,Five Factor Model,personality traits",
        },
    },
    introduction: {
        meta: {
            title: "What is Big Five Personality Test? Detailed Introduction to Big Five Dimensions",
            description:
                "The Big Five personality test is a scientific personality assessment tool that describes personality traits through five dimensions: Openness, Conscientiousness, Extraversion, Agreeableness, and Neuroticism.",
            keywords:
                "Big Five,personality test,trait assessment,openness,conscientiousness,extraversion,agreeableness,neuroticism,psychological test,personality analysis",
        },
        title: "Big Five Personality Test Introduction",
        whatIsBigFive: {
            title: "What is Big Five",
            content:
                "The Big Five (Five Factor Model) is one of the most influential personality theories in contemporary personality psychology. It describes personality traits through five basic dimensions, which were derived from extensive research and statistical analysis, showing strong scientific validity and cross-cultural applicability.",
        },
        dimensions: {
            title: "Five Dimensions",
            openness: {
                title: "Openness (O)",
                description:
                    "Reflects an individual's receptiveness to new experiences and ideas",
                high: "Imaginative, creative, enjoys trying new things, open-minded",
                low: "Practical, traditional, prefers familiar things, resistant to change",
            },
            conscientiousness: {
                title: "Conscientiousness (C)",
                description:
                    "Reflects an individual's level of self-discipline and goal-oriented behavior",
                high: "Organized, responsible, self-disciplined, planful",
                low: "Spontaneous, flexible, casual, less structured",
            },
            extraversion: {
                title: "Extraversion (E)",
                description:
                    "Reflects energy and sociability in interpersonal interactions",
                high: "Outgoing, sociable, energetic, optimistic",
                low: "Introverted, reserved, quiet, thoughtful",
            },
            agreeableness: {
                title: "Agreeableness (A)",
                description:
                    "Reflects cooperation and empathy in interpersonal relationships",
                high: "Friendly, compassionate, helpful, considerate of others",
                low: "Direct, independent, logical, task-focused",
            },
            neuroticism: {
                title: "Neuroticism (N)",
                description: "Reflects emotional stability and stress response",
                high: "Prone to anxiety, emotionally reactive, sensitive to stress",
                low: "Emotionally stable, calm, resilient to stress",
            },
        },
        applications: {
            title: "Applications",
            items: [
                "Career Planning: Helps individuals identify suitable careers, improving job satisfaction and career growth.",
                "Team Management: Optimizes team composition, enhancing collaboration and communication among members.",
                "Mental Health Assessment: Identifies emotional stability, predicts stress management ability, and provides insights for psychological counseling.",
                "Interpersonal Relationships: Improves social interactions and communication quality by understanding personal and others’ personality traits.",
                "Education & Learning: Helps educators understand students’ learning styles, allowing for tailored teaching approaches and improved learning outcomes.",
                "Leadership Development: Assists managers in understanding both their own and their team members’ personality traits, refining leadership strategies to enhance team performance.",
                "Personal Development & Self-Awareness: Helps individuals recognize their strengths and weaknesses, enabling personalized growth strategies.",
            ],
        },
        notes: {
            title: "Important Notes",
            items: [
                "Answer Honestly – Respond based on your true thoughts rather than societal expectations to ensure accurate test results.",
                "Avoid Over-Interpretation – The test results only represent a part of one’s personality and should not be used as the sole judgment criterion.",
                "Regular Evaluation – Personality traits may change over time due to experiences and growth, so periodic retesting can help track personal development.",
                "Cultural Influence – Cultural backgrounds can affect test results, as people from different regions may exhibit different scoring tendencies.",
                "Practical Application – The test results should be used alongside real-life observations rather than being blindly relied upon to guide personal and professional decisions effectively.",
            ],
        },
    },
    types: {
        meta: {
            title: "Big Five Dimensions - Understanding the Five Personality Dimensions",
            description:
                "Detailed explanation of the Big Five personality dimensions: Openness, Conscientiousness, Extraversion, Agreeableness, and Neuroticism, helping you better understand your personality traits.",
            keywords:
                "Big Five,personality dimensions,openness,conscientiousness,extraversion,agreeableness,neuroticism,personality traits,psychological test",
        },
        title: "Big Five Dimensions Explained",
        dimensions: {
            openness: {
                title: "Openness (O)",
                description:
                    "Reflects receptiveness to new experiences and creative thinking",
                highTitle: "High Openness Traits",
                highTraits: [
                    "Imaginative and creative",
                    "Interest in art and aesthetics",
                    "Enjoys trying new things",
                    "Flexible and open-minded",
                    "Interest in abstract concepts",
                    "Enjoys learning new knowledge",
                ],
                lowTitle: "Low Openness Traits",
                lowTraits: [
                    "Practical and concrete",
                    "Prefers familiar things",
                    "Traditional approach",
                    "Values practical experience",
                    "Less interested in art",
                    "Cautious about change",
                ],
            },
            conscientiousness: {
                title: "Conscientiousness (C)",
                description:
                    "Reflects organizational ability, responsibility, and goal-orientation",
                highTitle: "High Conscientiousness Traits",
                highTraits: [
                    "Strong organizational skills",
                    "Attention to planning and detail",
                    "Reliable and responsible",
                    "Self-disciplined",
                    "Perfectionist tendencies",
                    "Goal-oriented",
                ],
                lowTitle: "Low Conscientiousness Traits",
                lowTraits: [
                    "Flexible and spontaneous",
                    "Easy-going",
                    "Adaptable",
                    "Spontaneous behavior",
                    "Less focused on planning",
                    "Relaxed attitude",
                ],
            },
            extraversion: {
                title: "Extraversion (E)",
                description:
                    "Reflects energy and enthusiasm in social interactions",
                highTitle: "High Extraversion Traits",
                highTraits: [
                    "Outgoing and enthusiastic",
                    "Socially skilled",
                    "Energetic",
                    "Seeks excitement",
                    "Expressive",
                    "Optimistic",
                ],
                lowTitle: "Low Extraversion Traits",
                lowTraits: [
                    "Quiet and reserved",
                    "Comfortable alone",
                    "Thoughtful",
                    "Values privacy",
                    "Energy conservation",
                    "Good listener",
                ],
            },
            agreeableness: {
                title: "Agreeableness (A)",
                description:
                    "Reflects friendliness and cooperation in relationships",
                highTitle: "High Agreeableness Traits",
                highTraits: [
                    "Empathetic",
                    "Helpful",
                    "Values harmony",
                    "Trusting",
                    "Modest and friendly",
                    "Understanding",
                ],
                lowTitle: "Low Agreeableness Traits",
                lowTraits: [
                    "Direct and frank",
                    "Independent",
                    "Logic-focused",
                    "Competitive",
                    "Efficiency-oriented",
                    "Decisive",
                ],
            },
            neuroticism: {
                title: "Neuroticism (N)",
                description:
                    "Reflects emotional response patterns and stress management",
                highTitle: "High Neuroticism Traits",
                highTraits: [
                    "Emotionally sensitive",
                    "Prone to anxiety",
                    "Sensitive to stress",
                    "Self-conscious",
                    "Mood fluctuations",
                    "Worry tendency",
                ],
                lowTitle: "Low Neuroticism Traits",
                lowTraits: [
                    "Emotionally stable",
                    "Calm and composed",
                    "Stress resistant",
                    "Optimistic",
                    "Adaptable",
                    "Self-confident",
                ],
            },
        },
    },
    test: {
        meta: {
            title: "Big Five Personality Test - Professional Trait Assessment",
            description:
                "Discover your personality traits through the scientific Big Five test. Get a detailed personality analysis report after completion.",
            keywords:
                "Big Five test,personality test,trait assessment,psychological test,personality analysis,Five Factor Model",
        },
        title: "Big Five Personality Test",
        buttons: {
            viewTypes: "View Personality Types",
        },
        scale: {
            stronglyDisagree: "Strongly Disagree",
            stronglyAgree: "Strongly Agree",
        },
        standardOptions: [
            { value: 5, text: "Strongly Agree" },
            { value: 4, text: "Agree" },
            { value: 3, text: "Neutral" },
            { value: 2, text: "Disagree" },
            { value: 1, text: "Strongly Disagree" },
        ],
        questions: [
            // Openness (O) - 9 questions
            {
                question: "I am someone who likes to try new things",
                dimension: "O",
            },
            {
                question:
                    "I have strong emotional responses to art, music, or literature",
                dimension: "O",
            },
            {
                question: "I prefer to stick with familiar things",
                dimension: "O",
                reverse: true,
            },
            {
                question:
                    "I enjoy thinking about abstract concepts and theories",
                dimension: "O",
            },
            {
                question:
                    "I am enthusiastic about learning new knowledge and skills",
                dimension: "O",
            },
            {
                question: "I like to explore new ways of thinking",
                dimension: "O",
            },
            {
                question: "I am interested in philosophical discussions",
                dimension: "O",
            },
            {
                question: "I am passionate about artistic creation",
                dimension: "O",
            },
            {
                question: "I enjoy things that require imagination",
                dimension: "O",
            },
            // Conscientiousness (C) - 9 questions
            {
                question: "I am an organized person",
                dimension: "C",
            },
            {
                question:
                    "I like to make detailed plans and follow them strictly",
                dimension: "C",
            },
            {
                question: "I often procrastinate on important tasks",
                dimension: "C",
                reverse: true,
            },
            {
                question: "I pay attention to details",
                dimension: "C",
            },
            {
                question: "I often lack planning in my actions",
                dimension: "C",
                reverse: true,
            },
            {
                question: "I prepare in advance for important matters",
                dimension: "C",
            },
            {
                question: "I always do my best in everything",
                dimension: "C",
            },
            {
                question: "I take my work responsibilities seriously",
                dimension: "C",
            },
            {
                question:
                    "I often forget to put things back in their proper place",
                dimension: "C",
                reverse: true,
            },
            // Extraversion (E) - 8 questions
            {
                question: "I like being the center of attention",
                dimension: "E",
            },
            {
                question: "I prefer being alone rather than with others",
                dimension: "E",
                reverse: true,
            },
            {
                question: "I find it easy to talk to strangers",
                dimension: "E",
            },
            {
                question: "I tend to stay quiet in groups",
                dimension: "E",
                reverse: true,
            },
            {
                question: "I enjoy lively environments",
                dimension: "E",
            },
            {
                question: "I am a person full of energy",
                dimension: "E",
            },
            {
                question: "I enjoy participating in social activities",
                dimension: "E",
            },
            {
                question: "I am good at creating a lively atmosphere",
                dimension: "E",
            },
            // Agreeableness (A) - 9 questions
            {
                question: "I often care about others' feelings",
                dimension: "A",
            },
            {
                question: "I sometimes show indifference to others' needs",
                dimension: "A",
                reverse: true,
            },
            {
                question: "I am willing to spend time helping others",
                dimension: "A",
            },
            {
                question: "I am good at understanding others' perspectives",
                dimension: "A",
            },
            {
                question: "I tend to believe in others' good intentions",
                dimension: "A",
            },
            {
                question: "I easily empathize with others",
                dimension: "A",
            },
            {
                question: "I enjoy cooperating with others",
                dimension: "A",
            },
            {
                question: "I consider others' interests",
                dimension: "A",
            },
            {
                question: "I sometimes say things that hurt others",
                dimension: "A",
                reverse: true,
            },
            // Neuroticism (N) - 9 questions
            {
                question: "I often feel anxious or worried",
                dimension: "N",
            },
            {
                question: "I rarely worry about things",
                dimension: "N",
                reverse: true,
            },
            {
                question: "My emotions are easily affected by my environment",
                dimension: "N",
            },
            {
                question: "I am sensitive to criticism",
                dimension: "N",
            },
            {
                question: "I often feel under a lot of stress",
                dimension: "N",
            },
            {
                question: "I easily experience negative emotions",
                dimension: "N",
            },
            {
                question: "I worry about the future",
                dimension: "N",
            },
            {
                question: "I can control my emotions well",
                dimension: "N",
                reverse: true,
            },
            {
                question: "I stay calm under pressure",
                dimension: "N",
                reverse: true,
            },
        ],
    },
    result: {
        meta: {
            title: "Your Big Five Personality Test Results",
            description:
                "View your detailed Big Five personality test results and analysis",
            keywords:
                "Big Five results,personality test results,personality analysis,trait analysis",
        },
        title: "Your Big Five Personality Results",
        subtitle: "Discover your personality traits across five key dimensions",
        dimensions: {
            openness: {
                title: "Openness",
                description:
                    "Reflects your receptiveness to new experiences and ideas",
                highTraits: [
                    "Creative and imaginative",
                    "Intellectually curious",
                    "Appreciative of art",
                    "Willing to try new things",
                    "Good at abstract thinking",
                ],
                lowTraits: [
                    "Practical and grounded",
                    "Traditional",
                    "Prefer routine",
                    "Focus on concrete facts",
                    "Prefer familiarity",
                ],
            },
            conscientiousness: {
                title: "Conscientiousness",
                description:
                    "Shows your level of organization and responsibility",
                highTraits: [
                    "Well-organized",
                    "Responsible",
                    "Detail-oriented",
                    "Goal-driven",
                    "Self-disciplined",
                ],
                lowTraits: [
                    "Flexible and spontaneous",
                    "Relaxed approach to goals",
                    "Adaptable",
                    "Easy-going",
                    "Live in the moment",
                ],
            },
            extraversion: {
                title: "Extraversion",
                description:
                    "Indicates your level of sociability and energy in social situations",
                highTraits: [
                    "Outgoing and sociable",
                    "Energetic",
                    "Talkative",
                    "Action-oriented",
                    "Enthusiastic",
                ],
                lowTraits: [
                    "Reserved and quiet",
                    "Thoughtful",
                    "Independent",
                    "Prefer small groups",
                    "Value solitude",
                ],
            },
            agreeableness: {
                title: "Agreeableness",
                description:
                    "Reflects your approach to relationships with others",
                highTraits: [
                    "Compassionate",
                    "Cooperative",
                    "Considerate",
                    "Trusting",
                    "Helpful",
                ],
                lowTraits: [
                    "Direct and straightforward",
                    "Competitive",
                    "Skeptical",
                    "Task-focused",
                    "Independent minded",
                ],
            },
            neuroticism: {
                title: "Emotional Stability",
                description:
                    "Shows your tendency to experience negative emotions",
                highTraits: [
                    "Emotionally sensitive",
                    "Experience stress easily",
                    "Often worried",
                    "Self-conscious",
                    "Mood fluctuations",
                ],
                lowTraits: [
                    "Emotionally stable",
                    "Calm under pressure",
                    "Resilient",
                    "Self-confident",
                    "Even-tempered",
                ],
            },
        },
        traits: {
            title: "Key Characteristics",
        },
    },
};
