export default {
    index: {
        title: "Discover Your MBTI Personality Type",
        subtitle:
            "Explore your true self through scientific personality assessment",
        tips: {
            list: [
                "The MBTI test is based on Jung’s theory of psychological types and helps you understand your personality type and behavioral preferences.",
                "There is no good or bad MBTI type. Each type has its unique strengths. Do not use MBTI as the sole criterion for judging others.",
                "Personality types may change slightly over time and with different environments.",
                "The personality analysis shows tendencies only. Results are for reference, the key is to deepen self-understanding.",
                "The test consists of 93 questions. There are no right or wrong answers. Please choose according to your actual situation to get valid results.",
            ],
        },
        buttons: {
            whatIsMBTI: "What is MBTI",
            mbtiTypes: "MBTI Types",
        },
        meta: {
            title: "Free MBTI Personality Test - Explore Your Personality Type",
            description:
                "Explore and understand your personality type through scientific MBTI assessment. Take our professional 93-question test to better understand yourself.",
            keywords:
                "MBTI,free MBTI test,personality test,free test,personality assessment,personality analysis,career personality,psychological test",
        },
    },
    introduction: {
        meta: {
            title: "What is MBTI Personality Test? Detailed Introduction to MBTI Types",
            description:
                "The MBTI personality test is a scientific personality type indicator that describes personality traits through four dimensions: Extraversion/Introversion, Sensing/Intuition, Thinking/Feeling, and Judging/Perceiving.",
            keywords:
                "MBTI,free MBTI test,personality test,MBTI introduction,personality types,Myers-Briggs,personality analysis,psychological test,16 personalities,free test",
        },
        title: "MBTI Personality Type Introduction",
        whatIsMBTI: {
            title: "What is MBTI?",
            content:
                "MBTI (Myers-Briggs Type Indicator) is a personality type indicator developed by Katharine Briggs and Isabel Briggs Myers based on Jung's theory of psychological types. It describes personality characteristics through four dimensions:",
        },
        dimensions: {
            title: "Four Dimensions",
            energySource: {
                title: "Energy Source (E/I)",
                extroversion: {
                    title: "Extroversion (E)",
                    description:
                        "Tends to draw energy from the external world and prefers interacting with others",
                },
                introversion: {
                    title: "Introversion (I)",
                    description:
                        "Tends to draw energy from the internal world and prefers solitude and reflection",
                },
            },
            infoGet: {
                title: "Information Gathering (S/N)",
                sensing: {
                    title: "Sensing (S)",
                    description:
                        "Tends to focus on the real and concrete, preferring facts and practicality",
                },
                intuition: {
                    title: "Intuition (N)",
                    description:
                        "Tends to focus on the big picture and abstract concepts, preferring possibilities and innovation",
                },
            },
            decision: {
                title: "Decision Making (T/F)",
                thinking: {
                    title: "Thinking (T)",
                    description:
                        "Tends to make decisions based on logic and objective analysis, valuing fairness and principles",
                },
                feeling: {
                    title: "Feeling (F)",
                    description:
                        "Tends to make decisions based on values and emotions, valuing relationships and harmony",
                },
            },
            lifestyle: {
                title: "Lifestyle (J/P)",
                judging: {
                    title: "Judging (J)",
                    description:
                        "Tends to live a planned and organized life, preferring structure and goals",
                },
                perceiving: {
                    title: "Perceiving (P)",
                    description:
                        "Tends to live a flexible and spontaneous life, preferring to keep options open",
                },
            },
        },
        applications: {
            title: "Applications of MBTI",
            intro: "MBTI assessments can help us better understand ourselves and others, especially in the following areas:",
            items: [
                "Career Planning: Helps individuals understand their personality strengths, choose suitable career paths, and improve job satisfaction.",
                "Team Management: Organizations can use MBTI to identify employees’ personality types, optimize teamwork, and enhance communication efficiency.",
                "Interpersonal Relationships: Understanding different personality types improves relationships and fosters better communication.",
                "Personal Growth: Helps individuals recognize their strengths and weaknesses, enabling them to develop strategies that align with their traits.",
                "Education & Learning: Teachers can tailor their teaching methods based on students’ personality types to enhance learning effectiveness.",
                "Leadership Development: Assists leaders in understanding their management style and adapting their approach to different team members.",
                "Mental Well-being: MBTI serves as a self-awareness tool, helping individuals understand their emotions and behavioral patterns better.",
            ],
        },
        notes: {
            title: "Notes",
            items: [
                "Test Results Are Not Absolute – MBTI indicates personality tendencies, but personality can evolve over time.",
                "Avoid Labeling – The results should not be used to limit oneself or others, as everyone has unique growth potential.",
                "Answer Honestly – Respond based on your true self rather than societal expectations or an idealized version of yourself.",
                "Regular Retesting – Personality may change due to life experiences, so periodic testing can help track personal development.",
                "Cultural Influence – Results may vary based on cultural backgrounds, so they should be analyzed in the context of personal experiences.",
                "Practical Application – MBTI results should be used as a reference tool rather than a rigid criterion for career decisions or major life choices.",
            ],
        },
    },
    detail: {
        overview: "Overview",
        meta: {
            titleTemplate: "{code} {name} - MBTI Personality Type Analysis",
            defaultTitle: "MBTI Personality Type Details",
            defaultDescription:
                "Detailed introduction and analysis of MBTI personality types",
            descriptionTemplate:
                "Detailed analysis of {code} ({name}) personality type. {overview}",
            defaultKeywords: "MBTI,personality type,personality analysis",
            keywordsTemplate:
                "{code},{name},{traits},MBTI,personality type,personality analysis,career development,relationships",
        },
    },
    types: {
        title: "MBTI Personality Types",
        meta: {
            title: "MBTI 16 Personality Types - Detailed Introduction and Analysis",
            description:
                "Explore all 16 MBTI personality types, including Analysts, Diplomats, Guardians, and Explorers. Learn about their characteristics, strengths, and growth paths.",
            keywords:
                "MBTI,personality types,16 personalities,MBTI classification,personality analysis,personality test,psychological test",
        },
        groups: {
            analytical: "Analytical Types (NT)",
            diplomatic: "Diplomatic Types (NF)",
            guardian: "Guardian Types (SJ)",
            explorer: "Explorer Types (SP)",
        },
        types: {
            analyticalTypes: ["INTJ", "INTP", "ENTJ", "ENTP"],
            diplomaticTypes: ["INFJ", "INFP", "ENFJ", "ENFP"],
            guardianTypes: ["ISTJ", "ISFJ", "ESTJ", "ESFJ"],
            explorerTypes: ["ISTP", "ISFP", "ESTP", "ESFP"],
            typeMap: {
                ENFJ: {
                    code: "ENFJ",
                    name: "Hero",
                    callName: "Passionate Mentor",
                    description:
                        "Enthusiastic and contagious, good at motivating others to achieve their goals, and have a strong sense of responsibility.",
                    traits: [
                        "Leadership",
                        "Empathy",
                        "Enthusiasm",
                        "Coordination",
                    ],
                    overview:
                        'ENFJ (Extroversion, Intuition, Feeling, Judging) is often called a "mentor" or "teacher". They are very charismatic and contagious. They are born with leadership potential, good at motivating and influencing others, and take it as their responsibility to help others realize their potential. ENFJ is an extroverted personality who likes to interact with others and build deep relationships. They not only pay attention to the needs of others, but also are keenly aware of the emotions and needs of others, so they often play a bridging role in interpersonal relationships. ENFJ values ​​deep connections with others, especially in teamwork and leadership environments, and can exert great influence.',
                    detail: [
                        {
                            title: "Personality characteristics",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. Empathy and understanding: ENFJ They are usually very sympathetic people who have a keen insight into other people's emotions and needs and can understand other people's motivations and behaviors. They not only care about other people's feelings, but are also willing to provide support and help to others, especially when others are in trouble. ",
                                        "2. Infectious leaders: ENFJs are natural leaders who can play a positive role in any team. They lead the team to achieve common goals by encouraging, motivating and organizing others. ENFJs are good at mobilizing the enthusiasm and cooperation of the team and can quickly unite people. ",
                                        "3. Idealism and noble values: ENFJs are usually very idealistic and have a vision of making the world a better place. They tend to express their care for society, community or humanity by bringing positive changes to others themselves. ",
                                        "4. Good at communication and coordination: ENFJs have excellent communication skills. They can express their ideas clearly and listen to others. They usually act as coordinators and mediators in the team to ensure that everyone can communicate smoothly and work together. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Character Weaknesses",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. Over-focus on others: Because ENFJs are naturally caring about others, they may sometimes neglect their own needs and feelings and become overly invested in other people's problems, causing their own energy and emotions to be exhausted. After paying too much attention to others for too long, ENFJs may feel neglected or overwhelmed by the burden. ",
                                        "2. Over-idealization: ENFJs may fall into overly idealistic thinking, expecting others to act or change according to their expectations. When the gap between reality and ideal is too large, ENFJs may feel disappointed or frustrated. ",
                                        "3. Difficulty in facing conflict: ENFJs place great emphasis on interpersonal harmony, so they tend to avoid conflict. They may choose to avoid directly confronting disagreements or problems, although this behavior may cause problems to accumulate and ultimately become difficult to resolve. ",
                                        "4. Self-sacrifice: ENFJs often sacrifice their time and emotional well-being for others, especially when caring about others, they may neglect themselves. This can lead to burnout and emotional fatigue in the long term. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Suitable Careers",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "ENFJ's leadership, empathy, and communication skills allow them to shine in many careers. Here are some career fields suitable for ENFJ:",
                                        "1. Education and Training: ENFJs are born educators who are good at motivating and helping others develop their potential. Careers such as teachers, trainers, and consultants can play to their strengths. ",
                                        "2. Social Work and Psychological Counseling: ENFJs care about the well-being of others. Careers such as social work and psychological counseling allow them to help others overcome difficulties and improve their quality of life. ",
                                        "3. Business Management and Leadership Positions: ENFJs have excellent leadership and organizational skills and are suitable for management positions, especially those that require interpersonal interaction and teamwork, such as project managers and team leaders. ",
                                        "4. Nonprofits and Philanthropy: ENFJs find great satisfaction in nonprofits, helping the underprivileged, promoting social change, and realizing their idealistic visions. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Love and Partner",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "In love, ENFJs are very devoted and caring about their partners, and they are willing to make a lot of efforts to build a deep relationship. ENFJs value emotional communication and like to share their thoughts, feelings, and future ideals with their partners. They will make the other person feel special through careful care and understanding, and work hard to make the relationship more harmonious. ENFJ's idealism makes them have high expectations of their partners, often hoping that the two can grow together and achieve greater goals. ",
                                        "However, ENFJs may also ignore their own emotional needs because they are overly focused on their partner's needs. When faced with emotional conflicts, they may avoid facing the problem and choose to give in or compromise to maintain the harmony of the relationship. Therefore, ENFJs need to learn to balance their own needs and the needs of their partners in a relationship and avoid excessive sacrifice. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Development Suggestions",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. Setting Boundaries: ENFJs need to learn to set healthy boundaries when helping others and avoid over-sacrificing themselves. Only by ensuring that their own emotional needs are also met can they better support others. ",
                                        "2. Accepting Reality: ENFJs should learn to accept the shortcomings and limitations of reality and avoid over-idealization. They can reduce disappointment by accepting the uncontrollable factors in life through more pragmatic thinking. ",
                                        "3. Facing Conflicts and Challenges: ENFJs need to learn to face conflicts and challenges head-on, rather than avoiding or ignoring them. By actively dealing with problems, ENFJs are better able to resolve conflicts and enhance their ability to withstand stress. ",
                                        "4. Taking Care of Themselves: ENFJs should pay more attention to their physical and mental health, rest regularly, and maintain a good emotional and mental state to avoid energy burnout. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "Overall, ENFJs are charismatic, caring, and idealistic leaders who can succeed in many careers. With proper self-care, boundary-setting, and a more pragmatic attitude, ENFJs can find a more balanced and satisfying path in their relationships and careers. ",
                                    ],
                                },
                            ],
                        },
                    ],
                },
                ENFP: {
                    code: "ENFP",
                    name: "Dreamer",
                    callName: "Passionate Dreamer",
                    description:
                        "Full of creativity and vitality, pursuing diversity and freedom, good at discovering possibilities and infecting others.",
                    traits: [
                        "Enthusiasm",
                        "Creativity",
                        "Social Skills",
                        "Energy",
                    ],
                    overview:
                        'ENFP (Extroversion, Intuition, Feeling, Perception) is often called "motivator" or "creative advocate". They are passionate, creative and interpersonal. ENFP is an extroverted personality who likes to communicate with people, is happy to make friends, and draws energy from interacting with others. Their intuition enables them to see the potential and future development trends of things, rather than just focusing on the current details. ENFP is highly idealistic and pursues personal growth, freedom and self-expression. They are usually happy to bring positive changes to others, have a rich inner world and unique perspective.',
                    detail: [
                        {
                            title: "Personality characteristics",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. Creativity and curiosity: ENFP They are born creatives. They have a broad imagination, are good at generating new ideas, and are always curious and eager to explore the unknown world. Whether it is work or life, ENFPs are always able to see different perspectives and come up with innovative solutions. ",
                                        "2. Emotional and empathetic: ENFPs are deeply aware of the emotions of others and are able to establish deep emotional connections with others. They are sincere in their concern for the people around them and are often willing to help others, especially when friends and family members need support, ENFPs will not hesitate to lend a hand. ",
                                        "3. Inspire and influence others: ENFPs have extremely high interpersonal charm. They are good at inspiring others and can play a driving role in the team. They inspire others to pursue common goals and take positive actions by expressing their ideals, passions and beliefs. ",
                                        "4. Flexible and adaptable: ENFPs like freedom and change, and hate constraints and monotonous life. They are good at coping with changes and can flexibly adjust their directions and plans. ENFPs enjoy the ever-changing environment and can find new opportunities in the changes. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Character Weaknesses",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. Lack of patience and concentration: Due to their strong interest in new things, ENFPs are easily distracted, especially when faced with repetitive and monotonous tasks, and are prone to lose interest. Lack of patience may cause them to not have enough time or energy to complete certain tasks, and they may even abandon unfinished projects. ",
                                        "2. Over-idealism: ENFPs are full of ideals and dreams about the world, but sometimes there is a big gap between their ideals and reality. Over-idealism may cause them to feel disappointed or confused when facing practical problems, and they cannot bear the results that do not meet their expectations. ",
                                        "3. Easy to feel anxious: Due to the uncertainty about the future, ENFPs often feel anxious. Especially when faced with unfamiliar situations or important decisions, ENFPs may worry too much, resulting in greater mood swings. ",
                                        "4. Avoiding conflicts: ENFPs generally do not like conflicts, and they prefer to avoid direct confrontation, especially when it comes to personal relationships. When faced with conflict, they may choose to avoid or compromise rather than actively solve the problem. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Suitable Careers",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "ENFP's creativity, social skills and sensitivity to interpersonal relationships make them suitable for challenging, changing and creative jobs. Here are some suitable careers for ENFP:",
                                        "1. Creative Industry: ENFP likes to express themselves with their imagination, so they perform well in advertising, design, writing, art creation and other fields. They can create infectious works through unique perspectives. ",
                                        "2. Consulting and Education: ENFP is good at communicating and motivating others, and is suitable for positions such as consultants, teachers or trainers. They can help others solve problems by motivating others to realize their potential, especially in the fields of education and personal development. ",
                                        "3. Marketing and Public Relations: ENFP is good at capturing people's emotions and needs. They can promote products or services, inspire resonance and promote brand communication through communication with customers or the public. ",
                                        "4. Psychology and Therapy: ENFPs are empathetic and can listen to and understand the emotions of others. They are suitable for psychotherapy, social work and other industries to help others overcome psychological barriers and emotional distress. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Love and Partner",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "ENFPs are passionate and energetic partners who want to establish a deep emotional connection with their partners and share each other's dreams and passions. ENFPs desire emotional freedom and exploration, and their love is usually full of romance and freshness. In a relationship, ENFPs focus on the authenticity and interaction of emotions. They hope that they and their partners can grow together and pursue common ideals. ",
                                        "However, ENFPs' idealism may cause them to feel frustrated or dissatisfied when they encounter challenges in a relationship. They may also avoid dealing with conflicts due to their unwillingness to face them, leading to the accumulation of problems. Therefore, ENFPs need a partner who can understand their needs and is willing to grow together, who can provide emotional support and stay rational in conflicts. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Development Suggestions",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. Improve concentration and execution: ENFPs should learn to stay focused when completing tasks and try to avoid being distracted by new things or other ideas. By setting clear goals and time management, ENFPs can improve their execution and get the job done better. ",
                                        "2. Be down-to-earth and face reality: Although idealism is an ENFP's strength, sometimes excessive idealism may lead to disappointment. ENFPs should learn to maintain a pragmatic attitude while pursuing their dreams, face the challenges in reality, and find a balance. ",
                                        "3. Face anxiety and uncertainty: ENFPs are prone to anxiety when facing an uncertain future, so they can relieve stress through meditation, deep breathing, emotional expression, etc. Establishing a stable daily life and regular plan can help ENFPs better cope with anxiety. ",
                                        "4. Learn to deal with conflicts: ENFPs should learn to face and resolve conflicts instead of avoiding them. Through proactive communication and rational dialogue, ENFPs can better handle interpersonal problems and avoid pent-up emotional problems. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "In general, ENFPs are passionate, creative, and highly idealistic people who succeed in their careers and personal lives by inspiring others and creating innovative solutions. By better managing their focus, the balance between ideals and reality, and the ability to deal with anxiety, ENFPs can achieve greater achievement and deeper satisfaction in life. ",
                                    ],
                                },
                            ],
                        },
                    ],
                },
                ENTJ: {
                    code: "ENTJ",
                    name: "Commander",
                    callName: "Decisive Commander",
                    description:
                        "A confident and decisive leader who is good at developing strategies and mobilizing resources to pursue success and control.",
                    traits: [
                        "Leadership",
                        "Decisiveness",
                        "Execution",
                        "Ambition",
                    ],
                    overview:
                        'ENTJs (Extroversion, Intuition, Thinking, Judging) are often called "commanders" or "strategists". They are natural leaders, ambitious and have clear goals. ENTJs have strong organizational and decision-making abilities and can effectively plan and execute complex tasks. They focus on efficiency, logic and rationality, and are good at analyzing problems and proposing solutions. ENTJs often take leadership roles in the workplace and on teams because they are good at motivating and guiding others, and can quickly identify and take action to solve problems. Because they have a clear vision for the future, ENTJs are often drivers of change and innovation.',
                    detail: [
                        {
                            title: "Personality characteristics",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. Strong leadership: ENTJ Possess natural leadership charisma and organizational skills. They are able to set goals clearly and lead the team to achieve these goals in an orderly manner. ENTJ focuses on efficiency and results, so they can always lead the team to success and inspire others to reach their maximum potential. ",
                                        "2. Strategic thinking: ENTJ is rational and logically oriented, good at analyzing complex situations and making long-term strategic plans. They can see the big picture clearly and make decisions from a global perspective to solve current and future challenges. ",
                                        "3. Decisive and decision-making: ENTJs are very decisive when facing decisions. They don't like to procrastinate and are good at making decisions quickly and putting them into practice. After analyzing all the information, they will decisively choose the most effective solution. ",
                                        "4. Highly confident and independent: ENTJs are very confident and believe that they can overcome all challenges and succeed. They like to work independently, don't like to be constrained, and are not easily interfered with by others. Such a personality makes them often unwavering in pursuing their goals. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Character Weaknesses",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. Too Strong and Dominant: Due to ENTJ's leadership and decision-making ability, they may be too dominant in other people's thoughts and opinions. Sometimes, they may appear too strong, which can easily make others feel stressed or suppressed. Especially in teamwork, ENTJ needs to pay attention to balancing their dominance and avoid excessive interference in other people's ideas. ",
                                        "2. Ignoring Emotions: ENTJs are usually dominated by logic and rationality when making decisions, which may cause them to ignore the emotional needs and psychological feelings of others. Because they are not good at dealing with emotional details in interpersonal relationships, they may appear indifferent or lack empathy. ",
                                        "3. Overwork and Stress: ENTJs are very persistent in pursuing their goals, often over-investing in work or career, and easily neglecting the balance of their personal lives. Their high standards and perfectionism may put them in a state of high pressure, leading to physical and mental fatigue. ",
                                        "4. Difficulty accepting failure: Due to their self-confidence and clear goals, ENTJs have a low tolerance for failure. When they encounter setbacks or failures, they may feel extremely frustrated and find it difficult to accept results that do not meet their expectations. Excessive self-demands may cause them to put excessive pressure on failure. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Suitable careers",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "ENTJ's leadership, strategic thinking and decision-making ability make them very suitable for executive or management positions, especially in areas that require organization and execution. The following are some careers suitable for ENTJs:",
                                        "1. Corporate executives and managers: ENTJs are natural managers. They have systematic thinking and can sort out complex organizational structures and make effective decisions. Many successful entrepreneurs and senior managers are ENTJ-type people who lead their companies to success through strategic layout and decision-making. ",
                                        "2. Consulting and strategic planning: ENTJs are good at analyzing the market and corporate environment and formulating long-term development strategies. Positions such as strategic consulting and business analysis are suitable for them, which require them to use quick thinking and efficient solutions. ",
                                        "3. Politics and public affairs: Due to their strong leadership and rational decision-making ability, ENTJs often perform well in the political field. They are able to effectively organize and lead teams, promote reforms and policy implementation, and many outstanding politicians also show ENTJ traits. ",
                                        "4. Legal and financial industries: ENTJ's logic and analytical ability make them suitable for legal-related professions such as lawyers and judges. At the same time, management positions in the financial industry are also very suitable for ENTJs, who are able to make quick decisions in complex market environments. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Love and Partner",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "ENTJ In love, ENTJs are very focused and determined partners. They look forward to growing together with their partners and are willing to work hard for common goals. However, ENTJ's rationality and decisiveness may make them lack emotional expression and may ignore their partners' emotional needs. They tend to focus more on problem solving than emotional communication, which can lead to emotional alienation in relationships. ",
                                        "When looking for a partner, ENTJs tend to look for those who understand them and support their goals. An independent and challenging partner can usually reach a good consensus with ENTJs. If their partner is too dependent on them or cannot understand their work pressure, it may lead to a disharmonious situation. Therefore, ENTJs need to learn to express more care and understanding in relationships and avoid dealing with emotional problems too rationally. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Development Suggestions",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. Focus on emotional communication: ENTJs need to learn to pay attention to the emotional needs of others and add more care and understanding to communication. By listening to the opinions and feelings of others, ENTJs can better manage interpersonal relationships and improve teamwork. ",
                                        "2. Balance work and life: ENTJs often neglect their personal life and rest in the pursuit of career success. It is recommended that they make a reasonable schedule to ensure that they have enough time to rest and relax and maintain physical and mental health. ",
                                        "3. Accept failure and imperfection: ENTJs have a low tolerance for failure. It is recommended that they learn to accept imperfection and learn from failure instead of blaming themselves or being discouraged. By adjusting their mindset, ENTJs can deal with challenges more maturely and rationally. ",
                                        "4. Let go of others and learn to delegate: ENTJs need to learn to trust team members and let them take responsibility. Through proper delegation, ENTJs can not only reduce their own pressure, but also improve the cohesion and cooperation efficiency of the team. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "In general, ENTJs are natural leaders with excellent decision-making, strategic vision and execution capabilities. They can quickly stand out in the workplace. By improving emotional communication, acceptance of failure and work-life balance, ENTJs can achieve personal growth and achieve a more successful and happier life. ",
                                    ],
                                },
                            ],
                        },
                    ],
                },
                ENTP: {
                    code: "ENTP",
                    name: "Debater",
                    callName: "Witty Debater",
                    description:
                        "Quick-witted and witty, likes to challenge authority and put forward new ideas, and has an adventurous and innovative spirit.",
                    traits: ["wit", "innovation", "curiosity", "challenge"],
                    overview:
                        'ENTP (Extroversion, Intuition, Thinking, Perception) is often called "debater" or "innovator". They are creative, witty and quick-thinking people. They are always full of curiosity and are keen to explore new ideas, new theories and new fields. ENTP likes to challenge traditional concepts, is good at discovering the connection between things, and puts forward unique insights. They tend to have high self-confidence and expressiveness, and like to engage in a collision of ideas in debates with others. ENTP is not bound by a fixed framework, advocates freedom and change, and tends to find different solutions and methods to solve problems.',
                    detail: [
                        {
                            title: "Personality characteristics",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. Creativity and innovation: ENTP ENTPs are full of innovative ideas. They are constantly looking for ways to break the rules and are good at coming up with unique ideas and perspectives. Whether at work or in life, ENTPs like to explore new things and challenge existing ideas. ",
                                        "2. Curiosity and broad interests: ENTPs are interested in almost everything and are eager to understand how the world works. Because of their broad interests, ENTPs may be involved in multiple fields and like to discuss and share their views on various topics. They are constantly pursuing new knowledge and experiences to satisfy their curiosity. ",
                                        "3. Wit and good at debate: ENTPs are quick-witted and eloquent. They are good at debate and can break down the other party's arguments with logic and reason, and quickly identify and apply rebuttal techniques. This makes them very confident and charming in social situations. ",
                                        "4. Flexible and adaptable: ENTPs do not like constraints and repetitive daily life. They pursue freedom and change and are willing to adjust their plans and directions at any time to meet new challenges and opportunities. Because of their flexibility, ENTPs are able to make decisions quickly and respond to changes in an environment full of uncertainty. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Character Weaknesses",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. Too Radical and Challenging: ENTPs like to challenge traditional concepts and rules, but sometimes this challenge may become too radical and even make others uncomfortable. They may inadvertently break other people's comfort zones and bring unnecessary conflicts or troubles. ",
                                        "2. Lack of patience: ENTPs tend to lack patience when faced with tedious or repetitive tasks. They prefer to move forward quickly and seek new stimulation, so they may feel bored or lose interest in work that requires long-term concentration and patience. ",
                                        "3. Lack of attention to details: Because ENTJs tend to think in a macro way and have a big picture view, ENTPs may ignore details. They tend to focus on innovation and theory, and don't care much about the details of operation in practice. This may lead to some things not being completed perfectly or even making mistakes. ",
                                        "4. Easily distracted and difficult to concentrate: ENTPs have a wide range of interests, but sometimes they are easily disturbed by multiple ideas. Due to their excessive pursuit of new things, ENTPs may move on to another new idea before completing a project, resulting in delayed or unfinished tasks. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Suitable Careers",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "ENTPs' creativity, logical thinking, and social skills allow them to perform well in many fields. Here are some suitable careers for ENTPs:",
                                        "1. Innovation and Creative Industries: ENTPs are born with innovative abilities, so advertising, design, programming, technology research and development and other fields are very suitable for them. They can promote the development of the industry through independent thinking and innovative solutions. ",
                                        "2. Entrepreneurs and Entrepreneurship: Because ENTPs advocate freedom and change, many ENTPs perform well in the field of entrepreneurship. They are able to discover gaps and opportunities in the market, develop new business models, and act quickly to implement them. ENTPs are passionate about new things, so they are very suitable for entrepreneurship and business development. ",
                                        "3. Marketing and Public Relations: ENTPs perform well in industries such as marketing and public relations. They can quickly grasp the psychology of the crowd and promote products or services in a vivid and creative way. At the same time, they are also good at establishing connections in social situations and exerting a wide influence. ",
                                        "4. Consulting and Education: ENTPs are good at communication and like to convey their thoughts and knowledge to others, so consulting, training and education fields are very suitable for them. They are able to stimulate the thinking of others through creative and challenging methods. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Love and Partner",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "In love, ENTPs are energetic and adventurous partners. They like to share ideas and experiences with their partners and explore common interests and goals. ENTPs tend to enhance their relationships through challenges, debates and joint explorations, which makes their love life full of excitement and change. ",
                                        "However, ENTPs may lack in emotional expression. They tend to be rational and thinking-oriented, ignoring their partners' emotional needs. For emotionally immature ENTPs, delicate care in relationships may become a big challenge. In addition, ENTPs may sometimes feel that a relationship restricts their freedom and therefore need a partner who understands and respects their independence. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Development Suggestions",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. Strengthen detail management and execution: ENTPs need to learn to pay attention to details and execution while pursuing innovation. Although they are passionate about new things, to achieve long-term success, they must learn to stay focused and ensure that they are meticulous and efficient in actual operations. ",
                                        "2. Improve patience and concentration: Because ENTPs like to solve problems quickly and think in a jumpy way, they may be impatient with detailed work and long-term tasks. Therefore, ENTPs should learn to improve their patience, especially in work that requires long-term commitment and concentration. ",
                                        "3. Pay attention to the emotional needs of others: In the process of pursuing rationality and innovation, ENTPs may ignore the emotional needs of their partners and team members. They need to learn to better understand the feelings of others and pay more attention to the expression of emotions in communication to enhance the connection with others. ",
                                        "4. Learn to adapt to routine and stability: Although ENTPs are passionate about change and freedom, they also need to learn to adapt to stability and routine in certain situations, especially in teamwork and management. Learning to balance innovation and execution, change and stability can help ENTPs achieve greater long-term success. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "In general, ENTPs are creative, energetic, and resourceful individuals who perform very well in the workplace and social situations by constantly innovating and exploring new areas. By improving detail management, improving patience and concentration, and paying better attention to the emotional needs of others, ENTPs can gain more achievements and satisfaction in personal growth and career development. ",
                                    ],
                                },
                            ],
                        },
                    ],
                },
                ESFJ: {
                    code: "ESFJ",
                    name: "Supporter",
                    callName: "Good Supporter",
                    description:
                        "Good at communicating and interacting with others, helpful and creating a warm atmosphere, valuing teamwork, good at maintaining relationships and resolving conflicts.",
                    traits: ["Cooperation", "Loyalty", "Sensitivity", "Warmth"],
                    overview:
                        'ESFJ (Extroversion, Sensing, Feeling, Judging) is called a "social caregiver" or "supporter". They are individuals who attach great importance to others and the collective. ESFJs usually show a strong sense of responsibility, are willing to serve others, and tend to get satisfaction through caring, taking care of and building harmonious relationships. They pay attention to social order and tradition, like to follow rules and maintain a stable environment. ESFJ likes to work with others and is usually the core figure in the group. They have high social intelligence and interpersonal skills and can provide emotional support and help to others. Because of their delicacy and thoughtfulness, ESFJs are often regarded as reliable and trustworthy friends and partners.',
                    detail: [
                        {
                            title: "Personality characteristics",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. High empathy and responsibility: ESFJs are very concerned about the emotional needs of others and always try their best to take care of and care about those around them. They are very good at understanding the feelings of others and can provide help and support in their own way. They have a strong sense of responsibility to their family, friends, and team, and are willing to give without asking for anything in return. ",
                                        "2. Value tradition and stability: ESFJs value social norms and traditional customs, and like to work and live in a stable and orderly environment. Following rules and maintaining good social order are very important to them, which makes them core members of many groups and organizations. ",
                                        "3. Strong social skills: ESFJs usually have strong social skills and are good at building and maintaining interpersonal relationships. They enjoy interacting with others and are good at making others feel comfortable and accepted through careful listening and warm responses. In social situations, ESFJs are usually active participants and can quickly bring people closer. ",
                                        "4. Pragmatists: ESFJs are very practical and like to solve problems through action rather than abstract theories. They pay attention to the specific details of reality, are good at handling daily affairs, and are often relied upon and supported by family, friends, and colleagues. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Character weaknesses",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. Over-reliance on external evaluation: Because ESFJs are extremely concerned about the feelings and opinions of others, they may become overly dependent on external recognition and evaluation. When others are dissatisfied with them, ESFJs may feel very uneasy or lost, which will affect their self-confidence and emotional health. ",
                                        "2. Over-care for others and neglect of self: ESFJs often put the needs of others above their own, and may ignore their own needs and feelings when over-care for others. This tendency may cause them to feel exhausted or dissatisfied because they often over-invest and cannot get a return that matches their efforts. ",
                                        "3. Avoidance of conflict: ESFJs attach great importance to harmonious relationships, so they tend to avoid conflicts and confrontations. Sometimes, they may suppress their true thoughts and feelings in order to avoid disputes with others. This conflict avoidance behavior can lead to a backlog of emotions, or even an outburst of negative emotions. ",
                                        "4. Conservatism and lack of flexibility: Although ESFJs place great value on tradition and order, they may sometimes lack sufficient openness to new ideas or changes. This traditional attitude sometimes causes them to miss certain opportunities for innovation and progress, or even makes them feel uncomfortable when dealing with a changing environment. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Suitable careers",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "ESFJ's social skills, sense of responsibility, and careful care for others make them perform very well in many careers that involve interpersonal interaction. Here are some careers that are suitable for ESFJs:",
                                        "1. Education and teaching: ESFJs are well suited to work in education, especially positions that build deep relationships with students. Because they have a strong sense of empathy and responsibility, ESFJs are able to provide warmth and support to students, helping them grow and realize their potential. ",
                                        "2. Nursing and Healthcare: ESFJs care deeply about others, so nursing and healthcare are ideal for them. Whether as nurses, psychotherapists, or other supportive healthcare positions, ESFJs are able to provide loving and attentive care to patients. ",
                                        "3. Customer Service and Social Work: ESFJs are great at connecting with others and love to help, so customer service and social work are a good fit for them. By listening and solving problems, ESFJs can make a positive difference for others. ",
                                        "4. Event Planning and Public Relations: ESFJs are passionate about social events and interacting with others, so event planning and public relations are also suitable for them. They are good at organizing events, managing details, and maintaining good interactions with all kinds of people. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Love and Partners",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "In love, ESFJs are loving and dedicated partners. They usually devote themselves to the relationship, care about their partner's every need, and try their best to provide support and care. ESFJs look forward to building a deep emotional connection with their partners. They hope to share their feelings and life experiences with each other and express their love for each other through actions. ",
                                        "However, ESFJs' over-reliance on external feedback may cause them to feel insecure in their love relationships. If their partners do not respond adequately to their efforts, ESFJs may feel lost or even dissatisfied. Therefore, ESFJs need to find a partner who can understand and appreciate them to avoid losing themselves in the relationship. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Development Suggestions",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. Learn to take care of yourself: ESFJs need to realize that they deserve love and attention before taking care of others. By balancing caring for others with caring for themselves, ESFJs can avoid burnout and maintain physical and mental health. ",
                                        "2. Enhance emotional independence: ESFJs should learn to be more independent and less dependent on the evaluation of others. They need to learn to find confidence from within, rather than relying too much on external recognition. Cultivating the ability to affirm oneself helps to enhance inner stability. ",
                                        "3. Face conflict with courage: Although ESFJs prefer harmony, they need to learn to face conflict and contradictions and dare to express their true feelings. By dealing with conflict constructively, ESFJs can not only improve their interpersonal relationships, but also gain more personal growth. ",
                                        "4. Broaden your horizons and accept change: ESFJs are sometimes too rigid in their beliefs and existing rules and need to be more open to new ideas and changes. Cultivating flexibility and adaptability can help them better cope with the challenges of change and discover more opportunities. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "In general, ESFJs are warm, caring, and highly sociable people, and their presence is invaluable to those around them. By enhancing self-care, emotional independence, conflict resolution, and acceptance of change, ESFJs can gain more achievement and happiness in their personal growth and career development. ",
                                    ],
                                },
                            ],
                        },
                    ],
                },
                ESFP: {
                    code: "ESFP",
                    name: "Performer",
                    callName: "Happy Performer",
                    description:
                        "Love life and laughter, sociable and infectious, like to bring joy and vitality.",
                    traits: ["Energy", "Social", "Humor", "Infectious"],
                    overview:
                        'ESFP (Extraversion, Sensing, Feeling, Perception) is called "Performer" or "Active Hedonist". They are full of energy, enjoy the present moment, and like to find happiness through interaction with others. ESFP is cheerful, enthusiastic and infectious, able to quickly attract the attention of others and establish connections. They seek to enjoy every moment of life, pay attention to sensory experience and practical actions, and often perform well in social occasions. For them, life is a journey full of colors and adventures. They are happy to explore the diversity of the world and are willing to try new things and experiences.',
                    detail: [
                        {
                            title: "Personality characteristics",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. Extroversion and strong sociability: ESFP ESFPs are typical extroverts who draw energy from interacting with people. For them, socializing is part of life, and they love meeting new people, enjoy group activities, and can easily connect with people. They are good at making others feel at ease and can naturally attract attention. ",
                                        "2. Hedonists and Adventurous Spirits: ESFPs place great emphasis on fun and enjoyment in life, and they like to find beauty and excitement in daily life. Whether it is traveling, partying, or trying new activities, ESFPs are always actively involved and enrich their lives by experiencing sensory enjoyment and new adventures. They like to live in the present and enjoy a carefree time. ",
                                        "3. Emotional and Considerate: Although ESFPs are energetic and outgoing, they are also very concerned about the feelings of others and can sense and respond to the emotional needs of others. As emotional people, they tend to deal with emotional issues in an intuitive way and value emotional communication and connection in interpersonal relationships. ESFPs like to bring happiness to others and care and support others through their actions. ",
                                        "4. Practical and pragmatic: Although ESFPs love adventure and new things, they are also very practical people. In work and life, ESFPs tend to focus on practical needs at hand rather than abstract theories or grand plans. When faced with problems, they like to take quick action to solve problems and move things forward. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Character weaknesses",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. Lack of long-term planning: ESFPs often focus on the fun and enjoyment of the moment, so they may ignore long-term goals and plans. Because they live too much in the present, they may face difficulties in the future or lack the necessary preparation, which will cause them to regret it. ",
                                        "2. Avoiding pressure and responsibility: When faced with difficulties or pressure, ESFPs may tend to avoid or ignore problems instead of facing and solving them. Since they prefer to enjoy life rather than deal with heavy responsibilities and tasks, this may lead them to evade responsibility or avoid difficult situations. ",
                                        "3. Impulsiveness and impatience: ESFPs can sometimes be too impulsive and prone to making decisions without careful consideration. They like to act quickly, which sometimes causes them to overlook important details or make hasty choices. At the same time, ESFPs have poor patience and may feel impatient and eager to seek changes in tasks that require long-term persistence and patience. ",
                                        "4. Over-reliance on external recognition: Although ESFPs are generally optimistic and outgoing, they sometimes rely too much on external recognition and feedback. They need recognition from others to feel accepted and appreciated, and may feel frustrated or upset if they lack such feedback. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Suitable careers",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "Suitable careers: ESFPs are suitable for careers that require social, creative, and practical skills. Here are some careers that are suitable for ESFPs:",
                                        "1. Entertainment and Performance: ESFPs love to interact with people and show themselves, so careers such as entertainment, performance, or hosting are ideal for them. Whether it is an actor, singer, dancer, or other position that requires interaction with the audience, ESFPs can perform well. ",
                                        "2. Sales and Public Relations: Due to ESFPs' social skills and interpersonal sensitivity, they are also very advantageous in the fields of sales and public relations. Dealing with customers, building relationships with others, and influencing other people's decisions are all areas where ESFPs excel. They are able to attract others through their enthusiasm and infectiousness and succeed in business environments. ",
                                        "3. Travel and Event Planning: ESFPs love to explore new environments and new things, so working in the travel industry or event planning is a good choice. ESFPs can show their creativity by organizing and planning fun events, and enjoy sharing these experiences with others. ",
                                        "4. Health and Fitness: Since ESFPs enjoy physical activities and pay attention to sensory experiences, they can find satisfaction in the fields of health, fitness, or sports. As a coach, nutritionist, or health consultant, ESFPs can help others stay healthy and well through their interactions with them. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Love and Partners",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "In love, ESFPs are passionate and emotional people who like to express their love through actions and performance. ESFPs are very emotional and considerate partners who enjoy bringing happiness and surprises to their partners. They are usually very romantic and like to keep their relationship fresh by creating small surprises, dates, and activities. ",
                                        "However, ESFPs may tend to be too dependent on their partner's approval and are not good at dealing with complex issues in relationships. They sometimes avoid conflicts or ignore long-term commitments, so they need someone who can understand and tolerate their attitude towards life. A partner who can support them in enjoying life and provide stable emotional support will make ESFPs feel safe and satisfied. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Development Suggestions",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. Establish long-term goals and plans: While it is important to enjoy the present, ESFPs also need to learn to plan for the future and set clear goals. By setting short-term and long-term goals, ESFPs can stay motivated and avoid losing their way in the process of realizing their dreams. ",
                                        "2. Learn to deal with pressure and responsibility: ESFPs need to enhance their ability to face pressure and responsibility. When problems arise, they should learn to face them positively and take effective measures instead of avoiding them. This improvement in ability will help ESFPs become more mature and prepared for future challenges. ",
                                        "3. Develop patience and the ability to think carefully: ESFPs can avoid the trouble of impulsive decision-making by enhancing patience and self-control. Learning to examine the multiple sides of a problem and think carefully before making a decision will help them make wiser choices. ",
                                        "4. Cultivate an Inner Sense of Self-worth: Although external recognition is important to ESFPs, they should learn to find confidence from within rather than over-rely on the evaluation of others. By enhancing self-awareness and self-acceptance, ESFPs are able to build a more stable and independent inner world. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "Overall, ESFPs are energetic and fun people who attract others with their optimistic, extroverted personalities and rich emotional world. By establishing long-term goals, handling stress and responsibilities, strengthening patience, and cultivating an inner sense of self-worth, ESFPs can gain more growth and happiness in their careers and emotional lives. ",
                                    ],
                                },
                            ],
                        },
                    ],
                },
                ESTJ: {
                    code: "ESTJ",
                    name: "Manager",
                    callName: "Determined Manager",
                    description:
                        "A practical and efficient organizer who pays attention to rules and order, is good at leading a team, and ensures that plans are carried out in an orderly manner and goals are achieved.",
                    traits: [
                        "Organizational ability",
                        "Leadership",
                        "Efficiency",
                        "Realism",
                    ],
                    overview:
                        'ESTJs (Extraversion, Sensing, Thinking, Judging) are called "executors" or "organizers". They are pragmatic and realistic leaders who like order and structure and pay attention to rules and traditions. ESTJs are rigorous and decisive, with a strong sense of responsibility and action-oriented traits. They tend to rely on logic and facts to make decisions, and attach great importance to efficiency and goal achievement. As extroverts, ESTJs enjoy interacting with others, are good at organizing and managing affairs, and are happy to lead and take responsibility. They usually drive the team forward through clear plans and effective management.',
                    detail: [
                        {
                            title: "Personality characteristics",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. Pragmatic and practical: ESTJ Usually solve problems in a practical and pragmatic way. They tend to focus on the details of real life rather than theoretical or abstract concepts. ESTJ prefers to rely on experience and known facts to make decisions. They don't like complex and uncertain situations, but prefer known rules and frameworks. ",
                                        "2. Strong organizational skills: As natural leaders, ESTJs are good at planning and organizing things. They have excellent time management and task scheduling skills and can always complete their work efficiently. ESTJs attach great importance to order and believe that everything should be in order, so they often play the role of managers or organizers in the team. ",
                                        "3. Leadership and decision-making ability: ESTJs like to take leadership roles in groups and are usually able to make decisions quickly. They rely on logic and facts to evaluate situations and are not easily influenced by emotions or subjective views. ESTJs usually express their views in a clear and clear way, driving the team towards the set goals. ",
                                        "4. Responsibility and loyalty: ESTJs take their responsibilities very seriously and believe that it is very important to complete tasks and fulfill their responsibilities on time. They are very loyal to their team and family and have a strong sense of responsibility. They expect others to be as responsible, follow rules, and maintain order as they are. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Character Weaknesses",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. Stubbornness and Rigidity: Because ESTJs emphasize rules and order, they may sometimes appear too stubborn and unwilling to accept change. They tend to be conservative about novel or different ways of doing things and have difficulty adapting to changes. Over-reliance on known patterns and rules may limit their flexibility in certain situations. ",
                                        "2. Too much attention to details: Although ESTJs focus on efficiency and details, sometimes they may focus too much on details to the point of ignoring overall goals or more important matters. Over-dealing with trivial matters may cause them to be unable to respond quickly at critical moments or waste unnecessary energy. ",
                                        "3. Ignoring Others' Feelings: ESTJs tend to rely on logic and facts to make decisions, and may ignore the emotions and needs of others when doing things. Their communication style sometimes appears direct and harsh, which may make others feel uncomfortable. Especially when dealing with the emotions of team members, ESTJs may appear indifferent or lack empathy. ",
                                        "4. Difficulty adapting to uncertainty: ESTJs prefer a planned, rule-based environment and have difficulty dealing with highly uncertain or ambiguous situations. When faced with uncertainty, they may feel anxious or uneasy and tend to avoid or ignore these uncertainties. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Suitable careers",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "ESTJs are suitable for careers that require leadership, management, and organization. They have obvious advantages in pursuing efficiency and goal achievement. The following are some careers suitable for ESTJs:",
                                        "1. Business management and leadership positions: As natural leaders, ESTJs perform well in business management and leadership positions. They are able to organize and coordinate teams efficiently and develop and implement specific action plans. Whether it is a CEO, department manager or project manager, ESTJs are competent and drive the development of the company through their decisive decisions. ",
                                        "2. Law and Law Enforcement: ESTJs place a high value on rules and order, so the law and law enforcement field is an ideal choice for them. Lawyers, judges, police officers, court clerks and other professions are suitable for ESTJs because these professions require compliance with strict legal regulations and the enforcement of rules. ",
                                        "3. Military and Security Industry: ESTJs are usually adapted to highly structured and disciplined environments, so the military and security field is an ideal choice for them. They are good at following orders, performing tasks and maintaining order, and positions such as officers, police officers, and security managers are very suitable for them. ",
                                        "4. Project Management and Consulting: ESTJs are good at organizing and planning tasks, and they can also excel in the project management and consulting industry. Whether it is construction, engineering or IT project management, ESTJs can ensure the smooth completion of projects through effective team management and task coordination. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Love and Partner",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "In love, ESTJs are very loyal and stable partners. They place great value on long-term commitments, and often want to prove their love through actions and a sense of responsibility. ESTJs like to provide their partners with a sense of security and stability in their lives, and they provide support and security for their relationships through plans and arrangements. ",
                                        "However, ESTJs may sometimes appear to be too rational and businesslike, and they sometimes ignore their partners' emotional needs and prefer to focus on practical issues. Their partners may feel that they lack romantic or emotional expression, so ESTJs need to learn to pay more attention to their partners' emotional world and enhance emotional connection through more delicate communication. ",
                                        "The ideal partner is usually someone who can understand the ESTJ's work and responsibility characteristics, while also being able to give them emotional support and understanding. For ESTJs, a stable, mature and communicative partner is ideal. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Development Suggestions",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. Enhance flexibility and adaptability: Although ESTJs are accustomed to relying on rules and known patterns, they need to learn to adjust in complex and uncertain situations. Enhance flexibility, be able to better adapt to changes, and take different approaches when needed. ",
                                        "2. Pay attention to other people's feelings and emotional communication: ESTJs need to pay more attention to the emotional needs of team members and partners, and learn to express care in a more empathetic way. Improving the way they communicate with others, especially on emotional issues, can help ESTJs build more harmonious interpersonal relationships. ",
                                        "3. Cultivate creative thinking: Although ESTJs prefer practical operations and structured thinking, cultivating some creative thinking and an open mindset will be of great help to their career development and personal growth. Being able to introduce innovative approaches and ideas to work may bring unexpected success. ",
                                        "4. Learn to manage stress and relax: Because ESTJs often take on heavy responsibilities, they may easily feel overwhelmed. Learning to effectively manage stress and find appropriate ways to relax can help ESTJs maintain long-term motivation and a healthy work attitude. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "Overall, ESTJs are responsible, decisive, and effective leaders who perform best in orderly, structured environments. By increasing flexibility, focusing on emotional communication, and improving creativity, ESTJs can better adapt to a rapidly changing world and achieve greater success in their careers and interpersonal relationships. ",
                                    ],
                                },
                            ],
                        },
                    ],
                },
                ESTP: {
                    code: "ESTP",
                    name: "Adventurer",
                    callName: "Brave Adventurer",
                    description:
                        "Adventurous, like to try new things, energetic and active, good at seizing opportunities and making decisions quickly to meet challenges.",
                    traits: [
                        "Action",
                        "Confidence",
                        "Adventure",
                        "Adaptability",
                    ],
                    overview:
                        'ESTP (Extraversion, Sensing, Thinking, Perception) is called "Adventurer". They are energetic, witty, optimistic and active. As an extroverted personality, ESTP is good at communicating with people, likes socializing, and is usually an active member of the team. They have a high sense of reality and focus on the current actual situation rather than potential problems or excessive theories in the future. ESTP is an action-oriented person who often solves problems through practice rather than abstract thinking. When faced with pressure and challenges, ESTP shows a high degree of adaptability, can respond quickly and learn from experience.',
                    detail: [
                        {
                            title: "Personality characteristics",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. Energetic: ESTP Full of energy and vitality, they like adventure and excitement, and are good at seizing opportunities and responding quickly. They usually don't like static life and tend to constantly look for new things and challenges to stimulate their interest. ",
                                        "2. Realism: ESTPs focus on practical issues and tend to focus on the current affairs rather than long-term planning. They have a keen insight into details and can quickly assess the current situation and take effective actions. They like to solve immediate problems and often find the most direct and effective solutions. ",
                                        "3. Straightforwardness and extroversion: ESTPs are straightforward, like to interact with people, and are good at building relationships with others. They usually act as the active center in social occasions, and are good at making people around them feel relaxed and encouraging them to participate. ",
                                        "4. Strong adaptability: ESTPs perform particularly well in dealing with emergencies and can quickly adapt to new environments and challenges. They are good at staying calm under pressure and making decisions quickly with their agile thinking and action. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Character Weaknesses",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. Impulsiveness and Impatience: Due to their preference for quick action, ESTPs can sometimes appear too impulsive and impatient when doing things. They don't like procrastination and pursue quick results, which may cause them to ignore potential risks or consequences. ",
                                        "2. Ignoring Long-Term Planning: ESTPs are usually focused on current tasks and goals, and tend to ignore long-term planning and potential problems in the future. Because they pay more attention to the actual situation at hand, they may miss some opportunities that need to be planned in advance. ",
                                        "3. Lack of Empathy: Although ESTPs are good at socializing and building good relationships with others, they sometimes appear to be not delicate enough when dealing with emotional issues. They may ignore the feelings of others, especially when faced with complex emotional needs, and often express their concern through actions rather than words. ",
                                        "4. Dislike of Rules and Restrictions: ESTPs like freedom and flexibility, and usually don't like to be bound by rules. When faced with strict norms and structures, they may feel depressed or dissatisfied, and even have a tendency to challenge authority. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Career Development",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "ESTPs are suited to challenging, dynamic jobs that require instant decision making. They excel in fast-paced, stimulating jobs. Here are some careers that would be a good fit for ESTPs:",
                                        "1. Sales and Marketing: ESTPs are great at connecting with and motivating people, making them well suited to sales jobs. They can quickly determine customer needs and take action. ",
                                        "2. Business Management and Leadership Positions: As decisive and fast-acting people, ESTPs can take on leadership roles, especially in environments that require quick decision making and problem solving. ",
                                        "3. Emergency Rescue and Emergency Management: ESTPs are good at responding to crises and can act calmly in emergencies, so they excel in positions such as firefighters, paramedics, and police officers. ",
                                        "4. Athletes and Coaches: ESTPs enjoy sports and physical activity, and they can fully tap into their strengths in the sports field, especially in positions that require quick reactions and high-intensity performance. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Love and Partner",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "In love, ESTPs are passionate and energetic partners who enjoy sharing adventures and new experiences with each other. Their love style is usually passionate and exciting, and they like to express their love with practical actions. ESTPs' attitude towards love is often direct and sincere, but sometimes they may be too focused on the happiness of the moment and ignore the emotional needs of their partners. Therefore, their partners need to understand ESTPs' adventurous spirit and be able to accept their sometimes lack of emotional depth. ",
                                        "ESTPs may not be patient enough in relationships, especially when it comes to emotional communication, and may rely more on actions than words. The ideal partner is someone who can explore new things with ESTPs, enjoy the freedom and fun of life, and is willing to give them enough emotional space and understanding. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Development Suggestions",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. Cultivate thoughtful decision-making skills: Although ESTPs are good at making quick decisions, they are sometimes too impulsive. To avoid regret, they can learn to think more carefully before making decisions and consider more long-term impacts. ",
                                        "2. Focus on emotional expression and empathy: ESTPs can improve their emotional depth in interpersonal relationships by learning how to pay more attention to and understand the emotional needs of others, especially in close relationships, and pay more attention to listening and caring about others' feelings. ",
                                        "3. Develop planning and patience: Although ESTPs prefer to act immediately, they can try to make long-term plans and focus on long-term goals. By setting reasonable time frames and expectations, ESTPs can avoid over-reliance on short-term behaviors that rely on instant gratification. ",
                                        "4. Respect rules and structure: Although ESTPs don't like to be constrained, learning to follow rules when appropriate can help them work more smoothly in work and social environments. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "Overall, ESTPs are dynamic, decisive, and practical people who are able to respond to challenges with quick action and strong adaptability. If they can be more thoughtful in their decision-making and improve their emotional expression, ESTPs can achieve greater success and satisfaction in their personal and professional lives. ",
                                    ],
                                },
                            ],
                        },
                    ],
                },
                INFJ: {
                    code: "INFJ",
                    name: "Advocate",
                    callName: "The Profound Advocate",
                    description:
                        "Idealists, committed to helping others and social progress, insightful and mission-driven.",
                    traits: ["Empathy", "Idealism", "Insight", "Devotion"],
                    overview:
                        'INFJs (Introversion, Intuition, Feeling, Judging) are known as "advocates" or "mentors". They are a minority of idealists and thoughtful people who always strive to help others and bring positive changes to society. INFJs tend to focus on their inner thoughts and emotions and show deep empathy when interacting with others. They are often visionary and see potential and meaning that others cannot see. Although introverted, INFJs will devote a lot of energy and effort to the people or causes they care about, and strive to bring about changes that are beneficial to society.',
                    detail: [
                        {
                            title: "Personality Characteristics",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. Thoughtfulness and Idealism: INFJ Tend to think deeply, always focusing on long-term goals and ideals rather than the specific affairs at hand. They have a strong idealistic vision of the world and are committed to achieving these ideals. INFJs tend to focus on inner emotions and thoughts, like to be alone and think deeply about complex problems. ",
                                        "2. Deep empathy and understanding: INFJs pay great attention to the emotions and needs of others, and are usually able to perceive the unspoken thoughts and emotions of others. They have strong empathy, can establish deep emotional connections with others, and can provide help and support when others need it most. ",
                                        "3. Introspection and self-reflection: INFJs like to reflect on themselves and introspect, and explore their inner world. They usually have deep philosophical thinking and exploration of the meaning of life, and often constantly pursue truth and wisdom in self-exploration and growth. ",
                                        "4. Pursuit of meaning and goals: INFJs' lives are usually filled with a deep sense of purpose, and they are eager to make meaningful changes to the world. They often choose careers with greater social impact and are willing to devote their personal time and energy to them. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Character Weaknesses",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. Too Idealistic: INFJs often get caught up in the pursuit of an ideal world and may ignore the complexity of reality. They are sometimes too idealistic and may feel frustrated because they cannot see the realization of their ideals. When there is a huge gap between ideals and reality, INFJs tend to lose motivation or feel confused. ",
                                        "2. Prone to Overthinking and Self-doubt: Because INFJs are thoughtful and introspective, they may overthink and worry unnecessarily. This tendency may cause them to feel indecisive when making decisions, and even increase stress and anxiety invisibly. ",
                                        "3. Inner Conflict and Self-Repression: INFJs have very high demands on themselves and often feel inner conflicts in the process of pursuing perfection. They may ignore their own needs and emotions, over-repress their emotions, and ignore their own health and happiness when helping others. ",
                                        "4. Difficulty accepting criticism: INFJs are very sensitive to the opinions and criticisms of others. Because they rely heavily on their own inner values ​​and ideas, any negative feedback may make them feel extremely hurt and affect their self-confidence. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Suitable careers",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "INFJs are usually suitable for careers that can bring positive impact to society and can use their empathy and insight. Here are some suitable careers for INFJ:",
                                        "1. Psychologists and psychotherapists: INFJs are good at understanding other people's emotions and have a high degree of empathy. They can effectively help others in the fields of psychology and therapy. ",
                                        "2. Social workers and humanitarians: INFJ's sense of purpose and idealism make them suitable for social services and humanitarian work. ",
                                        "3. Writers and artistic creators: INFJ's inner expression and unique way of thinking make them excellent writers, poets or artists. ",
                                        "4. Educators and mentors: INFJs have deep insight and empathy for others, and they are suitable for educational work, especially those positions that can inspire students to think and grow. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Love and Partner",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "INFJs are very loyal and affectionate partners. They pursue deep and sincere emotional connections and tend to seek people who can understand their inner world. INFJs look forward to establishing a highly spiritual relationship with their partners. They hope that the two can grow together and pursue ideals and goals together. ",
                                        "However, INFJs may over-idealize love and partners, expecting the other party to fully meet their ideal standards. If the partner cannot meet these expectations, INFJs may feel disappointed or frustrated. In addition, INFJs may suppress their own needs and emotions in a relationship in order to take care of their partner's needs, which may cause them to feel exhausted and unsatisfied. ",
                                        "The ideal partner is usually someone who can understand and support INFJ's ideals and emotional needs. INFJ needs a gentle, understanding, and empathetic partner who can accept the complexity of INFJ and is willing to face the challenges of life with them. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Development Suggestions",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. Let go of overly idealistic expectations: INFJ needs to learn to accept reality and understand the gap between ideal and reality. Although pursuing lofty goals is encouraging, over-idealism may lead to disappointment and exhaustion. Learning to accept imperfections and realistic challenges will help them maintain inner peace and motivation. ",
                                        "2. Enhance self-care and balance: INFJ often tends to give too much to others and ignore their own emotions and needs. Therefore, they need to pay more attention to self-care, learn to set boundaries for themselves, and maintain emotional balance and stability. ",
                                        "3. Manage inner anxiety and uneasiness: INFJ is prone to overthinking and anxiety. It is recommended that they manage stress and anxiety through meditation, exercise or other means. Learning to better control their emotions and avoid self-doubt and overthinking will help them to be more resolute in the face of adversity. ",
                                        "4. Improve communication skills with others: Although INFJs have deep empathy, they sometimes have difficulty expressing their needs and emotions clearly. Improving communication skills with others, learning to express themselves proactively, and being open to feedback from others will help build more harmonious relationships. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "In summary, INFJs are thoughtful idealists who take it upon themselves to change the world and help others. By balancing ideals and reality, strengthening self-care and communication skills, INFJs will be able to better realize their potential in career and love and live a more fulfilling and satisfying life. ",
                                    ],
                                },
                            ],
                        },
                    ],
                },
                INFP: {
                    code: "INFP",
                    name: "Mediator",
                    callName: "Gentle Mediator",
                    description:
                        "Strive for inner harmony and realization of values, pay attention to the feelings of others, and are good at imagining future possibilities.",
                    traits: [
                        "Kindness",
                        "Sensitivity",
                        "Idealism",
                        "Creativity",
                    ],
                    overview:
                        'INFP (Introversion, Intuition, Feeling, Perception) is called "Mediator" or "Idealist". They are very idealistic, compassionate and creative people, often with dreams of changing the world. INFPs focus on inner emotions and values, and are usually unwilling to compromise their moral standards. For them, the pursuit of personal meaning and self-expression is more important than the pursuit of external success. INFPs tend to live an independent life and look for work and relationships that can meet their spiritual needs. Although they are introverted and tend to think deeply, they have a strong sense of mission and a deep concern for others.',
                    detail: [
                        {
                            title: "Personality characteristics",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. Idealism and Morality: INFP They have a strong idealistic vision of the world and always hope to bring positive changes to society. They have strong moral values ​​and cannot tolerate injustice or hypocrisy. They often have good expectations for the future and strive to pursue noble goals in their daily lives. ",
                                        "2. High Empathy and Emotional Depth: INFPs have strong empathy and can perceive the emotions of others and deeply understand the inner needs of others. They are good listeners and can give emotional support and understanding to others. This empathy also makes it easy for them to establish deep emotional connections with others. ",
                                        "3. Creativity and Artistic Sense: INFPs have rich imagination and creativity and are often immersed in creation and artistic expression. They may be writers, artists or other workers in creative fields, and are good at conveying their emotions and ideas through art forms. For them, art and self-expression are very important. ",
                                        "4. Independence and Introspection: INFPs explore their inner world deeply and usually spend a lot of time on self-reflection and exploration. They are usually very independent and like to think and recharge in a quiet environment, avoiding too much external interference. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Character Weaknesses",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. Over-idealization: Because INFPs are overly idealistic, they tend to have unrealistic expectations of themselves and others. If reality does not match their ideals, they may feel disappointed or even depressed. Especially when faced with injustice and imperfection in life, INFPs tend to feel powerless. ",
                                        "2. Emotional Oversensitivity: INFPs are very sensitive to criticism from the outside world and may feel deeply hurt by an unintentional remark from others. Because of their rich emotions, they often easily fall into negative emotions and find it difficult to let go. ",
                                        "3. Indecisiveness and escapism: INFPs tend to overthink, which may lead to indecision in making decisions. In addition, they may also have a tendency to escape reality and avoid facing difficult life challenges, which affects their growth and development. ",
                                        "4. Too much self-sacrifice: In order to take care of others or pursue their ideals, INFPs sometimes ignore their own needs and emotions. Excessive self-sacrifice may cause them to feel exhausted, lonely or misunderstood. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Suitable careers",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "INFPs are usually suitable for careers that can express personal ideas, creativity and emotions. The following are some career directions suitable for INFPs:",
                                        "1. Writers and creators: INFPs' creativity and emotional expression make them very suitable for careers such as writing, art creation, and design. They can show their ideals and emotions to the world through words, artworks, movies or music. Occupations such as writers, poets, novelists, and illustrators can satisfy their creative desires. ",
                                        "2. Psychologists and counselors: INFPs' empathy and emotional insight make them very suitable for work in the fields of psychology and counseling. They can listen to other people's problems and help others solve their inner troubles through in-depth analysis and understanding. ",
                                        "3. Social workers and public welfare: INFPs have a deep concern for social injustice and the plight of others. They are suitable for social work, non-profit organizations, volunteer work, etc. Through these professions, INFPs can truly realize their ideals and help the disadvantaged groups in society. ",
                                        "4. Educators and mentors: INFPs are good at inspiring others' potential and are willing to help others achieve personal growth through education. They will play an excellent role in the field of education, especially in non-traditional or innovative educational methods. Teachers, counselors, mentors and other positions can allow INFPs to play to their strengths. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Love and Partners",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "INFPs are very romantic and loyal partners. They have a deep emotional dependence on their partners and desire to establish a relationship based on deep understanding and mutual support. INFPs will do their best to provide their partners with unconditional love and support, and are willing to make great efforts for love. They usually pursue an ideal love relationship and hope to resonate with their partners in their hearts. ",
                                        "However, INFPs are also prone to being overly idealistic in relationships. They may see their partners as perfect objects, thus ignoring their partners' shortcomings. If their partners fail to meet their ideal standards, INFPs may feel disappointed and frustrated. In addition, INFPs may be too self-sacrificing in relationships, ignoring their own needs, leading to emotional imbalance. ",
                                        "An ideal partner is usually someone who can understand and support INFPs' ideals, values, and emotional needs. A partner who can accept their complexity and idealism and is willing to explore the meaning of life with them will be the best choice for INFPs. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Development Suggestions",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. Balancing ideals and reality: INFPs need to learn to balance the gap between ideals and reality. Although the pursuit of ideals is worthy of recognition, over-idealization will bring disappointment and frustration. Learn to accept imperfections in life and avoid being troubled by the lack of ideals. ",
                                        "2. Enhance decision-making ability: INFPs sometimes hesitate excessively or avoid making decisions. Improving decision-making skills and increasing self-confidence can help them face challenges in life more effectively. Learning to weigh the pros and cons and make decisions in an imperfect environment will be an important growth for INFPs. ",
                                        "3. Pay attention to self-needs: INFPs tend to ignore their own needs and emotions while caring about others. They need to learn to take care of themselves, clarify their boundaries, and avoid excessive self-sacrifice. Regular rest, maintaining emotional balance, and communicating their needs with others will help them better maintain their mental health. ",
                                        "4. Improve emotional management: INFPs are sensitive to emotional fluctuations and are easily influenced by the outside world. Through meditation, exercise, or psychological counseling, they can better manage their emotions and maintain inner peace and stability. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "INFPs are idealistic creators and caregivers. They are driven by deep emotions and strong values ​​and are committed to changing the world and helping others. By learning to balance ideals and realities, improving their decision-making skills, and focusing on their own needs, INFPs will be able to better realize themselves, overcome challenges, and gain more satisfaction in their careers and relationships. ",
                                    ],
                                },
                            ],
                        },
                    ],
                },
                INTJ: {
                    code: "INTJ",
                    name: "Strategist",
                    callName: "Calm Strategist",
                    description:
                        "Good at systematic thinking and planning, focusing on efficiency and goals, visionary and insightful but independent.",
                    traits: ["Calm", "Independence", "Vision", "Logic"],
                    overview:
                        'INTJ (Introversion, Intuition, Thinking, Judging) is called "Strategist" or "Architect". They are natural planners and analysts, thoughtful, focusing on rational thinking and long-term goals, with strong independence and inner confidence. They are usually driven by the pursuit of knowledge, like to understand things as a whole, and look for ways to optimize and improve. INTJ likes to think and plan in their own inner world, rarely relying on external opinions or social norms. Because they have a clear vision for the future, INTJs usually show outstanding talents and leadership in their respective fields.',
                    detail: [
                        {
                            title: "Personality characteristics",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. Strategic thinking and vision: INTJ Born with excellent strategic vision, they are good at long-term planning and can clearly see the connection between goals and paths. When faced with complex situations, INTJs are able to assess the situation from a macro perspective and propose feasible solutions. They like to explore problems in depth from a theoretical level and develop efficient action plans. ",
                                        "2. Highly rational and analytical ability: INTJs are extremely rational individuals who rely on logic and facts to make decisions, rather than emotions or intuition. They are good at analyzing complex problems and using systematic methods to solve them. This makes them particularly good at solving technical or strategic problems. ",
                                        "3. Confidence and independence: INTJs are confident in their abilities and usually do not need affirmation or praise from others. They are convinced of their own judgments and decisions, so they sometimes appear stubborn and unshakable. At the same time, INTJs have a strong sense of independence, do not like to be bound by traditions and external expectations, and tend to do things their own way. ",
                                        "4. Focus and determination: INTJs are very persistent in pursuing their goals. Once they set a goal, they will devote themselves to it and strive to overcome all difficulties. Whether it is academic research, technological innovation or career achievement, INTJs usually show high concentration and long-term persistence. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Personality Disadvantages",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. Too rational and indifferent: Because INTJs rely too much on logic and analysis, they may sometimes appear too rational or even indifferent. When facing the emotional expressions of others, INTJs may ignore the feelings of others, causing others to feel alienated or misunderstood. ",
                                        "2. Stubbornness and difficulty in compromise: INTJs are sometimes too persistent in their own opinions and plans, and often refuse to listen to opinions or suggestions from outsiders. This stubbornness may cause them to conflict with others, especially when working in a team. ",
                                        "3. Loneliness and social challenges: Although INTJs have strong social skills, they prefer to work independently and avoid large-scale social interactions. This preference sometimes makes them feel lonely, especially when they need more support at work or in life, and they may feel a lack of close relationships. ",
                                        "4. Too much pursuit of perfection: INTJs have extremely high requirements for the quality of work and may set demanding standards for themselves and others. When things do not go as they expect, INTJs may feel frustrated or even disappointed. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Suitable careers",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "INTJs tend to engage in careers that can make full use of their strategic thinking, analytical skills, and innovative spirit. Here are some career directions suitable for INTJs:",
                                        "1. Scientists and researchers: INTJs' logical analysis and problem-solving abilities allow them to excel in technical fields such as engineering and computer science. They can use their innovative thinking to solve complex technical problems and promote the successful implementation of projects. ",
                                        "2. Strategic consultants and entrepreneurs: INTJs' strategic vision and long-term planning ability make them suitable for positions such as strategic consultants and business management. They are good at analyzing market trends, identifying opportunities, and developing sustainable long-term development plans for companies or organizations. ",
                                        "3. Engineers and Technologists: INTJs' logical analysis and problem-solving abilities allow them to excel in technical fields such as engineering and computer science. They can use their innovative thinking to solve complex technical problems and promote the successful implementation of projects. ",
                                        "4. Lawyers and Politicians: INTJs' thoughtfulness and precise analysis make them suitable for work in the legal or political fields. As lawyers, INTJs are able to provide strategic advice to clients with their excellent reasoning and debate skills. In the political field, they are also able to analyze situations and develop effective strategies through keen insight. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Love and Partner",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "INTJs often pursue deep and lasting relationships in love. They are not satisfied with superficial emotional interactions and usually desire to have a deep resonance with their partners in terms of ideas and values. INTJs need a partner who understands their independence and rational thinking and respects their need for personal space. In intimate relationships, INTJs prefer structured communication and expect to grow together and achieve long-term goals. ",
                                        "However, INTJs' rationality may sometimes cause them to ignore their emotional needs, especially when faced with emotional expressions, they may appear calm or distant. Therefore, they need to learn to pay attention to their partner's emotional needs and avoid over-rationalizing emotional issues. The ideal partner is someone who can understand and accept INTJ's complex personality, can develop with them, and give emotional understanding and support. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Development Suggestions",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. Learn to express and listen emotionally: INTJs' rational thinking may sometimes cause them to ignore the emotional needs of others. Learning to be more flexible in emotional expression and communication, and to value the emotional reactions of others, will help improve interpersonal relationships. ",
                                        "2. Improve cooperation and compromise skills: Due to INTJs' independence and stubbornness, they may encounter challenges in teamwork. Learning to compromise when necessary and being open to the opinions of others will help enhance the efficiency of teamwork and personal development. ",
                                        "3. Enhance social skills: Although INTJs prefer to work independently, good interpersonal relationships and cooperation skills are equally important in modern society. By actively participating in social activities and building deep interpersonal relationships, INTJs can create more opportunities and support networks for themselves. ",
                                        "4. Accept imperfections: INTJ's pursuit of perfection may lead to excessive anxiety and frustration. Learning to accept imperfections and learn from failures, rather than blindly demanding perfection, will help improve the quality of life and personal mental health. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "In general, INTJs are visionary, analytical, and strategic individuals who think deeply and are committed to achieving personal goals and ideals. In their careers, they usually pursue challenging and in-depth work and are able to demonstrate excellent leadership. By learning to better express emotions, improve cooperation, and accept imperfections, INTJs will be able to better balance rationality and emotion, thereby achieving more fulfilling achievements in career, love, and life. ",
                                    ],
                                },
                            ],
                        },
                    ],
                },
                INTP: {
                    code: "INTP",
                    name: "Thinker",
                    callName: "Curious Thinker",
                    description:
                        "Eager to explore knowledge and theories, full of interest in complex problems, and pursue deep understanding.",
                    traits: [
                        "Analysis",
                        "Creativity",
                        "Curiosity",
                        "Rationality",
                    ],
                    overview:
                        'INTP (Introversion, Intuition, Thinking, Perception) is called "Thinker" or "Explorer". They are individuals with independent thinking and innovative spirit, often immersed in their own world of thought, happy to solve complex theoretical problems, and explore unknown knowledge. INTP pursues freedom and unrestrained thinking, and is usually full of desire for knowledge and truth. They are good at looking at problems from all angles and finding innovative solutions. INTP is introverted, likes to think alone, avoids complex interpersonal interactions, and prefers a quiet and deep social style. Due to its unique cognitive style and rational analytical ability, INTP often performs well in academic, scientific and technical fields.',
                    detail: [
                        {
                            title: "Personality characteristics",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. Thoughtful and rational analysis: INTPs usually think deeply about any problem and like to analyze and reason from multiple angles. They attach great importance to logic and theory, and have a strong interest in abstract concepts and complex problems. They are not easily confused by superficial phenomena, but will find the truth through in-depth analysis. ",
                                        "2. Creativity and innovation: INTPs have rich imagination and creativity, and they like to explore new theories, concepts and methods. INTPs are often able to come up with innovative solutions and are full of hope for the future. They like to break the shackles of traditional thinking and look at the world from a different perspective. ",
                                        "3. Independence and autonomy: INTPs attach great importance to personal independence and desire to have free thinking and action space. They usually don't like to be disturbed by the outside world and prefer to think deeply in their own space. They will choose to follow their inner voice instead of relying on the expectations of others or society. ",
                                        "4. Calmness and objectivity: INTPs are very calm and objective when facing problems. They can focus on the essence of the problem and make rational decisions with less emotional interference. This makes them particularly good at dealing with complex problems. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Character weaknesses",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. Lack of emotional expression: Due to over-reliance on rationality, INTPs may neglect the expression and understanding of emotions, giving others an impression of indifference. Especially in intimate relationships, they tend to focus more on the problem itself and ignore the emotional needs of their partners, which may lead to difficulties in emotional communication. ",
                                        "2. Procrastination and lack of decisiveness: INTPs sometimes find it difficult to make decisions quickly due to constant thinking and analysis. They like to weigh the pros and cons of various possibilities, which may result in procrastination and miss the best time. At the same time, their uncertainty about certain choices may make it difficult for them to make a final decision. ",
                                        "3. Escapism and over-idealization: INTPs may show a certain escapism when facing the trivialities of real life. They tend to focus more on theories and ideas, and are not very interested in daily tedious tasks or specific details, which may affect their execution and efficiency. ",
                                        "4. Difficulty in socializing: Due to their introverted and rational nature, INTPs tend to be more reserved in interpersonal communication. They may feel uncomfortable in social situations and lack initiative, which may cause them to appear distant and cold in interpersonal relationships. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Suitable careers",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "INTPs are suitable for careers that can give full play to their logical reasoning, creativity, and deep thinking. Here are some career directions suitable for INTPs:",
                                        "1. Scientists and researchers: INTPs like to explore the unknown and find the truth, so they perform well in science, research, or technology. They are good at solving complex theoretical problems and are happy to challenge existing knowledge frameworks in the laboratory or research. ",
                                        "2. Philosophers and scholars: Due to their interest in theories and abstract concepts, INTPs are suitable for work in academic fields such as philosophy, literature, and history. They are able to delve into the nature of human thought, put forward innovative insights, and promote the development of disciplines. ",
                                        "3. Engineers and Programmers: INTPs' analytical and logical abilities make them outstanding in fields such as engineering and computer science. They are able to design complex systems and come up with innovative technical solutions, especially in areas such as technology development, software programming, and data analysis. ",
                                        "4. Consultants and Strategic Analysts: INTPs' strategic vision and rational analytical abilities also give them great potential in the business and consulting fields. As strategic analysts or consultants, INTPs can help companies make decisions on market analysis, product innovation, and other areas. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Love and Partners",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "INTPs often seek deep emotional connections and resonance of ideas in love. They hope to have consistency in intelligence and values ​​with their partners, and desire a mutually supportive and creative relationship. INTPs' views on love are usually rational and idealistic, and they look forward to exploring the world, sharing ideas, and solving problems with their partners. INTPs like to be independent, but they also desire to establish a deep emotional connection with their partners. Since they are less likely to express emotions, they may need to pay more attention to and understand their partner's emotional needs in love. ",
                                        "When choosing a partner, INTPs prefer to find a partner who is equally thoughtful, rational, and creative. The ideal partner can respect INTP's independence and understand their lack of emotional expression in certain situations. The understanding and support of their partner will be an important factor in INTP's ability to find happiness and satisfaction in love. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Development Suggestions",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. Enhance emotional expression: INTPs need to learn how to better express their emotions and pay attention to the emotional needs of others. By enhancing emotional communication with others, INTPs will be able to gain more support and understanding in intimate relationships. ",
                                        "2. Overcome procrastination and decision-making difficulties: INTPs tend to overthink and analyze problems, which makes it difficult to make decisions. To avoid procrastination, INTPs can try to set clearer time limits and pay more attention to practice and execution when making decisions, rather than just staying at the theoretical level. ",
                                        "3. Improve social skills: INTPs' introverted nature may sometimes make them feel cramped in interpersonal relationships. By enhancing their ability to communicate and connect with others, INTPs can expand their social circles and gain more support at work and in life. ",
                                        "4. Accept the details of real life: INTPs sometimes ignore the details of life while pursuing theories and ideas. In order to improve execution and quality of life, INTPs can learn to accept the trivialities of daily life and find creative solutions in them. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "In general, INTPs are creative and rational individuals who demonstrate outstanding abilities in academic, technical, and scientific fields. By enhancing emotional expression, improving decision-making ability, and improving social skills, INTPs are better able to cope with the challenges of the real world, balance inner ideals with external reality, and further achieve personal growth and happiness. ",
                                    ],
                                },
                            ],
                        },
                    ],
                },
                ISFJ: {
                    code: "ISFJ",
                    name: "Guardian",
                    callName: "Warm Guardian",
                    description:
                        "Warm and considerate, loyal and reliable, caring about the needs of others, willing to maintain harmony and stability, and have a strong sense of responsibility.",
                    traits: ["loyal", "gentle", "careful", "responsible"],
                    overview:
                        'ISFJ (Introversion, Sensing, Feeling, Judging) is called a "guardian" or "caregiver". They are gentle, reliable and dedicated people who are usually happy to help others and show their care for others through practical actions. ISFJs pay attention to details and tend to pay attention to the needs of others in life, especially playing an important role in family, friends and community. Due to their deep sense of responsibility and sensitive emotions, ISFJs are often willing to give to others and strive to maintain harmonious relationships and a stable environment. ISFJs seek a sense of security and tend to maintain stability and order, avoiding the uncertainty brought about by change.',
                    detail: [
                        {
                            title: "Personality characteristics",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. Good at caring for others: ISFJ They show a strong desire to care in interpersonal relationships. They are empathetic, sensitive to the emotions and needs of others, and willing to help others selflessly. Whether it is family, friends or strangers, ISFJs will try their best to provide care and support. ",
                                        "2. Attention to details and traditions: ISFJs often pay attention to the details of daily life and like to do things that seem insignificant but can bring comfort and order. They have a high respect for traditions and routines and like to keep their lives stable and predictable, which makes them very reliable partners. ",
                                        "3. Strong sense of responsibility: ISFJs are born with a strong sense of responsibility. They are well aware of their responsibilities and will work hard to fulfill them. Whether at home, at work or in social situations, they always try their best to complete tasks to ensure that things go smoothly. ",
                                        "4. Introverted and conservative: ISFJs are introverted people. Although they care about others very much, they usually prefer to move in a small circle and tend to avoid overly complicated or noisy social situations. Compared with taking risks, ISFJs prefer to maintain established relationships and routines. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Character Weaknesses",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. Excessive Self-Sacrifice: ISFJ's selflessness and dedication may cause them to neglect their own needs and feelings. They tend to care too much about others, and are reluctant to ask for help even when they need it. Long-term self-sacrifice may lead to emotional exhaustion and stress. ",
                                        "2. Oversensitivity: Because they are extremely concerned about the needs of others, ISFJs sometimes over-interpret the actions and words of others, resulting in oversensitivity. This sensitivity may make them easily hurt or disappointed, especially when their efforts are not recognized. ",
                                        "3. Resistance to change: ISFJ likes stability and order, and is accustomed to living in an established way. This makes them hesitant and uncomfortable when faced with change. Especially at work and at home, they may feel uneasy when faced with new situations and avoid taking new approaches or plans. ",
                                        "4. Avoiding conflicts: ISFJ likes to maintain harmonious interpersonal relationships, so they tend to avoid conflicts with others. This avoidance attitude may cause them to suppress their inner dissatisfaction, leading to a long-term backlog of unhealthy emotions, and even affecting their physical and mental health. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Suitable careers",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "ISFJ is suitable for careers that allow them to use their caring, organizational skills and meticulous observation. The following are some career directions suitable for ISFJ:",
                                        "1. Nursing and medical industries: ISFJ is very suitable for nursing, medical, psychological counseling and other industries due to its sensitive and caring nature. In these professions, they can use their strengths in helping others and provide warmth and support. ",
                                        "2. Teachers and education industries: ISFJ is also very suitable for education, especially in elementary or secondary education. They are patient with students, can take good care of them, and are good at imparting knowledge and helping students grow. ",
                                        "3. Social Workers and Nonprofits: Due to their natural empathy and concern for others, ISFJs can also find their place in social work, charity, or nonprofits, especially in helping the disadvantaged and community services, where ISFJs can make great contributions. ",
                                        "4. Administration and Logistics: ISFJs are organized and detail-oriented people, so they excel in administration, logistics, or project management. Ensuring that work runs smoothly and maintaining a stable environment is exactly what they excel in. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Love and Partners",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "ISFJs are gentle, loyal, and caring partners in love. They will devote themselves to the relationship, care about their partner's needs, and do their best to provide comfort and security for each other. When choosing a partner, ISFJs look for a partner who can understand, respect, and cherish their efforts. ISFJs often value their partner's happiness more than their own, but also need their partners to pay attention to their emotional needs. In romantic relationships, ISFJs prefer stable and secure relationships. They dislike uncertainty and conflict, and prefer calm and harmonious interactions. ",
                                        "The ideal partner should be able to understand the delicate feelings of ISFJs and be willing to create a warm family environment for them. ISFJs are very focused and loyal in love. They hope to have a deep emotional connection with their partners, and both parties can grow together and share the little things in life. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Development Suggestions",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. Learn to focus on yourself: ISFJs sometimes focus too much on others and ignore their own needs. In order to avoid emotional exhaustion, they need to learn to focus on themselves and give themselves more time and space to relax and recover. Through proper rest and self-care, ISFJs can maintain inner balance and improve their physical and mental health. ",
                                        "2. Courage to face change: ISFJs usually avoid change, but appropriate change can bring new opportunities and growth. ISFJs can gradually adapt to new situations, try to accept changes, and find innovative possibilities in changes. This not only helps personal career development, but also improves their ability to cope with life challenges. ",
                                        "3. Enhance emotional expression: ISFJs may be conservative when expressing their feelings, which sometimes makes their emotional needs unnoticed by others. By expressing their feelings and needs more openly, ISFJs can avoid the backlog of inner dissatisfaction and strengthen their emotional connection with others. ",
                                        "4. Improve self-confidence and decision-making ability: ISFJs sometimes rely too much on the opinions of others and lack self-confidence, especially when facing decision-making. By cultivating decision-making ability and trusting their own judgment, ISFJs can be more confident and decisive in life and better achieve their goals. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "In general, ISFJs are very dedicated and meticulous individuals who show excellent ability to provide help and care. By focusing on their own needs, improving their ability to cope with change, and increasing their emotional expression, ISFJs are able to achieve greater personal and professional success and enjoy a more fulfilling and happy life. ",
                                    ],
                                },
                            ],
                        },
                    ],
                },
                ISFP: {
                    code: "ISFP",
                    name: "Artist",
                    callName: "Free Artist",
                    description:
                        "They pursue freedom and beauty, are keen on sensory experience and self-expression, and are good at expressing unique personality and emotions through creativity.",
                    traits: [
                        "Creativity",
                        "Freedom",
                        "Sensitivity",
                        "Personality",
                    ],
                    overview: [
                        'ISFP (Introversion, Sensing, Feeling, Perception) is called "Explorer" or "Artist". They are creative, sensitive and gentle people. ISFP likes to understand the world through experience, and tends to enjoy the present life rather than overthinking about the future. They pay more attention to inner feelings and emotions rather than external evaluations and standards, and usually pursue personal freedom, comfort and unique lifestyle. ISFPs are often unwilling to restrain themselves and tend to express their unique ideas and emotions through art, music or other forms.',
                        "ISFP ISFPs usually have a slower pace of life and prefer to spend time in a peaceful and natural environment, avoiding the pressure of an overly busy or stressful life. They are easy to help others, but usually in a gentle and unrequited way. They usually have a strong aesthetic sensibility, can appreciate and create beautiful things, and especially show extraordinary talent in artistic creation. ",
                    ],
                    detail: [
                        {
                            title: "Personality characteristics",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. Creativity and artistic talent: ISFPs have an innate talent in art. They can often find beauty in details and express their creativity through visual arts, music, writing, etc. Whether in painting, design, or other creative fields, ISFPs show unique talents. ",
                                        "2. Sensitive and gentle: ISFPs are very sensitive to external stimuli, especially in emotions. They can delicately perceive the emotions of others and give sympathy and care. This makes them often very gentle in interpersonal relationships and can respond sensitively to the needs of others. ",
                                        "3. Independence and freedom: ISFP They don't like to be bound or live according to other people's standards. They crave freedom and independence. Whether it's work or life, they prefer to move forward at their own pace and in their own way. ISFP likes to think independently and doesn't like to be forced to make decisions. Instead, they hope to guide their actions through their own feelings and intuition. ",
                                        "4. Focus on experience: ISFP likes to experience the world, especially sensory and emotional experiences. They like to explore the unknown, enjoy the pleasure brought by nature, art or other beautiful things, tend to live in the present, and don't pay much attention to the uncertainty of the future or the regrets of the past. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Character shortcomings",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. Avoiding conflicts and responsibilities: ISFP tends to avoid conflicts, especially in emotional issues. They often choose to avoid difficult problems that need to be faced and solved. This avoidance attitude sometimes makes them dare not face problems at critical moments, thus missing the opportunity to solve problems. ",
                                        "2. Too idealistic: ISFP has an idealistic view of life. They often hope that everything around them can develop according to their beautiful vision. This idealistic mentality may make them lose their sense of reality, and they may feel disappointed and frustrated when reality fails to meet their expectations. ",
                                        "3. Poor planning and organization: Because ISFPs prefer to experience and feel, they usually lack long-term planning and organizational skills. They sometimes feel that prior plans limit their freedom, which may cause them to be a little slow and disorderly when dealing with work and life tasks. ",
                                        "4. Emotional withdrawal: ISFPs are more introverted and are not good at expressing their needs or emotions to others. When they encounter dissatisfaction or pressure, they may choose to keep silent and bear it alone, and may even be passive and alienated in emotional relationships. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Suitable careers",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "ISFPs are more suitable for careers that can give full play to their creativity, artistic talents and independence. The following are some career directions suitable for ISFPs:",
                                        "1. Art and design fields: ISFPs often have outstanding talents in artistic creation. Whether it is painting, photography, sculpture, filmmaking, or other visual art forms, ISFPs are able to fully utilize their creativity and aesthetic vision. ",
                                        "2. Music Industry: Music is a way for ISFPs to express their emotions. They often show their talents in fields such as music composition, performance, or sound design. In the music industry, ISFPs are able to incorporate their emotions and creativity into their works and find their own artistic voice. ",
                                        "3. Therapy and Psychological Counseling: ISFPs are very sensitive to the emotions and needs of others, so they are very suitable for working in industries such as psychology, therapy, or counseling. They can provide warmth and support to those in need and create a safe emotional space. ",
                                        "4. Handicrafts and Creative Work: ISFPs like to express themselves through practical operations and creations. Whether it is woodworking, pottery, fashion design, or other handicraft work, ISFPs are able to create beautiful objects with their own hands and enjoy the process. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Love and Partner",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "In love, ISFPs are gentle, considerate and loyal partners. They pay attention to their partners' feelings and are willing to provide care and support to each other. ISFPs like to share deep emotional connections with their partners and express love in a warm way. In a romantic relationship, ISFPs expect to enjoy a relaxing and enjoyable time with their partners. They tend to avoid conflicts and quarrels, and hope to maintain the relationship through tacit understanding and understanding. ",
                                        "ISFPs have a high emotional dependence on their partners. They need their partners' understanding and support, but they don't like constraints and control and expect to have enough free space. When choosing a partner, ISFPs pay more attention to emotional compatibility. They hope to find someone who can share good times with them. The ideal partner should be able to respect ISFP's independence and be willing to pursue a beautiful and peaceful life with them. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Development Suggestions",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. Enhance confidence and decision-making ability: ISFPs sometimes seem hesitant when facing important decisions. In order to improve personal growth, ISFPs can increase their confidence by accumulating more experience, learn to trust their intuition, and make more decisive decisions. ",
                                        "2. Learn to deal with conflicts: ISFPs' tendency to avoid conflicts may cause them to escape from real-life problems. In order to avoid emotional repression, they need to learn to face and deal with conflicts, bravely express their feelings and needs, and maintain their position in relationships. ",
                                        "3. Improve time management and planning skills: ISFPs often don't like planning, but proper organization and planning can help them complete tasks more efficiently. In work and life, ISFPs can improve their productivity and avoid unnecessary stress caused by procrastination through some simple planning and time management techniques. ",
                                        "4. Accept reality and adjust flexibly: ISFPs' idealistic tendencies may make them feel disappointed when facing reality. To better cope with challenges, they can learn to accept the complexity of reality, adjust their expectations when necessary, and maintain a flexible attitude. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "ISFPs are creative and gentle people who pay attention to their inner feelings and are good at finding themselves in art and creation. By strengthening their self-confidence, conflict resolution skills, and flexible response to challenges in life, ISFPs can achieve greater personal and professional achievements and achieve a more fulfilling life. ",
                                    ],
                                },
                            ],
                        },
                    ],
                },
                ISTJ: {
                    code: "ISTJ",
                    name: "Doer",
                    callName: "Conscientious Doer",
                    description:
                        "Strong sense of responsibility, down-to-earth and reliable, pay attention to rules and efficiency, and are used to completing tasks in an orderly manner.",
                    traits: [
                        "rigorous",
                        "pragmatic",
                        "reliable",
                        "traditional",
                    ],
                    overview: [
                        'ISTJ (Introversion, Sensing, Thinking, Judging) is called "prosecutor" or "responsible person". They are down-to-earth, reliable, and detail-oriented. ISTJ\'s philosophy of life is usually step-by-step, conscientious and responsible, and they have high demands on themselves and others. They have a strong sense of responsibility, like to work within clear rules and structures, and advocate stability and efficiency. Due to their more conservative way of thinking, ISTJ is not easy to accept sudden changes and prefers to rely on experience and facts to make decisions.',
                        "ISTJ is a very practical person. They pay more attention to reality and pursue practical achievements rather than empty dreams. Although their emotional expressions are not as exposed as other types, their inner sense of responsibility and loyalty are often particularly strong. ISTJ Once they commit to something, they will do their best to complete it and will not give up easily. ",
                    ],
                    detail: [
                        {
                            title: "Personality",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. Efficient and detail-oriented: ISTJs are good at organizing things efficiently and pay attention to the accuracy of every detail in actual operations. They ensure that every task is completed smoothly through strict standards and meticulous planning. ",
                                        "2. Strong sense of responsibility: ISTJs are very responsible people. They will give 100% of their efforts to their tasks and commitments to ensure that the work is completed on time and with quality. Whether at work or in private life, ISTJs attach great importance to fulfilling their responsibilities. ",
                                        "3. Realists: Unlike some idealists, ISTJs tend to be more pragmatic. They don't like empty theories and unrealistic plans. They rely on experience and facts, and usually follow logic and routine when making decisions, rather than relying on intuition or emotion. ",
                                        "4. Conservative and traditional: ISTJs usually follow the traditional way of doing things and don't like to take risks or make too many innovations. They prefer to follow existing rules and systems, and any changes require sufficient reasons and preparation. ",
                                        "5. Introverted and independent: ISTJs are usually introverted. They like to think and solve problems independently and usually need time alone to restore their energy. In social situations, they are more conservative and not good at expressing emotions, but their loyalty and reliability are very reliable. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Character weaknesses",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. Lack of flexibility: Due to over-reliance on structure and routine, ISTJs sometimes appear inflexible. When faced with a new environment or a situation that requires innovation, they may feel uncomfortable and have difficulty adjusting their thinking quickly. ",
                                        "2. Too stubborn: ISTJs find it difficult to accept change, especially in the absence of sufficient evidence. They prefer to stick to what they think is right, which may make them appear less open and sometimes even stubborn when working in a team. ",
                                        "3. Inadequate emotional expression: Although ISTJs are loyal and reliable, they are often emotionally introverted and not good at expressing their feelings. In intimate relationships, ISTJs may make their partners feel distant and lack sufficient emotional communication. ",
                                        "4. High demands on others: Because they have high standards for themselves, ISTJs tend to be strict with others. They expect others to follow rules and complete tasks efficiently, which may make them appear too strict or intolerant. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Suitable careers",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "ISTJs are usually suitable for careers that require high organization, attention to detail, and clear goals. The following are some career fields suitable for ISTJs:",
                                        "1. Project manager or engineer: ISTJs have strong organizational and executive skills and excel in project management, engineering design, construction, and manufacturing. They are able to effectively manage time and resources and ensure that tasks are completed on time. ",
                                        "2. Accountant or Auditor: Because ISTJs pay attention to details and have strong logical skills, they are very suitable for jobs that require precision and compliance with rules, such as accounting and auditing. These positions require a sensitivity to numbers and a high demand for accuracy, and ISTJs are ideal candidates. ",
                                        "3. Legal Worker or Lawyer: ISTJ's rigorous thinking and sense of responsibility make them excel in the legal industry. They pay attention to details, abide by rules and regulations, and are able to provide precise analysis and solutions in complex legal matters. ",
                                        "4. Military or Law Enforcement Work: ISTJs are very suitable for careers that require discipline and strict organization, such as the military, police, firefighters, etc. In these fields, ISTJs are able to follow procedures, complete tasks, and maintain order. ",
                                        "5. Administration or Human Resources: Because ISTJs are good at managing and organizing things, they can also be at ease in positions such as administration, office management, and human resources to ensure that the workflow runs efficiently. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Love and Partner",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "In love, ISTJs are loyal and reliable partners. They value stability and commitment, and once they are in love, they will devote themselves wholeheartedly to the other party. Although ISTJs are not good at expressing their love through words, they will demonstrate their loyalty through practical actions and sense of responsibility. ",
                                        "ISTJs need a partner who understands and appreciates their introverted and practical side. The ideal partner should be able to provide stable emotional support while giving ISTJs appropriate space. Personality types such as ISFJ, ESFJ, or ENFJ may be more able to understand ISTJ's needs and form a complementary relationship with each other. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Development Suggestions",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. Improve flexibility and adaptability: In order to better adapt to the ever-changing world, ISTJs can try to accept new methods and innovative ways of thinking in work and life. By actively learning new skills or opening their minds, they can better face challenges. ",
                                        "2. Improve emotional communication: In intimate relationships, ISTJs can work to improve their ability to express emotions. Learning how to better communicate and share their feelings with their partners will help them better maintain and develop deep emotional connections. ",
                                        "3. Moderately relax control: Due to their excessive focus on rules and order, ISTJs may sometimes ignore flexibility and changes in life. Moderately relaxing the pursuit of perfection and accepting the reality of imperfection can make it easier for them to move forward in work and life. ",
                                        "4. Improve teamwork spirit: ISTJs can improve communication and collaboration with others by enhancing their sense of teamwork and cultivating more open minds. Understanding other people's ideas and learning to adapt to other people's working methods can enhance their leadership and influence. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "In general, ISTJs are stable, reliable, and efficient individuals who excel in work and life by adhering to principles and a sense of responsibility. By increasing flexibility, improving emotional expression, and the ability to adapt to change, ISTJs can further improve their overall personal qualities and achieve a more fulfilling and satisfying life. ",
                                    ],
                                },
                            ],
                        },
                    ],
                },
                ISTP: {
                    code: "ISTP",
                    name: "Craftsman",
                    callName: "Agile Craftsman",
                    description:
                        "Practical and flexible, good at hands-on problem solving, likes to act independently and explore new methods.",
                    traits: [
                        "Agile",
                        "Pragmatic",
                        "Independent",
                        "Exploration",
                    ],
                    overview: [
                        'ISTP (Introversion, Sensing, Thinking, Perception) is a "craftsman" or "adventurer" who usually looks at the world in an independent, pragmatic and calm way. ISTP likes to do things by themselves and enjoys the sense of accomplishment gained by solving practical problems. Because they tend to rely on facts and experience, they usually have a deeper and more direct understanding of things and have strong practical skills. ISTPs are also keen on adventures, like to challenge themselves, are not afraid of hardships, and have strong adaptability. Although they look low-key and introverted, once faced with a moment of action, ISTPs can show extraordinary courage and decision-making ability.',
                        "ISTP They don't like to be bound, usually pursue freedom and independence, and like to have space to do what they want. Their world is usually full of exploration and experimentation, so they often look for new challenges and opportunities at various stages of life. ",
                    ],
                    detail: [
                        {
                            title: "Personality characteristics",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. Practical and outstanding problem-solving ability: ISTPs are very good at hands-on practice and like to solve problems through practical operations. Whether it is repairing things or optimizing work processes, they can always find effective solutions. ",
                                        "2. Rational and calm: ISTPs can always keep a cool head when facing problems. They make decisions based on rationality and objectivity and are rarely disturbed by emotions. Even under stressful conditions, ISTPs can maintain clear thinking. ",
                                        "3. Independence and freedom: ISTPs usually cherish personal freedom very much, and they don't like to be restrained or restricted. In life, ISTPs prefer to act independently and don't like to rely on others or be managed by others. ",
                                        "4. Strong adaptability: ISTPs are highly adaptable, they can respond to changes quickly and can handle unknown environments with ease. Whether facing emergencies or in unfamiliar environments, they can quickly find their own rhythm and solve problems. ",
                                        "5. Pragmatic and informal: ISTPs are usually pragmatic, pay attention to practical results, and don't like empty talk. They focus on specific results and effects, and usually appear indifferent to things that have no practical significance. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Character weaknesses",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. Too independent and difficult to rely on others: Because ISTPs value their independence so much, they may appear indifferent and unwilling to rely on others when working with others. They may sometimes make people around them feel that they are difficult to get close to. ",
                                        "2. Avoid emotional expression: ISTPs are more conservative in expressing their emotions. They may suppress their emotions and rarely express their feelings actively. When communicating with others, they tend to focus on logic and actions rather than the delicateness of emotions and interpersonal relationships. ",
                                        "3. Impatience: ISTPs tend to lose interest in boring or repetitive tasks. Since they prefer to do challenging and hands-on things, they may appear impatient and even impulsive when faced with boring tasks. ",
                                        "4. Avoiding conflicts and escaping problems: ISTPs tend to avoid conflicts or emotional topics that make them uncomfortable, and may choose to avoid or deal with them coldly, which may lead to unresolved conflicts or problems. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Suitable careers",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "Since ISTPs are highly practical and have problem-solving skills, they are usually suitable for careers that require hands-on work, are challenging, and can be completed independently. Here are some careers suitable for ISTPs:",
                                        "1. Engineers or technical experts: ISTPs have strong technical skills and a talent for problem-solving, and are very suitable for work in engineering, technology, machinery, construction and other fields. They are able to solve practical problems by hands-on and are extremely effective in complex working environments. ",
                                        "2. Surgeons or paramedics: ISTPs are calm, rational, and have the ability to make decisions quickly under pressure, so they are very suitable for surgeons or paramedics in the medical field. In an emergency, ISTPs can quickly determine the condition and take effective action. ",
                                        "3. Pilots or drivers: Because ISTPs have strong operational capabilities and attention to detail, they are suitable for pilots or other professions that require high-precision operations. Pilots' work is both challenging and requires a high sense of responsibility, which is in line with the personality characteristics of ISTPs. ",
                                        "4. Police or agents: ISTPs like adventure, have strong adaptability and practical operation capabilities, and are very suitable for professions such as police and agents. In these professions, ISTPs can give full play to their decision-making and action capabilities. ",
                                        "5. Athletes or coaches: ISTPs have strong physical coordination and enthusiasm for challenges, so they are also very suitable for sports-related professions. Whether athletes or coaches, ISTPs can show excellent performance in the field of sports. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Love and Partner",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "In love, ISTPs tend to remain independent and free, but they can also provide stability and support to their partners. Due to their introverted personality, ISTPs are not good at expressing their feelings through words, but they will show their care and love for their partners through practical actions. ISTPs usually need someone who can understand and respect their independence in a relationship. ",
                                        "The ideal partner should be able to give ISTPs enough freedom and also enjoy the fun of adventure and exploration with them. Partners with personality types such as ESFJ and ISFJ may find it easier to establish a deep relationship with ISTPs because they can understand ISTPs' needs and provide more emotional support in life. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "Development Suggestions",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. Learn to express emotions better: Although ISTPs like to express care through actions, proper emotional communication helps deepen relationships with others. By learning to better express their inner feelings, ISTPs can build deeper interpersonal relationships, especially in intimate relationships. ",
                                        "2. Improve patience and endurance: ISTPs may lack patience when faced with tedious tasks. Learning how to deal with seemingly boring work and improving patience will help them be more successful in work and life. ",
                                        "3. Accept more cooperation and help: Although ISTPs like to act independently, learning to rely on others and accept help when appropriate will help them complete tasks better and avoid stress caused by excessive isolation. ",
                                        "4. Enhance emotional processing ability: ISTPs sometimes avoid conflicts and emotional problems. Properly handling emotions and conflicts is an important part of their personal growth. By learning how to face and solve emotional problems, ISTPs can build more mature interpersonal relationships. ",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "In summary, ISTPs are very practical, calm, rational, and adventurous people. They are efficient problem solvers at work and perform well under pressure and challenges. By improving their emotional expression, increasing their patience, and learning to adapt to the help of others, ISTPs can better develop their professional and personal relationships and live a more fulfilling life. ",
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
        },
    },
    result: {
        title: "Your MBTI Personality Type",
        meta: {
            title: "MBTI Personality Test Result - Understand Your Personality Type",
            description:
                "Take the MBTI personality test with 93 carefully designed questions to scientifically assess your personality type. The test is completely free and helps you better understand yourself.",
            keywords:
                "MBTI test,personality test,online test,free test,personality assessment,psychological test,MBTI questionnaire",
        },
        traitAnalysis: "Trait Analysis",
        traitsDescription: "Personality Traits",
        buttons: {
            viewDetail: "View Details",
        },
        traits: {
            E: {
                name: "Extrovert",
                code: "E",
            },
            I: {
                name: "Introvert",
                code: "I",
            },
            S: {
                name: "Sensing",
                code: "S",
            },
            N: {
                name: "Intuitive",
                code: "N",
            },
            T: {
                name: "Thinking",
                code: "T",
            },
            F: {
                name: "Feeling",
                code: "F",
            },
            J: {
                name: "Judging",
                code: "J",
            },
            P: {
                name: "Perceiving",
                code: "P",
            },
        },
    },
    test: {
        title: "MBTI Personality Test",
        meta: {
            title: "MBTI Personality Test Questionnaire - Professional Personality Assessment",
            description:
                "Take the professional MBTI personality test with 93 carefully designed questions to scientifically assess your personality type. The test is completely free and helps you better understand yourself.",
            keywords:
                "MBTI test,personality test,online test,free test,personality assessment,psychological test,MBTI questionnaire",
        },
        questions: [
            {
                question: "When you are going out for the whole day, you will",
                options: [
                    {
                        value: "J",
                        text: "Plan what you will do and when to do it",
                    },
                    {
                        value: "P",
                        text: "Just go",
                    },
                ],
            },
            {
                question: "Do you think you are a",
                options: [
                    {
                        value: "P",
                        text: "More spontaneous",
                    },
                    {
                        value: "J",
                        text: "More organized",
                    },
                ],
            },
            {
                question: "If you were a teacher, what would you teach",
                options: [
                    {
                        value: "S",
                        text: "Fact-based courses",
                    },
                    {
                        value: "N",
                        text: "Theory-based courses",
                    },
                ],
            },
            {
                question: "You usually",
                options: [
                    {
                        value: "E",
                        text: "Getting along easily",
                    },
                    {
                        value: "I",
                        text: "Quiet or reserved",
                    },
                ],
            },
            {
                question:
                    "Generally speaking, who do you get along with better?",
                options: [
                    {
                        value: "N",
                        text: "Imaginative people",
                    },
                    {
                        value: "S",
                        text: "Realistic people",
                    },
                ],
            },
            {
                question: "Do you often let",
                options: [
                    {
                        value: "F",
                        text: "Your emotions dominate your reason",
                    },
                    {
                        value: "T",
                        text: "Your reason dominates your emotions",
                    },
                ],
            },
            {
                question: "You like to deal with many things",
                options: [
                    {
                        value: "P",
                        text: "Act on impulse",
                    },
                    {
                        value: "J",
                        text: "Act according to plan",
                    },
                ],
            },
            {
                question: "Do you",
                options: [
                    {
                        value: "E",
                        text: "Easy to understand",
                    },
                    {
                        value: "I",
                        text: "Difficult to understand",
                    },
                ],
            },
            {
                question: "Follow a schedule",
                options: [
                    {
                        value: "J",
                        text: "Suitable to you",
                    },
                    {
                        value: "P",
                        text: "Feels constrained",
                    },
                ],
            },
            {
                question: "When you have a special task, you like it",
                options: [
                    {
                        value: "J",
                        text: "Carefully organize the plan before starting",
                    },
                    {
                        value: "P",
                        text: "Find what to do while doing it",
                    },
                ],
            },
            {
                question: "In most cases, you will choose",
                options: [
                    {
                        value: "P",
                        text: "Let it go",
                    },
                    {
                        value: "J",
                        text: "Follow a schedule",
                    },
                ],
            },
            {
                question: "Most people would say you are a",
                options: [
                    {
                        value: "I",
                        text: "People who value their privacy",
                    },
                    {
                        value: "E",
                        text: "People who are very frank and open",
                    },
                ],
            },
            {
                question: "You would rather be considered a",
                options: [
                    {
                        value: "S",
                        text: "People who seek truth from facts",
                    },
                    {
                        value: "N",
                        text: "Smart people",
                    },
                ],
            },
            {
                question: "In a large group of people, usually",
                options: [
                    {
                        value: "E",
                        text: "You introduce everyone to each other",
                    },
                    {
                        value: "I",
                        text: "People introduce you",
                    },
                ],
            },
            {
                question: "Who would you be friends with? ",
                options: [
                    {
                        value: "N",
                        text: "Often come up with new ideas",
                    },
                    {
                        value: "S",
                        text: "Down to earth",
                    },
                ],
            },
            {
                question: "You tend to",
                options: [
                    {
                        value: "F",
                        text: "Value feelings more than logic",
                    },
                    {
                        value: "T",
                        text: "Value logic more than feelings",
                    },
                ],
            },
            {
                question: "You prefer",
                options: [
                    {
                        value: "P",
                        text: "Wait and see how things develop before making plans",
                    },
                    {
                        value: "J",
                        text: "Make plans very early",
                    },
                ],
            },
            {
                question: "You like to spend a lot of time",
                options: [
                    {
                        value: "I",
                        text: "Be alone",
                    },
                    {
                        value: "E",
                        text: "Being with others",
                    },
                ],
            },
            {
                question: "Meeting with many people",
                options: [
                    {
                        value: "E",
                        text: "It makes you more energetic",
                    },
                    {
                        value: "I",
                        text: "It often makes you exhausted",
                    },
                ],
            },
            {
                question: "You prefer",
                options: [
                    {
                        value: "J",
                        text: "Arrange appointments, social gatherings and other things early",
                    },
                    {
                        value: "P",
                        text: "Be free and do whatever is fun at the time",
                    },
                ],
            },
            {
                question: "When planning a trip, you prefer",
                options: [
                    {
                        value: "P",
                        text: "Most of the time, I act according to my feelings on the day",
                    },
                    {
                        value: "J",
                        text: "Know what I will do most of the time in advance",
                    },
                ],
            },
            {
                question: "In social gatherings, you",
                options: [
                    {
                        value: "I",
                        text: "Sometimes feel depressed",
                    },
                    {
                        value: "E",
                        text: "Often enjoy it",
                    },
                ],
            },
            {
                question: "You usually",
                options: [
                    {
                        value: "E",
                        text: "Easily get along with others",
                    },
                    {
                        value: "I",
                        text: "Tend to be alone",
                    },
                ],
            },
            {
                question: "Which people are more attractive to you?",
                options: [
                    {
                        value: "N",
                        text: "a person with quick thinking and very smart",
                    },
                    {
                        value: "S",
                        text: "a person who is pragmatic and has rich common sense",
                    },
                ],
            },
            {
                question: "in daily work, you will",
                options: [
                    {
                        value: "P",
                        text: "prefer to deal with emergencies that force you to race against time",
                    },
                    {
                        value: "J",
                        text: "usually plan ahead to avoid working under pressure",
                    },
                ],
            },
            {
                question: "you think others are average",
                options: [
                    {
                        value: "I",
                        text: "it takes a long time to get to know you",
                    },
                    {
                        value: "E",
                        text: "it takes a short time to get to know you",
                    },
                ],
            },
            {
                question:
                    "in each of the following pairs of words, which word is more suitable for you? Please think carefully about the meaning of these words, regardless of their shape or pronunciation.",
                options: [
                    {
                        value: "I",
                        text: "privacy",
                    },
                    {
                        value: "E",
                        text: "open and frank",
                    },
                ],
            },
            {
                question:
                    "Which word do you prefer in each of the following pairs? Think carefully about the meaning of these words, regardless of their shape or pronunciation.",
                options: [
                    {
                        value: "J",
                        text: "pre-arranged",
                    },
                    {
                        value: "P",
                        text: "unplanned",
                    },
                ],
            },
            {
                question:
                    "Which word do you prefer in each of the following pairs? Think carefully about the meaning of these words, regardless of their shape or pronunciation.",
                options: [
                    {
                        value: "N",
                        text: "abstract",
                    },
                    {
                        value: "S",
                        text: "concrete",
                    },
                ],
            },
            {
                question:
                    "Which word is more suitable for you in each of the following pairs of words? Please think carefully about the meaning of these words, regardless of their shape or pronunciation.",
                options: [
                    {
                        value: "F",
                        text: "gentle",
                    },
                    {
                        value: "T",
                        text: "firm",
                    },
                ],
            },
            {
                question:
                    "Which word is more suitable for you in each of the following pairs of words? Please think carefully about the meaning of these words, regardless of their shape or pronunciation.",
                options: [
                    {
                        value: "T",
                        text: "thinking",
                    },
                    {
                        value: "F",
                        text: "feeling",
                    },
                ],
            },
            {
                question:
                    "Which word is more suitable for you in each of the following pairs of words? Please think carefully about the meaning of these words, regardless of their shape or pronunciation.",
                options: [
                    {
                        value: "S",
                        text: "fact",
                    },
                    {
                        value: "N",
                        text: "concept",
                    },
                ],
            },
            {
                question:
                    "Which word in each of the following pairs of words is more to your liking? Please think carefully about the meaning of these words, regardless of their shape or pronunciation.",
                options: [
                    {
                        value: "P",
                        text: "impulse",
                    },
                    {
                        value: "J",
                        text: "decision",
                    },
                ],
            },
            {
                question:
                    "Which word in each of the following pairs of words is more to your liking? Please think carefully about the meaning of these words, regardless of their shape or pronunciation.",
                options: [
                    {
                        value: "E",
                        text: "enthusiastic",
                    },
                    {
                        value: "I",
                        text: "quiet",
                    },
                ],
            },
            {
                question:
                    "Which word in each of the following pairs of words is more to your liking? Please think carefully about the meaning of these words, regardless of their shape or pronunciation.",
                options: [
                    {
                        value: "E",
                        text: "Quiet",
                    },
                    {
                        value: "I",
                        text: "Outgoing",
                    },
                ],
            },
            {
                question:
                    "In each of the following pairs of words, which one is more suitable for you? Please think carefully about the meaning of these words, regardless of their shape or pronunciation. ",
                options: [
                    {
                        value: "J",
                        text: "Systematic",
                    },
                    {
                        value: "P",
                        text: "Casual",
                    },
                ],
            },
            {
                question:
                    "In each of the following pairs of words, which one is more suitable for you? Please think carefully about the meaning of these words, regardless of their shape or pronunciation. ",
                options: [
                    {
                        value: "N",
                        text: "theory",
                    },
                    {
                        value: "S",
                        text: "affirmation",
                    },
                ],
            },
            {
                question:
                    "Which word in each of the following pairs of words is more to your liking? Please think carefully about the meaning of these words, regardless of their shape or pronunciation. ",
                options: [
                    {
                        value: "F",
                        text: "sensitive",
                    },
                    {
                        value: "T",
                        text: "fairness",
                    },
                ],
            },
            {
                question:
                    "Which word in each of the following pairs of words is more to your liking? Please think carefully about the meaning of these words, regardless of their shape or pronunciation. ",
                options: [
                    {
                        value: "T",
                        text: "convincing",
                    },
                    {
                        value: "F",
                        text: "affecting",
                    },
                ],
            },
            {
                question:
                    "Which word in each of the following pairs of words is more to your liking? Please think carefully about the meaning of these words, regardless of their shape or pronunciation. ",
                options: [
                    {
                        value: "S",
                        text: "statement",
                    },
                    {
                        value: "N",
                        text: "concept",
                    },
                ],
            },
            {
                question:
                    "Which word in each of the following pairs of words is more to your liking? Please think carefully about the meaning of these words, regardless of their shape or pronunciation. ",
                options: [
                    {
                        value: "P",
                        text: "Unconstrained",
                    },
                    {
                        value: "J",
                        text: "Prearranged",
                    },
                ],
            },
            {
                question:
                    "Which word is more suitable for you in each of the following pairs of words? Please think carefully about the meaning of these words, regardless of their shape or pronunciation. ",
                options: [
                    {
                        value: "I",
                        text: "Reserved",
                    },
                    {
                        value: "E",
                        text: "Talkative",
                    },
                ],
            },
            {
                question:
                    "Which word is more suitable for you in each of the following pairs of words? Please think carefully about the meaning of these words, regardless of their shape or pronunciation. ",
                options: [
                    {
                        value: "J",
                        text: "Organized",
                    },
                    {
                        value: "P",
                        text: "Not particular",
                    },
                ],
            },
            {
                question:
                    "Which of the following pairs of words do you prefer? Please think carefully about the meaning of these words, regardless of their shape or pronunciation. ",
                options: [
                    {
                        value: "N",
                        text: "Idea",
                    },
                    {
                        value: "S",
                        text: "Live",
                    },
                ],
            },
            {
                question:
                    "Which word in each of the following pairs of words is more to your liking? Please think carefully about the meaning of these words, regardless of their shape or pronunciation.",
                options: [
                    {
                        value: "F",
                        text: "Sympathy",
                    },
                    {
                        value: "T",
                        text: "Vision",
                    },
                ],
            },
            {
                question:
                    "Which word in each of the following pairs of words is more to your liking? Please think carefully about the meaning of these words, regardless of their shape or pronunciation.",
                options: [
                    {
                        value: "T",
                        text: "Benefit",
                    },
                    {
                        value: "F",
                        text: "Blessing",
                    },
                ],
            },
            {
                question:
                    "Which word in each of the following pairs of words is more to your liking? Please think carefully about the meaning of these words, regardless of their shape or pronunciation.",
                options: [
                    {
                        value: "S",
                        text: "Pragmatic",
                    },
                    {
                        value: "N",
                        text: "Theoretical",
                    },
                ],
            },
            {
                question:
                    "Which word is more suitable for you in each of the following pairs of words? Please think carefully about the meaning of these words, regardless of their shape or pronunciation.",
                options: [
                    {
                        value: "I",
                        text: "Not many friends",
                    },
                    {
                        value: "E",
                        text: "Many friends",
                    },
                ],
            },
            {
                question:
                    "Which word is more suitable for you in each of the following pairs of words? Please think carefully about the meaning of these words, regardless of their shape or pronunciation.",
                options: [
                    {
                        value: "J",
                        text: "Systematic",
                    },
                    {
                        value: "P",
                        text: "Impromptu",
                    },
                ],
            },
            {
                question:
                    "Which word is more suitable for you in each of the following pairs of words? Please think carefully about the meaning of these words, regardless of their shape or pronunciation. ",
                options: [
                    {
                        value: "N",
                        text: "Imaginative",
                    },
                    {
                        value: "S",
                        text: "Factual",
                    },
                ],
            },
            {
                question:
                    "In each of the following pairs of words, which one is more in line with your mind? Please think carefully about the meaning of these words, regardless of their shape or pronunciation. ",
                options: [
                    {
                        value: "F",
                        text: "Intimately",
                    },
                    {
                        value: "T",
                        text: "Objectively",
                    },
                ],
            },
            {
                question:
                    "In each of the following pairs of words, which one is more in line with your mind? Please think carefully about the meaning of these words, regardless of their shape or pronunciation. ",
                options: [
                    {
                        value: "T",
                        text: "objectively",
                    },
                    {
                        value: "F",
                        text: "passionately",
                    },
                ],
            },
            {
                question:
                    "Which word is more suitable for you in each of the following pairs of words? Please think carefully about the meaning of these words, regardless of their shape or pronunciation. ",
                options: [
                    {
                        value: "S",
                        text: "build",
                    },
                    {
                        value: "N",
                        text: "invent",
                    },
                ],
            },
            {
                question:
                    "Which word is more suitable for you in each of the following pairs of words? Please think carefully about the meaning of these words, regardless of their shape or pronunciation. ",
                options: [
                    {
                        value: "I",
                        text: "Quiet",
                    },
                    {
                        value: "E",
                        text: "Social",
                    },
                ],
            },
            {
                question:
                    "Which word do you prefer in each of the following pairs of words? Please think carefully about the meaning of these words, regardless of their shape or pronunciation. ",
                options: [
                    {
                        value: "N",
                        text: "Theory",
                    },
                    {
                        value: "S",
                        text: "Fact",
                    },
                ],
            },
            {
                question:
                    "Which word do you prefer in each of the following pairs of words? Please think carefully about the meaning of these words, regardless of their shape or pronunciation. ",
                options: [
                    {
                        value: "F",
                        text: "sympathetic",
                    },
                    {
                        value: "T",
                        text: "logical",
                    },
                ],
            },
            {
                question:
                    "Which word in each of the following pairs of words is more suitable for you? Please think carefully about the meaning of these words, regardless of their shape or pronunciation. ",
                options: [
                    {
                        value: "T",
                        text: "analytical",
                    },
                    {
                        value: "F",
                        text: "sentimental",
                    },
                ],
            },
            {
                question:
                    "Which word in each of the following pairs of words is more suitable for you? Please think carefully about the meaning of these words, regardless of their shape or pronunciation. ",
                options: [
                    {
                        value: "S",
                        text: "Reasonable",
                    },
                    {
                        value: "N",
                        text: "Fascinating",
                    },
                ],
            },
            {
                question:
                    "When you have to complete a large project within a week, you will start",
                options: [
                    {
                        value: "J",
                        text: "List the different tasks to be done in order",
                    },
                    {
                        value: "P",
                        text: "Start working immediately",
                    },
                ],
            },
            {
                question: "In social situations, you often feel",
                options: [
                    {
                        value: "I",
                        text: "It is difficult to start and maintain a conversation with some people",
                    },
                    {
                        value: "E",
                        text: "You can have a long conversation with most people calmly",
                    },
                ],
            },
            {
                question: "Do you prefer to do what many people do",
                options: [
                    {
                        value: "S",
                        text: "Do things in a generally accepted way",
                    },
                    {
                        value: "N",
                        text: "Develop an idea of ​​your own",
                    },
                ],
            },
            {
                question:
                    "Can a friend you just met tell you about your interests?",
                options: [
                    {
                        value: "E",
                        text: "Right away",
                    },
                    {
                        value: "I",
                        text: "Only after they really get to know you",
                    },
                ],
            },
            {
                question: "What subjects do you usually like more",
                options: [
                    {
                        value: "N",
                        text: "Teaching concepts and principles",
                    },
                    {
                        value: "S",
                        text: "Teaching facts and data",
                    },
                ],
            },
            {
                question: "Which is a higher praise or commendation?",
                options: [
                    {
                        value: "F",
                        text: "A person who is always emotional",
                    },
                    {
                        value: "T",
                        text: "A person who is always rational",
                    },
                ],
            },
            {
                question: "Do you think following a schedule",
                options: [
                    {
                        value: "P",
                        text: "Sometimes it is necessary, but generally you don't like to do it",
                    },
                    {
                        value: "J",
                        text: "In most cases it is helpful and you like to do it",
                    },
                ],
            },
            {
                question:
                    "When you are with a group of people, you usually choose",
                options: [
                    {
                        value: "I",
                        text: "Talk to individuals you are very familiar with",
                    },
                    {
                        value: "E",
                        text: "Join the conversation of the group",
                    },
                ],
            },
            {
                question: "At social gatherings, you will",
                options: [
                    {
                        value: "E",
                        text: "The one who talks a lot",
                    },
                    {
                        value: "I",
                        text: "Let others talk more",
                    },
                ],
            },
            {
                question:
                    "The idea of ​​making a list of things to accomplish during the weekend will",
                options: [
                    {
                        value: "J",
                        text: "Please your taste",
                    },
                    {
                        value: "P",
                        text: "Make you feel depressed",
                    },
                ],
            },
            {
                question: "Which is a higher praise, or compliment",
                options: [
                    {
                        value: "T",
                        text: "Competent",
                    },
                    {
                        value: "F",
                        text: "Compassionate",
                    },
                ],
            },
            {
                question: "You usually like",
                options: [
                    {
                        value: "J",
                        text: "Plan your social appointments in advance",
                    },
                    {
                        value: "P",
                        text: "Do things on a whim",
                    },
                ],
            },
            {
                question:
                    "In general, when doing a large assignment, you would choose",
                options: [
                    {
                        value: "P",
                        text: "Think about what to do while doing it",
                    },
                    {
                        value: "J",
                        text: "Break the work into steps first",
                    },
                ],
            },
            {
                question: "Can you chat with people at will",
                options: [
                    {
                        value: "I",
                        text: "Only with people who share your interests",
                    },
                    {
                        value: "E",
                        text: "Almost anyone",
                    },
                ],
            },
            {
                question: "You would",
                options: [
                    {
                        value: "S",
                        text: "Follow some proven methods",
                    },
                    {
                        value: "N",
                        text: "Almost anyone",
                    },
                ],
            },
            {
                question: "When reading for pleasure, you would",
                options: [
                    {
                        value: "N",
                        text: "Likes unusual or innovative expressions",
                    },
                    {
                        value: "S",
                        text: "Likes authors to speak directly",
                    },
                ],
            },
            {
                question:
                    "Which type of boss (or teacher) would you rather work for? ",
                options: [
                    {
                        value: "T",
                        text: "Good natured, but often inconsistent",
                    },
                    {
                        value: "N",
                        text: "Sharp words but always logical",
                    },
                ],
            },
            {
                question: "You do things mostly",
                options: [
                    {
                        value: "P",
                        text: "According to the mood of the day",
                    },
                    {
                        value: "J",
                        text: "Follow the prepared schedule",
                    },
                ],
            },
            {
                question: "Do you",
                options: [
                    {
                        value: "E",
                        text: "Can talk calmly with anyone as needed",
                    },
                    {
                        value: "I",
                        text: "Only to certain people or in certain situations can you speak freely",
                    },
                ],
            },
            {
                question:
                    "When making a decision, what do you think is more important",
                options: [
                    {
                        value: "T",
                        text: "Measure by facts",
                    },
                    {
                        value: "F",
                        text: "Consider other people's feelings and opinions",
                    },
                ],
            },
            {
                question:
                    "Which of the following pairs of words do you prefer?",
                options: [
                    {
                        value: "N",
                        text: "Imaginary",
                    },
                    {
                        value: "S",
                        text: "Real",
                    },
                ],
            },
            {
                question:
                    "Which word do you prefer in each of the following pairs of words?",
                options: [
                    {
                        value: "F",
                        text: "Kind and generous",
                    },
                    {
                        value: "T",
                        text: "Determined",
                    },
                ],
            },
            {
                question:
                    "Which word do you prefer in each of the following pairs of words?",
                options: [
                    {
                        value: "T",
                        text: "Fair",
                    },
                    {
                        value: "F",
                        text: "Caring",
                    },
                ],
            },
            {
                question:
                    "Which word do you prefer in each of the following pairs of words?",
                options: [
                    {
                        value: "S",
                        text: "Made",
                    },
                    {
                        value: "N",
                        text: "Design",
                    },
                ],
            },
            {
                question:
                    "Which word do you prefer in each of the following pairs of words?",
                options: [
                    {
                        value: "N",
                        text: "Possibility",
                    },
                    {
                        value: "S",
                        text: "Necessity",
                    },
                ],
            },
            {
                question:
                    "In each of the following pairs of words, which word is more to your liking?",
                options: [
                    {
                        value: "F",
                        text: "Gentleness",
                    },
                    {
                        value: "T",
                        text: "Power",
                    },
                ],
            },
            {
                question:
                    "In each of the following pairs of words, which word is more to your liking?",
                options: [
                    {
                        value: "T",
                        text: "Realistic",
                    },
                    {
                        value: "F",
                        text: "Sentimentality",
                    },
                ],
            },
            {
                question:
                    "In each of the following pairs of words, which word is more to your liking?",
                options: [
                    {
                        value: "S",
                        text: "manufacture",
                    },
                    {
                        value: "N",
                        text: "create",
                    },
                ],
            },
            {
                question:
                    "In each of the following pairs of words, which word is more in line with your mind? ",
                options: [
                    {
                        value: "N",
                        text: "novel",
                    },
                    {
                        value: "S",
                        text: "known",
                    },
                ],
            },
            {
                question:
                    "In each of the following pairs of words, which word is more in line with your mind? ",
                options: [
                    {
                        value: "F",
                        text: "sympathize",
                    },
                    {
                        value: "T",
                        text: "analyze",
                    },
                ],
            },
            {
                question:
                    "In each of the following pairs of words, which word is more in line with your mind? ",
                options: [
                    {
                        value: "T",
                        text: "Persistent",
                    },
                    {
                        value: "F",
                        text: "Tender and loving",
                    },
                ],
            },
            {
                question:
                    "In each of the following pairs of words, which word is more in line with your mind? ",
                options: [
                    {
                        value: "S",
                        text: "Specific",
                    },
                    {
                        value: "N",
                        text: "Abstract",
                    },
                ],
            },
            {
                question:
                    "In each of the following pairs of words, which word is more in line with your mind? ",
                options: [
                    {
                        value: "F",
                        text: "Dedicated",
                    },
                    {
                        value: "T",
                        text: "Determined",
                    },
                ],
            },
            {
                question:
                    "In each of the following pairs of words, which word is more in line with your mind? ",
                options: [
                    {
                        value: "T",
                        text: "Capable",
                    },
                    {
                        value: "F",
                        text: "Kind",
                    },
                ],
            },
            {
                question:
                    "Which of the following pairs of words do you prefer?",
                options: [
                    {
                        value: "S",
                        text: "Practical",
                    },
                    {
                        value: "N",
                        text: "Innovative",
                    },
                ],
            },
        ],
    },
};
