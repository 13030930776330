export default {
    index: {
        title: "探索你的 MBTI 性格类型",
        subtitle: "通过科学的性格测试，发现真实的自己",
        tips: {
            list: [
                "MBTI 测试基于荣格的心理类型理论，帮助您了解自己的性格类型和行为偏好。",
                "MBTI 类型没有好坏之分，每种类型都有其独特的优势，不要将 MBTI 类型作为判断他人的唯一标准。",
                "性格类型可能会随时间和环境略有变化。",
                "性格分析展示的是性格倾向，测试结果仅供参考，重要的是加深对自己的了解。",
                "测试共 93 题，题目没有对错之分，请根据自己的实际情况选择，才能得到有效的结果。",
            ],
        },
        buttons: {
            whatIsMBTI: "什么是 MBTI",
            mbtiTypes: "MBTI 性格类型",
        },
        meta: {
            title: "免费 MBTI 性格测试 - 探索你的性格类型",
            description:
                "通过科学的 MBTI 性格测试，免费探索和了解你的性格类型。提供93道专业测试题目，帮助你更好地认识自己。",
            keywords:
                "MBTI,免费MBTI测试,性格测试,免费测试,人格测试,性格分析,职业性格,心理测试",
        },
    },
    introduction: {
        meta: {
            title: "什么是 MBTI 性格测试？MBTI 性格类型详细介绍",
            description:
                "MBTI 性格测试是一种科学的性格类型指标工具，通过外向/内向、感觉/直觉、思考/情感、判断/感知四个维度来描述人的性格特征。了解 MBTI 可以帮助职业发展、改善人际关系、优化团队合作。",
            keywords:
                "MBTI,免费MBTI测试,性格测试,MBTI介绍,性格类型,Myers-Briggs,人格分析,心理测试,16型人格,免费测试",
        },
        title: "MBTI 性格类型简介",
        whatIsMBTI: {
            title: "什么是 MBTI",
            content:
                "MBTI（Myers-Briggs Type Indicator）是一种性格类型指标，由卡特琳·布里格斯和伊莎贝尔·迈尔斯·布里格斯根据荣格的性格类型理论开发。它通过四个维度来描述人的性格特征：",
        },
        dimensions: {
            title: "四个维度",
            energySource: {
                title: "能量来源 (E/I)",
                extroversion: {
                    title: "外向（Extroversion, E）",
                    description: "倾向于从外部世界获取能量，更喜欢与他人互动",
                },
                introversion: {
                    title: "内向（Introversion, I）",
                    description: "倾向于从内心世界获取能量，更喜欢独处和反思",
                },
            },
            infoGet: {
                title: "信息获取 (S/N)",
                sensing: {
                    title: "感觉（Sensing, S）",
                    description: "倾向于关注现实和具体细节，注重事实和实践",
                },
                intuition: {
                    title: "直觉（Intuition, N）",
                    description: "倾向于关注全局和抽象概念，注重可能性和创新",
                },
            },
            decision: {
                title: "决策方式 (T/F)",
                thinking: {
                    title: "思考（Thinking, T）",
                    description:
                        "倾向于通过逻辑和客观分析做决策，注重公平性和原则",
                },
                feeling: {
                    title: "情感（Feeling, F）",
                    description:
                        "倾向于通过价值观和感情做决策，注重人际关系和和谐",
                },
            },
            lifestyle: {
                title: "生活方式 (J/P)",
                judging: {
                    title: "判断（Judging, J）",
                    description:
                        "倾向于有计划、有组织地生活，喜欢明确的结构和目标",
                },
                perceiving: {
                    title: "感知（Perceiving, P）",
                    description: "倾向于灵活、随性地生活，喜欢保持开放的选择",
                },
            },
        },
        applications: {
            title: "MBTI 的应用",
            intro: "MBTI 测试可以帮助我们更好地了解自己和他人，在以下方面特别有帮助：",
            items: [
                "职业规划：帮助个人了解自己的性格优势，选择适合的职业方向，提高职业满意度。",
                "团队管理：企业可以利用 MBTI 识别员工的性格类型，优化团队合作，提高沟通效率。",
                "人际关系：通过了解不同性格类型的相处方式，改善人际关系，增进沟通理解。",
                "个人成长：帮助个人认清自己的优势和弱点，制定更符合自身特点的发展策略。",
                "教育与学习：教师可以根据学生的性格类型调整教学方法，提高学习效果。",
                "领导力发展：帮助领导者了解自己的管理风格，并调整策略以适应不同团队成员。",
                "心理健康：MBTI 可以作为自我认知的一种工具，帮助人们更好地理解自己的情绪和行为模式。",
            ],
        },
        notes: {
            title: "注意事项",
            items: [
                "测试结果并非绝对：MBTI 仅提供性格倾向，个性是动态变化的，不能完全固定在某种类型。",
                "避免标签化：测试结果不应被用作限制自己或他人的依据，每个人都有独特的成长空间。",
                "真实回答：尽量根据自己的真实想法回答问题，而不是迎合社会期望或理想形象。",
                "定期测试：由于个性可能随经历和环境变化，建议定期测试以了解自身变化。",
                "文化影响：不同文化背景可能影响测试结果，因此应结合自身经历和社会环境进行分析。",
                "结合实际应用：MBTI 结果可作为参考工具，但不应被用于严格的职业筛选或重大决策。",
            ],
        },
    },
    detail: {
        overview: "概述",
        meta: {
            titleTemplate: "{code} {name} - MBTI性格类型详细分析",
            defaultTitle: "MBTI 性格类型详情",
            defaultDescription: "MBTI 性格类型详细介绍和分析",
            descriptionTemplate: "{code} ({name}) 性格类型详细分析。{overview}",
            defaultKeywords: "MBTI,性格类型,性格分析",
            keywordsTemplate:
                "{code},{name},{traits},MBTI,性格类型,性格分析,职业发展,人际关系",
        },
    },
    types: {
        title: "MBTI 性格类型",
        meta: {
            title: "MBTI 16种性格类型详细介绍与分析",
            description:
                "详细了解 MBTI 的16种性格类型，包括分析型、外交型、守卫型和探索型人格的特点、优势及发展方向。",
            keywords:
                "MBTI,性格类型,16型人格,MBTI分类,性格分析,人格测试,心理测试",
        },
        groups: {
            analytical: "分析型人格 (NT)",
            diplomatic: "外交型人格 (NF)",
            guardian: "守卫型人格 (SJ)",
            explorer: "探索型人格 (SP)",
        },
        types: {
            analyticalTypes: ["INTJ", "INTP", "ENTJ", "ENTP"],
            diplomaticTypes: ["INFJ", "INFP", "ENFJ", "ENFP"],
            guardianTypes: ["ISTJ", "ISFJ", "ESTJ", "ESFJ"],
            explorerTypes: ["ISTP", "ISFP", "ESTP", "ESFP"],
            typeMap: {
                ENFJ: {
                    code: "ENFJ",
                    name: "主人公",
                    callName: "热情的导师",
                    description:
                        "充满热情和感染力，擅长激励他人实现目标，具有强烈的责任感。",
                    traits: ["领导力", "共情", "热情", "协调"],
                    overview:
                        'ENFJ（外向、直觉、情感、判断）通常被称为"导师"或"教师"，是极具魅力和感染力的人。他们天生具有领导潜质，善于激励和影响他人，并且以帮助别人实现潜力为己任。ENFJ 是外向型人格，喜欢与他人互动，建立深厚的关系。他们不仅关注他人的需求，还能敏锐地察觉他人的情感和需求，因此常常在人际关系中起到桥梁作用。ENFJ 重视与他人的深层次联系，尤其在团队合作和领导环境中，能够发挥巨大的影响力。',
                    detail: [
                        {
                            title: "性格特点",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1.  富有同理心与理解力：ENFJ 通常是非常同情他人的人，他们对他人的情感和需求有敏锐的洞察力，能够理解他人的动机和行为。他们不仅关心他人的感受，还愿意为他人提供支持和帮助，尤其是在他人遇到困境时。",
                                        "2.  充满感染力的领导者：ENFJ 天生是领导者，能够在任何团队中发挥积极作用。他们通过鼓励、激励和组织他人，带领团队实现共同的目标。ENFJ 擅长调动团队的热情和合作精神，能够迅速凝聚人心。",
                                        "3.  理想主义与高尚的价值观：ENFJ 通常非常理想主义，怀抱着让世界变得更美好的愿景。他们倾向于通过自己为他人带来正面改变，表达他们对社会、社区或人类的关怀。",
                                        "4.  善于沟通与协调：ENFJ 具备出色的沟通技巧，他们能够清晰地表达自己的想法，并且倾听他人的声音。他们在团队中通常充当协调者和调解者，确保每个人都能顺畅地沟通并共同努力。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "性格缺点",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1.  过度关注他人：由于 ENFJ 天生关心他人，他们有时可能会忽视自己的需求和感受，过度投入于他人的问题，导致自己的精力和情感被消耗殆尽。在长时间关注他人后，ENFJ 可能会感到自己被忽视或被负担压垮。",
                                        "2.  过度理想化：ENFJ 可能会陷入过于理想化的思维，期待他人按照他们的期望去行动或改变。当现实与理想之间的差距过大时，ENFJ 可能会感到失望或沮丧。",
                                        "3.  难以面对冲突：ENFJ 非常注重人际和谐，因此他们往往避免冲突。他们可能会选择回避直接面对不和或问题，尽管这种行为可能会导致问题的积累，最终难以解决。",
                                        "4.  自我牺牲：ENFJ 常常为了他人而牺牲自己的时间和情感健康，尤其是在关心他人时，他们可能会忽略自己。长期这样可能导致精疲力竭和情感疲劳。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "适合的职业",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "ENFJ 的领导才能、同理心和沟通技巧使得他们在许多职业中都能大放异彩。以下是适合 ENFJ 的一些职业领域：",
                                        "1.  教育与培训：ENFJ 天生是教育者，他们善于激励和帮助他人发展潜力。教师、培训师、顾问等职业可以发挥他们的优势。",
                                        "2.  社会工作与心理咨询：ENFJ 关心他人的福祉，社会工作、心理咨询等职业能够让他们帮助别人克服困难、提升生活质量。",
                                        "3.  企业管理与领导职位：ENFJ 具备出色的领导力和组织能力，适合担任管理职务，尤其是那些需要人际互动和团队协作的角色，如项目经理、团队领导等。",
                                        "4.  非营利组织与慈善事业：ENFJ 在非营利组织中能够得到极大的满足感，帮助弱势群体、推动社会变革和实现自己的理想主义愿景。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "爱情与伴侣",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "在爱情中，ENFJ 是非常投入和关心伴侣的，他们愿意为建立深厚的感情付出很多。ENFJ 重视情感交流，喜欢与伴侣分享自己的思想、感受和未来的理想。他们会通过细心的关怀和理解让对方感到特别，并努力让关系更加和谐。ENFJ 的理想主义使他们对伴侣的期望较高，往往希望两个人能够共同成长并实现更大的目标。",
                                        "然而，ENFJ 也可能会因为过度关注伴侣的需求，而忽略自己的情感需求。在面对感情冲突时，他们可能会避免直面问题，选择让步或妥协，以维持关系的和谐。因此，ENFJ 需要在感情中学会平衡自己的需求和伴侣的需求，避免过度牺牲。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "发展建议",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1.  设定界限：ENFJ 需要学会在帮助他人时设定健康的界限，避免过度牺牲自己。只有确保自己的情感需求也得到满足，才能更好地支持他人。",
                                        "2.  接受现实：ENFJ 应该学会接受现实中的不足与局限，避免过度理想化。他们可以通过更加务实的思维，接受生活中不可控的因素，以减少失望感。",
                                        "3.  面对冲突与挑战：ENFJ 需要学会直面冲突和挑战，而不是回避或忽视。通过积极应对问题，ENFJ 能够更好地解决冲突，增强自身的抗压能力。",
                                        "4.  照顾自我：ENFJ 应该更加关注自身的身心健康，定期休息，保持良好的情感和精神状态，以避免精力枯竭。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "总的来说，ENFJ 是充满魅力、关怀他人且富有理想的领导者，他们在许多职业领域都能取得成功。通过适当的自我关照、设定边界以及更加务实的态度，ENFJ 可以在人际关系和职业发展中找到更加平衡和满足的路径。",
                                    ],
                                },
                            ],
                        },
                    ],
                },
                ENFP: {
                    code: "ENFP",
                    name: "梦想家",
                    callName: "激情的梦想家",
                    description:
                        "充满创意与活力，追求多样性与自由，善于发现可能性和感染他人。",
                    traits: ["热情", "创造力", "社交能力", "活力"],
                    overview:
                        'ENFP（外向、直觉、情感、知觉）通常被称为"激励者"或"创意倡导者"，他们是富有热情、创造力和人际魅力的人。ENFP 是外向型人格，喜欢与人交流，乐于结交朋友，并且通过与他人互动来汲取能量。他们的直觉使得他们能够看到事物的潜力和未来的发展趋势，而不是仅仅关注当前的细节。ENFP 具有高度的理想主义，追求个人成长、自由和表达自我。他们通常乐于为别人带来积极变化，拥有丰富的内心世界和独特的视角。',
                    detail: [
                        {
                            title: "性格特点",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1.  富有创造力与好奇心：ENFP 是天生的创意者，他们拥有广阔的想象力，善于产生新颖的想法，并且总是充满好奇心，渴望探索未知的世界。无论是工作还是生活，ENFP 总是能够看到不同的视角并提出创新的解决方案。",
                                        "2.  情感丰富且充满同理心：ENFP 深感他人的情绪并能够与他人建立深刻的情感连接。他们对身边人的关心是真诚的，往往愿意为他人提供帮助，尤其是在朋友和家庭成员需要支持时，ENFP 会毫不犹豫地伸出援手。",
                                        "3.  激励与影响他人：ENFP 具有极高的人际魅力，他们擅长鼓舞他人，并能够在团队中起到推动力的作用。他们通过表达自己的理想、激情和信念，激励他人追求共同目标，并积极行动。",
                                        "4.  灵活且适应性强：ENFP 喜欢自由和变化，讨厌束缚和单调的生活。他们擅长应对变化，能够灵活地调整自己的方向和计划。ENFP 享受不断变化的环境，并能在变化中找到新的机会。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "性格缺点",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1.  缺乏耐心与专注：由于对新鲜事物的强烈兴趣，ENFP 很容易分心，特别是在面对重复和单调的任务时，容易失去兴趣。缺乏耐心可能导致他们没有足够的时间或精力来完成某些任务，甚至可能放弃未完成的项目。",
                                        "2.  过度理想化：ENFP 对世界充满理想和梦想，但有时他们的理想与现实之间存在较大差距。过于理想化可能导致他们在面对实际问题时感到失望或迷茫，难以承受与期望不符的结果。",
                                        "3.  容易感到焦虑：由于对未来充满不确定感，ENFP 经常感到焦虑。特别是在面对不熟悉的情境或重要决策时，ENFP 可能会过度担忧，导致情绪波动较大。",
                                        "4.  逃避冲突：ENFP 通常不喜欢冲突，他们更倾向于避免直接对抗，尤其是在涉及个人关系时。面对冲突时，他们可能选择回避或采取妥协的态度，而不是积极解决问题。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "适合的职业",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "ENFP 的创造力、社交能力和对人际关系的敏感，使他们适合从事那些富有挑战、变化和创造性的工作。以下是一些适合 ENFP 的职业：",
                                        "1.  创意行业：ENFP 喜欢用自己的想象力表达自己，因此在广告、设计、写作、艺术创作等领域表现出色。他们能通过独特的视角创造出具有感染力的作品。",
                                        "2.  咨询与教育：ENFP 善于与人沟通并激励他人，适合担任咨询师、教师或培训师等职位。他们能够通过激励他人发挥潜力，帮助他人解决问题，尤其是在教育和个人发展领域。",
                                        "3.  市场营销与公关：ENFP 擅长捕捉人们的情感和需求，他们能够通过与客户或公众的沟通，推广产品或服务，激发共鸣并推动品牌传播。",
                                        "4.  心理学与治疗：ENFP 具有同理心，能够倾听和理解他人的情感，适合从事心理治疗、社会工作等行业，帮助他人克服心理障碍和情感困扰。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "爱情与伴侣",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "ENFP 是充满热情和活力的伴侣，他们希望与伴侣建立深刻的情感连接，并分享彼此的梦想与激情。ENFP 渴望感情的自由和探索，他们的爱情通常充满浪漫和新鲜感。在关系中，ENFP 注重感情的真实与互动，他们希望自己和伴侣能够共同成长并追求共同的理想。",
                                        "然而，ENFP 的理想主义可能导致他们在遇到关系中的挑战时感到沮丧或不满。他们也可能由于不愿面对冲突而避免处理矛盾，导致问题的积累。因此，ENFP 需要一个能够理解他们需求并愿意共同成长的伴侣，能够提供情感支持并在冲突中保持理智。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "发展建议",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1.  提高专注力与执行力：ENFP 应该学会在完成任务时保持专注，尽量避免被新事物或其他想法分散注意力。通过设定明确的目标和时间管理，ENFP 可以提高自己的执行力，更好地完成工作。",
                                        "2.  脚踏实地，面对现实：虽然理想主义是 ENFP 的优点，但有时过度理想化可能导致失望。ENFP 应该学会在追求梦想的同时，保持务实的态度，正视现实中的挑战，寻找平衡。",
                                        "3.  面对焦虑与不确定感：ENFP 在面对不确定的未来时容易感到焦虑，因此可以通过冥想、深呼吸、情感表达等方式来缓解压力。建立稳定的日常生活和常规计划，可以帮助 ENFP 更好地应对焦虑。",
                                        "4.  学会处理冲突：ENFP 应该学会面对和解决冲突，而不是回避。通过主动沟通和理性对话，ENFP 可以更好地处理人际关系中的问题，避免积压情感问题。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "总的来说，ENFP 是充满热情、富有创造力且有着强烈理想主义的人，他们通过激励他人和创造创新的解决方案在职场和个人生活中取得成功。通过更好地管理自己的专注力、理想与现实之间的平衡以及面对焦虑的能力，ENFP 可以在生活中实现更高的成就和更深的满足感。",
                                    ],
                                },
                            ],
                        },
                    ],
                },
                ENTJ: {
                    code: "ENTJ",
                    name: "指挥官",
                    callName: "果断的指挥官",
                    description:
                        "自信且果断的领导者，善于制定策略并调动资源，追求成功和掌控力。",
                    traits: ["领导力", "果断", "执行力", "野心"],
                    overview:
                        'ENTJ（外向、直觉、思维、判断）通常被称为"指挥官"或"战略家"，他们是天生的领导者，充满野心并且有明确的目标。ENTJ 具有极强的组织能力和决策力，能够有效地策划和执行复杂的任务。他们注重效率、逻辑和理性，且善于分析问题，提出解决方案。ENTJ 通常在职场和团队中担任领导角色，因为他们擅长激励和指导他人，同时能够快速识别并采取行动解决问题。由于他们对未来有着清晰的远见，ENTJ 常常是变革和创新的推动者。',
                    detail: [
                        {
                            title: "性格特点",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1.  强大的领导力：ENTJ 拥有天生的领导魅力和组织能力。他们能够清晰地设定目标，并有条不紊地带领团队实现这些目标。ENTJ 注重效率和成果，因此他们总是能将团队带向成功，并且能够激励他人发挥最大潜力。",
                                        "2.  战略性思维：ENTJ 以理性和逻辑为导向，擅长分析复杂的情况，制定长远的战略规划。他们能够清晰地看到大局，并从全局的角度来做决策，解决眼前和未来的挑战。",
                                        "3.  果断且有决策力：ENTJ 在面对决策时表现得非常果断，他们不喜欢拖延，善于迅速做出决策并付诸实践。他们会在分析所有信息后，果断地选择最有效的方案。",
                                        "4.  高度自信与独立：ENTJ 非常自信，坚信自己能够克服一切挑战并取得成功。他们喜欢独立工作，不喜欢受到约束，也不容易接受他人的干预。这样的性格让他们在追求目标时往往能够坚定不移。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "性格缺点",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1.  过于强势和主导：由于 ENTJ 的领导能力和决策力，他们可能过于主导他人的想法和意见。有时，他们可能显得过于强势，容易让别人感到压力或者被压制。特别是在团队合作中，ENTJ 需要注意平衡自己的主导地位，避免过度干预他人的想法。",
                                        "2.  对情感的忽视：ENTJ 在决策时通常以逻辑和理性为主导，这可能导致他们忽视他人的情感需求和心理感受。由于他们不太擅长处理人际关系中的情感细节，他们可能显得冷漠或缺乏同理心。",
                                        "3.  过度工作与压力：ENTJ 对目标的追求非常执着，往往过度投入于工作或事业，容易忽视个人生活的平衡。他们的高标准和完美主义可能会让他们陷入高压状态，导致身心疲劳。",
                                        "4.  难以接受失败：由于自信且目标明确，ENTJ 对失败的容忍度较低。当遇到挫折或失败时，他们可能会感到极度沮丧，并且很难接受不符合预期的结果。过度的自我要求可能导致他们对失败产生过度的压力。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "适合的职业",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "ENTJ 的领导能力、战略思维和决策力使得他们非常适合担任高管或管理职位，特别是在那些需要组织和执行的领域。以下是一些适合 ENTJ 的职业：",
                                        "1.  企业高管与管理者：ENTJ 是天生的管理者，他们具备系统性思维，能够理清复杂的组织结构并进行有效的决策。许多成功的企业家和高层管理者都是 ENTJ 类型的人，他们通过战略布局和决策力带领公司走向成功。",
                                        "2.  咨询与战略规划：ENTJ 善于分析市场和企业环境，制定长期发展战略。战略咨询、商业分析等职位适合他们，这些职位需要他们运用思维敏捷和高效的解决方案。",
                                        "3.  政治与公共事务：由于其强大的领导力和理性决策能力，ENTJ 常常在政治领域中表现出色。他们能够有效组织和领导团队，推动改革和政策实施，许多杰出的政治人物也表现出 ENTJ 的特质。",
                                        "4.  法务与金融行业：ENTJ 的逻辑性和分析能力使得他们适合从事律师、法官等与法律相关的职业。同时，金融行业的管理职位也非常适合 ENTJ，他们能够在复杂的市场环境中作出快速决策。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "爱情与伴侣",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "ENTJ 在爱情中，ENTJ 是非常专注且充满决心的伴侣。他们期待与伴侣共同成长，并且愿意为共同的目标而努力。然而，ENTJ 的理性和果断可能使他们缺乏情感表达，可能会忽视伴侣的情感需求。他们倾向于更关注问题的解决而非情感的沟通，这可能会导致关系中的情感疏离。",
                                        "ENTJ 在寻找伴侣时，更倾向于寻找那些能够理解他们、支持他们目标的伙伴。一个独立且具备挑战精神的伴侣通常能够与 ENTJ 达成良好的共识。如果伴侣过于依赖他们或无法理解他们的工作压力，可能会导致不和谐的局面。因此，ENTJ 需要学会在关系中表达更多的关怀与理解，避免过于理性地处理情感问题。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "发展建议",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1.  注重情感沟通：ENTJ 需要学会关注他人的情感需求，并在沟通中加入更多的关怀和理解。通过倾听他人的意见和感受，ENTJ 可以更好地管理人际关系，提升团队合作效果。",
                                        "2.  平衡工作与生活：ENTJ 在追求事业成功的过程中，往往忽视个人生活和休息。建议他们制定合理的时间表，确保有足够的时间休息和放松，保持身心健康。",
                                        "3.  接纳失败与不完美：ENTJ 对失败的容忍度较低，建议他们学会接受不完美，学会从失败中汲取教训，而不是自责或气馁。通过调整心态，ENTJ 能够更加成熟和理智地应对挑战。",
                                        "4.  放手他人，学会授权：ENTJ 需要学会信任团队成员，放手让他们承担责任。通过适当的授权，ENTJ 不仅能减轻自己的压力，还能提升团队的凝聚力和合作效率。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "总的来说，ENTJ 是天生的领导者，具备卓越的决策力、战略眼光和执行力，他们在职场中能够迅速崭露头角。通过在情感沟通、失败接受和工作生活平衡方面的提升，ENTJ 可以实现个人成长，获得更加成功和幸福的人生。",
                                    ],
                                },
                            ],
                        },
                    ],
                },
                ENTP: {
                    code: "ENTP",
                    name: "辩论家",
                    callName: "机智的辩论家",
                    description:
                        "思维敏捷且机智，喜欢挑战权威和提出新观点，具有冒险和创新精神。",
                    traits: ["机智", "创新", "好奇", "挑战"],
                    overview:
                        'ENTP（外向、直觉、思维、知觉）通常被称为"辩论家"或"创新者"，他们是富有创意、机智且思维敏捷的人，总是充满了好奇心，热衷于探索新思想、新理论和新领域。ENTP 喜欢挑战传统观念，善于发现事物之间的联系，并提出独到的见解。他们往往具有高度的自信和表达能力，喜欢在与他人的辩论中展开思维碰撞。ENTP 不拘泥于固定的框架，崇尚自由和变化，倾向于寻找不同的解决方案和方法来解决问题。',
                    detail: [
                        {
                            title: "性格特点",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1.  创造力与创新性：ENTP 的头脑中充满了创新的想法，他们不断寻找打破常规的方式，善于提出独特的观点和看法。无论是在工作还是生活中，ENTP 都喜欢探索新鲜的事物，挑战现有的理念。",
                                        "2.  好奇心强与广泛兴趣：ENTP 对几乎所有事物都感兴趣，他们渴望了解世界的运作方式。因为广泛的兴趣，ENTP 可能涉猎多个领域，喜欢在各种话题上进行讨论并分享自己的观点。他们不断追求新的知识和经验，以满足他们的好奇心。",
                                        "3.  机智与善于辩论：ENTP 思维敏捷，口才出众，他们擅长辩论，能够用逻辑和理性打破对方的论点，迅速识别并运用反驳的技巧。这使得他们在社交场合中表现得非常自信和有魅力。",
                                        "4.  灵活且适应性强：ENTP 不喜欢束缚和重复的日常生活，他们追求自由和变化，愿意随时调整自己的计划和方向以应对新的挑战和机会。由于他们的灵活性，ENTP 能够在充满不确定性的环境中快速做出决策并应对变化。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "性格缺点",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1.  过于激进和挑战性强：ENTP 喜欢挑战传统观念和规则，但有时这种挑战性可能变得过于激进，甚至让他人感到不适。他们可能无意中打破别人的舒适区，带来不必要的冲突或困扰。",
                                        "2.  缺乏耐心：ENTP 在面对繁琐或重复性的任务时，往往表现得缺乏耐心。他们更喜欢快速推进并寻求新的刺激，因此对于需要长时间专注和耐心的工作，ENTP 可能感到无聊或失去兴趣。",
                                        "3.  缺乏细节关注：由于 ENTJ 倾向于宏观思维和大局观，ENTP 可能忽视细节。他们倾向于关注创新和理论，而不太关心实践中的细节操作。这可能导致某些事情未能完美完成，甚至出错。",
                                        "4.  容易分心，难以集中：ENTP 的兴趣广泛，但有时他们容易受到多种想法的干扰。由于过度追求新鲜事物，ENTP 可能在某个项目上未完成前就转向另一个新想法，从而导致任务的拖延或未完成。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "适合的职业",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "ENTP 的创造力、逻辑思维和社交能力使他们在很多领域中都能展现出色的表现。以下是一些适合 ENTP 的职业：",
                                        "1.  创新与创意行业：ENTP 天生具有创新能力，因此广告、设计、编程、科技研发等领域非常适合他们。他们可以通过独立思考和创新解决方案推动行业的发展。",
                                        "2.  企业家与创业：由于 ENTP 崇尚自由和变化，许多 ENTP 在创业领域表现出色。他们能够发现市场中的空白和机会，制定新的商业模式，并迅速行动实施。ENTP 对新事物充满热情，因此他们非常适合从事创业和业务拓展。",
                                        "3.  市场营销与公关：ENTP 在市场营销、公共关系等行业中表现突出。他们能快速抓住人群心理，用生动和有创意的方式推销产品或服务。同时，他们也擅长在社交场合建立联系，发挥广泛的影响力。",
                                        "4.  咨询与教育：ENTP 善于交流，喜欢向他人传递自己的思想和知识，因此咨询、培训和教育领域非常适合他们。他们能够通过富有创意和具有挑战性的方法激发他人的思维。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "爱情与伴侣",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "在爱情中，ENTP 是充满活力且富有冒险精神的伴侣。他们喜欢与伴侣一起分享思想和经验，并探索共同的兴趣和目标。ENTP 倾向于通过挑战、辩论和共同探索来增进彼此的关系，这使得他们的爱情生活充满了刺激和变革。",
                                        "然而，ENTP 可能在情感表达上有所欠缺，他们倾向于以理性和思维为导向，忽视了伴侣的情感需求。对于情感不够成熟的 ENTP 来说，关系中的细腻关怀可能会成为一大挑战。此外，ENTP 有时可能会觉得恋爱关系限制了他们的自由，因此需要一个能够理解并尊重他们独立性的伴侣。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "发展建议",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1.  加强细节管理与执行力：ENTP 需要学会在追求创新的同时，也要注重细节和执行力。尽管他们对新鲜事物充满热情，但要取得长期的成功，他们必须学会保持专注，确保在实际操作中做到细致和高效。",
                                        "2.  提高耐心和专注力：由于 ENTP 喜欢快速解决问题并跳跃思维，他们可能会对细致的工作和长时间的任务感到不耐烦。因此，ENTP 应该学会提高自己的耐心，尤其是在需要长期投入和专注的工作中。",
                                        "3.  关注他人的情感需求：ENTP 在追求理性和创新的过程中，可能忽视伴侣和团队成员的情感需求。他们需要学会更好地理解他人的感受，并且在沟通中更加注重情感的表达，增强与他人之间的联系。",
                                        "4.  学会适应常规与稳定：虽然 ENTP 对变化和自由充满热情，但在某些情况下，他们也需要学会适应稳定和常规，特别是在团队合作和管理过程中。学会平衡创新与执行、变化与稳定，可以帮助 ENTP 实现更长远的成功。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "总的来说，ENTP 是富有创意、充满活力和机智的个体，他们通过不断创新和探索新领域，在职场和社交场合中表现得非常出色。通过提升细节管理、提高耐心和专注力，以及更好地关注他人的情感需求，ENTP 可以在个人成长和职业发展中获得更多的成就与满足感。",
                                    ],
                                },
                            ],
                        },
                    ],
                },
                ESFJ: {
                    code: "ESFJ",
                    name: "支持者",
                    callName: "善良的支持者",
                    description:
                        "善于与人沟通互动，乐于助人并营造温暖氛围，重视团队合作，擅长维系关系和解决冲突。",
                    traits: ["合作", "忠诚", "敏感", "温暖"],
                    overview:
                        'ESFJ（外向、感觉、情感、判断）被称为"社交型照顾者"或"支持者"，他们是非常重视他人和集体的个体。ESFJ 通常表现出强烈的责任感，乐于服务他人，倾向于通过关心、照顾和建立和谐关系来获得满足感。他们注重社会秩序和传统，喜欢遵循规则并维护稳定的环境。ESFJ 喜欢与他人合作，通常是团体中的核心人物，拥有很高的社交智慧和人际交往能力，能够为别人提供情感支持和帮助。由于他们的细腻与体贴，ESFJ 经常被视为可靠和值得信赖的朋友和伴侣。',
                    detail: [
                        {
                            title: "性格特点",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1.  高度的同理心与责任感：ESFJ 非常关注他人的情感需求，总是尽力去照顾和关心周围的人。他们非常擅长理解他人的感受，并能通过自己的方式提供帮助与支持。他们对家庭、朋友和团队都有强烈的责任感，乐于付出而不求回报。",
                                        "2.  重视传统与稳定：ESFJ 很看重社会规范和传统习俗，喜欢在稳定和有秩序的环境中工作和生活。对他们来说，遵循规则和维持良好的社交秩序是非常重要的，这使他们成为许多团体和组织中的核心成员。",
                                        "3.  社交能力强：ESFJ 通常具有极强的社交能力，擅长建立和维护人际关系。他们喜欢与他人交往，并且善于通过细心聆听和温暖的回应让他人感到舒适和被接纳。在社交场合中，ESFJ 通常是积极的参与者，能迅速拉近人与人之间的距离。",
                                        "4.  实用主义者：ESFJ 非常实际，喜欢通过行动而非抽象的理论来解决问题。他们关注现实中的具体细节，善于处理日常事务，常常是家人、朋友和同事的依赖和支持者。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "性格缺点",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1.  过于依赖外界评价：由于 ESFJ 极其关注他人的感受和意见，他们可能会变得过于依赖外界的认可和评价。当别人对他们不满意时，ESFJ 可能会感到非常不安或失落，这会影响他们的自信心和情感健康。",
                                        "2.  过度照顾他人，忽视自我：ESFJ 常常将他人的需求放在自己之上，可能会在过度照顾他人时忽视了自己的需求和感受。这种倾向可能导致他们感到精疲力尽或内心不满，因为他们常常过度投入，无法得到与付出相匹配的回报。",
                                        "3.  对冲突的回避：ESFJ 非常重视和谐的关系，因此他们倾向于避免冲突和对抗。有时，他们可能会为了避免与他人发生争执，而压抑自己的真实想法和感受。这种回避冲突的行为可能会导致情绪积压，甚至爆发负面情绪。",
                                        "4.  固守传统，缺乏灵活性：虽然 ESFJ 对传统和秩序非常重视，但他们有时可能对新的想法或变革缺乏足够的开放性。固守传统的态度有时会让他们错失某些创新和进步的机会，甚至让他们在应对变动的环境时感到不适应。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "适合的职业",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "ESFJ 的社交能力、责任感和细心照顾他人的性格特点使他们在许多涉及人与人互动的职业中都表现得非常出色。以下是一些适合 ESFJ 的职业：",
                                        "1.  教育与教学：ESFJ 非常适合从事教育工作，特别是与学生建立深厚关系的职位。由于他们有很强的同理心和责任感，ESFJ 能够为学生提供温暖和支持，帮助他们成长并实现潜力。",
                                        "2.  护理与医疗：ESFJ 非常关心他人，因此护理和医疗行业是他们的理想选择。无论是作为护士、心理治疗师，还是其他支持性医疗职位，ESFJ 都能够给予患者关爱和细致入微的照顾。",
                                        "3.  客户服务与社会工作：ESFJ 很擅长与他人建立联系，喜欢提供帮助，因此客户服务和社会工作非常适合他们。通过倾听和解决问题，ESFJ 可以为他人带来积极的改变。",
                                        "4.  活动策划与公共关系：ESFJ 对社交活动和与他人互动充满热情，因此活动策划和公共关系领域也适合他们。他们善于组织活动，管理细节，并与各类人员保持良好的互动。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "爱情与伴侣",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "在爱情中，ESFJ 是充满爱心和奉献精神的伴侣。他们通常会全身心投入到感情中，关心伴侣的每一个需求，并尽力为对方提供支持与关怀。ESFJ 期待与伴侣建立深厚的情感连接，他们希望彼此共享感情和生活的点滴，并通过行动来表达对对方的爱。",
                                        "然而，ESFJ 过于依赖外界反馈的特点可能导致他们在爱情关系中感到不安全。如果伴侣对他们的付出没有给予足够的回应，ESFJ 可能会感到失落，甚至产生不满。因此，ESFJ 需要找到一个能够理解并欣赏他们的伴侣，避免在感情中失去自我。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "发展建议",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1.  学会照顾自己：ESFJ 需要意识到，在照顾他人之前，自己也应该得到关爱和关注。通过平衡对他人的照顾与对自己的照顾，ESFJ 可以避免精力枯竭，保持身心健康。",
                                        "2.  增强情感独立性：ESFJ 应该学会更为独立，减少对他人评价的依赖。他们需要学会从内部寻找自信，而不是过分依赖外界的认可。培养自我肯定的能力，有助于增强内心的稳定性。",
                                        "3.  勇于面对冲突：虽然 ESFJ 喜欢和谐，但他们需要学会面对冲突和矛盾，敢于表达自己的真实感受。通过建设性地处理冲突，ESFJ 不仅能改善人际关系，还能获得更多的个人成长。",
                                        "4.  拓宽视野，接受变化：ESFJ 有时过于固守传统和现有的规则，需要更加开放地接受新的想法和变化。培养灵活性和适应力，可以帮助他们更好地应对变化中的挑战，发掘更多的机会。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "总的来说，ESFJ 是温暖、关怀和社交能力极强的人，他们的存在对周围的人来说非常宝贵。通过增强自我照顾、情感独立性、解决冲突的能力以及接受变化，ESFJ 可以在个人成长和职业发展中获得更多的成就和幸福感。",
                                    ],
                                },
                            ],
                        },
                    ],
                },
                ESFP: {
                    code: "ESFP",
                    name: "表演者",
                    callName: "欢乐的表演者",
                    description:
                        "热爱生活与欢笑，富有社交能力与感染力，喜欢带来欢乐与活力。",
                    traits: ["活力", "社交", "幽默", "感染力"],
                    overview:
                        'ESFP（外向、感觉、情感、知觉）被称为"表演者"或"活跃的享乐主义者"。他们充满活力，乐于享受当下，喜欢通过与他人互动来寻找快乐。ESFP 性格开朗，热情且具有感染力，能够迅速吸引他人的注意并建立联系。他们追求享受生活中的每一刻，注重感官体验和实际行动，常常在社交场合中表现出色。对于他们来说，生活是一场充满色彩和冒险的旅程，他们乐于探索世界的多样性，并且愿意去尝试新的事物和体验。',
                    detail: [
                        {
                            title: "性格特点",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1.  外向与社交能力强：ESFP 是典型的外向型人格，他们从与人交往中汲取能量。对他们来说，社交是生活的一部分，他们喜欢结识新朋友，享受团体活动，并能够轻松地与人建立联系。他们善于让他人感到轻松愉快，并能很自然地吸引注意力。",
                                        "2.  享乐主义者与冒险精神：ESFP 非常注重生活中的乐趣和享受，他们喜欢在日常生活中寻找美好和刺激。无论是旅行、聚会还是尝试新活动，ESFP 总是积极参与，并通过体验感官享受和新奇的冒险来丰富自己的生活。他们喜欢活在当下，享受无忧无虑的时光。",
                                        "3.  情感丰富与体贴：尽管 ESFP 充满活力且外向，他们也非常关注他人的感受，能够感知并回应他人的情绪需求。作为情感丰富的人，他们往往以直觉方式处理情感问题，重视人际关系中的情感交流和连结。ESFP 喜欢为他人带来快乐，并通过自己的行为关心和支持他人。",
                                        "4.  实际与务实：尽管 ESFP 热衷于冒险和新鲜事物，他们同样也是非常实际的人。在工作和生活中，ESFP 倾向于注重眼前的实际需求，而不是抽象的理论或远大的计划。面对问题时，他们喜欢迅速采取行动，解决问题并推动事物的发展。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "性格缺点",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1.  缺乏长远规划：ESFP 常常专注于眼前的乐趣和享受，因此他们可能忽视长远的目标和规划。由于过于活在当下，他们可能会在未来面临困境或缺乏必要的准备，从而感到后悔。",
                                        "2.  逃避压力与责任：当遇到困难或压力时，ESFP 可能倾向于逃避或忽视问题，而不是面对和解决。由于他们更喜欢享受生活而非处理沉重的责任和任务，这可能导致他们逃避责任或避免困难的局面。",
                                        "3.  冲动和缺乏耐性：ESFP 有时可能过于冲动，容易做出不经过深思熟虑的决定。他们喜欢快速行动，这有时会导致他们忽略重要的细节或做出仓促的选择。同时，ESFP 的耐性较差，在需要长时间坚持和耐心的任务中，他们可能感到不耐烦并急于寻求变化。",
                                        "4.  过度依赖外部认可：尽管 ESFP 通常以乐观和外向的形象示人，但他们有时过于依赖外部的认可和反馈。他们需要通过他人的认可来感到自己被接纳和赞赏，如果缺乏这种反馈，可能会感到沮丧或不安。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "适合的职业",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "适合的职业：ESFP 适合从事那些需要社交、创意和实践能力的职业。以下是一些适合 ESFP 的职业：",
                                        "1.  娱乐与表演行业：ESFP 喜欢与人互动并展示自己，因此娱乐、表演或主持等职业是他们的理想选择。无论是演员、歌手、舞者，还是其他需要与观众互动的职位，ESFP 都能表现出色。",
                                        "2.  销售与公关：由于 ESFP 的社交能力和人际敏感度，他们在销售和公关领域也非常有优势。与客户打交道、与他人建立关系以及影响他人决定都是 ESFP 擅长的领域。他们能够通过热情和感染力吸引他人，并在商业环境中取得成功。",
                                        "3.  旅游与活动策划：ESFP 喜欢探索新环境和新事物，因此从事旅游行业或活动策划是一个不错的选择。ESFP 可以通过组织和策划有趣的活动来展示他们的创意，并享受与他人分享这些体验的乐趣。",
                                        "4.  健康与健身行业：由于 ESFP 喜欢身体活动并关注感官体验，他们可以在健康、健身或运动领域找到满足感。作为教练、营养师或健康顾问等职位，ESFP 能够通过与他人的互动帮助他们保持身心健康。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "爱情与伴侣",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "在爱情中，ESFP 是充满激情和感性的人，他们喜欢用行动和表现来表达爱意。ESFP 是非常感性和体贴的伴侣，乐于带给对方快乐和惊喜。他们通常非常浪漫，喜欢通过制造小惊喜、约会和活动来维持感情的新鲜感。",
                                        "然而，ESFP 可能会倾向于过于依赖伴侣的认可，并且不擅长处理情感中的复杂问题。他们有时会回避冲突或忽视长远的承诺，因此需要一个能够理解和包容他们生活态度的人。一个能够支持他们享受生活并提供稳定情感支持的伴侣，会使 ESFP 感到安全和满足。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "发展建议",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1.  建立长远的目标和计划：虽然享受当下很重要，但 ESFP 也需要学会规划未来并设定明确的目标。通过制定短期和长期目标，ESFP 可以在实现自己梦想的过程中保持动力，并避免迷失方向。",
                                        "2.  学会处理压力和责任：ESFP 需要增强面对压力和责任的能力。当问题出现时，他们应该学会积极面对并采取有效的措施，而不是回避。这种能力的提高将帮助 ESFP 更加成熟，并为未来的挑战做好准备。",
                                        "3.  发展耐性和深思熟虑的能力：ESFP 可以通过增强耐性和自我控制能力，避免冲动决策的困扰。学会审视问题的多面性，并在做出决定前进行深思熟虑，有助于他们做出更明智的选择。",
                                        "4.  培养内在的自我价值感：尽管外部认可对 ESFP 很重要，但他们应该学会从内在寻找自信，而不是过度依赖他人的评价。通过增强自我认知和自我接纳，ESFP 能够建立更加稳定和独立的内心世界。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "总的来说，ESFP 是充满活力和乐趣的人，他们以乐观、外向的性格和丰富的情感世界吸引他人。通过建立长远目标、处理压力和责任、增强耐性，以及培养内在的自我价值感，ESFP 可以在职业和感情生活中获得更多的成长与幸福。",
                                    ],
                                },
                            ],
                        },
                    ],
                },
                ESTJ: {
                    code: "ESTJ",
                    name: "管理者",
                    callName: "坚定的管理者",
                    description:
                        "实际且高效的组织者，注重规则和秩序，善于领导团队，确保计划有序推进并达成目标。",
                    traits: ["组织能力", "领导力", "效率", "现实主义"],
                    overview:
                        'ESTJ（外向、感觉、思维、判断）被称为"执行者"或"组织者"，他们是务实、现实的领导者，喜欢秩序和结构，注重规则和传统。ESTJ 性格严谨、果断，拥有强烈的责任感和行动导向的特质。他们倾向于依赖逻辑和事实做决策，并且非常重视效率和目标的实现。作为外向型人格，ESTJ 喜欢与他人互动，善于组织和管理事务，乐于领导和承担责任。他们通常会通过清晰的计划和有效的管理来推动团队向前发展。',
                    detail: [
                        {
                            title: "性格特点",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1.  务实与实际：ESTJ 通常以实际和务实的方式解决问题。他们倾向于关注现实生活中的细节，而不是理论或抽象的概念。ESTJ 更喜欢依靠经验和已知的事实来制定决策，他们不喜欢复杂和不确定的情境，而是更倾向于已知的规则和框架。",
                                        "2.  组织能力强：作为天生的领导者，ESTJ 善于规划和组织事务。他们具有出色的时间管理和任务安排能力，总是能高效地完成工作。ESTJ 非常注重秩序，认为一切都应该井然有序，因此他们常常会在团队中扮演管理者或组织者的角色。",
                                        "3.  领导力与决策能力：ESTJ 喜欢在团体中担任领导角色，并且通常能够快速做出决策。他们依靠逻辑和事实来评估情况，并不轻易受情感或主观看法的影响。ESTJ 通常会以清晰、明确的方式表达自己的观点，推动团队朝着既定目标前进。",
                                        "4.  责任感与忠诚：ESTJ 对自己的责任非常重视，认为按时完成任务和履行职责是非常重要的。他们对团队和家庭非常忠诚，并且有强烈的责任心。他们期望他人也能像自己一样有责任感，遵循规则和保持秩序。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "性格缺点",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1.  固执与僵化：由于 ESTJ 强调规则和秩序，他们有时可能会显得过于固执和不愿意接受改变。对于新颖的或与传统不同的方式，他们往往持保守态度，难以适应变化。过于依赖已知的模式和规则，可能会限制他们在某些情况下的灵活应变。",
                                        "2.  过于注重细节：虽然 ESTJ 注重效率和细节，但有时他们可能过于关注细节，以至于忽略了整体目标或更重要的事项。过度处理琐事可能导致他们在关键时刻无法作出快速反应，或者浪费不必要的精力。",
                                        "3.  忽视他人感受：ESTJ 倾向于依靠逻辑和事实做决策，可能在做事时忽视了他人的情感和需求。他们的沟通方式有时显得直接、严厉，这可能让他人感到不舒服。尤其是在处理团队成员的情绪时，ESTJ 可能会显得冷漠或缺乏同理心。",
                                        "4.  难以适应不确定性：ESTJ 喜欢有计划、有规则的环境，难以应对高度不确定或模糊的情况。在面对不确定性时，他们可能会感到焦虑或不安，倾向于回避或忽视这些不确定性。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "适合的职业",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "ESTJ 适合那些需要领导、管理和组织的职业，他们在追求效率和目标实现方面有着明显的优势。以下是一些适合 ESTJ 的职业：",
                                        "1.  企业管理与领导职位：作为天生的领导者，ESTJ 在企业管理和领导岗位上表现出色。他们能够高效地组织和协调团队，制定并实施具体的行动计划。无论是 CEO、部门经理还是项目经理，ESTJ 都能胜任，并且通过其果断的决策推动企业发展。",
                                        "2.  法律与执法领域：ESTJ 对规则和秩序有很高的重视，因此法律和执法领域是他们的理想选择。律师、法官、警察、法院书记员等职业都适合 ESTJ，因为这些职业要求遵守严格的法律规定并执行规则。",
                                        "3.  军事和安全行业：ESTJ 通常适应高度结构化和纪律性的环境，因此军事和安全领域是他们的理想选择。他们擅长遵循命令、执行任务并维护秩序，军官、警官、安保经理等职位非常适合他们。",
                                        "4.  项目管理与咨询：ESTJ 擅长组织和规划任务，他们在项目管理和咨询行业中也能够脱颖而出。无论是建筑、工程还是IT项目管理，ESTJ 都能通过有效的团队管理和任务协调确保项目顺利完成。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "爱情与伴侣",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "在爱情中，ESTJ 是非常忠诚和稳定的伴侣。他们非常重视长期承诺，并且通常希望通过行动和责任感来证明他们的爱。ESTJ 喜欢为伴侣提供安全感和稳定的生活，他们通过计划和安排为感情提供支持和保障。",
                                        "然而，ESTJ 可能会有时显得过于理性和事务化，他们有时忽视伴侣的情感需求，更倾向于关注实际问题。伴侣可能会感到他们缺乏浪漫或情感上的表达，因此 ESTJ 需要学会更加关注伴侣的情感世界，并且通过更加细腻的沟通来增进情感联系。",
                                        "理想的伴侣通常是能够理解 ESTJ 工作和责任心的特质，同时也能够给予他们情感上的支持和理解。对于 ESTJ 来说，一个稳定、成熟且具备沟通能力的伴侣最为理想。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "发展建议",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1.  增强灵活性和适应性：虽然 ESTJ 习惯于依赖规则和已知的模式，但他们需要学会在复杂和不确定的情况下做出调整。增强灵活性，能够更好地适应变化，并在需要时采取不同的方法。",
                                        "2.  注重他人感受和情感交流：ESTJ 需要更多地关注团队成员和伴侣的情感需求，学会通过更具同理心的方式来表达关怀。改善与他人的沟通方式，尤其是在情感问题上，能够帮助 ESTJ 建立更加和谐的人际关系。",
                                        "3.  培养创造性思维：虽然 ESTJ 更喜欢实际操作和结构化的思维，但培养一些创造性思维和开放的心态对于他们的职业发展和个人成长有很大帮助。能够在工作中引入创新的方式和想法，可能会带来意想不到的成功。",
                                        "4.  学会管理压力与放松：由于 ESTJ 经常承担重大的责任，他们可能容易感到压力过大。学会有效地管理压力和寻找适当的放松方式，可以帮助 ESTJ 保持长久的动力和健康的工作态度。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "总的来说，ESTJ 是充满责任感、果断和高效的领导者，他们在有序、结构化的环境中表现最佳。通过增强灵活性、关注情感交流以及提升创造力，ESTJ 可以更好地适应快速变化的世界，并在事业和人际关系中取得更大的成功。",
                                    ],
                                },
                            ],
                        },
                    ],
                },
                ESTP: {
                    code: "ESTP",
                    name: "冒险家",
                    callName: "勇敢的冒险家",
                    description:
                        "勇于冒险，喜欢尝试新事物，充满活力和行动力，善于捕捉机会并迅速作出决策以应对挑战。",
                    traits: ["行动力", "自信", "冒险", "适应"],
                    overview:
                        'ESTP（外向、感觉、思考、知觉）被称为"冒险家"，他们是充满活力、机智、乐观且富有行动力的人。作为外向型人格，ESTP 善于与人沟通，喜欢社交，通常是团队中的活跃分子。他们具有高度的现实感，关注当前的实际情况，而非未来的潜在问题或过多的理论。ESTP 是行动导向型的人，往往通过实践而非抽象的思考来解决问题。在面对压力和挑战时，ESTP 展现出极高的适应能力，能够迅速做出反应并从经验中学习。',
                    detail: [
                        {
                            title: "性格特点",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1.  活力四射：ESTP 充满动力和活力，喜欢冒险与刺激，善于抓住机遇并迅速作出反应。他们通常不喜欢静止的生活，倾向于不断寻找新鲜事物与挑战来激发自己的兴趣。",
                                        "2.  现实主义：ESTP 注重实际问题，倾向于关注眼前的事务，而非长远规划。他们对细节有敏锐的洞察力，能够迅速评估当前情况并采取有效行动。他们喜欢解决即时问题，且往往能找到最直接有效的解决方法。",
                                        "3.  直率与外向：ESTP 个性直率，喜欢与人交往，善于与他人建立关系。他们通常在社交场合中充当活跃的中心，擅长让周围人感到放松并鼓励他们参与。",
                                        "4.  应变能力强：ESTP 在应对突发事件时表现尤为出色，能够迅速适应新的环境和挑战。他们善于在压力下保持冷静，凭借敏捷的思维和行动力迅速作出决策。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "性格缺点",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1.  冲动与缺乏耐心：由于偏好迅速的行动，ESTP 有时会显得过于冲动，做事时缺乏耐心。他们不喜欢拖延，追求快速结果，这可能导致他们忽视潜在的风险或后果。",
                                        "2.  忽视长远规划：ESTP 通常专注于当前的任务和目标，容易忽视长期规划和未来的潜在问题。由于他们更多关注眼前的实际情况，可能会错过一些需要提前筹划的机会。",
                                        "3.  缺乏同理心：虽然 ESTP 擅长社交并能与他人建立良好的关系，但有时他们在处理情感问题时显得不够细腻。他们可能忽视他人的感受，尤其是在面对复杂的情感需求时，往往通过行动而非言辞表达关心。",
                                        "4.  不喜欢规则与限制：ESTP 喜欢自由和灵活性，通常不喜欢受到规则的束缚。面对严格的规范和结构时，他们可能会感到压抑或不满，甚至有挑战权威的倾向。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "职业发展",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "ESTP 适合从事那些充满挑战、动态多变且需要即时决策的工作。他们在快节奏、刺激性的职业中表现尤为突出。以下是一些适合 ESTP 的职业：",
                                        "1.  销售与市场营销：ESTP 擅长与人交往、激励他人，因此非常适合销售工作。他们能快速判断客户需求并采取行动。",
                                        "2.  企业管理与领导职位：作为决策果断、行动迅速的人，ESTP 可以胜任领导角色，尤其是在需要做出快速决策和解决问题的环境中。",
                                        "3.  紧急救援与应急管理：ESTP 擅长应对危机，能够在紧急情况下冷静行动，因此在消防员、急救人员、警察等岗位上表现出色。",
                                        "4.  运动员与教练：ESTP 喜欢运动和身体活动，在体育领域特别是那些要求快速反应和高强度表现的岗位中，他们能够充分发挥其优势。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "爱情与伴侣",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "在爱情中，ESTP 是充满热情和活力的伴侣，喜欢与对方一起分享冒险和新的体验。他们的恋爱方式通常充满激情和刺激，喜欢用实际行动表达爱意。ESTP 的恋爱态度往往是直接和真诚的，但有时可能过于专注于当下的快乐，忽略了对方的情感需求。因此，他们的伴侣需要理解 ESTP 的冒险精神，并能接受他们有时缺乏情感深度的表现。",
                                        "ESTP 可能在关系中表现得不够耐心，尤其在面对情感交流时，可能更多依赖行动而非言辞。理想的伴侣是能够与 ESTP 一起探索新事物、享受生活的自由和乐趣，同时愿意在情感上给予他们足够的空间和理解。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "发展建议",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1.  培养深思熟虑的决策能力：虽然 ESTP 擅长快速决策，但有时过于冲动。为了避免后悔，他们可以学会在决策前多做一些深思熟虑，考虑更多长期的影响。",
                                        "2.  注重情感表达与同理心：ESTP 可以通过学习如何更加关注和理解他人的情感需求，来提升自己在人际关系中的情感深度，尤其是在亲密关系中，要更注意倾听和关心他人的感受。",
                                        "3.  发展规划和耐心：尽管 ESTP 偏好即时行动，但他们可以尝试制定长远的规划，关注长期目标。通过设定合理的时间框架和期望，ESTP 可以避免过度依赖即时满足的短期行为。",
                                        "4.  尊重规则和结构：虽然 ESTP 不喜欢受到束缚，但学会在合适的场合遵循规则，能够帮助他们在工作和社会环境中更加顺利地开展合作。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "总的来说，ESTP 是充满活力、果敢且实用的人，他们能够通过快速行动和强大的适应性应对各种挑战。如果能在决策中更加深思熟虑，并提升情感表达能力，ESTP 可以在个人生活和职业发展中达到更高的成功和满足。",
                                    ],
                                },
                            ],
                        },
                    ],
                },
                INFJ: {
                    code: "INFJ",
                    name: "倡导者",
                    callName: "深邃的倡导者",
                    description:
                        "理想主义者，致力于帮助他人和社会进步，富有洞察力且忠于使命。",
                    traits: ["共情", "理想主义", "洞察", "奉献"],
                    overview:
                        'INFJ（内向、直觉、情感、判断）被称为"提倡者"或"导师"。他们是少数的理想主义者和深思熟虑的人，总是力求帮助他人并为社会带来积极变化。INFJ 倾向于注重内心的想法和情感，并在与他人互动时展现出深刻的同理心。他们通常很有远见，能看到别人看不见的潜力和意义。虽然内向，INFJ 会为他们所关心的人或事业付出大量的精力与努力，努力促成对社会有益的变革。',
                    detail: [
                        {
                            title: "性格特点",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1.  深思熟虑与理想主义：INFJ 倾向于深思熟虑，总是关注长期的目标和理想，而不是眼前的具体事务。他们对世界有着强烈的理想化愿景，并致力于实现这些理想。INFJ 更倾向于关注内心的情感和思考，喜欢独处并深入思考复杂的问题。",
                                        "2.  深刻的同理心和理解力：INFJ 非常关注他人的情感和需求，通常能感知他人未说出口的想法和情感。他们有着强烈的同理心，能够与他人建立深刻的情感联系，并能在他人最需要的时候提供帮助和支持。",
                                        "3.  内省与自我反思：INFJ 喜欢进行自我反思和内省，探索自己的内心世界。他们通常有深厚的哲学思考和对人生意义的探寻，常常在自我探索和成长中不断追求真理和智慧。",
                                        "4.  追求意义和目标：INFJ 的生活通常充满了深刻的目的感，他们渴望为世界带来有意义的改变。他们往往会选择具有较大社会影响力的事业，并愿意为此付出个人的时间和精力。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "性格缺点",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1.  过于理想化：INFJ 经常陷入对理想世界的追求，可能忽视现实的复杂性。他们有时过于理想化，可能会因为无法看到理想的实现而感到沮丧。当理想与现实之间存在巨大差距时，INFJ 容易失去动力或感到迷茫。",
                                        "2.  容易过度思考和自我怀疑：由于 INFJ 深思熟虑且注重内省，他们可能会过度思考并产生不必要的担忧。这种倾向可能会导致他们在决策时感到犹豫不决，甚至会在无形中增加压力和焦虑。",
                                        "3.  内心冲突和自我压抑：INFJ 对自己的要求非常高，常常在追求完美的过程中感到内心的冲突。他们可能会忽视自己的需求和情感，过度压抑自己的情感，为他人提供帮助时忽略自身的健康与幸福。",
                                        "4.  难以接受批评：INFJ 对他人的意见和批评非常敏感。由于他们高度依赖自己内心的价值观和理念，任何负面的反馈都可能令他们感到极度受伤，影响他们的自信心。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "适合的职业",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "INFJ 通常适合那些能够为社会带来正面影响并能够发挥他们同理心和洞察力的职业。以下是一些适合 INFJ 的职业：",
                                        "1.  心理学家与心理治疗师：INFJ 擅长理解他人的情感，并具有高度的同理心，他们在心理学和治疗领域中能够有效地帮助他人。",
                                        "2.  社会工作者与人道主义者：INFJ 的目标感和理想主义使得他们适合从事社会服务和人道主义工作。",
                                        "3.  作家与艺术创作者：INFJ 对内心的表达和独特的思维方式使得他们成为优秀的作家、诗人或艺术家。",
                                        "4.  教育者与导师：INFJ 对他人有着深刻的洞察力和同理心，他们适合从事教育工作，尤其是那些能够启发学生思考和成长的职位。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "爱情与伴侣",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "INFJ 是非常忠诚和深情的伴侣。他们追求深刻而真挚的情感连接，倾向于寻求能够理解他们内心世界的人。INFJ 期待与伴侣建立一种具有高度心灵共鸣的关系，他们希望两人能够共同成长、共同追求理想和目标。",
                                        "然而，INFJ 可能会过于理想化爱情和伴侣，期待对方完全符合他们的理想标准。如果伴侣无法满足这些期待，INFJ 可能会感到失望或沮丧。此外，INFJ 可能会在恋爱中压抑自己的需求和情感，以照顾伴侣的需求，这可能导致他们感到疲惫和不满足。",
                                        "理想的伴侣通常是能够理解和支持 INFJ 理想和情感需求的人。INFJ 需要一个温柔、理解、能够共情的伴侣，他们能够接纳 INFJ 的复杂性，并愿意与其共同面对生活的挑战。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "发展建议",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1.  放下过于理想化的期望：INFJ 需要学会接受现实，理解理想与现实之间的差距。虽然追求高远的目标是值得鼓励的，但过于理想化可能导致失望和疲惫。学会接纳不完美和现实的挑战，将有助于他们保持内心的平和与动力。",
                                        "2.  增强自我关怀与平衡：INFJ 常常倾向于为他人付出过多，而忽视了自己的情感和需求。因此，他们需要更加注重自我关怀，学会为自己设定边界，保持情感上的平衡和稳定。",
                                        "3.  管理内心的焦虑与不安：INFJ 容易陷入过度思考和焦虑，建议他们通过冥想、运动或其他方式管理压力与焦虑。学会更好地控制情绪，避免自我怀疑和过度思虑，将帮助他们在面对困境时更加坚定。",
                                        "4.  提升与他人的沟通技巧：尽管 INFJ 具有深刻的同理心，但他们有时难以清晰地表达自己的需求和情感。提升与他人的沟通技巧，学会主动表达自己，并开放心态接纳他人的反馈，有助于建立更加和谐的关系。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "总结来说，INFJ 是深思熟虑的理想主义者，他们以改变世界、帮助他人为己任。通过平衡理想与现实、加强自我关怀和沟通能力，INFJ 将能够在职业和爱情中更好地发挥自己的潜力，过上更加充实和满足的生活。",
                                    ],
                                },
                            ],
                        },
                    ],
                },
                INFP: {
                    code: "INFP",
                    name: "调解者",
                    callName: "温柔的调解者",
                    description:
                        "追求内心的和谐与价值观的实现，关注他人感受，善于想象未来的可能性。",
                    traits: ["善良", "敏感", "理想主义", "创造力"],
                    overview:
                        'INFP（内向、直觉、情感、知觉）被称为"调解者"或"理想主义者"。他们是非常理想化、富有同情心和创造力的人，常常怀揣着改变世界的梦想。INFP 关注内心的情感与价值观，通常不愿意妥协自己的道德标准。对于他们来说，追求个人的意义与自我表达比追求外在的成功更为重要。INFP 倾向于过着独立的生活，并寻找能够满足他们精神需求的工作和人际关系。虽然他们内向并且倾向于深思熟虑，但却有着强烈的使命感和对他人的深刻关怀。',
                    detail: [
                        {
                            title: "性格特点",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1.  理想主义和道德感：INFP 对世界有着强烈的理想化愿景，总是希望能为社会带来积极的变化。他们有坚定的道德观念，无法容忍不公正或虚伪的行为。常常抱有对未来的美好期望，并在日常生活中力求追求高尚的目标。",
                                        "2.  高度同理心与情感深度：INFP 具备极强的同理心，能够感知他人的情感并深入理解他人的内心需求。他们善于倾听，能够给予他人情感上的支持与理解。这种同理心也使得他们容易与他人建立深厚的情感联系。",
                                        "3.  创造性和艺术感：INFP 拥有丰富的想象力和创造力，常常沉浸在创作和艺术表达中。他们可能是写作者、艺术家或其他创意领域的工作者，善于通过艺术形式传达自己的情感与理念。对于他们来说，艺术和自我表达是非常重要的。",
                                        "4.  独立和内省：INFP 深入探索自己的内心世界，通常会花费大量时间进行自我反思与探索。他们通常非常独立，喜欢在安静的环境中思考和充电，避免过多的外界干扰。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "性格缺点",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1.  过度理想化：由于 INFP 过于理想化，他们往往对自己和他人抱有不切实际的期待。如果现实不能与自己的理想对接，他们可能会感到失望，甚至产生沮丧情绪。尤其是在面对生活中的不公与不完美时，INFP 容易感到无力。",
                                        "2.  情感过度敏感：INFP 对外界的批评非常敏感，可能因为他人的一句无心之言而感到深受伤害。由于情感丰富，他们常常容易陷入负面情绪，难以释怀。",
                                        "3.  优柔寡断和逃避现实：INFP 倾向于过度思考，可能导致在做决策时犹豫不决。此外，他们也可能有逃避现实的倾向，避免面对艰难的生活挑战，从而影响到他们的成长与发展。",
                                        "4.  过于自我牺牲：为了照顾他人或追求理想，INFP 有时会忽略自己的需求和情感。过度的自我牺牲可能导致他们感到疲惫、孤独或不被理解。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "适合的职业",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "INFP 通常适合那些能够表达个人理念、创造性与情感的职业。以下是一些适合 INFP 的职业方向：",
                                        "1.  作家与创作者：INFP 的创造力和情感表达使得他们非常适合从事写作、艺术创作、设计等职业。他们能够通过文字、艺术作品、电影或音乐等形式将自己的理想和情感展现给世界。作家、诗人、小说家、插画师等职业都能满足他们的创作欲望。",
                                        "2.  心理学家与咨询师：INFP 的同理心和情感洞察力使得他们非常适合从事心理学和咨询领域的工作。他们能够倾听他人的问题，并通过深入的分析和理解帮助他人解决内心的困扰。",
                                        "3.  社会工作者与公益事业：INFP 对社会不公和他人困境有着深切的关怀，他们适合从事社会工作、非营利组织、志愿者工作等。通过这些职业，INFP 可以真正实现自己的理想，帮助社会上的弱势群体。",
                                        "4.  教育者与导师：INFP 善于激发他人潜力，并愿意通过教育帮助他人实现个人成长。他们在教育领域，尤其是非传统或创新的教育方法中，会发挥出色的能力。教师、辅导员、导师等职位都能让 INFP 发挥他们的优势。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "爱情和伴侣",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "INFP 是非常浪漫和忠诚的伴侣。他们对伴侣有着深刻的情感依赖，并渴望建立一种基于深度理解和相互支持的关系。INFP 会尽其所能为伴侣提供无条件的爱和支持，愿意为了爱情而付出巨大努力。他们通常追求理想中的爱情关系，希望与伴侣在心灵上达成共鸣。",
                                        "然而，INFP 也容易在感情中表现出过度理想化的一面。他们可能会将伴侣看作是完美的对象，从而忽视对方的缺点。如果伴侣未能符合他们的理想标准，INFP 可能会感到失望和沮丧。此外，INFP 也可能在感情中过于自我牺牲，忽略自身的需求，导致情感的失衡。",
                                        "理想的伴侣通常是能够理解和支持 INFP 理想、价值观和情感需求的人。能够接受他们的复杂性和理想主义，并愿意与其共同探索人生意义的伴侣，将是 INFP 的最佳选择。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "发展建议",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1.  平衡理想与现实：INFP 需要学会平衡理想与现实之间的差距。虽然追求理想值得肯定，但过于理想化会带来失望和沮丧。学会接受生活中的不完美，避免被理想的缺失困扰。",
                                        "2.  增强决策能力：INFP 有时会过度犹豫或逃避决策。提升决策能力、增强自信心，可以帮助他们更有效地面对生活中的挑战。学会权衡利弊并在不完美中做出决定，将是 INFP 的一项重要成长。",
                                        "3.  关注自我需求：INFP 在关心他人的同时，容易忽视自己的需求和情感。他们需要学会照顾自己，明确自己的界限，避免过度自我牺牲。定期休息、保持情感平衡和与他人沟通自己的需求，会有助于他们更好地维持心理健康。",
                                        "4.  提高情绪管理：INFP 对情绪波动比较敏感，容易受到外界影响。通过冥想、运动或心理疏导等方式，他们可以更好地管理自己的情绪，保持内心的平和与稳定。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "INFP 是理想主义的创造者和关怀者，他们以深刻的情感和强烈的价值观为驱动力，致力于改变世界和帮助他人。通过学会平衡理想与现实、增强决策能力并关注自我需求，INFP 将能够更好地实现自我、克服挑战，并在职业和感情中获得更多的满足感。",
                                    ],
                                },
                            ],
                        },
                    ],
                },
                INTJ: {
                    code: "INTJ",
                    name: "战略家",
                    callName: "冷静的战略家",
                    description:
                        "擅长系统思考和规划，注重效率与目标，富有远见和洞察但性格独立。",
                    traits: ["冷静", "独立", "远见", "逻辑"],
                    overview:
                        'INTJ（内向、直觉、思维、判断）被称为"战略家"或"建筑师"。他们是天生的规划者和分析家，深思熟虑，注重理性思维和长远的目标，拥有强烈的独立性与内在的自信。他们通常以对知识的追求为驱动力，喜欢从整体上理解事物，并寻找优化和改进的方法。INTJ 喜欢在自己的内心世界中思考和策划，鲜少依赖外界意见或社会规范。由于他们对于未来有着清晰的远见，INTJ 通常在各自的领域中展现出卓越的才智和领导力。',
                    detail: [
                        {
                            title: "性格特点",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1.  战略思维与远见：INTJ 天生具有出色的战略眼光，他们善于进行长远规划，能够清晰地看到目标与路径之间的联系。在面对复杂情况时，INTJ 能够从宏观的角度评估局势，并提出可行的解决方案。他们喜欢从理论层面深入探讨问题，并制定高效的行动计划。",
                                        "2.  高度理性与分析能力：INTJ 是极其理性的个体，依赖逻辑与事实来做出决策，而非感情或直觉。他们擅长分析复杂的问题，并运用系统化的方法解决问题。这使得他们在解决技术性或策略性难题时尤为出色。",
                                        "3.  自信与独立性：INTJ 对自己的能力充满信心，通常不需要他人的肯定或赞扬。他们深信自己的判断和决策，因此有时显得固执和不易动摇。同时，INTJ 具有很强的独立性，不喜欢被束缚于传统和外界期待之中，倾向于按照自己的方式行事。",
                                        "4.  专注与决心：INTJ 对目标的追求十分执着，一旦设定了目标，他们便会全身心投入，努力克服一切困难。无论是学术研究、技术创新还是职业成就，INTJ 通常都表现出高度的专注力和长时间的坚持。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "性格缺点",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1.  过于理性与冷漠：由于 INTJ 过度依赖逻辑和分析，他们有时可能显得过于理性甚至冷漠。在面对他人情感表达时，INTJ 可能会忽视他人的感受，导致别人感到被疏远或不被理解。",
                                        "2.  固执与难以妥协：INTJ 有时对自己的观点和计划过于坚持，往往拒绝听取外界的意见或建议。这种固执性可能导致他们与他人产生冲突，特别是在团队合作时。",
                                        "3.  孤独感与社交挑战：虽然 INTJ 具有强大的社交能力，但他们更倾向于独立工作，避免大规模的社交互动。这种偏好有时会使他们感到孤独，尤其是在工作或生活中需要更多支持时，他们可能会感到缺乏亲密的关系。",
                                        "4.  过于追求完美：INTJ 对工作质量的要求极高，可能对自己和他人都设置了苛刻的标准。当事情没有按照他们的预期进展时，INTJ 可能会感到挫败甚至失望。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "适合的职业",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "INTJ 倾向于从事能够充分利用其战略思维、分析能力和创新精神的职业。以下是一些适合 INTJ 的职业方向：",
                                        "1.  科学家与研究人员：INTJ 的逻辑分析能力和解决问题的能力使得他们在工程、计算机科学等技术领域表现出色。他们可以运用自己的创新思维解决复杂的技术难题，并推动项目的成功实施。",
                                        "2.  战略顾问与企业家：INTJ 的战略眼光和长远规划能力使他们适合从事战略顾问、企业管理等职位。他们善于分析市场趋势、识别机会，并为公司或组织制定可持续的长期发展计划。",
                                        "3.  工程师与技术专家：INTJ 的逻辑分析能力和解决问题的能力使得他们在工程、计算机科学等技术领域表现出色。他们可以运用自己的创新思维解决复杂的技术难题，并推动项目的成功实施。",
                                        "4.  律师与政治家：INTJ 的深思熟虑和精确分析能力使得他们适合从事法律或政治领域的工作。作为律师，INTJ 能够凭借出色的推理和辩论能力为客户提供战略建议。在政治领域，他们也能够通过敏锐的洞察力分析局势并制定有效的策略。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "爱情和伴侣",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "INTJ 在爱情中常常追求深度与持久的关系。他们不满足于表面上的情感互动，通常渴望与伴侣在思想和价值观上有深刻的共鸣。INTJ 需要一个能够理解他们独立性和理性思维的伴侣，并且能够尊重他们对于个人空间的需求。在亲密关系中，INTJ 喜欢有条理的沟通，并期望能够共同成长和实现长远目标。",
                                        "然而，INTJ 的理性有时可能使得他们忽视感情需求，特别是在面对情感表达时，他们可能表现得较为冷静或疏远。因此，他们需要学会关注伴侣的情感需求，避免过度理性化处理感情问题。理想的伴侣是能够理解并接纳 INTJ 的复杂性格，能够与其共同发展，并在感情上给予理解与支持。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "发展建议",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1.  学会情感表达与倾听：INTJ 的理性思维有时可能使得他们忽视他人的情感需求。学会在情感表达和沟通中更加灵活，并重视他人的情感反应，将有助于改善人际关系。",
                                        "2.  提高合作与妥协能力：由于 INTJ 的独立性和固执，他们可能在团队合作中遇到挑战。学会在必要时做出妥协，开放心态接受他人的意见，将有助于增强团队合作和个人发展的效率。",
                                        "3.  增强社交技能：尽管 INTJ 更倾向于独立工作，但在现代社会，良好的人际关系和合作能力同样重要。通过积极参与社交活动和建立深厚的人际关系，INTJ 可以为自己创造更多的机会和支持网络。",
                                        "4.  接纳不完美：INTJ 对完美的追求可能导致过度焦虑和挫败感。学会接受不完美，并从失败中汲取经验教训，而非一味苛求完美，将有助于提高生活的质量和个人的心理健康。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "总的来说，INTJ 是具有远见、分析能力和战略眼光的个体，他们深思熟虑并致力于实现个人目标和理想。在职业上，他们通常追求具有挑战性和深度的工作，并且能够发挥出色的领导力。通过学会更好地表达情感、提升合作能力并接纳不完美，INTJ 将能够更好地平衡理性与感性，从而在职业、爱情和生活中取得更加圆满的成就。",
                                    ],
                                },
                            ],
                        },
                    ],
                },
                INTP: {
                    code: "INTP",
                    name: "思考者",
                    callName: "好奇的思考者",
                    description:
                        "热衷于探索知识与理论，对复杂问题充满兴趣，追求深层次的理解。",
                    traits: ["分析", "创造力", "好奇", "理性"],
                    overview:
                        'INTP（内向、直觉、思维、知觉）被称为"思想家"或"探险者"。他们是极具独立思考和创新精神的个体，常常沉浸在自己的思想世界中，乐于解答复杂的理论问题，并探索未知的知识。INTP 追求思想的自由和无拘无束，通常对知识和真理充满渴望。他们善于从各个角度审视问题，寻找创新的解决方案。INTP 个性内向，喜欢独自思考，避免复杂的人际互动，更偏向于安静且具有深度的社交方式。由于其独特的认知方式和理性分析能力，INTP 常常在学术、科学和技术领域中表现出色。',
                    detail: [
                        {
                            title: "性格特点",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1.  深思熟虑与理性分析：INTP 通常对任何问题都会进行深入思考，喜欢从多个角度分析和推理。他们极其重视逻辑和理论，对于抽象的概念和复杂的问题有着强烈的兴趣。他们不容易被表面的现象所迷惑，而是会通过深度分析来寻找真相。",
                                        "2.  创造性与创新精神：INTP 有着丰富的想象力和创造力，他们喜欢探究新的理论、观念和方法。INTP 往往能够提出具有创新性的解决方案，并且对未来充满憧憬。他们喜欢打破传统思维的束缚，寻找不一样的角度看待世界。",
                                        "3.  独立与自主性：INTP 极为重视个人的独立性，渴望拥有自由的思考和行动空间。他们通常不喜欢受到外界的干扰，更倾向于在个人的空间内进行深入思考。他们会选择追随自己内心的声音，而不是依赖他人或社会的期望。",
                                        "4.  冷静与客观：INTP 在面对问题时非常冷静和客观。他们能在情感干扰较少的情况下，专注于问题的本质，并做出理性的决策。这使得他们在处理复杂问题时表现得尤为出色。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "性格缺点",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1.  缺乏情感表达：由于过度依赖理性，INTP 可能会忽视情感的表达与理解，给他人带来冷漠的印象。特别是在亲密关系中，他们往往更专注于问题本身，而忽略了伴侣的情感需求，可能导致情感交流的困难。",
                                        "2.  拖延与不够决断：INTP 由于不断思考和分析，有时难以迅速做出决策。他们喜欢在各种可能性中权衡优劣，结果可能导致拖延，错失最佳时机。同时，他们对于某些选择的不确定性，可能使得他们很难做出最终的决定。",
                                        "3.  逃避现实与过于理想化：INTP 在面对现实生活的琐事时，可能表现出一定的逃避态度。他们更倾向于关注理论和理念，对于日常的繁琐任务或具体的细节不太感兴趣，这可能影响到他们的执行力和效率。",
                                        "4.  与人交往困难：由于其内向和理性特质，INTP 在人际交往中往往较为拘谨。他们可能在社交场合感到不自在，缺乏主动性，这可能导致他们在人际关系中显得疏远和冷淡。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "适合的职业",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "INTP 适合那些能够发挥其逻辑推理、创造力和深度思维的职业。以下是一些适合 INTP 的职业方向：",
                                        "1.  科学家与研究人员：INTP 喜欢探索未知，寻找真理，因此他们在科学、研究或技术领域表现出色。他们善于解决复杂的理论问题，并且乐于在实验室或研究中挑战现有的知识框架。",
                                        "2.  哲学家与学者：由于对理论和抽象概念的兴趣，INTP 适合从事哲学、文学、历史等学术领域的工作。他们能够深入探讨人类思想的本质，提出创新的见解，并推动学科的发展。",
                                        "3.  工程师与程序员：INTP 的分析和逻辑能力使得他们在工程、计算机科学等领域表现突出。他们能够设计复杂的系统并提出创新的技术解决方案，尤其是在技术开发、软件编程和数据分析等领域。",
                                        "4.  顾问与战略分析师：INTP 的战略眼光和理性分析能力，使得他们在商业和咨询领域也有很大的潜力。作为战略分析师或顾问，INTP 能够帮助公司进行市场分析、产品创新等方面的决策。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "爱情和伴侣",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "INTP 在爱情中往往追求深度的情感连接与思想上的共鸣。他们希望与伴侣在智力和价值观上有一致性，渴望一段能够相互支持并且富有创造性的关系。INTP 的爱情观通常理性且理想化，他们期待能够与伴侣共同探索世界、分享理念并且解决问题。INTP 喜欢独立，但同时也渴望与伴侣建立起深刻的情感联系。由于他们较少表现情感，因此在爱情中可能需要更加关注和理解伴侣的情感需求。",
                                        "在选择伴侣时，INTP 更倾向于找一个具有同样深思熟虑、理性且充满创意的伴侣。理想的伴侣能够尊重 INTP 的独立性，并且理解他们在某些情况下对情感表达的不足。伴侣的理解与支持，将是 INTP 能够在爱情中获得幸福和满足的重要因素。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "发展建议",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1.  增强情感表达：INTP 需要学习如何更好地表达自己的情感，并关注他人的情感需求。通过增进与他人的情感交流，INTP 将能够在亲密关系中获得更多的支持和理解。",
                                        "2.  克服拖延与决策困难：INTP 倾向于过度思考和分析问题，导致难以做出决策。为了避免拖延，INTP 可以尝试设定更明确的时间限制，并在做决策时更注重实践和执行，而不是仅仅停留在理论层面。",
                                        "3.  提高社交技能：INTP 的内向特质有时可能使他们在人际交往中感到局促。通过增强与他人沟通和建立联系的能力，INTP 可以扩大自己的社交圈，并在工作和生活中获得更多支持。",
                                        "4.  接纳现实生活的细节：INTP 在追求理论和理念的同时，有时会忽视生活中的细节。为了提高执行力和生活质量，INTP 可以学会接纳日常生活中的琐事，并在其中找到创造性解决方案。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "总的来说，INTP 是充满创造力和理性思维的个体，他们在学术、技术和科学领域展现出色的能力。通过增强情感表达、提升决策能力以及改进社交技能，INTP 能够更好地应对现实世界的挑战，平衡内心的理想与外部的实际，进一步实现个人的成长与幸福。",
                                    ],
                                },
                            ],
                        },
                    ],
                },
                ISFJ: {
                    code: "ISFJ",
                    name: "守卫者",
                    callName: "温暖的守护者",
                    description:
                        "温暖体贴，忠诚可靠，关心他人的需求，乐于维护和谐与稳定，具有强烈的责任感。",
                    traits: ["忠诚", "温和", "细心", "责任心"],
                    overview:
                        'ISFJ（内向、感觉、情感、判断）被称为"守护者"或"照顾者"。他们是温和、可靠且具有奉献精神的人，通常乐于为他人提供帮助，并以实际行动表现出对他人的关爱。ISFJ 们注重细节，生活中倾向于关注他人的需求，尤其在家庭、朋友和社区中担任重要角色。由于他们深刻的责任感和敏感的情感，ISFJ 往往愿意为他人付出，努力维护和谐的关系和稳定的环境。ISFJ 追求安全感，并倾向于保持稳定与秩序，避免变化带来的不确定性。',
                    detail: [
                        {
                            title: "性格特点",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1.  善于关心他人：ISFJ 在人际关系中表现出极强的照顾欲望。他们具有同理心，能够敏锐地察觉他人的情感和需求，愿意无私地为他人提供帮助。无论是亲朋好友还是陌生人，ISFJ 都会尽力提供关怀与支持。",
                                        "2.  注重细节与传统：ISFJ 常常注重日常生活中的细节，喜欢做一些看似微不足道但能带来舒适和秩序的事情。他们对传统和常规有很高的尊重，喜欢保持生活的稳定和可预见性，这使得他们成为非常可靠的伙伴。",
                                        "3.  责任感强：ISFJ 是天生的责任感强者，他们深知自己的责任，并且会付出努力去履行。无论是在家庭、工作还是社交场合，他们总是能够尽力完成任务，以保证事情的顺利进行。",
                                        "4.  内向且保守：ISFJ 是内向型的人，虽然他们非常关心他人，但通常更喜欢在小范围内活动，倾向于避免过于复杂或喧嚣的社交场合。相较于冒险，ISFJ 更偏向于维护已经建立的关系与常规。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "性格缺点",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1.  过度自我牺牲：ISFJ 的无私和奉献精神可能会让他们忽视自己的需求和感受。他们往往过度关心他人，甚至在自己需要帮助时也不愿意向他人求助。长时间的自我牺牲可能导致情感上的疲惫和压力。",
                                        "2.  过于敏感：由于他们极其关注他人的需求，ISFJ 有时会过度解读他人的行为和话语，导致过度敏感。这种敏感可能让他们容易感到受伤或失望，特别是当他们的付出没有得到认可时。",
                                        "3.  抵触变化：ISFJ 喜欢稳定和秩序，习惯于按照既定的方式进行生活。这使得他们在面对变化时可能显得犹豫和不适应。尤其在工作和家庭中，面对新情况时，他们可能会感到不安，并避免采取新的方法或方案。",
                                        "4.  回避冲突：ISFJ 喜欢维持和谐的人际关系，因此，他们往往避免与他人发生冲突。这种回避态度可能使他们压抑内心的不满，导致长期的不健康情感积压，甚至影响到他们的身心健康。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "适合的职业",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "ISFJ 适合那些能够让他们发挥关怀、组织能力和细致观察力的职业。以下是一些适合 ISFJ 的职业方向：",
                                        "1.  护理与医疗行业：ISFJ 由于其敏感和关怀他人的天性，非常适合从事护理、医疗、心理咨询等行业。在这些职业中，他们可以发挥帮助他人的长处，提供温暖和支持。",
                                        "2.  教师与教育行业：ISFJ 也非常适合教育工作，尤其是在小学或中学教育领域。他们对学生有耐心，能够悉心照料，并且善于传授知识，帮助学生成长。",
                                        "3.  社会工作者与非营利组织：由于他们天生的同理心和对他人的关怀，ISFJ 在社会工作、慈善事业或非营利组织中也能找到自己的位置，尤其在帮助弱势群体和社区服务方面，ISFJ 可以做出很大贡献。",
                                        "4.  行政管理与后勤支持：ISFJ 是有条理和注重细节的人，因此，他们在行政、后勤或项目管理方面非常出色。确保工作的顺利进行，并维持稳定的环境，正是他们擅长的领域。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "爱情和伴侣",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "在爱情中，ISFJ 是温柔、忠诚和关爱的伴侣。他们会全身心投入到关系中，关心伴侣的需求，并尽力为对方提供支持与关怀。在选择伴侣时，ISFJ 寻找的是能够理解、尊重和珍惜他们付出的伴侣。ISFJ 常常把伴侣的幸福看得比自己的更重要，但也需要伴侣关注他们的情感需求。在恋爱关系中，ISFJ 倾向于建立稳定且有安全感的关系，他们不喜欢不确定性和冲突，而更享受平静和和谐的互动。",
                                        "理想的伴侣应该是能够理解 ISFJ 的细腻感情，并且愿意为他们创造一个温暖的家庭环境。ISFJ 在爱情中非常专注并且忠诚，他们希望自己与伴侣之间有深厚的情感联系，双方能够共同成长并分享生活中的点滴。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "发展建议",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1.  学会关注自己：ISFJ 有时会过于关注他人，忽略自己的需求。为了避免情感上的消耗，他们需要学会关注自己，给自己更多的时间和空间来放松和恢复。通过适当的休息和自我关爱，ISFJ 能够保持内心的平衡，提升身心健康。",
                                        "2.  勇于面对变化：ISFJ 通常会回避变化，但适当的变化可以带来新的机会和成长。ISFJ 可以通过逐步适应新的情境，尝试接受变化，并在变化中寻找创新的可能性。这不仅有助于个人的职业发展，也能够提高其应对生活挑战的能力。",
                                        "3.  增强情感表达：ISFJ 在表达自己感受时可能比较保守，这有时会让他们的情感需求未被他人察觉。通过更加开放地表达自己的感受和需求，ISFJ 可以避免内心的不满积压，增强与他人的情感联结。",
                                        "4.  提升自信与决策能力：ISFJ 有时过于依赖他人的看法，缺乏自信，尤其在面对决策时会显得犹豫。通过培养决策能力，并相信自己的判断，ISFJ 可以在生活中更加自信和果断，从而更好地实现自己的目标。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "总的来说，ISFJ 是极具奉献精神、细致入微的个体，他们在提供帮助和关怀方面展现出色的能力。通过关注自我需求、提高应对变化的能力以及增强情感表达，ISFJ 能够在个人和职业上取得更大的成就，享受更加充实和幸福的生活。",
                                    ],
                                },
                            ],
                        },
                    ],
                },
                ISFP: {
                    code: "ISFP",
                    name: "艺术家",
                    callName: "自由的艺术家",
                    description:
                        "追求自由与美感，热衷于感官体验与自我表达，擅长通过创意展现独特个性和情感。",
                    traits: ["创造力", "自由", "敏感", "个性"],
                    overview: [
                        'ISFP（内向、感觉、情感、知觉）被称为"探险家"或"艺术家"，是富有创造力、敏感而温和的人。ISFP 喜欢通过体验来了解世界，倾向于享受当下的生活而非过度思考未来。他们更关注内心的感受和情感，而非外界的评价和标准，通常追求个人自由、舒适与独特的生活方式。ISFP 往往不愿束缚自己，倾向于通过艺术、音乐或其他形式表达自己独特的想法与情感。',
                        "ISFP 的生活节奏通常较慢，更喜欢在平和与自然的环境中度过时光，避免过于繁忙或紧张的生活压力。他们容易为他人提供帮助，但通常是以一种温和而不求回报的方式进行。他们通常具有较强的美学感受力，能够欣赏和创造美好的事物，尤其在艺术创作上展现非凡的天赋。",
                    ],
                    detail: [
                        {
                            title: "性格特点",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. 创造性与艺术天赋：ISFP 在艺术方面有着与生俱来的天赋，他们常常能从细节中发现美，并通过视觉艺术、音乐、写作等方式表达自己的创意。无论是在绘画、设计，还是在其他创造性的领域，ISFP 都展现出独特的才华。",
                                        "2. 敏感而温和：ISFP 对外界的刺激十分敏感，尤其在情感上，他们能够细腻地察觉到他人的情绪，并给予同情与关怀。这使得他们在人际关系中往往表现得非常温柔，能够敏锐地回应他人的需求。",
                                        "3. 独立与自由：ISFP 不喜欢被束缚或按照他人的标准生活，他们渴望自由和独立。无论是工作还是生活，他们都更倾向于按照自己的节奏和方式前进。ISFP 喜欢独立思考，不喜欢被强迫做出决定，而是希望通过自身的感觉和直觉来引导自己的行动。",
                                        "4. 注重体验：ISFP 喜欢体验世界，尤其是感官和情感上的体验。他们喜欢去探索未知，享受大自然、艺术或其他美好事物带来的愉悦，倾向于活在当下，不太关注未来的不确定性或过去的遗憾。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "性格缺点",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. 逃避冲突与责任：ISFP 倾向于避免冲突，尤其是在情感问题上，他们常常选择回避那些需要面对和解决的难题。这种回避态度有时会使他们在关键时刻不敢直面问题，从而错失解决问题的机会。",
                                        "2. 过于理想化：ISFP 对生活有着理想化的看法，他们往往希望周围的一切都能按照他们的美好愿景发展。这种理想化的心态可能让他们失去现实感，当现实无法满足他们的期望时，他们可能会感到失望和沮丧。",
                                        "3. 不善于计划和组织：由于 ISFP 更倾向于体验和感受，他们通常缺乏长远的规划和组织能力。他们有时会觉得事先的计划限制了自己的自由，导致他们在处理工作和生活任务时可能显得有些拖沓和无序。",
                                        "4. 情感上的退缩：ISFP 的情感较为内向，他们不善于向他人表达自己的需求或情感。在遭遇不满或压力时，他们可能会选择闭口不言，独自承受，甚至在感情关系中也可能表现得较为被动和疏远。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "适合的职业",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "ISFP 更适合那些能够发挥他们创意、艺术天赋与独立性的职业。以下是一些适合 ISFP 的职业方向：",
                                        "1. 艺术与设计领域：ISFP 常常在艺术创作方面有卓越的天赋。无论是绘画、摄影、雕塑、电影制作还是其他视觉艺术形式，ISFP 都能够充分发挥他们的创造力和审美眼光。",
                                        "2. 音乐行业：音乐对于 ISFP 来说是一种情感表达的方式。他们常常在音乐创作、表演或音响设计等领域展示才能。在音乐行业，ISFP 能够将自己的情感与创意融入到作品中，找到自己的艺术声音。",
                                        "3. 治疗与心理咨询：ISFP 对他人的情感和需求十分敏感，因此他们非常适合从事心理学、治疗或咨询等行业。他们能够为需要帮助的人提供温暖和支持，创造一个安全的情感空间。",
                                        "4. 手工艺与创意工作：ISFP 喜欢通过实际的操作与创作来表达自己。无论是木工、陶艺、服装设计，还是其他手工艺工作，ISFP 都能够通过自己的双手创造美好的物品，并享受其中的过程。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "爱情和伴侣",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "在爱情中，ISFP 是温柔、体贴和忠诚的伴侣。他们关注伴侣的感受，愿意为对方提供关爱和支持。ISFP 喜欢与伴侣分享深刻的情感联系，并以温暖的方式表达爱意。在恋爱关系中，ISFP 期望能够与伴侣共同享受轻松和愉快的时光，他们倾向于避免冲突和争吵，更希望通过默契与理解来维系关系。",
                                        "ISFP 对伴侣有较高的情感依赖，他们需要伴侣的理解和支持，但他们不喜欢束缚和控制，期望有足够的自由空间。在选择伴侣时，ISFP 更加注重情感上的契合，他们希望找到一个能够与自己共享美好时光的人。理想的伴侣应当能够尊重 ISFP 的独立性，并且愿意与他们一起追求美好与和平的生活。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "发展建议",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. 增强自信与决策能力：ISFP 有时在面对重要决策时会显得犹豫不决。为了提升个人成长，ISFP 可以通过积累更多经验来增强自信，学会相信自己的直觉，并作出更加果断的决策。",
                                        "2. 学会处理冲突：ISFP 避免冲突的倾向可能会导致他们逃避现实中的难题。为了避免情感上的压抑，他们需要学会面对和处理冲突，勇敢地表达自己的感受和需求，维护自己在关系中的立场。",
                                        "3. 提高时间管理与规划能力：ISFP 往往不喜欢规划，但适当的组织和计划可以帮助他们更加高效地完成任务。在工作和生活中，ISFP 可以通过一些简单的规划和时间管理技巧，提升自己的生产力，避免因拖延而产生不必要的压力。",
                                        "4. 接受现实与灵活调整：ISFP 的理想化倾向可能让他们在面对现实时感到失望。为了更好地应对挑战，他们可以学会接受现实的复杂性，并在必要时调整自己的期望，保持灵活应变的态度。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "ISFP 是具有创造力和温柔心灵的人，他们注重内心感受，善于在艺术与创造中找到自我。通过加强自信、解决冲突的能力以及灵活应对生活中的挑战，ISFP 能够在个人和职业上取得更大的成就，并实现更充实的人生。",
                                    ],
                                },
                            ],
                        },
                    ],
                },
                ISTJ: {
                    code: "ISTJ",
                    name: "实干家",
                    callName: "尽责的实干家",
                    description:
                        "责任感强，踏实可靠，注重规则和效率，习惯有条理地完成任务。",
                    traits: ["严谨", "务实", "可靠", "传统"],
                    overview: [
                        'ISTJ（内向、感觉、思维、判断）被称为"检察官"或"责任者"，他们是踏实、可靠、并且注重细节的人。ISTJ 的人生哲学通常是按部就班、认真负责，对自己和他人都有较高的要求。他们有强烈的责任感，喜欢在清晰的规则与结构中工作，崇尚稳定和效率。由于其较为保守的思维方式，ISTJ 不容易接受突如其来的变化，更倾向于依赖经验和事实来做决策。',
                        "ISTJ 是非常实际的人，他们更注重现实，追求实际的成就而非空泛的梦想。尽管他们的情感表现不如其他类型那么外露，但他们内心的责任感和忠诚感常常表现得尤为强烈。ISTJ 一旦承诺了一件事情，就会尽全力去完成，不会轻言放弃。",
                    ],
                    detail: [
                        {
                            title: "性格特点",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. 高效且注重细节：ISTJ 擅长于高效地组织事务，并在实际操作中注重每个细节的准确性。他们通过严格的标准和细致的规划，确保每个任务都能顺利完成。",
                                        "2. 责任心强：ISTJ 是非常有责任感的人，他们会对自己的任务和承诺付出百分百的努力，确保按时按质地完成工作。无论是在工作中还是在私人生活中，ISTJ 都非常看重履行责任。",
                                        "3. 现实主义者：与一些理想主义者不同，ISTJ 更倾向于务实，他们不喜欢空洞的理论和不切实际的计划。他们依赖于经验和事实，做决策时通常会遵循逻辑和常规，而非依赖直觉或感情。",
                                        "4. 保守与传统：ISTJ 通常遵循传统的做事方式，不太喜欢冒险或做出太多创新。他们更倾向于遵循已有的规则和系统，任何的变化都需要充分的理由和准备。",
                                        "5. 内向且独立：ISTJ 通常是内向的，他们喜欢独立思考和解决问题，通常需要独处的时间来恢复精力。在社交场合中，他们比较保守，不擅长表达情感，但他们的忠诚和可靠是非常值得依赖的。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "性格缺点",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. 缺乏灵活性：由于过于依赖结构和常规，ISTJ 有时会显得不够灵活。当面对新的环境或需要创新的情况时，他们可能会感到不适应，难以快速调整自己的思维方式。",
                                        "2. 过于固执：ISTJ 很难接受改变，尤其是在缺乏充分证据的情况下。他们更倾向于坚持自己认为正确的做法，这可能会使他们在团队合作时显得不够开放，甚至有时显得顽固。",
                                        "3. 情感表达不足：虽然 ISTJ 很忠诚、可靠，但他们在情感上常常比较内向，不善于表达自己的感受。在亲密关系中，ISTJ 可能让伴侣感觉到距离感，缺乏足够的情感交流。",
                                        "4. 对他人要求高：由于对自己有高标准，ISTJ 往往也对他人要求严格。他们希望他人能够遵循规则并高效完成任务，可能会因此显得过于严苛或不够宽容。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "适合的职业",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "ISTJ 通常适合那些要求高度组织化、注重细节且有明确目标的职业。以下是一些适合 ISTJ 的职业领域：",
                                        "1. 项目经理或工程师：ISTJ 有很强的组织能力和执行力，在项目管理、工程设计、建筑和制造等领域非常出色。他们能够有效地管理时间、资源，并确保各项任务按时完成。",
                                        "2. 会计或审计师：由于 ISTJ 注重细节并且具备较强的逻辑能力，他们非常适合从事会计、审计等需要精确与遵守规则的工作。这些职位需要对数字的敏感性和对准确性的极高要求，ISTJ 是理想的人选。",
                                        "3. 法务工作者或律师：ISTJ 的严谨思维和责任感使他们在法律行业表现出色。他们注重细节、遵守规章制度，能够在复杂的法律事务中提供精确的分析和解决方案。",
                                        "4. 军事或执法工作：ISTJ 非常适合从事需要纪律和严格组织的职业，例如军事、警察、消防员等。在这些领域，ISTJ 能够遵循流程，完成任务并维护秩序。",
                                        "5. 行政管理或人力资源：由于 ISTJ 善于管理和组织事务，他们在行政管理、办公室管理、人力资源等职位上也能够游刃有余，确保工作流程高效运行。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "爱情和伴侣",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "在爱情中，ISTJ 是忠诚且可靠的伴侣。他们注重稳定和承诺，一旦投入感情，就会全心全意地为对方付出。虽然 ISTJ 不善于通过言语表达爱意，但他们会通过实际行动和责任感来体现自己的忠诚。",
                                        "ISTJ 需要一个理解并欣赏他们内向和实际一面的伴侣。理想的伴侣应当能够提供稳定的情感支持，同时给予 ISTJ 适当的空间。ISFJ、ESFJ 或 ENFJ 等性格类型可能更能理解 ISTJ 的需求，彼此间能够形成互补关系。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "发展建议",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. 提高灵活性与适应能力：为了更好地适应不断变化的世界，ISTJ 可以在工作和生活中尝试接受新的方法和创新的思维方式。通过主动学习新技能或开放心态，他们能更好地面对挑战。",
                                        "2. 改善情感沟通：在亲密关系中，ISTJ 可以努力提高情感表达的能力。学习如何更好地与伴侣沟通和分享自己的感受，将帮助他们更好地维系和发展深厚的情感联系。",
                                        "3. 适度放松控制：由于过于注重规则和秩序，ISTJ 有时可能会忽视生活中的灵活性和变化。适度放松对完美的追求，接受不完美的现实，可以让他们在工作和生活中更轻松地前进。",
                                        "4. 提升团队合作精神：ISTJ 可以通过增强团队合作意识，培养更多的开放性思维来改善与他人的沟通和协作。了解他人的想法并学会适应他人的工作方式，可以增强他们的领导力与影响力。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "总的来说，ISTJ 是稳定、可靠且高效的个体，他们通过坚守原则和责任感在工作和生活中表现出色。通过提高灵活性、改善情感表达以及适应变化的能力，ISTJ 可以进一步提升个人的综合素质，并实现更充实和满意的人生。",
                                    ],
                                },
                            ],
                        },
                    ],
                },
                ISTP: {
                    code: "ISTP",
                    name: "工匠",
                    callName: "灵巧的工匠",
                    description:
                        "务实而灵活，擅长动手解决问题，喜欢独立行动和探索新方法。",
                    traits: ["灵巧", "务实", "独立", "探索"],
                    overview: [
                        'ISTP（内向、感觉、思维、知觉）是"工匠"或"冒险家"，他们通常以独立、务实和冷静的方式看待世界。ISTP 喜欢自己动手做事，享受通过解决实际问题来获得成就感。因为他们倾向于依赖事实和经验，所以他们通常对事物的理解较为深入、直接，具有极强的实际操作能力。ISTP 也热衷于冒险，喜欢挑战自己，不畏艰险，且具有很强的应变能力。虽然他们看起来低调和内向，但一旦面对需要行动的时刻，ISTP 就能展现出非凡的勇气与决策能力。',
                        "ISTP 不喜欢被束缚，通常追求自由与独立，喜欢有空间去做自己想做的事情。他们的世界通常充满了探索和实验，因此常常在人生的各个阶段寻找新的挑战和机会。",
                    ],
                    detail: [
                        {
                            title: "性格特点",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. 实践性强，解决问题能力突出：ISTP 很擅长动手实践，喜欢通过实际操作来解决问题。无论是修理东西还是优化工作流程，他们总能找到有效的解决方案。",
                                        "2. 理性且冷静：ISTP 在面对问题时，总能保持冷静的头脑，他们以理性和客观为基础做出决策，很少被情绪干扰。即便在压力环境下，ISTP 也能保持清晰的思维。",
                                        "3. 独立与自由：ISTP 通常非常珍惜个人自由，他们不喜欢被拘束或受限制。在生活中，ISTP 更倾向于独立行动，不喜欢依赖他人或被他人管理。",
                                        "4. 适应性强：ISTP 的适应性极强，他们可以快速应对变化，并能在未知的环境中游刃有余。无论是面对突发事件，还是在陌生的环境中，他们都能够迅速找到自己的节奏并解决问题。",
                                        "5. 务实且不拘小节：ISTP 通常务实，注重实际效果，不喜欢空谈。他们关注的是具体的成果和效果，对于那些没有实际意义的事情通常会显得漠不关心。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "性格缺点",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. 过于独立，难以依赖他人：由于 ISTP 极度珍惜自己的独立性，他们在与他人合作时可能会显得冷漠和不愿意依赖他人。他们有时可能会让身边的人觉得自己很难亲近。",
                                        "2. 避免情感表达：ISTP 在情感表达方面较为保守，他们可能会压抑自己的情感，很少主动表达自己的感受。与他人沟通时，他们更倾向于关注逻辑和行动，而非情感和人际关系的细腻。",
                                        "3. 缺乏耐心：对于无聊或重复性的工作，ISTP 容易失去兴趣。由于他们更喜欢做充满挑战性和动手操作的事情，面对枯燥的任务时，他们可能会显得缺乏耐心，甚至有些冲动。",
                                        "4. 避免冲突，逃避问题：ISTP 倾向于避免冲突或面对让他们不舒服的情感话题，可能会选择回避或冷处理，这可能导致未解决的矛盾或问题。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "适合的职业",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "由于 ISTP 具有高度的实践性和解决问题的能力，他们通常适合那些需要动手操作、具有挑战性并能独立完成的职业。以下是一些适合 ISTP 的职业：",
                                        "1. 工程师或技术专家：ISTP 具备极强的技术操作能力和解决问题的天赋，非常适合从事工程、技术、机械、建筑等领域的工作。他们能够通过动手解决实际问题，并在复杂的工作环境中发挥极大的效能。",
                                        "2. 外科医生或急救人员：ISTP 冷静、理性，且具备在压力下迅速作出决策的能力，因此非常适合在医疗领域担任外科医生或急救人员。在紧急情况下，ISTP 能够迅速判断病情并采取有效行动。",
                                        "3. 飞行员或司机：由于 ISTP 具备较强的操作能力和对细节的关注，他们适合从事飞行员或其他需要高精度操作的职业。飞行员的工作既富有挑战性，又需要高度的责任感，正符合 ISTP 的性格特点。",
                                        "4. 警察或特工：ISTP 喜欢冒险，具备较强的应变能力和实际操作能力，非常适合从事警察、特工等职业。在这些职业中，ISTP 可以充分发挥自己的决策能力和行动力。",
                                        "5. 运动员或教练：ISTP 具有较强的身体协调能力和对挑战的热情，因此他们也非常适合从事体育相关的职业。无论是运动员还是教练，ISTP 都能在体育领域中展现出色的表现。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "爱情和伴侣",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "在爱情中，ISTP 倾向于保持独立和自由，但他们也能为伴侣提供稳定和支持。由于他们的内向性格，ISTP 不善于通过言语表达感情，但他们会通过实际行动来表示对伴侣的关心和爱意。ISTP 在感情关系中通常需要一个能够理解并尊重他们独立性的人。",
                                        "理想的伴侣应该能给 ISTP 足够的自由空间，同时也能与他们共同享受冒险与探索的乐趣。像 ESFJ、ISFJ 等性格类型的伴侣可能会更容易与 ISTP 建立深厚的关系，因为他们能够理解 ISTP 的需求，并且能在生活中提供更多的情感支持。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "发展建议",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "1. 学会更好地表达情感：虽然 ISTP 喜欢通过行动来表达关心，但适当的情感交流有助于加深与他人的关系。通过学习更好地表达内心的感受，ISTP 可以建立更为深厚的人际关系，尤其是在亲密关系中。",
                                        "2. 提高耐心和耐性：ISTP 面对繁琐的任务时，可能会缺乏耐心。学习如何对待那些看似枯燥的工作，并提升耐性，将有助于他们在工作和生活中更加成功。",
                                        "3. 接受更多的合作与帮助：尽管 ISTP 很喜欢独立行动，但学会在适当的情况下依赖他人和接受帮助，将有助于他们更好地完成任务，避免因孤立过度而产生压力。",
                                        "4. 增强情感处理能力：ISTP 有时会回避冲突和情感问题，适当地处理情感和冲突是他们在个人成长中的重要环节。通过学习如何面对并解决情感问题，ISTP 可以建立更加成熟的人际关系。",
                                    ],
                                },
                            ],
                        },
                        {
                            title: "",
                            detail: [
                                {
                                    title: "",
                                    detail: [
                                        "总结而言，ISTP 是极具实践性、冷静理性且充满冒险精神的人。他们在工作中能够高效解决问题，并且在面临压力和挑战时表现出色。通过提高情感表达能力、增加耐性并学会适应他人的帮助，ISTP 可以更好地发展自己的职业和个人关系，过上更加充实的生活。",
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
        },
    },
    result: {
        title: "您的MBTI性格类型",
        meta: {
            title: "MBTI性格测试结果 - 了解你的性格类型",
            description:
                "通过MBTI性格测试，了解你的性格类型，探索你的性格特点和潜在优势。",
            keywords: "MBTI性格测试,性格类型,性格分析,性格测试",
        },
        traitAnalysis: "特质分析",
        traitsDescription: "性格特征",
        buttons: {
            viewDetail: "查看详细信息",
        },
        traits: {
            E: {
                name: "外向型",
                code: "E",
            },
            I: {
                name: "内向型",
                code: "I",
            },
            S: {
                name: "感觉型",
                code: "S",
            },
            N: {
                name: "直觉型",
                code: "N",
            },
            T: {
                name: "思考型",
                code: "T",
            },
            F: {
                name: "情感型",
                code: "F",
            },
            J: {
                name: "判断型",
                code: "J",
            },
            P: {
                name: "知觉型",
                code: "P",
            },
        },
    },
    test: {
        title: "MBTI 性格测试",
        meta: {
            title: "MBTI 性格测试问卷 - 专业的性格类型测评",
            description:
                "参与专业的 MBTI 性格测试，通过93个精心设计的问题，科学评估你的性格类型。测试完全免费，帮助你更好地了解自己。",
            keywords:
                "MBTI测试,性格测试,在线测试,免费测试,人格测试,心理测试,MBTI问卷",
        },
        questions: [
            {
                question: "当你要外出一整天，你会",
                options: [
                    {
                        value: "J",
                        text: "计划你要做什么和在什么时候做",
                    },
                    {
                        value: "P",
                        text: "说去就去",
                    },
                ],
            },
            {
                question: "你认为自己是一个",
                options: [
                    {
                        value: "P",
                        text: "较为随兴所至的人",
                    },
                    {
                        value: "J",
                        text: "较为有条理的人",
                    },
                ],
            },
            {
                question: "假如你是一名老师，你会选教",
                options: [
                    {
                        value: "S",
                        text: "以事实为主的课程",
                    },
                    {
                        value: "N",
                        text: "涉及理论的课程",
                    },
                ],
            },
            {
                question: "你通常",
                options: [
                    {
                        value: "E",
                        text: "与人容易混熟",
                    },
                    {
                        value: "I",
                        text: "比较沉静或矜持",
                    },
                ],
            },
            {
                question: "一般来说，你和哪些人比较合得来？",
                options: [
                    {
                        value: "N",
                        text: "富于想象力的人",
                    },
                    {
                        value: "S",
                        text: "现实的人",
                    },
                ],
            },
            {
                question: "你是否经常让",
                options: [
                    {
                        value: "F",
                        text: "你的情感支配你的理智",
                    },
                    {
                        value: "T",
                        text: "你的理智主宰你的情感",
                    },
                ],
            },
            {
                question: "处理许多事情上，你会喜欢",
                options: [
                    {
                        value: "P",
                        text: "凭兴所至行事",
                    },
                    {
                        value: "J",
                        text: "按照计划行事",
                    },
                ],
            },
            {
                question: "你是否",
                options: [
                    {
                        value: "E",
                        text: "容易让人了解",
                    },
                    {
                        value: "I",
                        text: "难于让人了解",
                    },
                ],
            },
            {
                question: "按照程序表做事",
                options: [
                    {
                        value: "J",
                        text: "合你心意",
                    },
                    {
                        value: "P",
                        text: "令你感到束缚",
                    },
                ],
            },
            {
                question: "当你有一项特别的任务，你会喜欢",
                options: [
                    {
                        value: "J",
                        text: "开始前小心组织计划",
                    },
                    {
                        value: "P",
                        text: "边做边找要做什么",
                    },
                ],
            },
            {
                question: "在大多数情况下，你会选择",
                options: [
                    {
                        value: "P",
                        text: "顺其自然",
                    },
                    {
                        value: "J",
                        text: "按程序表做事",
                    },
                ],
            },
            {
                question: "大多数人会说你是一个",
                options: [
                    {
                        value: "I",
                        text: "重视自我隐私的人",
                    },
                    {
                        value: "E",
                        text: "非常坦率开放的人",
                    },
                ],
            },
            {
                question: "你宁愿被人认为是一个",
                options: [
                    {
                        value: "S",
                        text: "实事求是的人",
                    },
                    {
                        value: "N",
                        text: "机灵的人",
                    },
                ],
            },
            {
                question: "在一大群人当中，通常是",
                options: [
                    {
                        value: "E",
                        text: "你介绍大家认识",
                    },
                    {
                        value: "I",
                        text: "别人介绍你",
                    },
                ],
            },
            {
                question: "你会跟哪些人做朋友？",
                options: [
                    {
                        value: "N",
                        text: "常提出新主意的",
                    },
                    {
                        value: "S",
                        text: "脚踏实地的",
                    },
                ],
            },
            {
                question: "你倾向",
                options: [
                    {
                        value: "F",
                        text: "重视感情多于逻辑",
                    },
                    {
                        value: "T",
                        text: "重视逻辑多于感情",
                    },
                ],
            },
            {
                question: "你比较喜欢",
                options: [
                    {
                        value: "P",
                        text: "坐观事情发展才作计划",
                    },
                    {
                        value: "J",
                        text: "很早就作计划",
                    },
                ],
            },
            {
                question: "你喜欢花很多的时间",
                options: [
                    {
                        value: "I",
                        text: "一个人独处",
                    },
                    {
                        value: "E",
                        text: "和别人在一起",
                    },
                ],
            },
            {
                question: "与很多人一起会",
                options: [
                    {
                        value: "E",
                        text: "令你活力倍增",
                    },
                    {
                        value: "I",
                        text: "常常令你心力交瘁",
                    },
                ],
            },
            {
                question: "你比较喜欢",
                options: [
                    {
                        value: "J",
                        text: "很早便把约会、社交聚集等事情安排妥当",
                    },
                    {
                        value: "P",
                        text: "无拘无束，看当时有什么好玩就做什么",
                    },
                ],
            },
            {
                question: "计划一个旅程时，你较喜欢",
                options: [
                    {
                        value: "P",
                        text: "大部分的时间都是跟当天的感觉行事",
                    },
                    {
                        value: "J",
                        text: "事先知道大部分的日子会做什么",
                    },
                ],
            },
            {
                question: "在社交聚会中，你",
                options: [
                    {
                        value: "I",
                        text: "有时感到郁闷",
                    },
                    {
                        value: "E",
                        text: "常常乐在其中",
                    },
                ],
            },
            {
                question: "你通常",
                options: [
                    {
                        value: "E",
                        text: "和别人容易混熟",
                    },
                    {
                        value: "I",
                        text: "趋向自处一隅",
                    },
                ],
            },
            {
                question: "哪些人会更吸引你？",
                options: [
                    {
                        value: "N",
                        text: "一个思维敏捷及非常聪颖的人",
                    },
                    {
                        value: "S",
                        text: "实事求是，具丰富常识的人",
                    },
                ],
            },
            {
                question: "在日常工作中，你会",
                options: [
                    {
                        value: "P",
                        text: "颇为喜欢处理迫使你分秒必争的突发",
                    },
                    {
                        value: "J",
                        text: "通常预先计划，以免要在压力下工作",
                    },
                ],
            },
            {
                question: "你认为别人一般",
                options: [
                    {
                        value: "I",
                        text: "要花很长时间才认识你",
                    },
                    {
                        value: "E",
                        text: "用很短的时间便认识你",
                    },
                ],
            },
            {
                question:
                    "在下列每一对词语中，哪一个词语更合你心意？请仔细想想这些词语的意义，而不要理会他们的字形或读音。",
                options: [
                    {
                        value: "I",
                        text: "注重隐私",
                    },
                    {
                        value: "E",
                        text: "坦率开放",
                    },
                ],
            },
            {
                question:
                    "在下列每一对词语中，哪一个词语更合你心意？请仔细想想这些词语的意义，而不要理会他们的字形或读音。",
                options: [
                    {
                        value: "J",
                        text: "预先安排地",
                    },
                    {
                        value: "P",
                        text: "无计划地",
                    },
                ],
            },
            {
                question:
                    "在下列每一对词语中，哪一个词语更合你心意？请仔细想想这些词语的意义，而不要理会他们的字形或读音。",
                options: [
                    {
                        value: "N",
                        text: "抽象",
                    },
                    {
                        value: "S",
                        text: "具体",
                    },
                ],
            },
            {
                question:
                    "在下列每一对词语中，哪一个词语更合你心意？请仔细想想这些词语的意义，而不要理会他们的字形或读音。",
                options: [
                    {
                        value: "F",
                        text: "温柔",
                    },
                    {
                        value: "T",
                        text: "坚定",
                    },
                ],
            },
            {
                question:
                    "在下列每一对词语中，哪一个词语更合你心意？请仔细想想这些词语的意义，而不要理会他们的字形或读音。",
                options: [
                    {
                        value: "T",
                        text: "思考",
                    },
                    {
                        value: "F",
                        text: "感受",
                    },
                ],
            },
            {
                question:
                    "在下列每一对词语中，哪一个词语更合你心意？请仔细想想这些词语的意义，而不要理会他们的字形或读音。",
                options: [
                    {
                        value: "S",
                        text: "事实",
                    },
                    {
                        value: "N",
                        text: "意念",
                    },
                ],
            },
            {
                question:
                    "在下列每一对词语中，哪一个词语更合你心意？请仔细想想这些词语的意义，而不要理会他们的字形或读音。",
                options: [
                    {
                        value: "P",
                        text: "冲动",
                    },
                    {
                        value: "J",
                        text: "决定",
                    },
                ],
            },
            {
                question:
                    "在下列每一对词语中，哪一个词语更合你心意？请仔细想想这些词语的意义，而不要理会他们的字形或读音。",
                options: [
                    {
                        value: "E",
                        text: "热衷",
                    },
                    {
                        value: "I",
                        text: "文静",
                    },
                ],
            },
            {
                question:
                    "在下列每一对词语中，哪一个词语更合你心意？请仔细想想这些词语的意义，而不要理会他们的字形或读音。",
                options: [
                    {
                        value: "I",
                        text: "文静",
                    },
                    {
                        value: "E",
                        text: "外向",
                    },
                ],
            },
            {
                question:
                    "在下列每一对词语中，哪一个词语更合你心意？请仔细想想这些词语的意义，而不要理会他们的字形或读音。",
                options: [
                    {
                        value: "J",
                        text: "有系统",
                    },
                    {
                        value: "P",
                        text: "随意",
                    },
                ],
            },
            {
                question:
                    "在下列每一对词语中，哪一个词语更合你心意？请仔细想想这些词语的意义，而不要理会他们的字形或读音。",
                options: [
                    {
                        value: "N",
                        text: "理论",
                    },
                    {
                        value: "S",
                        text: "肯定",
                    },
                ],
            },
            {
                question:
                    "在下列每一对词语中，哪一个词语更合你心意？请仔细想想这些词语的意义，而不要理会他们的字形或读音。",
                options: [
                    {
                        value: "F",
                        text: "敏感",
                    },
                    {
                        value: "T",
                        text: "公正",
                    },
                ],
            },
            {
                question:
                    "在下列每一对词语中，哪一个词语更合你心意？请仔细想想这些词语的意义，而不要理会他们的字形或读音。",
                options: [
                    {
                        value: "T",
                        text: "令人信服",
                    },
                    {
                        value: "F",
                        text: "感人的",
                    },
                ],
            },
            {
                question:
                    "在下列每一对词语中，哪一个词语更合你心意？请仔细想想这些词语的意义，而不要理会他们的字形或读音。",
                options: [
                    {
                        value: "S",
                        text: "声明",
                    },
                    {
                        value: "N",
                        text: "概念",
                    },
                ],
            },
            {
                question:
                    "在下列每一对词语中，哪一个词语更合你心意？请仔细想想这些词语的意义，而不要理会他们的字形或读音。",
                options: [
                    {
                        value: "P",
                        text: "不受约束",
                    },
                    {
                        value: "J",
                        text: "预先安排",
                    },
                ],
            },
            {
                question:
                    "在下列每一对词语中，哪一个词语更合你心意？请仔细想想这些词语的意义，而不要理会他们的字形或读音。",
                options: [
                    {
                        value: "I",
                        text: "矜持",
                    },
                    {
                        value: "E",
                        text: "健谈",
                    },
                ],
            },
            {
                question:
                    "在下列每一对词语中，哪一个词语更合你心意？请仔细想想这些词语的意义，而不要理会他们的字形或读音。",
                options: [
                    {
                        value: "J",
                        text: "有条不紊",
                    },
                    {
                        value: "P",
                        text: "不拘小节",
                    },
                ],
            },
            {
                question:
                    "在下列每一对词语中，哪一个词语更合你心意？请仔细想想这些词语的意义，而不要理会他们的字形或读音。",
                options: [
                    {
                        value: "N",
                        text: "意念",
                    },
                    {
                        value: "S",
                        text: "实况",
                    },
                ],
            },
            {
                question:
                    "在下列每一对词语中，哪一个词语更合你心意？请仔细想想这些词语的意义，而不要理会他们的字形或读音。",
                options: [
                    {
                        value: "F",
                        text: "同情怜悯",
                    },
                    {
                        value: "T",
                        text: "远见",
                    },
                ],
            },
            {
                question:
                    "在下列每一对词语中，哪一个词语更合你心意？请仔细想想这些词语的意义，而不要理会他们的字形或读音。",
                options: [
                    {
                        value: "T",
                        text: "利益",
                    },
                    {
                        value: "F",
                        text: "祝福",
                    },
                ],
            },
            {
                question:
                    "在下列每一对词语中，哪一个词语更合你心意？请仔细想想这些词语的意义，而不要理会他们的字形或读音。",
                options: [
                    {
                        value: "S",
                        text: "务实的",
                    },
                    {
                        value: "N",
                        text: "理论的",
                    },
                ],
            },
            {
                question:
                    "在下列每一对词语中，哪一个词语更合你心意？请仔细想想这些词语的意义，而不要理会他们的字形或读音。",
                options: [
                    {
                        value: "I",
                        text: "朋友不多",
                    },
                    {
                        value: "E",
                        text: "朋友众多",
                    },
                ],
            },
            {
                question:
                    "在下列每一对词语中，哪一个词语更合你心意？请仔细想想这些词语的意义，而不要理会他们的字形或读音。",
                options: [
                    {
                        value: "J",
                        text: "有系统",
                    },
                    {
                        value: "P",
                        text: "即兴",
                    },
                ],
            },
            {
                question:
                    "在下列每一对词语中，哪一个词语更合你心意？请仔细想想这些词语的意义，而不要理会他们的字形或读音。",
                options: [
                    {
                        value: "N",
                        text: "富想象的",
                    },
                    {
                        value: "S",
                        text: "以事论事",
                    },
                ],
            },
            {
                question:
                    "在下列每一对词语中，哪一个词语更合你心意？请仔细想想这些词语的意义，而不要理会他们的字形或读音。",
                options: [
                    {
                        value: "F",
                        text: "亲切地",
                    },
                    {
                        value: "T",
                        text: "客观地",
                    },
                ],
            },
            {
                question:
                    "在下列每一对词语中，哪一个词语更合你心意？请仔细想想这些词语的意义，而不要理会他们的字形或读音。",
                options: [
                    {
                        value: "T",
                        text: "客观地",
                    },
                    {
                        value: "F",
                        text: "热情地",
                    },
                ],
            },
            {
                question:
                    "在下列每一对词语中，哪一个词语更合你心意？请仔细想想这些词语的意义，而不要理会他们的字形或读音。",
                options: [
                    {
                        value: "S",
                        text: "建造",
                    },
                    {
                        value: "N",
                        text: "发明",
                    },
                ],
            },
            {
                question:
                    "在下列每一对词语中，哪一个词语更合你心意？请仔细想想这些词语的意义，而不要理会他们的字形或读音。",
                options: [
                    {
                        value: "I",
                        text: "文静",
                    },
                    {
                        value: "E",
                        text: "爱合群",
                    },
                ],
            },
            {
                question:
                    "在下列每一对词语中，哪一个词语更合你心意？请仔细想想这些词语的意义，而不要理会他们的字形或读音。",
                options: [
                    {
                        value: "N",
                        text: "理论",
                    },
                    {
                        value: "S",
                        text: "事实",
                    },
                ],
            },
            {
                question:
                    "在下列每一对词语中，哪一个词语更合你心意？请仔细想想这些词语的意义，而不要理会他们的字形或读音。",
                options: [
                    {
                        value: "F",
                        text: "富同情",
                    },
                    {
                        value: "T",
                        text: "合逻辑",
                    },
                ],
            },
            {
                question:
                    "在下列每一对词语中，哪一个词语更合你心意？请仔细想想这些词语的意义，而不要理会他们的字形或读音。",
                options: [
                    {
                        value: "T",
                        text: "具分析力",
                    },
                    {
                        value: "F",
                        text: "多愁善感",
                    },
                ],
            },
            {
                question:
                    "在下列每一对词语中，哪一个词语更合你心意？请仔细想想这些词语的意义，而不要理会他们的字形或读音。",
                options: [
                    {
                        value: "S",
                        text: "合情合理",
                    },
                    {
                        value: "N",
                        text: "令人着迷",
                    },
                ],
            },
            {
                question: "当你要在一个星期内完成一个大项目，你在开始的时候会",
                options: [
                    {
                        value: "J",
                        text: "把要做的不同工作依次列出",
                    },
                    {
                        value: "P",
                        text: "马上动工",
                    },
                ],
            },
            {
                question: "在社交场合中，你经常会感到",
                options: [
                    {
                        value: "I",
                        text: "与某些人很难打开话匣儿和保持对话",
                    },
                    {
                        value: "E",
                        text: "与多数人都能从容地长谈",
                    },
                ],
            },
            {
                question: "要做许多人也做的事，你比较喜欢",
                options: [
                    {
                        value: "S",
                        text: "按照一般认可的方法去做",
                    },
                    {
                        value: "N",
                        text: "构想一个自己的想法",
                    },
                ],
            },
            {
                question: "你刚认识的朋友能否说出你的兴趣？",
                options: [
                    {
                        value: "E",
                        text: "马上可以",
                    },
                    {
                        value: "I",
                        text: "要待他们真正了解你之后才可以",
                    },
                ],
            },
            {
                question: "你通常较喜欢的科目是",
                options: [
                    {
                        value: "N",
                        text: "讲授概念和原则的",
                    },
                    {
                        value: "S",
                        text: "讲授事实和数据的",
                    },
                ],
            },
            {
                question: "哪个是较高的赞誉，或称许为？",
                options: [
                    {
                        value: "F",
                        text: "一贯感性的人",
                    },
                    {
                        value: "T",
                        text: "一贯理性的人",
                    },
                ],
            },
            {
                question: "你认为按照程序表做事",
                options: [
                    {
                        value: "P",
                        text: "有时是需要的，但一般来说你不大喜欢这样做",
                    },
                    {
                        value: "J",
                        text: "大多数情况下是有帮助而且是你喜欢做的",
                    },
                ],
            },
            {
                question: "和一群人在一起，你通常会选",
                options: [
                    {
                        value: "I",
                        text: "跟你很熟悉的个别人谈话",
                    },
                    {
                        value: "E",
                        text: "参与大伙的谈话",
                    },
                ],
            },
            {
                question: "在社交聚会上，你会",
                options: [
                    {
                        value: "E",
                        text: "是说话很多的一个",
                    },
                    {
                        value: "I",
                        text: "让别人多说话",
                    },
                ],
            },
            {
                question: "把周末期间要完成的事列成清单，这个主意会",
                options: [
                    {
                        value: "J",
                        text: "合你意",
                    },
                    {
                        value: "P",
                        text: "使你提不起劲",
                    },
                ],
            },
            {
                question: "哪个是较高的赞誉，或称许为",
                options: [
                    {
                        value: "T",
                        text: "能干的",
                    },
                    {
                        value: "F",
                        text: "富有同情心",
                    },
                ],
            },
            {
                question: "你通常喜欢",
                options: [
                    {
                        value: "J",
                        text: "事先安排你的社交约会",
                    },
                    {
                        value: "P",
                        text: "随兴之所至做事",
                    },
                ],
            },
            {
                question: "总的说来，要做一个大型作业时，你会选",
                options: [
                    {
                        value: "P",
                        text: "边做边想该做什么",
                    },
                    {
                        value: "J",
                        text: "首先把工作按步细分",
                    },
                ],
            },
            {
                question: "你能否滔滔不绝地与人聊天",
                options: [
                    {
                        value: "I",
                        text: "只限于跟你有共同兴趣的人",
                    },
                    {
                        value: "E",
                        text: "几乎跟任何人都可以",
                    },
                ],
            },
            {
                question: "你会",
                options: [
                    {
                        value: "S",
                        text: "跟随一些证明有效的方法",
                    },
                    {
                        value: "N",
                        text: "几乎跟任何人都可以",
                    },
                ],
            },
            {
                question: "为乐趣而阅读时，你会",
                options: [
                    {
                        value: "N",
                        text: "喜欢奇特或创新的表达方式",
                    },
                    {
                        value: "S",
                        text: "喜欢作者直话直说",
                    },
                ],
            },
            {
                question: "你宁愿替哪一类上司（或者老师）工作？",
                options: [
                    {
                        value: "T",
                        text: "天性淳良，但常常前后不一的",
                    },
                    {
                        value: "N",
                        text: "言辞尖锐但永远合乎逻辑的",
                    },
                ],
            },
            {
                question: "你做事多数是",
                options: [
                    {
                        value: "P",
                        text: "按当天心情去做",
                    },
                    {
                        value: "J",
                        text: "照拟好的程序表去做",
                    },
                ],
            },
            {
                question: "你是否",
                options: [
                    {
                        value: "E",
                        text: "可以和任何人按需求从容地交谈",
                    },
                    {
                        value: "I",
                        text: "只是对某些人或在某种情况下才可以畅所欲言",
                    },
                ],
            },
            {
                question: "要做决定时，你认为比较重要的是",
                options: [
                    {
                        value: "T",
                        text: "据事实衡量",
                    },
                    {
                        value: "F",
                        text: "考虑他人的感受和意见",
                    },
                ],
            },
            {
                question: "在下列每一对词语中，哪一个词语更合你心意？",
                options: [
                    {
                        value: "N",
                        text: "想象的",
                    },
                    {
                        value: "S",
                        text: "真实的",
                    },
                ],
            },
            {
                question: "在下列每一对词语中，哪一个词语更合你心意？",
                options: [
                    {
                        value: "F",
                        text: "仁慈慷慨的",
                    },
                    {
                        value: "T",
                        text: "意志坚定地",
                    },
                ],
            },
            {
                question: "在下列每一对词语中，哪一个词语更合你心意？",
                options: [
                    {
                        value: "T",
                        text: "公正的",
                    },
                    {
                        value: "F",
                        text: "有关怀心的",
                    },
                ],
            },
            {
                question: "在下列每一对词语中，哪一个词语更合你心意？",
                options: [
                    {
                        value: "S",
                        text: "制作",
                    },
                    {
                        value: "N",
                        text: "设计",
                    },
                ],
            },
            {
                question: "在下列每一对词语中，哪一个词语更合你心意？",
                options: [
                    {
                        value: "N",
                        text: "可能性",
                    },
                    {
                        value: "S",
                        text: "必然性",
                    },
                ],
            },
            {
                question: "在下列每一对词语中，哪一个词语更合你心意？",
                options: [
                    {
                        value: "F",
                        text: "温柔",
                    },
                    {
                        value: "T",
                        text: "力量",
                    },
                ],
            },
            {
                question: "在下列每一对词语中，哪一个词语更合你心意？",
                options: [
                    {
                        value: "T",
                        text: "实际",
                    },
                    {
                        value: "F",
                        text: "多愁善感",
                    },
                ],
            },
            {
                question: "在下列每一对词语中，哪一个词语更合你心意？",
                options: [
                    {
                        value: "S",
                        text: "制造",
                    },
                    {
                        value: "N",
                        text: "创造",
                    },
                ],
            },
            {
                question: "在下列每一对词语中，哪一个词语更合你心意？",
                options: [
                    {
                        value: "N",
                        text: "新颖的",
                    },
                    {
                        value: "S",
                        text: "已知的",
                    },
                ],
            },
            {
                question: "在下列每一对词语中，哪一个词语更合你心意？",
                options: [
                    {
                        value: "F",
                        text: "同情",
                    },
                    {
                        value: "T",
                        text: "分析",
                    },
                ],
            },
            {
                question: "在下列每一对词语中，哪一个词语更合你心意？",
                options: [
                    {
                        value: "T",
                        text: "坚持己见",
                    },
                    {
                        value: "F",
                        text: "温柔有爱心",
                    },
                ],
            },
            {
                question: "在下列每一对词语中，哪一个词语更合你心意？",
                options: [
                    {
                        value: "S",
                        text: "具体的",
                    },
                    {
                        value: "N",
                        text: "抽象地",
                    },
                ],
            },
            {
                question: "在下列每一对词语中，哪一个词语更合你心意？",
                options: [
                    {
                        value: "F",
                        text: "全心投入地",
                    },
                    {
                        value: "T",
                        text: "有决心地",
                    },
                ],
            },
            {
                question: "在下列每一对词语中，哪一个词语更合你心意？",
                options: [
                    {
                        value: "T",
                        text: "能干",
                    },
                    {
                        value: "F",
                        text: "仁慈",
                    },
                ],
            },
            {
                question: "在下列每一对词语中，哪一个词语更合你心意？",
                options: [
                    {
                        value: "S",
                        text: "实际",
                    },
                    {
                        value: "N",
                        text: "创新",
                    },
                ],
            },
        ],
    },
};
