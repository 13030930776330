export default {
    index: {
        meta: {
            title: "九型人格测试 - 了解你的性格类型",
            description:
                "通过专业的九型人格测试，深入了解你的性格类型、行为模式和人际关系。",
            keywords: "九型人格,性格测试,人格测试,性格类型,九型人格测试",
        },
        title: "九型人格测试",
        subtitle: "探索你的性格类型，了解自己的行为模式和人际关系",
        tips: {
            list: [
                "欢迎参加九型人格测试！通过测试，您将了解自己在九种人格类型中的倾向，帮助您更好地认识自己并在生活中做出更有意义的决策。",
                "通过九型人格测试，您可以深入了解自己的核心动机、恐惧以及情感模式，提升个人自我觉察并优化人际关系。",
                "请认真作答九型人格测试题，尽量根据自己最真实的感受作答，测试结果将帮助您洞察自己的行为模式和潜在的成长机会。",
                "九型人格测试旨在帮助您发现自己潜在的人格特质，并提供一条更具针对性的个人成长路径。",
            ],
        },
        buttons: {
            whatIsEnneagram: "什么是九型人格",
            enneagramTypes: "九型人格类型",
        },
    },
    introduction: {
        meta: {
            title: "九型人格介绍 - 了解九型人格理论",
            description:
                "深入了解九型人格理论，了解九种性格类型的特点、优势和局限性。",
            keywords: "九型人格,性格类型,性格理论,人格发展,性格分析",
        },
        title: "九型人格理论介绍",
        whatIsEnneagram: {
            title: "什么是九型人格？",
            content:
                "九型人格（Enneagram）是一个古老而深奥的性格类型系统，它描述了九种基本的人格类型，每种类型都有其独特的思维模式、情感反应和行为倾向。这个系统不仅帮助我们理解自己，也帮助我们更好地理解他人。",
        },
        types: {
            title: "九种性格类型",
            content:
                "九型人格将人类性格分为九种基本类型，每种类型都有其独特的特征：",
            list: [
                {
                    title: "完美主义者",
                    description: "追求完美，注重原则，有强烈的责任感和道德感。",
                },
                {
                    title: "助人者",
                    description: "富有同理心，乐于助人，渴望被爱和认可。",
                },
                {
                    title: "成就者",
                    description: "追求成功，注重形象，适应能力强。",
                },
                {
                    title: "艺术家",
                    description: "富有创造力，追求独特性，情感丰富。",
                },
                {
                    title: "观察者",
                    description: "善于思考，追求知识，注重隐私。",
                },
                {
                    title: "忠诚者",
                    description: "注重安全，寻求支持，谨慎负责。",
                },
                {
                    title: "享乐者",
                    description: "乐观开朗，追求快乐，富有想象力。",
                },
                {
                    title: "领导者",
                    description: "自信果断，保护他人，追求控制。",
                },
                {
                    title: "调停者",
                    description: "平和包容，追求和谐，避免冲突。",
                },
            ],
        },
        applications: {
            title: "应用价值",
            intro: "九型人格理论在多个领域都有重要的应用价值：",
            items: [
                "自我认知：帮助个体了解自身的人格特质、动机和行为模式，促进自我认知的提升。",
                "人际关系：通过了解自己和他人的人格类型，改善与他人的沟通与互动。",
                "职业发展：为个人职业发展提供有益的建议，根据不同人格类型找到适合的职业方向。",
                "心理健康：提供个体心理状态的深度分析，有助于应对压力、焦虑和情感困扰。",
                "团队建设：帮助团队成员理解彼此的个性差异，从而增强团队的协作性和效率。",
                "领导力发展：为管理者提供人格上的洞察，帮助提高领导力和团队管理能力。",
            ],
        },
        notes: {
            title: "注意事项",
            items: [
                "保持真实：回答时尽量保持真实，基于自己内心的感受和习惯，不要迎合或避免某种答案。",
                "勿过度解读：九型人格测试结果应作为参考工具，避免过度解读或将其视为完全的定论。",
                "人格发展是动态的：人格是不断变化和成长的，测试结果并非一成不变，随着生活经历的不同，个体的特质可能会发生改变。",
                "文化差异：不同的文化背景可能对人格类型的表现产生不同影响，因此应考虑文化差异对测试结果的影响。",
                "仅供参考：九型人格测试结果提供自我认知的视角，但不能代替专业心理评估，遇到心理困扰时，建议寻求专业人士的帮助。",
            ],
        },
    },
    detail: {
        meta: {
            title: "九型人格{type}号{typeName} - 详细分析",
            description:
                "深入分析九型人格{type}号{typeName}，包括核心特征、优势、挑战和成长机会。",
            keywords:
                "九型人格{type}号{typeName},性格分析,个人成长,行为模式,人格发展",
        },
        coreTraits: {
            title: "核心特征",
            types: [
                {
                    list: [
                        "强烈的对错观念",
                        "高标准和完美主义",
                        "自律和责任感",
                        "批判性思维和分析",
                        "追求进步",
                    ],
                },
                {
                    list: [
                        "富有同理心和关怀",
                        "慷慨和乐于助人",
                        "取悦他人的倾向",
                        "强大的情商",
                        "渴望连接",
                    ],
                },
                {
                    list: [
                        "目标导向和雄心勃勃",
                        "注重形象和适应性强",
                        "高效和富有成效",
                        "自信和魅力",
                        "追求成功",
                    ],
                },
                {
                    list: [
                        "创造性和艺术性",
                        "情感丰富",
                        "个人主义",
                        "深度和内省",
                        "真实和独特",
                    ],
                },
                {
                    list: [
                        "分析性和观察力强",
                        "追求知识",
                        "独立和注重隐私",
                        "客观和超然",
                        "专注和彻底",
                    ],
                },
                {
                    list: [
                        "忠诚和承诺",
                        "注重安全",
                        "负责任和可靠",
                        "谨慎和准备充分",
                        "支持和保护",
                    ],
                },
                {
                    list: [
                        "乐观和热情",
                        "追求冒险",
                        "自发和爱玩",
                        "多才多艺和适应性强",
                        "面向未来",
                    ],
                },
                {
                    list: [
                        "自信和果断",
                        "保护他人和强大",
                        "直接和果断",
                        "独立和自力更生",
                        "追求正义",
                    ],
                },
                {
                    list: [
                        "平和和随和",
                        "耐心和包容",
                        "外交和调解",
                        "轻松和放松",
                        "追求和谐",
                    ],
                },
            ],
        },
        strengths: {
            title: "优势",
            types: [
                {
                    list: [
                        "高道德标准",
                        "强大的组织能力",
                        "可靠和负责任",
                        "注重细节",
                        "自律",
                    ],
                },
                {
                    list: [
                        "出色的人际交往能力",
                        "天生的照顾者",
                        "情感直觉强",
                        "温暖和支持",
                        "优秀的团队成员",
                    ],
                },
                {
                    list: [
                        "高度积极",
                        "出色的问题解决者",
                        "适应性强和灵活",
                        "天生的领导者",
                        "目标达成者",
                    ],
                },
                {
                    list: [
                        "高度创造性",
                        "深刻的情感理解",
                        "真实和真诚",
                        "独特的视角",
                        "艺术表达",
                    ],
                },
                {
                    list: [
                        "分析性思维",
                        "独立工作能力",
                        "客观观察",
                        "知识专家",
                        "问题解决者",
                    ],
                },
                {
                    list: [
                        "忠诚和承诺",
                        "负责任的团队成员",
                        "善于规划",
                        "可靠和值得信赖",
                        "强大的支持者",
                    ],
                },
                {
                    list: [
                        "乐观态度",
                        "创造性问题解决",
                        "热情激励者",
                        "适应变化",
                        "天生的表演者",
                    ],
                },
                {
                    list: [
                        "强大的领导者",
                        "保护他人",
                        "果断和直接",
                        "天生的权威",
                        "追求正义",
                    ],
                },
                {
                    list: [
                        "和平缔造者",
                        "好的倾听者",
                        "外交调解者",
                        "耐心和包容",
                        "和谐创造者",
                    ],
                },
            ],
        },
        challenges: {
            title: "挑战",
            types: [
                {
                    list: [
                        "完美主义可能令人窒息",
                        "自我批评",
                        "思维僵化",
                        "难以放松",
                        "高期望",
                    ],
                },
                {
                    list: [
                        "难以说不",
                        "忽视自己的需求",
                        "过度参与他人事务",
                        "寻求认可",
                        "情感依赖",
                    ],
                },
                {
                    list: [
                        "工作狂",
                        "形象迷恋",
                        "害怕失败",
                        "竞争性",
                        "情感压抑",
                    ],
                },
                {
                    list: [
                        "情感强度高",
                        "自我中心",
                        "情绪化",
                        "难以融入",
                        "身份认同问题",
                    ],
                },
                {
                    list: [
                        "情感疏离",
                        "社交孤立",
                        "过度分析",
                        "难以表达感受",
                        "隐私迷恋",
                    ],
                },
                {
                    list: [
                        "焦虑和担忧",
                        "优柔寡断",
                        "过度谨慎",
                        "依赖他人",
                        "害怕改变",
                    ],
                },
                {
                    list: [
                        "冲动",
                        "难以承诺",
                        "注意力分散",
                        "逃避痛苦",
                        "不安分",
                    ],
                },
                {
                    list: [
                        "固执",
                        "控制欲强",
                        "强度高",
                        "难以展现脆弱",
                        "攻击性倾向",
                    ],
                },
                {
                    list: [
                        "被动",
                        "逃避冲突",
                        "难以做决定",
                        "压抑需求",
                        "抗拒改变",
                    ],
                },
            ],
        },
        growth: {
            title: "成长建议",
            types: [
                {
                    list: [
                        "学会接受不完美",
                        "练习自我同情",
                        "更加灵活",
                        "平衡工作和休息",
                        "接受不同观点",
                    ],
                },
                {
                    list: [
                        "设定健康的界限",
                        "练习自我照顾",
                        "学会说不",
                        "重视自己的需求",
                        "发展独立性",
                    ],
                },
                {
                    list: [
                        "平衡工作和生活",
                        "注重真实性",
                        "接受失败作为学习机会",
                        "开放表达情感",
                        "重视关系胜过成就",
                    ],
                },
                {
                    list: [
                        "平衡情感和逻辑",
                        "练习自我接纳",
                        "发展稳定性",
                        "与他人建立联系",
                        "找到建设性的出口",
                    ],
                },
                {
                    list: [
                        "发展情感意识",
                        "建立社交联系",
                        "平衡分析和行动",
                        "与他人分享知识",
                        "练习当下",
                    ],
                },
                {
                    list: [
                        "建立自信",
                        "承担经过计算的风险",
                        "相信直觉",
                        "拥抱改变",
                        "发展独立性",
                    ],
                },
                {
                    list: [
                        "发展专注和承诺",
                        "面对困难情绪",
                        "练习正念",
                        "完成项目",
                        "建立持久关系",
                    ],
                },
                {
                    list: [
                        "练习展现脆弱",
                        "发展耐心",
                        "倾听他人",
                        "控制强度",
                        "接受他人帮助",
                    ],
                },
                {
                    list: [
                        "采取主动",
                        "表达需求",
                        "主动做决定",
                        "建设性处理冲突",
                        "发展果断性",
                    ],
                },
            ],
        },
    },
    test: {
        meta: {
            title: "九型人格测试 - 开始测试",
            description: "通过专业的九型人格测试题目，发现你的性格类型。",
            keywords: "九型人格测试,性格测试,人格测试,在线测试",
        },
        title: "九型人格测试",
        questions: [
            {
                question: "当面对困难时，我倾向于：",
                options: [
                    "仔细分析问题并制定完美的解决方案",
                    "寻求他人的帮助和支持",
                    "迅速采取行动解决问题",
                ],
                types: [1, 6, 8],
            },
            {
                question: "在团队中，我通常会：",
                options: [
                    "确保一切按规则进行",
                    "照顾他人的感受和需求",
                    "推动团队达成目标",
                ],
                types: [1, 2, 3],
            },
            {
                question: "当遇到压力时，我会：",
                options: [
                    "变得更加完美主义",
                    "寻求他人的认可和支持",
                    "通过工作和成就来分散注意力",
                ],
                types: [1, 2, 3],
            },
            {
                question: "在做决定时，我通常：",
                options: [
                    "考虑什么是对的和正确的",
                    "考虑对他人的影响",
                    "考虑如何最有效地达成目标",
                ],
                types: [1, 2, 3],
            },
            {
                question: "我最看重：",
                options: ["正直和道德标准", "关系和情感联系", "成就和认可"],
                types: [1, 2, 3],
            },
            {
                question: "我害怕：",
                options: ["犯错和不完美", "被他人拒绝", "失败和无价值"],
                types: [1, 2, 3],
            },
            {
                question: "在工作中，我倾向于：",
                options: [
                    "追求完美和高标准",
                    "建立良好的人际关系",
                    "追求效率和成功",
                ],
                types: [1, 2, 3],
            },
            {
                question: "当受到批评时，我会：",
                options: ["努力改正错误", "感到情感受伤", "证明自己的能力"],
                types: [1, 4, 3],
            },
            {
                question: "我认为最重要的是：",
                options: ["做正确的事", "保持独特性", "获得成功"],
                types: [1, 4, 3],
            },
            {
                question: "在人际关系中，我倾向于：",
                options: ["保持适当距离", "深入投入", "展现最好的一面"],
                types: [5, 2, 3],
            },
            {
                question: "我最常有的想法是：",
                options: ["如何做得更好", "别人需要什么", "如何取得成功"],
                types: [1, 2, 3],
            },
            {
                question: "当面对冲突时，我会：",
                options: ["坚持原则", "寻求和解", "直面问题"],
                types: [1, 9, 8],
            },
            {
                question: "我内心深处渴望：",
                options: [
                    "成为一个完美的人",
                    "被他人需要和爱",
                    "获得成功和认可",
                ],
                types: [1, 2, 3],
            },
            {
                question: "当独处时，我倾向于：",
                options: ["思考和分析", "感受和体验情感", "计划未来"],
                types: [5, 4, 3],
            },
            {
                question: "在新环境中，我通常会：",
                options: [
                    "观察和分析情况",
                    "寻找可以帮助的人",
                    "寻找机会展现自己",
                ],
                types: [5, 2, 3],
            },
            {
                question: "我最容易感到：",
                options: [
                    "愤怒（对不完美的事物）",
                    "悲伤（对失去的联系）",
                    "羞耻（对失败）",
                ],
                types: [1, 4, 3],
            },
            {
                question: "在压力下，我会：",
                options: [
                    "变得更加严格和批评",
                    "变得更加情绪化",
                    "变得更加竞争",
                ],
                types: [1, 4, 3],
            },
            {
                question: "我认为生活中最困难的是：",
                options: ["接受不完美", "处理情感需求", "承认失败"],
                types: [1, 2, 3],
            },
            {
                question: "我最希望别人说我：",
                options: ["正直可靠", "富有同情心", "能干成功"],
                types: [1, 2, 3],
            },
            {
                question: "在关系中，我最在意：",
                options: ["诚实和信任", "亲密和理解", "相互支持和成长"],
                types: [1, 2, 3],
            },
            {
                question: "我经常会：",
                options: ["反思和自我批评", "关注他人的需要", "比较和竞争"],
                types: [1, 2, 3],
            },
            {
                question: "当事情出错时，我会：",
                options: [
                    "分析原因并改进",
                    "寻求安慰和支持",
                    "立即采取补救行动",
                ],
                types: [1, 2, 8],
            },
            {
                question: "我最欣赏的品质是：",
                options: ["正直和道德", "同理心和关怀", "能力和成就"],
                types: [1, 2, 3],
            },
            {
                question: "在工作中，我最重视：",
                options: ["质量和准确性", "团队合作", "效率和结果"],
                types: [1, 2, 3],
            },
            {
                question: "我倾向于：",
                options: ["过分关注细节", "过分关注他人感受", "过分关注成功"],
                types: [1, 2, 3],
            },
            {
                question: "当面对变化时，我会：",
                options: ["仔细评估利弊", "考虑对人际关系的影响", "寻找机会"],
                types: [6, 2, 7],
            },
            {
                question: "我的优势在于：",
                options: ["组织和规划", "理解和关怀", "执行和完成"],
                types: [1, 2, 3],
            },
            {
                question: "在团队中，我通常扮演：",
                options: ["质量把关者", "协调者", "推动者"],
                types: [1, 9, 3],
            },
            {
                question: "我最不能容忍：",
                options: ["不负责任", "冷漠无情", "无能低效"],
                types: [1, 2, 3],
            },
            {
                question: "我的动力来自：",
                options: ["追求完美", "帮助他人", "追求成功"],
                types: [1, 2, 3],
            },
            {
                question: "在困难情况下，我会：",
                options: ["坚持原则", "寻求支持", "积极应对"],
                types: [1, 6, 8],
            },
            {
                question: "我最容易：",
                options: ["对自己苛刻", "忽视自己需求", "过度竞争"],
                types: [1, 2, 3],
            },
            {
                question: "别人说我：",
                options: ["太过完美主义", "太在意他人感受", "太在意成功"],
                types: [1, 2, 3],
            },
            {
                question: "我希望自己能：",
                options: ["更加放松", "更加独立", "更加平和"],
                types: [1, 2, 9],
            },
            {
                question: "在做决定时，我依靠：",
                options: ["原则和逻辑", "直觉和感受", "经验和效果"],
                types: [1, 4, 3],
            },
            {
                question: "我最大的挑战是：",
                options: ["接受现实", "设立界限", "放慢脚步"],
                types: [1, 2, 3],
            },
            {
                question: "生活中，我追求：",
                options: ["道德和正义", "和谐与关爱", "成就与卓越"],
                types: [1, 2, 3],
            },
            {
                question: "我常常担心：",
                options: ["是否做得足够好", "他人是否需要我", "是否足够成功"],
                types: [1, 2, 3],
            },
            {
                question: "我的行为准则是：",
                options: ["做正确的事", "关心他人", "追求卓越"],
                types: [1, 2, 3],
            },
            {
                question: "在休闲时，我倾向于：",
                options: ["提升自我", "陪伴他人", "尝试新鲜事物"],
                types: [1, 2, 7],
            },
            {
                question: "我认为自己是：",
                options: ["有责任心的人", "富有同情心的人", "有进取心的人"],
                types: [1, 2, 3],
            },
            {
                question: "当面对选择时，我会：",
                options: ["选择正确的", "选择对他人有利的", "选择有效的"],
                types: [1, 2, 3],
            },
            {
                question: "我最重视的关系是：",
                options: ["彼此尊重的", "情感深厚的", "互相支持的"],
                types: [1, 4, 2],
            },
            {
                question: "我的生活态度是：",
                options: ["追求完美", "追求和谐", "追求成功"],
                types: [1, 9, 3],
            },
            {
                question: "在处理问题时，我会：",
                options: ["寻找最佳方案", "考虑各方感受", "寻找快速解决方法"],
                types: [1, 2, 8],
            },
            {
                question: "我最看重的能力是：",
                options: ["判断是非", "理解他人", "解决问题"],
                types: [1, 2, 5],
            },
            {
                question: "我的人生目标是：",
                options: ["追求完美品德", "帮助他人成长", "取得杰出成就"],
                types: [1, 2, 3],
            },
            {
                question: "我最容易感到满足的是：",
                options: ["做对了正确的事", "帮助了需要的人", "达成了预期目标"],
                types: [1, 2, 3],
            },
            {
                question: "在社交场合，我通常：",
                options: ["保持克制和礼貌", "积极关心他人", "展现自信和魅力"],
                types: [1, 2, 3],
            },
            {
                question: "与他人相处时，我注重：",
                options: ["诚信和原则", "关怀和温暖", "能力和成就"],
                types: [1, 2, 3],
            },
            {
                question: "当他人犯错时，我会：",
                options: [
                    "指出错误并建议改正",
                    "表示理解和支持",
                    "提供解决方案",
                ],
                types: [1, 2, 3],
            },
            {
                question: "在团队合作中，我期望：",
                options: ["每个人都尽职尽责", "团队氛围和谐", "取得优秀成果"],
                types: [1, 9, 3],
            },
            {
                question: "面对他人的情绪，我会：",
                options: ["保持理性和克制", "感同身受", "寻求解决方案"],
                types: [1, 2, 3],
            },
            {
                question: "在沟通时，我倾向于：",
                options: ["直接指出问题", "照顾对方感受", "强调解决方案"],
                types: [1, 2, 3],
            },
            {
                question: "当他人需要帮助时，我会：",
                options: ["提供正确的建议", "提供情感支持", "提供实际解决方案"],
                types: [1, 2, 3],
            },
            {
                question: "在人际冲突中，我通常：",
                options: ["坚持原则", "寻求和解", "采取行动"],
                types: [1, 9, 8],
            },
            {
                question: "我认为好的关系应该：",
                options: [
                    "彼此尊重对方的原则",
                    "彼此理解对方的感受",
                    "彼此支持对方的发展",
                ],
                types: [1, 2, 3],
            },
            {
                question: "在给予反馈时，我会：",
                options: [
                    "指出需要改进的地方",
                    "注意措辞避免伤害",
                    "强调如何提高效率",
                ],
                types: [1, 2, 3],
            },
            {
                question: "我期待的领导是：",
                options: [
                    "公正且有原则的",
                    "关心体贴下属的",
                    "有能力且有魄力的",
                ],
                types: [1, 2, 8],
            },
            {
                question: "在处理分歧时，我倾向于：",
                options: [
                    "坚持正确的立场",
                    "寻求双方都接受的方案",
                    "采取果断的行动",
                ],
                types: [1, 9, 8],
            },
            {
                question: "在压力情况下，我会：",
                options: [
                    "更加严格要求自己",
                    "更需要他人支持",
                    "更加努力证明自己",
                ],
                types: [1, 2, 3],
            },
            {
                question: "面对失败时，我通常：",
                options: [
                    "仔细分析原因并改进",
                    "寻求安慰和鼓励",
                    "迅速制定新计划",
                ],
                types: [1, 2, 3],
            },
            {
                question: "我的自我提升方向是：",
                options: [
                    "提高做事的准确性",
                    "增进情感的敏感度",
                    "提升工作的效率",
                ],
                types: [1, 2, 3],
            },
            {
                question: "当感到不安时，我会：",
                options: [
                    "检查是否有做错的地方",
                    "寻找情感支持",
                    "专注于解决问题",
                ],
                types: [1, 2, 3],
            },
            {
                question: "我最需要克服的是：",
                options: ["对自己和他人的苛刻", "对他人的依赖", "对成功的执着"],
                types: [1, 2, 3],
            },
            {
                question: "为了成长，我需要学会：",
                options: ["接受不完美", "关注自身需求", "享受过程"],
                types: [1, 2, 3],
            },
            {
                question: "在逆境中，我会：",
                options: ["坚持原则和标准", "寻求他人帮助", "积极寻找机会"],
                types: [1, 2, 3],
            },
            {
                question: "我的理想生活是：",
                options: [
                    "有序且有意义的",
                    "充满爱与关怀的",
                    "成功且受人尊敬的",
                ],
                types: [1, 2, 3],
            },
            {
                question: "面对改变，我会：",
                options: [
                    "确保改变是正确的",
                    "关注改变对人的影响",
                    "把握改变带来的机会",
                ],
                types: [1, 2, 3],
            },
            {
                question: "我认为成功的关键是：",
                options: ["坚持正确的原则", "维护良好的关系", "把握重要的机会"],
                types: [1, 2, 3],
            },
            {
                question: "当面对挑战时，我会：",
                options: ["谨慎地评估和规划", "寻求建议和支持", "迎难而上"],
                types: [1, 6, 8],
            },
            {
                question: "我的人生准则是：",
                options: ["追求完美和正确", "追求关爱和和谐", "追求成功和卓越"],
                types: [1, 2, 3],
            },
        ],
    },
    result: {
        meta: {
            title: "九型人格测试结果",
            description: "查看你的九型人格测试结果，了解你的性格特点。",
            keywords: "九型人格结果,性格分析,人格类型",
        },
        title: "你的九型人格类型是",
        characteristics: {
            title: "性格特征",
        },
        advice: {
            title: "成长建议",
        },
        scoreDistribution: "类型分布",
        strengths: "优势特点",
        weaknesses: "发展挑战",
        growthSuggestions: "成长建议",
        buttons: {
            viewDetail: "查看详细解读",
        },
        types: {
            type1: {
                title: "完美主义者",
                description: "追求完美，注重原则，有强烈的责任感和道德感。",
                characteristics: [
                    "高度的自律性",
                    "强烈的是非观",
                    "追求完美和进步",
                    "注重细节",
                    "有责任感",
                ],
                strengths: ["有原则", "可靠", "勤奋", "追求卓越", "注重质量"],
                weaknesses: [
                    "过分苛刻",
                    "自我批评",
                    "不够灵活",
                    "难以放松",
                    "过分认真",
                ],
                growthSuggestions: [
                    "学会接纳不完美",
                    "培养同理心",
                    "放松心态",
                    "提高灵活性",
                    "享受当下",
                ],
            },
            type2: {
                title: "助人者",
                description: "富有同理心，乐于助人，渴望被爱和认可。",
                characteristics: [
                    "强烈的同理心",
                    "乐于助人",
                    "重视人际关系",
                    "情感丰富",
                    "善解人意",
                ],
                strengths: [
                    "富有同情心",
                    "善于关怀",
                    "乐于奉献",
                    "人际关系好",
                    "情感敏锐",
                ],
                weaknesses: [
                    "过分在意他人感受",
                    "忽视自身需求",
                    "情感依赖",
                    "难以拒绝",
                    "过度付出",
                ],
                growthSuggestions: [
                    "学会设立界限",
                    "关注自身需求",
                    "培养独立性",
                    "适度付出",
                    "保持自我",
                ],
            },
            type3: {
                title: "成就者",
                description: "追求成功，注重形象，适应能力强。",
                characteristics: [
                    "目标导向",
                    "追求效率",
                    "注重形象",
                    "适应力强",
                    "充满干劲",
                ],
                strengths: [
                    "执行力强",
                    "善于沟通",
                    "高效率",
                    "适应性强",
                    "领导能力",
                ],
                weaknesses: [
                    "过分在意成功",
                    "工作狂倾向",
                    "情感压抑",
                    "过度竞争",
                    "形象执着",
                ],
                growthSuggestions: [
                    "平衡工作与生活",
                    "关注内心感受",
                    "建立真实联系",
                    "接纳失败",
                    "保持真我",
                ],
            },
            type4: {
                title: "艺术家",
                description: "富有创造力，追求独特性，情感丰富。",
                characteristics: [
                    "独特个性",
                    "丰富情感",
                    "艺术气质",
                    "自我意识强",
                    "追求真实",
                ],
                strengths: [
                    "创造力强",
                    "情感深刻",
                    "审美能力",
                    "直觉敏锐",
                    "追求真实",
                ],
                weaknesses: [
                    "情绪波动大",
                    "自我中心",
                    "理想化倾向",
                    "难以适应",
                    "过度敏感",
                ],
                growthSuggestions: [
                    "培养情绪稳定",
                    "接纳平凡",
                    "建立规律",
                    "增强适应力",
                    "保持平衡",
                ],
            },
            type5: {
                title: "观察者",
                description: "善于思考，追求知识，注重隐私。",
                characteristics: [
                    "理性思维",
                    "追求知识",
                    "独立自主",
                    "观察敏锐",
                    "重视隐私",
                ],
                strengths: [
                    "分析能力强",
                    "专业知识深",
                    "独立思考",
                    "洞察力强",
                    "专注力强",
                ],
                weaknesses: [
                    "情感疏离",
                    "社交退缩",
                    "过度分析",
                    "资源储存",
                    "难以行动",
                ],
                growthSuggestions: [
                    "增进情感联系",
                    "参与社交",
                    "付诸行动",
                    "分享知识",
                    "开放心态",
                ],
            },
            type6: {
                title: "忠诚者",
                description: "注重安全，寻求支持，谨慎负责。",
                characteristics: [
                    "忠诚可靠",
                    "谨慎细心",
                    "责任感强",
                    "重视安全",
                    "寻求确定",
                ],
                strengths: [
                    "忠诚度高",
                    "防范意识强",
                    "责任心强",
                    "团队合作",
                    "问题预见",
                ],
                weaknesses: [
                    "过度担忧",
                    "犹豫不决",
                    "过分谨慎",
                    "缺乏自信",
                    "依赖倾向",
                ],
                growthSuggestions: [
                    "建立自信",
                    "培养勇气",
                    "相信直觉",
                    "接纳不确定",
                    "独立决策",
                ],
            },
            type7: {
                title: "享乐者",
                description: "乐观开朗，追求快乐，富有想象力。",
                characteristics: [
                    "乐观积极",
                    "活力充沛",
                    "思维灵活",
                    "追求快乐",
                    "热爱自由",
                ],
                strengths: [
                    "创意丰富",
                    "适应力强",
                    "感染力强",
                    "乐观向上",
                    "活力充沛",
                ],
                weaknesses: [
                    "难以专注",
                    "逃避痛苦",
                    "计划性差",
                    "缺乏耐心",
                    "过度乐观",
                ],
                growthSuggestions: [
                    "培养专注力",
                    "面对现实",
                    "完成承诺",
                    "接纳负面",
                    "深化体验",
                ],
            },
            type8: {
                title: "领导者",
                description: "自信果断，保护他人，追求控制。",
                characteristics: [
                    "领导气质",
                    "意志坚强",
                    "保护意识",
                    "直接果断",
                    "追求控制",
                ],
                strengths: [
                    "决策果断",
                    "执行力强",
                    "保护他人",
                    "直言不讳",
                    "承担责任",
                ],
                weaknesses: [
                    "过于强势",
                    "控制欲强",
                    "不够敏感",
                    "易起冲突",
                    "难以示弱",
                ],
                growthSuggestions: [
                    "培养柔和",
                    "倾听他人",
                    "展现脆弱",
                    "控制力度",
                    "增进理解",
                ],
            },
            type9: {
                title: "调停者",
                description: "平和包容，追求和谐，避免冲突。",
                characteristics: [
                    "性格温和",
                    "包容理解",
                    "追求和平",
                    "调解能力",
                    "适应性强",
                ],
                strengths: [
                    "包容性强",
                    "调解能力",
                    "倾听能力",
                    "维护和谐",
                    "平和稳定",
                ],
                weaknesses: [
                    "优柔寡断",
                    "回避冲突",
                    "被动消极",
                    "自我忽视",
                    "拖延习惯",
                ],
                growthSuggestions: [
                    "提升主动性",
                    "表达需求",
                    "及时行动",
                    "面对冲突",
                    "重视自我",
                ],
            },
        },
    },
};
