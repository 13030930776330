export default {
    index: {
        meta: {
            title: "HSP Test - Discover Your Highly Sensitive Personality",
            description:
                "Take the professional HSP test to understand if you're a Highly Sensitive Person. Discover your unique sensitive traits and understand yourself better.",
            keywords:
                "HSP test,highly sensitive person,sensitivity traits,personality test,psychological assessment",
        },
        title: "HSP Personality Test",
        subtitle:
            "Explore your sensitivity traits and understand your uniqueness",
        tips: {
            list: [
                "Welcome to the HSP (Highly Sensitive Person) test! By taking this test, you will understand whether you belong to the highly sensitive group and how to better cope with external stimuli.",
                "The HSP test evaluates your response to environmental stimuli and others’ emotions, helping you understand your sensitivity traits.",
                "Please answer the questions based on how you feel about various situations in life. The test results will help you better understand yourself and make appropriate life adjustments.",
                "High sensitivity is not a flaw but a unique personality trait. This test will help you discover how to live in harmony with your sensitivity.",
            ],
        },
        buttons: {
            whatIsHSP: "About HSP",
        },
    },
    introduction: {
        meta: {
            title: "What is HSP (Highly Sensitive Person)? - Traits and Advantages",
            description:
                "Learn about the definition, characteristics, and advantages of being a Highly Sensitive Person (HSP). Better understand the uniqueness of sensitive individuals.",
            keywords:
                "HSP introduction,highly sensitive person,sensitivity traits,personality characteristics",
        },
        title: "Understanding HSP",
        sections: [
            {
                title: "What is HSP",
                content:
                    "The HSP (Highly Sensitive Person) test is a psychological assessment designed to evaluate whether an individual possesses highly sensitive traits. Based on the research of psychologist Elaine Aron, people with high sensitivity tend to have stronger reactions to environmental stimuli such as noise, smells, or emotional cues. They are more attuned to others’ emotions and may experience overstimulation or fatigue easily. The HSP test helps individuals understand whether they belong to the highly sensitive group and provides suggestions on how to manage their sensitivity in daily life.",
            },
            {
                title: "Key Characteristics of HSP",
                content: [
                    "Depth of Processing: Tendency to process information deeply",
                    "Emotional Sensitivity: Highly attuned to others' emotions and environmental changes",
                    "Sensory Sensitivity: Sensitive to physical stimuli like sounds, lights, and smells",
                    "Overstimulation: Easily overwhelmed in intense environments",
                    "Aesthetic Sensitivity: Strong appreciation for arts and music",
                    "Strong Empathy: Easy to understand and feel others' emotions and needs",
                ],
            },
            {
                title: "Advantages of HSP",
                content: [
                    "Creativity: Rich imagination and creative potential",
                    "Insight: Keen observation and intuition",
                    "Empathy: Deep understanding of others",
                    "Focus: Attention to detail and concentration",
                    "Aesthetic Appreciation: Keen sense of beauty",
                    "Deep Thinking: Good at analyzing problems thoroughly",
                ],
            },
            {
                title: "Understanding HSP Better",
                content: [
                    "Accept the Trait: Recognize high sensitivity as a normal personality trait",
                    "Manage Environment: Pay attention to environmental stimuli and rest",
                    "Leverage Strengths: Make good use of sensitivity advantages",
                    "Self-Care: Learn to take care of your needs",
                    "Seek Support: Get professional help and understanding when needed",
                ],
            },
            {
                title: "Applications of HSP",
                content: [
                    "Self-awareness: Helps individuals understand whether they belong to the highly sensitive group, enhancing self-awareness.",
                    "Emotional regulation: By understanding one’s sensitivity traits, individuals can adopt strategies to regulate emotions and stress, reducing the negative effects of external stimuli.",
                    "Lifestyle adjustments: Helps highly sensitive people adjust their living and working environments to avoid overstimulation and fatigue, promoting physical and mental well-being.",
                    "Improving interpersonal relationships: Understanding high sensitivity can help individuals improve communication and interaction with others, reducing misunderstandings and conflicts.",
                    "Mental health: Highly sensitive people may experience emotional fluctuations; this test provides strategies for coping and improving mental health.",
                ],
            },
            {
                title: "Important Notes",
                content: [
                    "Answer honestly: Please answer based on your true feelings about various situations in life, without trying to cater to the test’s expectations.",
                    "Test results for reference only: The HSP test only assesses an individual’s sensitivity and is not a comprehensive psychological evaluation tool. Results should be interpreted in conjunction with other assessments.",
                    "Respect individual differences: Each person has different sensitivity levels. The test results are only a reference, and it’s important to accept and understand one’s sensitivity without overreacting.",
                    "Sensitivity is not a flaw: High sensitivity is not a weakness or flaw but a unique trait. The purpose of the test is to help individuals find ways to live peacefully with their sensitivity.",
                    "Use the test moderately: This test is not a diagnostic tool. If you have mental health concerns, it’s recommended to seek help from a professional counselor.",
                ],
            },
        ],
    },
    test: {
        meta: {
            title: "HSP Test - Start the Assessment",
            description:
                "Start the HSP (Highly Sensitive Person) test to understand your sensitivity traits",
            keywords:
                "HSP test,highly sensitive person test,online assessment,psychological evaluation",
        },
        title: "HSP Personality Test",
        defaultOptions: [
            { value: "a", text: "Strongly Agree" },
            { value: "b", text: "Agree" },
            { value: "c", text: "Neutral" },
            { value: "d", text: "Disagree" },
            { value: "e", text: "Strongly Disagree" },
        ],
        scale: {
            stronglyDisagree: "Strongly Disagree",
            stronglyAgree: "Strongly Agree",
        },
        questions: [
            {
                id: 1,
                text: "I am easily overwhelmed by strong sensory input (bright lights, noise, smells, etc.)",
            },
            {
                id: 2,
                text: "I can detect subtle emotional changes in others",
            },
            {
                id: 3,
                text: "I feel uncomfortable when under stress or pressure",
            },
            {
                id: 4,
                text: "I have a deep appreciation for art and music",
            },
            {
                id: 5,
                text: "I need time alone to recharge my energy",
            },
            {
                id: 6,
                text: "I am particularly sensitive to details",
            },
            {
                id: 7,
                text: "I am easily affected by others' emotions",
            },
            {
                id: 8,
                text: "I am sensitive to pain",
            },
            {
                id: 9,
                text: "I find it difficult to concentrate in noisy environments",
            },
            {
                id: 10,
                text: "I often think deeply about life's various aspects",
            },
            {
                id: 11,
                text: "I am sensitive to stimulants like caffeine",
            },
            {
                id: 12,
                text: "I am easily affected by subtle changes in my environment",
            },
            {
                id: 13,
                text: "I feel strong empathy for others' suffering",
            },
            {
                id: 14,
                text: "I need a quiet environment to work or study effectively",
            },
            {
                id: 15,
                text: "I have a keen sense of beauty",
            },
            {
                id: 16,
                text: "I experience frequent mood fluctuations",
            },
            {
                id: 17,
                text: "I am sensitive to temperature changes",
            },
            {
                id: 18,
                text: "I can sense others' unspoken needs",
            },
            {
                id: 19,
                text: "I feel uncomfortable in crowded places",
            },
            {
                id: 20,
                text: "I am sensitive to the taste of food",
            },
            {
                id: 21,
                text: "I feel overwhelmed by task deadlines",
            },
            {
                id: 22,
                text: "I am sensitive to perfumes and other scents",
            },
            {
                id: 23,
                text: "I need to avoid taking on too many tasks at once",
            },
            {
                id: 24,
                text: "I am sensitive to changes in lighting",
            },
            {
                id: 25,
                text: "I can deeply feel the emotions conveyed in artworks",
            },
            {
                id: 26,
                text: "I feel uneasy in tense atmospheres",
            },
            {
                id: 27,
                text: "I am sensitive to changes in the atmosphere around me",
            },
        ],
    },
    result: {
        meta: {
            title: "HSP Test Result - Understand Your Sensitivity",
            description:
                "View your HSP Highly Sensitive Person test result and understand your sensitivity level and traits.",
            keywords:
                "HSP test result,Highly Sensitive Person,sensitivity traits,personality analysis",
        },
        title: "Your HSP Test Result",
        sensitivityLevels: {
            high: {
                threshold: 75,
                name: "Highly Sensitive",
            },
            medium: {
                threshold: 50,
                name: "Moderately Sensitive",
            },
            low: {
                threshold: 0,
                name: "Less Sensitive",
            },
        },
        analysis: {
            overview: {
                title: "Overall Assessment",
                content: "Your HSP sensitivity score is {score}. {level}",
                levels: {
                    high: "You are a highly sensitive person with an extremely strong awareness of external stimuli.",
                    medium: "You show moderate sensitivity traits and display sensitivity in certain aspects.",
                    low: "You have a lower sensitivity level and show strong resilience to external stimuli.",
                },
            },
            characteristics: {
                title: "Your Sensitivity Traits",
                high: [
                    "Extremely strong awareness of environmental changes",
                    "Highly affected by emotions and environment",
                    "Deep processing and thinking abilities",
                    "Keen appreciation for art and beauty",
                    "Need significant alone time for energy recovery",
                ],
                medium: [
                    "Moderately sensitive to environmental changes",
                    "Affected by environment in specific situations",
                    "Moderate depth in thinking",
                    "Some appreciation for art and beauty",
                    "Occasional need for alone time",
                ],
                low: [
                    "Strong adaptability to environmental changes",
                    "Less affected by emotions and environment",
                    "More practical in approach",
                    "Higher tolerance for external stimuli",
                    "Social activities less affected",
                ],
            },
            suggestions: {
                title: "Personalized Recommendations",
                high: [
                    "Manage the intensity of environmental stimuli",
                    "Ensure sufficient alone time and rest",
                    "Leverage the advantages of your sensitivity",
                    "Learn to set appropriate boundaries",
                    "Develop self-care habits",
                ],
                medium: [
                    "Monitor your sensitivity thresholds",
                    "Take breaks when needed",
                    "Use sensitivity to enhance insight",
                    "Balance social and alone time",
                    "Develop self-regulation skills",
                ],
                low: [
                    "Increase attention to details",
                    "Cultivate empathy",
                    "Enhance emotional awareness",
                    "Develop deeper thinking skills",
                    "Maintain an open and inclusive attitude",
                ],
            },
        },
    },
};
