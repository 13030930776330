export default {
    index: {
        meta: {
            title: "16PF Personality Test - Discover Your Personality Traits",
            description:
                "Take the 16PF personality test to gain deep insights into your personality traits and understand yourself better",
            keywords:
                "16PF test,personality test,character analysis,psychological assessment,Cattell 16 personality factors",
        },
        title: "16PF Personality Test",
        subtitle: "Discover Your Unique Personality Traits",
        tips: {
            list: [
                "Welcome to the 16PF personality test! By taking this test, you will gain insights into your behavior across 16 personality traits, helping you to better understand your own personality.",
                "The 16PF test quantifies your personality traits and provides a detailed analysis of your character, helping you understand your strengths and weaknesses.",
                "Please answer based on your true feelings and habitual behaviors. The test results will assist you in making more suitable career decisions.",
                "The 16PF test is a scientific and precise tool that reveals your inner traits and provides personalized advice and guidance.",
            ],
        },
        buttons: {
            what16PF: "About 16PF Test",
        },
    },
    introduction: {
        meta: {
            title: "What is the 16PF Personality Test? - Comprehensive Guide",
            description:
                "Learn about the theoretical foundation, history, applications, and detailed interpretation of the 16 personality factors in the 16PF test",
            keywords:
                "16PF introduction,personality factors,Cattell theory,psychological test interpretation,personality analysis",
        },
        title: "About the 16PF Personality Test",
        sections: [
            {
                title: "What is the 16PF Test?",
                content: [
                    "The 16PF (Sixteen Personality Factor Questionnaire) is a scientific personality assessment system developed by renowned psychologist Dr. Raymond Cattell.",
                    "It is a professional psychological tool that comprehensively evaluates personal personality traits by measuring 16 basic personality dimensions using factor analysis.",
                    "With over half a century of research and practice, the 16PF has become one of the most recognized personality assessment tools worldwide.",
                ],
            },
            {
                title: "The 16 Personality Factors",
                content: [
                    "Warmth (A): Measures attitude and approach in interpersonal relationships",
                    "Reasoning (B): Reflects abstract thinking ability",
                    "Emotional Stability (C): Measures emotional stability and stress handling",
                    "Dominance (E): Shows assertiveness and tendency to dominate",
                    "Liveliness (F): Reflects level of enthusiasm and spontaneity",
                    "Rule-Consciousness (G): Measures sense of responsibility and rule adherence",
                    "Social Boldness (H): Measures boldness in social situations",
                    "Sensitivity (I): Reflects tendency towards sensitivity vs. objectivity",
                    "Vigilance (L): Shows level of trust towards others",
                    "Abstractedness (M): Measures practical vs. creative orientation",
                    "Privateness (N): Reflects social astuteness",
                    "Apprehension (O): Measures self-assurance vs. anxiety",
                    "Openness to Change (Q1): Shows receptiveness to new ideas",
                    "Self-Reliance (Q2): Measures group dependency vs. self-sufficiency",
                    "Perfectionism (Q3): Reflects level of self-discipline",
                    "Tension (Q4): Measures stress and anxiety levels",
                ],
            },
            {
                title: "Test Applications",
                content: [
                    "Career Choice and Planning: Helps individuals understand their personality traits, making it easier to choose a suitable career path.",
                    "Psychological Health Assessment: Assesses an individual’s psychological health status, providing a basis for counseling and therapy.",
                    "Talent Recruitment and Management: Helps HR professionals understand candidates’ personality characteristics, improving the recruitment match.",
                    "Team Building and Communication: Facilitates team members’ understanding of each other’s personality traits, enhancing team collaboration and communication.",
                    "Personal Development and Self-Improvement: Helps individuals identify their strengths and areas for improvement, fostering personal growth.",
                ],
            },
            {
                title: "Important Notes",
                content: [
                    "Answer Honestly: Please answer based on your true feelings to ensure the accuracy of the test results. Avoid tailoring your answers to what you think the test expects.",
                    "Understand the Limitations: Although the 16PF test is scientific and comprehensive, it is only part of a broader personality assessment. Do not make significant decisions based solely on the results.",
                    "Interpret Results in Context: Test results should be interpreted in conjunction with other psychological tools and expert opinions, not as the sole basis for judging an individual.",
                    "Cultural Differences: Cultural and background differences may influence personality traits. Test results should be analyzed considering cultural contexts.",
                    "Scope of Use: The 16PF test is designed for adults and may not be suitable for adolescents or certain groups, so it should be used accordingly.",
                ],
            },
        ],
    },
    test: {
        meta: {
            title: "16PF Personality Test - Start Test",
            description:
                "Take the 16PF personality test to understand your personality traits",
            keywords:
                "16PF test,personality test,online test,psychological assessment",
        },
        title: "16PF Personality Test",
        defaultOptions: [
            { value: "a", text: "True" },
            { value: "b", text: "Uncertain" },
            { value: "c", text: "False" },
        ],
        questions: [
            {
                id: 1,
                text: "I see myself as a very energetic person.",
            },
            {
                id: 2,
                text: "I prefer to think things through rather than make quick decisions.",
            },
            {
                id: 3,
                text: "If neighbors are unfriendly, it must be their fault.",
            },
            {
                id: 4,
                text: "I can easily make a dull party lively.",
            },
            {
                id: 5,
                text: "I often feel the need to seek advice from friends.",
            },
            {
                id: 6,
                text: "I tend to complete tasks independently, rarely seeking help from others.",
            },
            {
                id: 7,
                text: "In social situations, I usually wait for others to approach me first.",
            },
            {
                id: 8,
                text: "I prefer to follow plans rather than act spontaneously.",
            },
            {
                id: 9,
                text: "I often worry about small things.",
            },
            {
                id: 10,
                text: "I like to try new and different things.",
            },
            {
                id: 11,
                text: "I am very organized in everything I do.",
            },
            {
                id: 12,
                text: "I often feel lonely, even when I'm around people.",
            },
            {
                id: 13,
                text: "I prefer working with others rather than alone.",
            },
            {
                id: 14,
                text: "I tend to be cautious with people.",
            },
            {
                id: 15,
                text: "I find it easy to understand abstract concepts.",
            },
            {
                id: 16,
                text: "I like to do things my own way rather than being guided by others.",
            },
            {
                id: 17,
                text: "I rarely regret my actions.",
            },
            {
                id: 18,
                text: "In a group, I usually take on a leadership role.",
            },
            {
                id: 19,
                text: "I often need time alone to collect my thoughts.",
            },
            {
                id: 20,
                text: "I tend to believe in people's good intentions.",
            },
            {
                id: 21,
                text: "I prefer a routine lifestyle.",
            },
            {
                id: 22,
                text: "I often feel full of creativity and imagination.",
            },
            {
                id: 23,
                text: "I am usually one of the most active people in social situations.",
            },
            {
                id: 24,
                text: "I am easily affected by others' emotions.",
            },
            {
                id: 25,
                text: "I enjoy solving complex problems.",
            },
            {
                id: 26,
                text: "I rarely doubt my decisions.",
            },
            {
                id: 27,
                text: "I prefer well-ordered environments.",
            },
            {
                id: 28,
                text: "I often feel under a lot of pressure.",
            },
            {
                id: 29,
                text: "I like trying new ways of doing things.",
            },
            {
                id: 30,
                text: "I find it easy to talk to strangers.",
            },
            {
                id: 31,
                text: "I pay attention to details when doing things.",
            },
            {
                id: 32,
                text: "I often worry about what might happen in the future.",
            },
            {
                id: 33,
                text: "I enjoy social gatherings.",
            },
            {
                id: 34,
                text: "I prefer to make decisions independently.",
            },
            {
                id: 35,
                text: "I am easily troubled by criticism.",
            },
            {
                id: 36,
                text: "I like to make detailed plans.",
            },
            {
                id: 37,
                text: "I often feel suspicious of others.",
            },
            {
                id: 38,
                text: "I enjoy taking risks and seeking excitement.",
            },
            {
                id: 39,
                text: "I find it easy to empathize with others.",
            },
            {
                id: 40,
                text: "I prefer to follow rules and regulations.",
            },
            {
                id: 41,
                text: "I often take the initiative to help others.",
            },
            {
                id: 42,
                text: "I prefer to complete work in a systematic way.",
            },
            {
                id: 43,
                text: "I easily empathize with others' suffering.",
            },
            {
                id: 44,
                text: "I tend to take responsibility alone.",
            },
            {
                id: 45,
                text: "I often feel energetic.",
            },
            {
                id: 46,
                text: "I enjoy exploring new ways of thinking.",
            },
            {
                id: 47,
                text: "In a team, I prefer being a follower rather than a leader.",
            },
            {
                id: 48,
                text: "I rarely worry about the future.",
            },
            {
                id: 49,
                text: "I enjoy interacting with different types of people.",
            },
            {
                id: 50,
                text: "I think things through carefully before acting.",
            },
            {
                id: 51,
                text: "I find it easy to trust others.",
            },
            {
                id: 52,
                text: "I prefer an organized lifestyle.",
            },
            {
                id: 53,
                text: "I often feel inner peace.",
            },
            {
                id: 54,
                text: "I like trying new things.",
            },
            {
                id: 55,
                text: "I tend to maintain a cautious attitude.",
            },
            {
                id: 56,
                text: "I adapt easily to changes in my environment.",
            },
            {
                id: 57,
                text: "I like to think deeply about problems.",
            },
            {
                id: 58,
                text: "I often maintain an optimistic attitude.",
            },
            {
                id: 59,
                text: "I like to act according to plans.",
            },
            {
                id: 60,
                text: "I'm good at handling emergencies.",
            },
            {
                id: 61,
                text: "I often pay attention to details.",
            },
            {
                id: 62,
                text: "I easily feel sympathy for others.",
            },
            {
                id: 63,
                text: "I like to think independently.",
            },
            {
                id: 64,
                text: "I often stay alert.",
            },
            {
                id: 65,
                text: "I'm good at controlling my emotions.",
            },
            {
                id: 66,
                text: "I like to pursue perfection.",
            },
            {
                id: 67,
                text: "I often feel nervous and anxious.",
            },
            {
                id: 68,
                text: "I enjoy challenging work.",
            },
            {
                id: 69,
                text: "I tend to trust my intuition.",
            },
            {
                id: 70,
                text: "I'm good at cooperating with others.",
            },
            {
                id: 71,
                text: "I like to follow rules.",
            },
            {
                id: 72,
                text: "I often feel full of energy.",
            },
            {
                id: 73,
                text: "I'm good at solving complex problems.",
            },
            {
                id: 74,
                text: "I find it easy to open up to others.",
            },
            {
                id: 75,
                text: "I prefer an orderly environment.",
            },
            {
                id: 76,
                text: "I often remain calm.",
            },
            {
                id: 77,
                text: "I like innovative ideas.",
            },
            {
                id: 78,
                text: "I tend to act cautiously.",
            },
            {
                id: 79,
                text: "I'm good at regulating my emotions.",
            },
            {
                id: 80,
                text: "I like to pursue excellence.",
            },
            {
                id: 81,
                text: "I enjoy having deep conversations with people.",
            },
            {
                id: 82,
                text: "I rarely procrastinate when doing things.",
            },
            {
                id: 83,
                text: "I am easily influenced by my surroundings.",
            },
            {
                id: 84,
                text: "I enjoy exploring unknown territories.",
            },
            {
                id: 85,
                text: "I often take initiative in taking responsibility.",
            },
            {
                id: 86,
                text: "I rarely regret my decisions.",
            },
            {
                id: 87,
                text: "I prefer a regular rhythm in life.",
            },
            {
                id: 88,
                text: "I'm good at handling interpersonal relationships.",
            },
            {
                id: 89,
                text: "I often maintain focus.",
            },
            {
                id: 90,
                text: "I like helping others solve problems.",
            },
            {
                id: 91,
                text: "I am patient when doing things.",
            },
            {
                id: 92,
                text: "I easily accept new viewpoints.",
            },
            {
                id: 93,
                text: "I prefer to complete tasks independently.",
            },
            {
                id: 94,
                text: "I often stay vigilant.",
            },
            {
                id: 95,
                text: "I'm good at controlling my impulses.",
            },
            {
                id: 96,
                text: "I like to pursue high standards.",
            },
            {
                id: 97,
                text: "I often feel anxious.",
            },
            {
                id: 98,
                text: "I enjoy creative work.",
            },
            {
                id: 99,
                text: "I tend to trust first impressions.",
            },
            {
                id: 100,
                text: "I'm good at teamwork.",
            },
            {
                id: 101,
                text: "I strictly follow rules.",
            },
            {
                id: 102,
                text: "I often feel energetic.",
            },
            {
                id: 103,
                text: "I'm good at analyzing complex situations.",
            },
            {
                id: 104,
                text: "I easily build trust with others.",
            },
            {
                id: 105,
                text: "I prefer a tidy environment.",
            },
            {
                id: 106,
                text: "I rarely feel tense.",
            },
            {
                id: 107,
                text: "I like trying new methods.",
            },
            {
                id: 108,
                text: "I'm cautious when making decisions.",
            },
            {
                id: 109,
                text: "I'm good at managing my moods.",
            },
            {
                id: 110,
                text: "I pursue perfect performance.",
            },
            {
                id: 111,
                text: "I'm good at listening to others.",
            },
            {
                id: 112,
                text: "I am organized in doing things.",
            },
            {
                id: 113,
                text: "I am easily affected by emotions.",
            },
            {
                id: 114,
                text: "I enjoy taking on new challenges.",
            },
            {
                id: 115,
                text: "I often demonstrate leadership.",
            },
            {
                id: 116,
                text: "I am confident in my judgments.",
            },
            {
                id: 117,
                text: "I like to follow established procedures.",
            },
            {
                id: 118,
                text: "I'm good at handling interpersonal conflicts.",
            },
            {
                id: 119,
                text: "I am dedicated and focused when working.",
            },
            {
                id: 120,
                text: "I am willing to help others.",
            },
            {
                id: 121,
                text: "I am systematic in doing things.",
            },
            {
                id: 122,
                text: "I often feel peaceful inside.",
            },
            {
                id: 123,
                text: "I enjoy trying new things.",
            },
            {
                id: 124,
                text: "I am cautious when doing things.",
            },
            {
                id: 125,
                text: "I adapt easily to environmental changes.",
            },
            {
                id: 126,
                text: "I like thinking about deep issues.",
            },
            {
                id: 127,
                text: "I often stay optimistic.",
            },
            {
                id: 128,
                text: "I like to follow plans.",
            },
            {
                id: 129,
                text: "I'm good at handling unexpected situations.",
            },
            {
                id: 130,
                text: "I pay attention to details.",
            },
            {
                id: 131,
                text: "I easily understand others' feelings.",
            },
            {
                id: 132,
                text: "I like to think independently.",
            },
            {
                id: 133,
                text: "I often stay alert.",
            },
            {
                id: 134,
                text: "I'm good at controlling emotions.",
            },
            {
                id: 135,
                text: "I pursue perfectionism.",
            },
            {
                id: 136,
                text: "I often feel uneasy.",
            },
            {
                id: 137,
                text: "I enjoy challenging work.",
            },
            {
                id: 138,
                text: "I trust intuitive judgments.",
            },
            {
                id: 139,
                text: "I'm good at cooperating with others.",
            },
            {
                id: 140,
                text: "I follow rules.",
            },
            {
                id: 141,
                text: "I am full of energy.",
            },
            {
                id: 142,
                text: "I'm good at solving problems.",
            },
            {
                id: 143,
                text: "I easily open up to others.",
            },
            {
                id: 144,
                text: "I like tidiness and order.",
            },
            {
                id: 145,
                text: "I stay calm.",
            },
            {
                id: 146,
                text: "I like innovation.",
            },
            {
                id: 147,
                text: "I act with caution.",
            },
            {
                id: 148,
                text: "I am emotionally stable.",
            },
            {
                id: 149,
                text: "I pursue excellence.",
            },
            {
                id: 150,
                text: "I'm good at communication.",
            },
            {
                id: 151,
                text: "I plan things ahead.",
            },
            {
                id: 152,
                text: "I am easily influenced by the environment.",
            },
            {
                id: 153,
                text: "I like to explore.",
            },
            {
                id: 154,
                text: "I take responsibility proactively.",
            },
            {
                id: 155,
                text: "I rarely have regrets.",
            },
            {
                id: 156,
                text: "I have a regular lifestyle.",
            },
            {
                id: 157,
                text: "I'm good at handling relationships.",
            },
            {
                id: 158,
                text: "I maintain focused attention.",
            },
            {
                id: 159,
                text: "I am helpful to others.",
            },
            {
                id: 160,
                text: "I am patient.",
            },
            {
                id: 161,
                text: "I easily accept new ideas.",
            },
            {
                id: 162,
                text: "I prefer working independently.",
            },
            {
                id: 163,
                text: "I maintain alertness.",
            },
            {
                id: 164,
                text: "I can control my impulses.",
            },
            {
                id: 165,
                text: "I pursue high quality.",
            },
            {
                id: 166,
                text: "I rarely feel anxious.",
            },
            {
                id: 167,
                text: "I am creative.",
            },
            {
                id: 168,
                text: "I trust my own judgment.",
            },
            {
                id: 169,
                text: "I'm good at teamwork.",
            },
            {
                id: 170,
                text: "I follow discipline.",
            },
            {
                id: 171,
                text: "I am full of vitality.",
            },
            {
                id: 172,
                text: "I'm good at analysis.",
            },
            {
                id: 173,
                text: "I easily build trust.",
            },
            {
                id: 174,
                text: "I keep my environment tidy.",
            },
            {
                id: 175,
                text: "I maintain a peaceful mindset.",
            },
            {
                id: 176,
                text: "I am brave in innovation.",
            },
            {
                id: 177,
                text: "I think things through thoroughly.",
            },
            {
                id: 178,
                text: "I am emotionally stable.",
            },
            {
                id: 179,
                text: "I pursue progress.",
            },
            {
                id: 180,
                text: "I'm good at expression.",
            },
            {
                id: 181,
                text: "I do things in an orderly manner.",
            },
            {
                id: 182,
                text: "I am highly adaptable.",
            },
            {
                id: 183,
                text: "I am brave in exploration.",
            },
            {
                id: 184,
                text: "I am responsible.",
            },
            {
                id: 185,
                text: "I handle things calmly.",
            },
        ],
    },
    result: {
        meta: {
            title: "16PF Test Results - Detailed Analysis",
            description:
                "View your 16PF personality test results and detailed analysis",
            keywords:
                "16PF results,personality analysis,personality traits,career advice",
        },
        title: "Test Results Analysis",
        note: "The results are for reference only and should be used in conjunction with your actual situation.",
        summary: {
            title: "Overall Profile",
            description:
                "Based on your responses, we've analyzed your personality across 16 dimensions. Here are your scores and interpretations:",
        },
        factors: {
            A: {
                name: "Warmth",
                high: "Outgoing, sociable, warm",
                low: "Reserved, impersonal, distant",
            },
            B: {
                name: "Reasoning",
                high: "Abstract thinking, quick learner",
                low: "Concrete thinking, practical",
            },
            C: {
                name: "Emotional Stability",
                high: "Emotionally stable, adaptive",
                low: "Reactive, emotionally changeable",
            },
            E: {
                name: "Dominance",
                high: "Dominant, assertive, competitive",
                low: "Deferential, cooperative, avoids conflict",
            },
            F: {
                name: "Liveliness",
                high: "Lively, animated, spontaneous",
                low: "Serious, restrained, careful",
            },
            G: {
                name: "Rule-Consciousness",
                high: "Rule-conscious, dutiful",
                low: "Expedient, nonconforming",
            },
            H: {
                name: "Social Boldness",
                high: "Socially bold, venturesome",
                low: "Shy, threat-sensitive, timid",
            },
            I: {
                name: "Sensitivity",
                high: "Sensitive, aesthetic, sentimental",
                low: "Utilitarian, objective, unsentimental",
            },
            L: {
                name: "Vigilance",
                high: "Vigilant, suspicious, skeptical",
                low: "Trusting, accepting, unsuspecting",
            },
            M: {
                name: "Abstractedness",
                high: "Abstract, imaginative, idealistic",
                low: "Grounded, practical, solution-oriented",
            },
            N: {
                name: "Privateness",
                high: "Private, discreet, non-disclosing",
                low: "Forthright, genuine, artless",
            },
            O: {
                name: "Apprehension",
                high: "Apprehensive, self-doubting",
                low: "Self-assured, unworried, complacent",
            },
            Q1: {
                name: "Openness to Change",
                high: "Open to change, experimental",
                low: "Traditional, attached to familiar",
            },
            Q2: {
                name: "Self-Reliance",
                high: "Self-reliant, solitary",
                low: "Group-oriented, affiliative",
            },
            Q3: {
                name: "Perfectionism",
                high: "Perfectionistic, organized",
                low: "Tolerates disorder, flexible",
            },
            Q4: {
                name: "Tension",
                high: "Tense, driven, frustrated",
                low: "Relaxed, placid, patient",
            },
        },
        analysis: {
            title: "Personality Analysis",
            sections: {
                personality: {
                    title: "Core Personality Traits",
                    content:
                        "Based on your test results, your personality shows the following characteristics:",
                },
                strengths: {
                    title: "Personal Strengths",
                    content: "Your main strengths include:",
                },
                weaknesses: {
                    title: "Areas for Development",
                    content: "Consider improving in the following areas:",
                },
            },
            format: {
                highFactors: "You show outstanding performance in {factors}.",
                lowFactors: "Areas that need improvement include {factors}.",
                noStrengths: "No significant strengths identified",
                noWeaknesses: "No significant areas for improvement",
                weaknessFormat: "Improve performance in {factor}",
                delimiter: ", ",
                noPersonality: "No significant personality traits identified",
            },
        },
        suggestions: {
            title: "Personalized Recommendations",
            career: {
                title: "Career Development Suggestions",
                content:
                    "Based on your personality traits, the following career paths might suit you:",
                empty: "No career suggestions available",
                A: "With high warmth, you are suited for jobs involving interpersonal interactions, such as: sales, teaching, HR, etc.",
                B: "With high reasoning, you are suited for analytical work, such as: research, planning, consulting, etc.",
                C: "With high emotional stability, you are suited for high-pressure jobs, such as: management, crisis handling, healthcare, etc.",
                E: "With high dominance, you are suited for leadership roles, such as: business management, project leadership, etc.",
                F: "With high liveliness, you are suited for creative and dynamic work, such as: marketing, PR, arts, etc.",
                G: "With high rule-consciousness, you are suited for jobs requiring precision, such as: finance, law, quality control, etc.",
                H: "With high social boldness, you are suited for risk-taking roles, such as: entrepreneurship, investment, product development, etc.",
                I: "With high sensitivity, you are suited for jobs requiring empathy, such as: artistic creation, counseling, design, etc.",
                L: "With high vigilance, you are suited for jobs requiring scrutiny, such as: auditing, investigation, assessment, etc.",
                M: "With high abstractedness, you are suited for creative work, such as: advertising, literary creation, etc.",
                N: "With high privateness, you are suited for diplomatic roles, such as: negotiation, public relations, etc.",
                O: "With high apprehension, you are suited for detail-oriented work, such as: research, editing, quality inspection, etc.",
                Q1: "With high openness to change, you are suited for innovative work, such as: R&D, innovation management, etc.",
                Q2: "With high self-reliance, you are suited for independent work, such as: academic research, independent consulting, etc.",
                Q3: "With high perfectionism, you are suited for jobs requiring discipline, such as: project management, quality control, etc.",
                Q4: "With high tension, you are suited for challenging work, such as: competitive industries, entrepreneurship, etc.",
            },
            relationship: {
                title: "Interpersonal Relationship Advice",
                content: "In your relationships, you might want to:",
                empty: "No relationship suggestions available",
                A: "Your social skills are strong. Consider participating in group activities",
                B: "Your quick thinking can make you a valuable advisor in teams",
                C: "Your emotional stability can help maintain team harmony",
                E: "Your leadership qualities can help you take on more responsibilities",
                F: "Your liveliness can help energize social situations",
                G: "Your rule-consciousness can help establish team standards",
                H: "Your social boldness allows you to initiate conversations",
                I: "Your sensitivity makes you a good emotional mediator",
                L: "Your vigilance can help the team avoid risks",
                M: "Your imagination can bring innovative ideas to the team",
                N: "Your social astuteness makes you a good team communicator",
                O: "Your attention to detail can help perfect team projects",
                Q1: "Your openness can bring fresh perspectives to the team",
                Q2: "Your self-reliance helps you complete independent tasks",
                Q3: "Your discipline can help improve team efficiency",
                Q4: "Your drive can motivate team members",
            },
            growth: {
                title: "Personal Growth Suggestions",
                content: "For better personal development, consider:",
                empty: "No personal growth suggestions available",
                A: "Enhance your interpersonal skills through social activities",
                B: "Improve your thinking abilities through reading and learning",
                C: "Develop emotional management through meditation and exercise",
                E: "Enhance decision-making by taking leadership roles",
                F: "Develop expressiveness through creative activities",
                G: "Improve execution through planning and organization",
                H: "Build confidence by trying new things",
                I: "Cultivate sensitivity through artistic activities",
                L: "Build trust through team collaboration",
                M: "Develop imagination through creative exercises",
                N: "Improve social skills through practical interaction",
                O: "Reduce anxiety through relaxation practices",
                Q1: "Broaden horizons by exploring new things",
                Q2: "Practice independence through solo projects",
                Q3: "Enhance self-discipline through goal management",
                Q4: "Improve stress management through relevant techniques",
            },
            format: {
                item: "{name}: {description}",
                growthAdvice: "Suggested to strengthen development in {factor}",
            },
        },
        share: {
            title: "Share Results",
            buttons: {
                download: "Download PDF Report",
                share: "Share with Friends",
            },
        },
    },
};
