export default {
    common: {
        scanQRCode: "Scan QR Code",
        scanQRCodeTip: "Scan QR code with your mobile phone",
        loading: "Loading...",
        test: {
            title: "Before You Start:",
        },
        buttons: {
            startTest: "Start Test",
            viewTypes: "View Personality Types",
            learnMore: "Learn More",
            backHome: "Back to Home",
            reTakeTest: "ReTake Test",
        },
        notFound: {
            meta: {
                title: "404 Page Not Found - Professional Psychological Assessment Center",
                description:
                    "The page you are looking for does not exist. Please check the URL or return to our homepage.",
                keywords:
                    "404,page not found,error page,psychological assessment,personality test",
            },
            title: "Page Not Found",
            message: "Sorry, the page you are looking for does not exist.",
        },
        progress: {
            question: "Question {current}/{total}",
            percentage: "{percent}%",
        },
        result: {
            tips: "Test results are for reference only, each person is unique, and the results may vary at different times, locations, and situations",
            error: "Sorry, an error occurred while loading the results",
        },
    },
    home: {
        title: "Psychological Assessment Center",
        subtitle:
            "Professional psychological assessment platform to discover your true self",
        categories: {
            popular: "Popular Tests",
            career: "Career Assessment",
            traits: "Trait Assessment",
        },
        tests: {
            mbti: {
                title: "MBTI Personality Test",
                brief: "Analyze your personality through 16 types and guide your career planning",
                tags: ["Personality", "Career Planning"],
            },
            bigFive: {
                title: "Big Five Personality Test",
                brief: "Science-based comprehensive personality assessment widely used in psychological research",
                tags: ["Comprehensive Assessment", "Research"],
            },
            enneagram: {
                title: "Enneagram Test",
                brief: "Explore your personality motivations and behavior patterns to improve relationships",
                tags: ["Relationships", "Psychological Exploration"],
            },
            disc: {
                title: "DISC Behavioral Style",
                brief: "Assess your behavioral style to enhance workplace communication and team collaboration",
                tags: ["Workplace Communication", "Team Collaboration"],
            },
            holland: {
                title: "Holland Career Interest Test",
                brief: "Help you choose suitable majors and plan your future career path",
                tags: ["Major Selection", "Career Planning"],
            },
            "16pf": {
                title: "16PF Personality Test",
                brief: "Analyze your personality traits through 16 detailed dimensions",
                tags: ["Detailed Personality Analysis"],
            },
            hsp: {
                title: "Highly Sensitive Person Test",
                brief: "Evaluate your emotional sensitivity and learn to manage sensitive traits",
                tags: ["Emotional Sensitivity Assessment"],
            },
            trueColors: {
                title: "True Colors Personality Test",
                brief: "Analyze personality through color preferences to improve personal and team collaboration",
                tags: ["Personal & Team Dynamics"],
            },
            attachment: {
                title: "Attachment Style Test",
                brief: "Understand your attachment patterns in intimate relationships",
                tags: ["Intimate Relationship Patterns"],
            },
        },
        meta: {
            title: "Professional Psychological Assessment Center - Comprehensive Testing Platform",
            description:
                "Offering professional psychological assessments including MBTI, Big Five, Enneagram, and more to help you better understand yourself.",
            keywords:
                "psychological test,personality test,career assessment,MBTI,Big Five,Enneagram,DISC,Holland,16PF,HSP,True Colors,Attachment Style",
        },
    },
    noResults: "No Test Results",
    pleaseCompleteTest: "Please complete the test first",
};
