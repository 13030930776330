import { createRouter, createWebHistory } from "vue-router";

const routes = [
    {
        path: "/",
        name: "Home",
        component: () => import("../views/Home.vue"),
    },
    {
        path: "/mbti",
        name: "MBTI",
        component: () => import("../views/mbti/Index.vue"),
    },
    {
        path: "/mbti/test",
        name: "Test",
        component: () => import("../views/mbti/Test.vue"),
    },
    {
        path: "/mbti/result",
        name: "Result",
        component: () => import("../views/mbti/Result.vue"),
    },
    {
        path: "/mbti/types",
        name: "PersonalityTypes",
        component: () => import("../views/mbti/Types.vue"),
    },
    {
        path: "/mbti/type/:code",
        name: "PersonalityDetail",
        component: () => import("../views/mbti/Detail.vue"),
    },
    {
        path: "/mbti/introduction",
        name: "MBTIIntroduction",
        component: () => import("../views/mbti/Introduction.vue"),
    },
    {
        path: "/bigfive",
        name: "BigFive",
        component: () => import("../views/bigfive/Index.vue"),
    },
    {
        path: "/bigfive/introduction",
        name: "BigFiveIntroduction",
        component: () => import("../views/bigfive/Introduction.vue"),
    },
    {
        path: "/bigfive/types",
        name: "BigFiveTypes",
        component: () => import("../views/bigfive/Types.vue"),
    },
    {
        path: "/bigfive/test",
        name: "BigFiveTest",
        component: () => import("../views/bigfive/Test.vue"),
    },
    {
        path: "/bigfive/result",
        name: "BigFiveResult",
        component: () => import("../views/bigfive/Result.vue"),
    },
    {
        path: "/disc",
        name: "DISC",
        component: () => import("../views/disc/Index.vue"),
    },
    {
        path: "/disc/introduction",
        name: "DISCIntroduction",
        component: () => import("../views/disc/Introduction.vue"),
    },
    {
        path: "/disc/types",
        name: "DISCType",
        component: () => import("../views/disc/Types.vue"),
    },
    {
        path: "/disc/test",
        name: "DISCTest",
        component: () => import("../views/disc/Test.vue"),
    },
    {
        path: "/disc/result",
        name: "DISCResult",
        component: () => import("../views/disc/Result.vue"),
    },
    {
        path: "/holland",
        name: "Holland",
        component: () => import("../views/holland/Index.vue"),
    },
    {
        path: "/holland/introduction",
        name: "HollandIntroduction",
        component: () => import("../views/holland/Introduction.vue"),
    },
    {
        path: "/holland/types",
        name: "HollandType",
        component: () => import("../views/holland/Types.vue"),
    },
    {
        path: "/holland/test",
        name: "HollandTest",
        component: () => import("../views/holland/Test.vue"),
    },
    {
        path: "/holland/result",
        name: "HollandResult",
        component: () => import("../views/holland/Result.vue"),
    },
    {
        path: "/enneagram",
        name: "Enneagram",
        component: () => import("../views/enneagram/Index.vue"),
    },
    {
        path: "/enneagram/introduction",
        name: "EnneagramIntroduction",
        component: () => import("../views/enneagram/Introduction.vue"),
    },
    {
        path: "/enneagram/type/:type",
        name: "EnneagramTypeDetail",
        component: () => import("../views/enneagram/Detail.vue"),
    },
    {
        path: "/enneagram/test",
        name: "EnneagramTest",
        component: () => import("../views/enneagram/Test.vue"),
    },
    {
        path: "/enneagram/result",
        name: "EnneagramResult",
        component: () => import("../views/enneagram/Result.vue"),
    },
    {
        path: "/16pf",
        name: "16PF",
        component: () => import("../views/16pf/Index.vue"),
    },
    {
        path: "/16pf/introduction",
        name: "16PFIntroduction",
        component: () => import("../views/16pf/Introduction.vue"),
    },
    {
        path: "/16pf/test",
        name: "16PFTest",
        component: () => import("../views/16pf/Test.vue"),
    },
    {
        path: "/16pf/result",
        name: "16PFResult",
        component: () => import("../views/16pf/Result.vue"),
    },
    {
        path: "/hsp",
        name: "HSP",
        component: () => import("../views/hsp/Index.vue"),
    },
    {
        path: "/hsp/introduction",
        name: "HSPIntroduction",
        component: () => import("../views/hsp/Introduction.vue"),
    },
    {
        path: "/hsp/test",
        name: "HSPTest",
        component: () => import("../views/hsp/Test.vue"),
    },
    {
        path: "/hsp/result",
        name: "HSPResult",
        component: () => import("../views/hsp/Result.vue"),
    },
    {
        path: "/truecolors",
        name: "TrueColors",
        component: () => import("../views/truecolors/Index.vue"),
    },
    {
        path: "/truecolors/introduction",
        name: "TrueColorsIntroduction",
        component: () => import("../views/truecolors/Introduction.vue"),
    },
    {
        path: "/truecolors/types/:id",
        name: "TrueColorsDetail",
        component: () => import("../views/truecolors/Detail.vue"),
    },
    {
        path: "/truecolors/test",
        name: "TrueColorsTest",
        component: () => import("../views/truecolors/Test.vue"),
    },
    {
        path: "/truecolors/result",
        name: "TrueColorsResult",
        component: () => import("../views/truecolors/Result.vue"),
    },
    {
        path: "/attachment",
        name: "Attachment",
        component: () => import("../views/attachment/Index.vue"),
    },
    {
        path: "/attachment/introduction",
        name: "AttachmentIntroduction",
        component: () => import("../views/attachment/Introduction.vue"),
    },
    {
        path: "/attachment/types",
        name: "AttachmentTypes",
        component: () => import("../views/attachment/Types.vue"),
    },
    {
        path: "/attachment/test",
        name: "AttachmentTest",
        component: () => import("../views/attachment/Test.vue"),
    },
    {
        path: "/attachment/result",
        name: "AttachmentResult",
        component: () => import("../views/attachment/Result.vue"),
    },
    {
        path: "/:pathMatch(.*)*",
        name: "NotFound",
        component: () => import("../views/NotFound.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { top: 0 };
    },
});

router.beforeEach((to, from, next) => {
    if (to.name === "NotFound") {
        console.warn(`Route not found: ${to.fullPath}`);
    }
    next();
});

export default router;
