export default {
    index: {
        meta: {
            title: "Attachment Style Test - Understand Your Relationship Patterns",
            description:
                "Discover your attachment style through this scientific test and understand how it affects your relationships and emotional expressions.",
            keywords:
                "attachment style,attachment theory,relationships,psychology test,secure attachment,anxious attachment,avoidant attachment",
        },
        title: "Attachment Style Test",
        subtitle:
            "Explore your attachment patterns and understand your behavior in relationships",
        tips: {
            list: [
                "Welcome to the Attachment Style Test! Through this test, you will understand your attachment style and behavioral patterns in intimate relationships.",
                "The Attachment Style Test will help you identify your emotional needs in relationships and how you form deep emotional connections with others.",
                "Please answer based on your genuine experiences in forming relationships. The results will offer valuable insights into your attachment style.",
                "This test is for self-exploration purposes and is not intended to impact your life or relationships directly. It provides insight into your attachment style.",
            ],
        },
        buttons: {
            whatIsAttachment: "What is Attachment Style",
        },
    },
    introduction: {
        meta: {
            title: "Introduction to Attachment Theory - Understanding Different Attachment Styles",
            description:
                "Learn about the core concepts of attachment theory and how different attachment styles influence relationships.",
            keywords:
                "attachment theory,attachment styles,secure attachment,anxious attachment,avoidant attachment,relationships",
        },
        title: "Attachment Theory Introduction",
        sections: [
            {
                title: "What is Attachment Theory",
                content: [
                    "Attachment Theory, developed by British psychologist John Bowlby, describes how people form emotional bonds with others.",
                    "Originally used to explain the emotional bond between infants and caregivers, it has been extended to adult relationships.",
                    "Attachment patterns formed in early childhood influence our future relationships, emotional expression, and handling of intimate relationships.",
                ],
            },
            {
                title: "Formation of Attachment Styles",
                content: [
                    "Attachment styles primarily develop through early interactions with primary caregivers.",
                    "Early attachment experiences form internal working models that affect our basic understanding of self and others.",
                    "These patterns often persist into adulthood, influencing our intimate relationships and social interactions.",
                ],
            },
            {
                title: "Main Attachment Styles",
                content: [
                    "Secure Attachment: Comfortable with intimacy and able to form healthy emotional connections.",
                    "Anxious Attachment: Feels insecure about relationships, fears abandonment, needs frequent reassurance.",
                    "Avoidant Attachment: Tends to maintain emotional distance, struggles with deep intimate relationships.",
                    "Disorganized Attachment: Shows contradictory behavior patterns in intimate relationships.",
                ],
            },
            {
                title: "Impact of Attachment Styles",
                content: [
                    "Attachment styles influence how we express emotions, handle conflicts, and maintain relationship quality.",
                    "Understanding your attachment style can help improve interpersonal relationships and emotional well-being.",
                    "Attachment patterns can be changed through self-awareness and effort.",
                ],
            },
            {
                title: "Applications",
                content: [
                    "Self-awareness: Helps individuals understand their behavioral patterns in intimate relationships and identify their attachment style and emotional needs.",
                    "Emotional growth: By recognizing attachment styles, individuals can better manage their emotional needs in relationships, promoting personal emotional development.",
                    "Relationship improvement: Understanding attachment styles can help improve existing interpersonal relationships, reduce emotional conflicts, and enhance emotional connection quality.",
                    "Psychological counseling and therapy: Provides therapists with foundational information about an individual’s emotional needs, useful for addressing attachment-related issues.",
                    "Emotional support: Helps individuals identify blind spots in their emotional relationships and foster a healthier emotional support system.",
                ],
            },
            {
                title: "Important Notes",
                content: [
                    "Answer honestly: Please answer based on your true feelings in emotional relationships, without idealizing or over-modifying your answers.",
                    "Understand attachment styles: Attachment styles are personalized analyses of emotional needs and do not represent all personality traits.",
                    "Consider multiple factors: Attachment styles can be influenced by life experiences, cultural backgrounds, and other factors, so the results should be understood in context.",
                    "Emotional development: Attachment styles may change over time due to personal growth and life experiences. Regularly reflecting on your emotional needs and attachment style is beneficial.",
                    "Seek professional help: If the results suggest emotional challenges or attachment issues, consider seeking the assistance of a psychological counselor for deeper self-exploration and growth.",
                ],
            },
        ],
    },
    test: {
        meta: {
            title: "Attachment Style Test",
            description: "Discover your attachment style through 36 questions",
            keywords: "attachment style test,relationship test,ECR scale",
        },
        title: "Attachment Style Test",
        options: [
            { value: 1, text: "Strongly Disagree" },
            { value: 2, text: "Disagree" },
            { value: 3, text: "Slightly Disagree" },
            { value: 4, text: "Neutral" },
            { value: 5, text: "Slightly Agree" },
            { value: 6, text: "Agree" },
            { value: 7, text: "Strongly Agree" },
        ],
        questions: [
            {
                question:
                    "I worry about being abandoned by my romantic partners",
                dimension: "anxiety",
            },
            {
                question: "I find it difficult to open up to romantic partners",
                dimension: "avoidance",
            },
            {
                question:
                    "I often worry that my partner doesn't really love me",
                dimension: "anxiety",
            },
            {
                question:
                    "I feel uncomfortable being close to romantic partners",
                dimension: "avoidance",
            },
            {
                question: "I find it difficult to trust partners completely",
                dimension: "anxiety",
            },
            {
                question:
                    "It's hard for me to express my true feelings to my partner",
                dimension: "avoidance",
            },
            {
                question:
                    "I often wish that my partner's feelings for me were as strong as my feelings for them",
                dimension: "anxiety",
            },
            {
                question:
                    "I don't like sharing my personal thoughts and feelings with my partner",
                dimension: "avoidance",
            },
            {
                question: "I often worry about my partner leaving me",
                dimension: "anxiety",
            },
            {
                question:
                    "I find it difficult to allow myself to depend on romantic partners",
                dimension: "avoidance",
            },
            {
                question:
                    "I worry that romantic partners won't care about me as much as I care about them",
                dimension: "anxiety",
            },
            {
                question: "I prefer not to show a partner how I feel deep down",
                dimension: "avoidance",
            },
            {
                question: "I pull away when partners start getting close to me",
                dimension: "avoidance",
            },
            { question: "I worry about being alone", dimension: "anxiety" },
            {
                question:
                    "I am uncomfortable sharing private thoughts with my partner",
                dimension: "avoidance",
            },
            {
                question: "I get anxious when I don't get attention",
                dimension: "anxiety",
            },
            {
                question: "I find it difficult to depend on romantic partners",
                dimension: "avoidance",
            },
            {
                question: "I need a lot of reassurance that I am loved",
                dimension: "anxiety",
            },
            {
                question: "It's hard for me to lean on romantic partners",
                dimension: "avoidance",
            },
            {
                question: "I easily become emotionally attached to partners",
                dimension: "anxiety",
            },
            {
                question: "I rarely discuss my problems with my partner",
                dimension: "avoidance",
            },
            {
                question:
                    "I feel frustrated if partners are not available when I need them",
                dimension: "anxiety",
            },
            {
                question: "I try to avoid getting too close to my partner",
                dimension: "avoidance",
            },
            {
                question: "I feel insecure when my partner is away from me",
                dimension: "anxiety",
            },
            { question: "I don't rely much on others", dimension: "avoidance" },
            {
                question:
                    "When I need my partner's support, I feel they won't be there",
                dimension: "anxiety",
            },
            {
                question: "I prefer maintaining emotional distance",
                dimension: "avoidance",
            },
            {
                question: "I often wish I could be closer to others",
                dimension: "anxiety",
            },
            {
                question: "I don't like to show that I need my partner",
                dimension: "avoidance",
            },
            {
                question: "I'm afraid of losing my partner's love",
                dimension: "anxiety",
            },
            {
                question: "I'm not comfortable letting others comfort me",
                dimension: "avoidance",
            },
            {
                question: "I worry that I'm not good enough",
                dimension: "anxiety",
            },
            {
                question: "I prefer to maintain independence in relationships",
                dimension: "avoidance",
            },
            {
                question: "I often worry about being rejected",
                dimension: "anxiety",
            },
            {
                question: "I don't like it when others depend too much on me",
                dimension: "avoidance",
            },
            {
                question:
                    "I need my partner to constantly confirm their love for me",
                dimension: "anxiety",
            },
        ],
        scale: {
            disagree: "Strongly Disagree",
            agree: "Strongly Agree",
        },
    },
    types: {
        meta: {
            title: "Attachment Styles - Understanding the Four Main Types",
            description:
                "Learn about secure, anxious, avoidant, and disorganized attachment styles, their characteristics, causes, and impacts",
            keywords:
                "attachment styles,secure attachment,anxious attachment,avoidant attachment,disorganized attachment,relationship patterns",
        },
        title: "Understanding Attachment Styles",
        subtitle: "Explore the characteristics of different attachment styles",
        characteristics: "Key Characteristics",
        formation: "Formation",
        types: [
            {
                id: "secure",
                name: "Secure Attachment",
                shortDesc:
                    "Able to maintain healthy closeness and independence in relationships",
                characteristics: [
                    "Positive view of self and others",
                    "Comfortable and secure in relationships",
                    "Able to form and maintain healthy intimate relationships",
                    "Effective in handling conflicts and communication",
                    "Neither overly dependent nor avoidant",
                ],
                formation:
                    "Received consistent and reliable care and emotional support in childhood",
                relationships: {
                    title: "In Relationships",
                    points: [
                        "Good at expressing emotions and needs",
                        "Able to trust and depend on partners",
                        "Balance between intimacy and independence",
                        "Willing to seek help when needed",
                        "Can give partners appropriate space",
                    ],
                },
                improvement: {
                    title: "Strengths",
                    points: [
                        "Healthy emotional expression",
                        "Stable relationships",
                        "Clear self-awareness",
                        "Effective conflict resolution",
                        "Strong psychological resilience",
                    ],
                },
            },
            {
                id: "anxious",
                name: "Anxious Attachment",
                shortDesc:
                    "Excessive need for reassurance and security in relationships",
                characteristics: [
                    "Insecure about relationships",
                    "Excessive need for approval and attention",
                    "Fear of abandonment or rejection",
                    "Tendency to be overly dependent",
                    "Highly sensitive to partner's responses",
                ],
                formation:
                    "Experienced inconsistent emotional responses in childhood",
                relationships: {
                    title: "In Relationships",
                    points: [
                        "Frequently seeking reassurance",
                        "Over-interpreting partner's behaviors",
                        "Difficulty with separation",
                        "Prone to dependency",
                        "Emotional volatility",
                    ],
                },
                improvement: {
                    title: "Improvement Suggestions",
                    points: [
                        "Develop self-worth",
                        "Learn independence",
                        "Practice emotional regulation",
                        "Establish healthy boundaries",
                        "Develop personal interests",
                    ],
                },
            },
            {
                id: "avoidant",
                name: "Avoidant Attachment",
                shortDesc:
                    "Tends to maintain emotional distance in relationships",
                characteristics: [
                    "Difficulty with intimate relationships",
                    "Emphasis on personal independence",
                    "Reluctant to express emotional needs",
                    "Maintains distance in relationships",
                    "Avoids emotional dependency",
                ],
                formation:
                    "Experienced emotional neglect or unresponsiveness in childhood",
                relationships: {
                    title: "In Relationships",
                    points: [
                        "Difficulty expressing emotions",
                        "Avoids intimate contact",
                        "Values personal space highly",
                        "Reluctant to depend on others",
                        "Tends to withdraw under stress",
                    ],
                },
                improvement: {
                    title: "Improvement Suggestions",
                    points: [
                        "Learn to express emotions",
                        "Gradually accept intimacy",
                        "Develop empathy",
                        "Practice vulnerability",
                        "Seek professional help",
                    ],
                },
            },
            {
                id: "disorganized",
                name: "Disorganized Attachment",
                shortDesc:
                    "Shows contradictory behavior patterns in relationships",
                characteristics: [
                    "Both craves and fears intimacy",
                    "Inconsistent behavior patterns",
                    "Difficulty regulating emotions",
                    "Unstable relationships",
                    "Unclear self-perception",
                ],
                formation:
                    "Experienced trauma or severe emotional neglect in childhood",
                relationships: {
                    title: "In Relationships",
                    points: [
                        "Chaotic relationship patterns",
                        "Intense emotional reactions",
                        "Difficulty building trust",
                        "Confusion in intimate relationships",
                        "Prone to conflicts",
                    ],
                },
                improvement: {
                    title: "Improvement Suggestions",
                    points: [
                        "Seek therapy",
                        "Build sense of security",
                        "Learn emotion management",
                        "Develop stable relationships",
                        "Foster self-awareness",
                    ],
                },
            },
        ],
    },
    result: {
        meta: {
            title: "Attachment Style Test Results - Understanding Your Pattern",
            description:
                "View your attachment style test results and understand your behavior in relationships",
            keywords:
                "attachment style results,attachment test,relationships,psychology test results,personal insights",
        },
        title: "Your Attachment Style Results",
        subtitle: "Based on your answers, here's what we found",
        dimensions: {
            anxiety: {
                title: "Attachment Anxiety",
                high: "High",
                medium: "Medium",
                low: "Low",
                description: {
                    high: "You tend to worry about abandonment and need frequent reassurance",
                    medium: "You have a moderate level of relationship anxiety",
                    low: "You feel relatively secure in relationships",
                },
            },
            avoidance: {
                title: "Attachment Avoidance",
                high: "High",
                medium: "Medium",
                low: "Low",
                description: {
                    high: "You tend to maintain emotional distance and struggle with intimacy",
                    medium: "You have a moderate level of avoidance in relationships",
                    low: "You're comfortable with emotional intimacy",
                },
            },
        },
        attachmentType: {
            title: "Your Primary Attachment Style",
            secure: {
                title: "Secure Attachment",
                description:
                    "You feel comfortable with intimacy and independence in relationships",
            },
            anxious: {
                title: "Anxious Attachment",
                description:
                    "You often seek reassurance and worry about relationship security",
            },
            avoidant: {
                title: "Avoidant Attachment",
                description:
                    "You tend to maintain emotional distance and struggle with intimacy",
            },
            disorganized: {
                title: "Disorganized Attachment",
                description:
                    "You show mixed patterns of both anxiety and avoidance in relationships",
            },
        },
        scores: {
            title: "Detailed Scores",
            anxiety: "Anxiety Score",
            avoidance: "Avoidance Score",
            outOf: "out of {total}",
        },
        suggestions: {
            title: "Improvement Suggestions",
            secure: [
                "Continue maintaining healthy relationship patterns",
                "Help others develop secure attachments",
                "Maintain positive communication during difficult times",
            ],
            anxious: [
                "Develop self-worth independent of others' approval",
                "Build independence and personal interests",
                "Practice emotional regulation",
                "Establish healthy boundaries",
            ],
            avoidant: [
                "Practice expressing emotional needs",
                "Learn to build deeper connections",
                "Accept vulnerability in relationships",
                "Develop empathy and emotional expression",
            ],
            disorganized: [
                "Consider seeking professional counseling",
                "Learn emotional management techniques",
                "Gradually build security",
                "Develop stable social support systems",
            ],
        },
        buttons: {
            retake: "Retake Test",
            share: "Share Results",
            learnMore: "Learn More",
        },
        share: {
            title: "Share Your Attachment Style Results",
            text: "My attachment style is {type}, with {anxiety}% anxiety and {avoidance}% avoidance. Take the test to discover yours!",
            success: "Share link copied to clipboard",
            error: "Failed to share, please try again",
        },
    },
};
